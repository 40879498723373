import { createEnumParam } from 'use-query-params'
import { z } from 'zod'
import { type I18nId } from '@/i18n/config'

export const defaultPriorityCountries = [
  { value: 'tw', countryCallingCode: '+886', currency: 'TWD' },
  { value: 'hk', countryCallingCode: '+852', currency: 'HKD' },
  { value: 'jp', countryCallingCode: '+81', currency: 'JPY' },
  { value: 'cn', countryCallingCode: '+86', currency: 'CNY' },
  { value: 'my', countryCallingCode: '+60', currency: 'MYR' },
  { value: 'sg', countryCallingCode: '+65', currency: 'SGD' },
  { value: 'th', countryCallingCode: '+66', currency: 'THB' },
  { value: 'vn', countryCallingCode: '+84', currency: 'VND' },
  { value: 'kr', countryCallingCode: '+82', currency: 'KRW' },
] as const

// 根據 ISO 3166-2 產生全部國家
// ref: https://docs.google.com/spreadsheets/d/18JbCi4uvwDplnCOY-UJ5LVPXUXGlnckEqqL9SQcJI7M/edit?gid=2080513620#gid=2080513620
export const allCountries = [
  { value: 'tw', countryCallingCode: '+886', currency: 'TWD' },
  { value: 'jp', countryCallingCode: '+81', currency: 'JPY' },
  { value: 'hk', countryCallingCode: '+852', currency: 'HKD' },
  { value: 'my', countryCallingCode: '+60', currency: 'MYR' },
  { value: 'th', countryCallingCode: '+66', currency: 'THB' },
  { value: 'vn', countryCallingCode: '+84', currency: 'VND' },
  { value: 'sg', countryCallingCode: '+65', currency: 'SGD' },
  { value: 'kr', countryCallingCode: '+82', currency: 'KRW' },
  { value: 'af', countryCallingCode: '+93', currency: 'AFN' },
  {
    value: 'ax',
    countryCallingCode: '+358',
    currency: 'EUR',
  },
  {
    value: 'al',
    countryCallingCode: '+355',
    currency: 'ALL',
  },
  {
    value: 'dz',
    countryCallingCode: '+213',
    currency: 'DZD',
  },
  {
    value: 'as',
    countryCallingCode: '+1684',
    currency: 'USD',
  },
  { value: 'ad', countryCallingCode: '+376', currency: 'EUR' },
  { value: 'ao', countryCallingCode: '+244', currency: 'AOA' },
  { value: 'ai', countryCallingCode: '+1264', currency: 'XCD' },
  { value: 'aq', countryCallingCode: '', currency: '' },
  {
    value: 'ag',
    countryCallingCode: '+1268',
    currency: 'XCD',
  },
  { value: 'ar', countryCallingCode: '+54', currency: 'ARS' },
  {
    value: 'am',
    countryCallingCode: '+374',
    currency: 'AMD',
  },
  { value: 'aw', countryCallingCode: '+297', currency: 'AWG' },
  { value: 'au', countryCallingCode: '+61', currency: 'AUD' },
  { value: 'at', countryCallingCode: '+43', currency: 'EUR' },
  {
    value: 'az',
    countryCallingCode: '+994',
    currency: 'AZN',
  },
  { value: 'bs', countryCallingCode: '+1242', currency: 'BSD' },
  { value: 'bh', countryCallingCode: '+973', currency: 'BHD' },
  { value: 'bd', countryCallingCode: '+880', currency: 'BDT' },
  { value: 'bb', countryCallingCode: '+1246', currency: 'BBD' },
  {
    value: 'by',
    countryCallingCode: '+375',
    currency: 'BYN',
  },
  { value: 'be', countryCallingCode: '+32', currency: 'EUR' },
  { value: 'bz', countryCallingCode: '+501', currency: 'BZD' },
  { value: 'bj', countryCallingCode: '+229', currency: 'XOF' },
  { value: 'bm', countryCallingCode: '+1441', currency: 'BMD' },
  { value: 'bt', countryCallingCode: '+975', currency: 'BTN' },
  {
    value: 'bo',
    countryCallingCode: '+591',
    currency: 'BOB',
  },
  { value: 'bq', countryCallingCode: '+599', currency: 'USD' },
  {
    value: 'ba',
    countryCallingCode: '+387',
    currency: 'BAM',
  },
  { value: 'bw', countryCallingCode: '+267', currency: 'BWP' },
  { value: 'bv', countryCallingCode: '', currency: 'NOK' },
  { value: 'br', countryCallingCode: '+55', currency: 'BRL' },
  {
    value: 'io',
    countryCallingCode: '+246',
    currency: 'INR',
  },
  { value: 'bn', countryCallingCode: '+673', currency: 'BND' },
  {
    value: 'bg',
    countryCallingCode: '+359',
    currency: 'BGN',
  },
  {
    value: 'bf',
    countryCallingCode: '+226',
    currency: 'XOF',
  },
  { value: 'bi', countryCallingCode: '+257', currency: 'BIF' },
  { value: 'cv', countryCallingCode: '+238', currency: 'CVE' },
  { value: 'kh', countryCallingCode: '+855', currency: 'KHR' },
  { value: 'cm', countryCallingCode: '+237', currency: 'XAF' },
  { value: 'ca', countryCallingCode: '+1', currency: 'CAD' },
  {
    value: 'ky',
    countryCallingCode: '+1345',
    currency: 'KYD',
  },
  {
    value: 'cf',
    countryCallingCode: '+236',
    currency: 'XAF',
  },
  { value: 'td', countryCallingCode: '+235', currency: 'XAF' },
  { value: 'cl', countryCallingCode: '+56', currency: 'CLP' },
  { value: 'cn', countryCallingCode: '+86', currency: 'CNY' },
  { value: 'cx', countryCallingCode: '+61', currency: 'AUD' },
  {
    value: 'cc',
    countryCallingCode: '+61',
    currency: 'AUD',
  },
  { value: 'co', countryCallingCode: '+57', currency: 'COP' },
  { value: 'km', countryCallingCode: '+269', currency: 'KMF' },
  {
    value: 'cg',
    countryCallingCode: '+242',
    currency: 'XAF',
  },
  {
    value: 'cd',
    countryCallingCode: '+243',
    currency: 'CDF',
  },
  {
    value: 'ck',
    countryCallingCode: '+682',
    currency: 'CKD',
  },
  {
    value: 'cr',
    countryCallingCode: '+506',
    currency: 'CRC',
  },
  {
    value: 'ci',
    countryCallingCode: '+225',
    currency: 'XOF',
  },
  {
    value: 'hr',
    countryCallingCode: '+385',
    currency: 'HRK',
  },
  { value: 'cu', countryCallingCode: '+53', currency: 'CUP' },
  { value: 'cw', countryCallingCode: '+599', currency: 'ANG' },
  {
    value: 'cy',
    countryCallingCode: '+357',
    currency: 'EUR',
  },
  { value: 'cz', countryCallingCode: '+420', currency: 'CZK' },
  { value: 'dk', countryCallingCode: '+45', currency: 'DKK' },
  { value: 'dj', countryCallingCode: '+253', currency: 'DJF' },
  {
    value: 'dm',
    countryCallingCode: '+1767',
    currency: 'XCD',
  },
  {
    value: 'do',
    countryCallingCode: '+1809',
    currency: 'DOP',
  },
  { value: 'ec', countryCallingCode: '+593', currency: 'USD' },
  { value: 'eg', countryCallingCode: '+20', currency: 'EGP' },
  {
    value: 'sv',
    countryCallingCode: '+503',
    currency: 'USD',
  },
  {
    value: 'gq',
    countryCallingCode: '+240',
    currency: 'XAF',
  },
  {
    value: 'er',
    countryCallingCode: '+291',
    currency: 'ERN',
  },
  {
    value: 'ee',
    countryCallingCode: '+372',
    currency: 'EUR',
  },
  {
    value: 'sz',
    countryCallingCode: '+268',
    currency: 'SZL',
  },
  {
    value: 'et',
    countryCallingCode: '+251',
    currency: 'ETB',
  },
  {
    value: 'fk',
    countryCallingCode: '+500',
    currency: 'FKP',
  },
  {
    value: 'fo',
    countryCallingCode: '+298',
    currency: 'DKK',
  },
  { value: 'fj', countryCallingCode: '+679', currency: 'FJD' },
  { value: 'fi', countryCallingCode: '+358', currency: 'EUR' },
  { value: 'fr', countryCallingCode: '+33', currency: 'EUR' },
  {
    value: 'gf',
    countryCallingCode: '+594',
    currency: 'EUR',
  },
  {
    value: 'pf',
    countryCallingCode: '+689',
    currency: 'XPF',
  },
  {
    value: 'tf',
    countryCallingCode: '',
    currency: 'EUR',
  },
  { value: 'ga', countryCallingCode: '+241', currency: 'XAF' },
  { value: 'gm', countryCallingCode: '+220', currency: 'GMD' },
  { value: 'ge', countryCallingCode: '+995', currency: 'GEL' },
  { value: 'de', countryCallingCode: '+49', currency: 'EUR' },
  { value: 'gh', countryCallingCode: '+233', currency: 'GHS' },
  {
    value: 'gi',
    countryCallingCode: '+350',
    currency: 'GIP',
  },
  { value: 'gr', countryCallingCode: '+30', currency: 'EUR' },
  { value: 'gl', countryCallingCode: '+299', currency: 'DKK' },
  {
    value: 'gd',
    countryCallingCode: '+1473',
    currency: 'XCD',
  },
  {
    value: 'gp',
    countryCallingCode: '+590',
    currency: 'EUR',
  },
  { value: 'gu', countryCallingCode: '+1671', currency: 'USD' },
  {
    value: 'gt',
    countryCallingCode: '+502',
    currency: 'GTQ',
  },
  { value: 'gg', countryCallingCode: '+44', currency: 'GGP' },
  { value: 'gn', countryCallingCode: '+224', currency: 'GNF' },
  {
    value: 'gw',
    countryCallingCode: '+245',
    currency: 'XOF',
  },
  { value: 'gy', countryCallingCode: '+592', currency: 'GYD' },
  { value: 'ht', countryCallingCode: '+509', currency: 'HTG' },
  {
    value: 'hm',
    countryCallingCode: '',
    currency: 'AUD',
  },
  { value: 'va', countryCallingCode: '+39', currency: 'EUR' },
  {
    value: 'hn',
    countryCallingCode: '+504',
    currency: 'HNL',
  },
  { value: 'hu', countryCallingCode: '+36', currency: 'HUF' },
  { value: 'is', countryCallingCode: '+354', currency: 'ISK' },
  { value: 'in', countryCallingCode: '+91', currency: 'INR' },
  { value: 'id', countryCallingCode: '+62', currency: 'IDR' },
  { value: 'ir', countryCallingCode: '+98', currency: 'IRR' },
  { value: 'iq', countryCallingCode: '+964', currency: 'IQD' },
  { value: 'ie', countryCallingCode: '+353', currency: 'EUR' },
  { value: 'im', countryCallingCode: '+44', currency: 'IMP' },
  { value: 'il', countryCallingCode: '+972', currency: 'ILS' },
  { value: 'it', countryCallingCode: '+39', currency: 'EUR' },
  { value: 'jm', countryCallingCode: '+1876', currency: 'JMD' },
  { value: 'je', countryCallingCode: '+44', currency: 'JEP' },
  { value: 'jo', countryCallingCode: '+962', currency: 'JOD' },
  { value: 'kz', countryCallingCode: '+7', currency: 'KZT' },
  { value: 'ke', countryCallingCode: '+254', currency: 'KES' },
  {
    value: 'ki',
    countryCallingCode: '+686',
    currency: 'AUD',
  },
  { value: 'kp', countryCallingCode: '+850', currency: 'KPW' },
  { value: 'kw', countryCallingCode: '+965', currency: 'KWD' },
  {
    value: 'kg',
    countryCallingCode: '+996',
    currency: 'KGS',
  },
  { value: 'la', countryCallingCode: '+856', currency: 'LAK' },
  {
    value: 'lv',
    countryCallingCode: '+371',
    currency: 'EUR',
  },
  { value: 'lb', countryCallingCode: '+961', currency: 'LBP' },
  { value: 'ls', countryCallingCode: '+266', currency: 'LSL' },
  {
    value: 'lr',
    countryCallingCode: '+231',
    currency: 'LRD',
  },
  { value: 'ly', countryCallingCode: '+218', currency: 'LYD' },
  {
    value: 'li',
    countryCallingCode: '+423',
    currency: 'CHF',
  },
  { value: 'lt', countryCallingCode: '+370', currency: 'EUR' },
  { value: 'lu', countryCallingCode: '+352', currency: 'EUR' },
  { value: 'mo', countryCallingCode: '+853', currency: 'MOP' },
  {
    value: 'mg',
    countryCallingCode: '+261',
    currency: 'MGA',
  },
  { value: 'mw', countryCallingCode: '+265', currency: 'MWK' },
  {
    value: 'mv',
    countryCallingCode: '+960',
    currency: 'MVR',
  },
  { value: 'ml', countryCallingCode: '+223', currency: 'XOF' },
  { value: 'mt', countryCallingCode: '+356', currency: 'EUR' },
  {
    value: 'mh',
    countryCallingCode: '+692',
    currency: 'USD',
  },
  {
    value: 'mq',
    countryCallingCode: '+596',
    currency: 'EUR',
  },
  {
    value: 'mr',
    countryCallingCode: '+222',
    currency: 'MRU',
  },
  {
    value: 'mu',
    countryCallingCode: '+230',
    currency: 'MUR',
  },
  { value: 'yt', countryCallingCode: '+262', currency: 'EUR' },
  { value: 'mx', countryCallingCode: '+52', currency: 'MXN' },
  {
    value: 'fm',
    countryCallingCode: '+691',
    currency: 'USD',
  },
  {
    value: 'md',
    countryCallingCode: '+373',
    currency: 'MDL',
  },
  { value: 'mc', countryCallingCode: '+377', currency: 'EUR' },
  { value: 'mn', countryCallingCode: '+976', currency: 'MNT' },
  {
    value: 'me',
    countryCallingCode: '+382',
    currency: 'EUR',
  },
  {
    value: 'ms',
    countryCallingCode: '+1664',
    currency: 'XCD',
  },
  { value: 'ma', countryCallingCode: '+212', currency: 'MAD' },
  {
    value: 'mz',
    countryCallingCode: '+258',
    currency: 'MZN',
  },
  { value: 'mm', countryCallingCode: '+95', currency: 'MMK' },
  {
    value: 'na',
    countryCallingCode: '+264',
    currency: 'NAD',
  },
  { value: 'nr', countryCallingCode: '+674', currency: 'AUD' },
  { value: 'np', countryCallingCode: '+977', currency: 'NPR' },
  { value: 'nl', countryCallingCode: '+31', currency: 'EUR' },
  {
    value: 'nc',
    countryCallingCode: '+687',
    currency: 'XPF',
  },
  { value: 'nz', countryCallingCode: '+64', currency: 'NZD' },
  {
    value: 'ni',
    countryCallingCode: '+505',
    currency: 'NIO',
  },
  { value: 'ne', countryCallingCode: '+227', currency: 'XOF' },
  {
    value: 'ng',
    countryCallingCode: '+234',
    currency: 'NGN',
  },
  { value: 'nu', countryCallingCode: '+683', currency: 'NZD' },
  {
    value: 'nf',
    countryCallingCode: '+672',
    currency: 'AUD',
  },
  {
    value: 'mk',
    countryCallingCode: '+389',
    currency: 'MKD',
  },
  {
    value: 'mp',
    countryCallingCode: '+1670',
    currency: 'USD',
  },
  { value: 'no', countryCallingCode: '+47', currency: 'NOK' },
  { value: 'om', countryCallingCode: '+968', currency: 'OMR' },
  { value: 'pk', countryCallingCode: '+92', currency: 'PKR' },
  { value: 'pw', countryCallingCode: '+680', currency: 'USD' },
  {
    value: 'ps',
    countryCallingCode: '+970',
    currency: 'ILS',
  },
  { value: 'pa', countryCallingCode: '+507', currency: 'PAB' },
  {
    value: 'pg',
    countryCallingCode: '+675',
    currency: 'PGK',
  },
  { value: 'py', countryCallingCode: '+595', currency: 'PYG' },
  { value: 'pe', countryCallingCode: '+51', currency: 'PEN' },
  { value: 'ph', countryCallingCode: '+63', currency: 'PHP' },
  { value: 'pn', countryCallingCode: '+64', currency: 'USD' },
  { value: 'pl', countryCallingCode: '+48', currency: 'PLN' },
  { value: 'pt', countryCallingCode: '+351', currency: 'EUR' },
  { value: 'pr', countryCallingCode: '+1', currency: 'USD' },
  { value: 'qa', countryCallingCode: '+974', currency: 'QAR' },
  { value: 're', countryCallingCode: '+262', currency: 'EUR' },
  { value: 'ro', countryCallingCode: '+40', currency: 'RON' },
  { value: 'ru', countryCallingCode: '+7', currency: 'RUB' },
  { value: 'rw', countryCallingCode: '+250', currency: 'RWF' },
  {
    value: 'bl',
    countryCallingCode: '+590',
    currency: 'EUR',
  },
  {
    value: 'sh',
    countryCallingCode: '+290',
    currency: 'SHP',
  },
  {
    value: 'kn',
    countryCallingCode: '+1869',
    currency: 'XCD',
  },
  {
    value: 'lc',
    countryCallingCode: '+1758',
    currency: 'XCD',
  },
  {
    value: 'mf',
    countryCallingCode: '+590',
    currency: 'EUR',
  },
  {
    value: 'pm',
    countryCallingCode: '+508',
    currency: 'EUR',
  },
  {
    value: 'vc',
    countryCallingCode: '+1784',
    currency: 'XCD',
  },
  { value: 'ws', countryCallingCode: '+685', currency: 'WST' },
  {
    value: 'sm',
    countryCallingCode: '+378',
    currency: 'EUR',
  },
  {
    value: 'st',
    countryCallingCode: '+239',
    currency: 'STN',
  },
  {
    value: 'sa',
    countryCallingCode: '+966',
    currency: 'SAR',
  },
  {
    value: 'sn',
    countryCallingCode: '+221',
    currency: 'XOF',
  },
  {
    value: 'rs',
    countryCallingCode: '+381',
    currency: 'RSD',
  },
  { value: 'sc', countryCallingCode: '+248', currency: 'SCR' },
  { value: 'sl', countryCallingCode: '+232', currency: 'SLL' },
  {
    value: 'sx',
    countryCallingCode: '+1721',
    currency: 'ANG',
  },
  {
    value: 'sk',
    countryCallingCode: '+421',
    currency: 'EUR',
  },
  {
    value: 'si',
    countryCallingCode: '+386',
    currency: 'EUR',
  },
  {
    value: 'sb',
    countryCallingCode: '+677',
    currency: 'SBD',
  },
  {
    value: 'so',
    countryCallingCode: '+252',
    currency: 'SOS',
  },
  { value: 'za', countryCallingCode: '+27', currency: 'ZAR' },
  {
    value: 'gs',
    countryCallingCode: '',
    currency: 'GBP',
  },
  { value: 'ss', countryCallingCode: '+211', currency: 'SSP' },
  { value: 'es', countryCallingCode: '+34', currency: 'EUR' },
  { value: 'lk', countryCallingCode: '+94', currency: 'LKR' },
  { value: 'sd', countryCallingCode: '+249', currency: 'SDG' },
  { value: 'sr', countryCallingCode: '+597', currency: 'SRD' },
  {
    value: 'sj',
    countryCallingCode: '+4779',
    currency: 'NOK',
  },
  { value: 'se', countryCallingCode: '+46', currency: 'SEK' },
  { value: 'ch', countryCallingCode: '+41', currency: 'CHF' },
  { value: 'sy', countryCallingCode: '+963', currency: 'SYP' },
  { value: 'tj', countryCallingCode: '+992', currency: 'TJS' },
  {
    value: 'tz',
    countryCallingCode: '+255',
    currency: 'TZS',
  },
  { value: 'tl', countryCallingCode: '+670', currency: 'USD' },
  { value: 'tg', countryCallingCode: '+228', currency: 'XOF' },
  { value: 'tk', countryCallingCode: '+690', currency: 'NZD' },
  { value: 'to', countryCallingCode: '+676', currency: 'TOP' },
  {
    value: 'tt',
    countryCallingCode: '+1868',
    currency: 'TTD',
  },
  {
    value: 'tn',
    countryCallingCode: '+216',
    currency: 'TND',
  },
  { value: 'tr', countryCallingCode: '+90', currency: 'TRY' },
  { value: 'tm', countryCallingCode: '+993', currency: 'TMT' },
  {
    value: 'tc',
    countryCallingCode: '+1649',
    currency: 'USD',
  },
  { value: 'tv', countryCallingCode: '+688', currency: 'AUD' },
  { value: 'ug', countryCallingCode: '+256', currency: 'UGX' },
  { value: 'ua', countryCallingCode: '+380', currency: 'UAH' },
  { value: 'ae', countryCallingCode: '+971', currency: 'AED' },
  { value: 'gb', countryCallingCode: '+44', currency: 'GBP' },
  { value: 'us', countryCallingCode: '+1', currency: 'USD' },
  {
    value: 'um',
    countryCallingCode: '+1',
    currency: 'USD',
  },
  { value: 'uy', countryCallingCode: '+598', currency: 'UYU' },
  {
    value: 'uz',
    countryCallingCode: '+998',
    currency: 'UZS',
  },
  { value: 'vu', countryCallingCode: '+678', currency: 'VUV' },
  { value: 've', countryCallingCode: '+58', currency: 'VES' },
  {
    value: 'vg',
    countryCallingCode: '+1284',
    currency: 'GBP',
  },
  {
    value: 'vi',
    countryCallingCode: '+1340',
    currency: 'USD',
  },
  {
    value: 'wf',
    countryCallingCode: '+681',
    currency: 'XPF',
  },
  {
    value: 'eh',
    countryCallingCode: '+212',
    currency: 'MAD',
  },
  { value: 'ye', countryCallingCode: '+967', currency: 'YER' },
  { value: 'zm', countryCallingCode: '+260', currency: 'ZMW' },
  { value: 'zw', countryCallingCode: '+263', currency: 'ZWL' },
] as const

export enum CountryCodeEnum {
  TW = 'tw',
  JP = 'jp',
  HK = 'hk',
  MY = 'my',
  TH = 'th',
  VN = 'vn',
  SG = 'sg',
  KR = 'kr',
  AF = 'af',
  AX = 'ax',
  AL = 'al',
  DZ = 'dz',
  AS = 'as',
  AD = 'ad',
  AO = 'ao',
  AI = 'ai',
  AQ = 'aq',
  AG = 'ag',
  AR = 'ar',
  AM = 'am',
  AW = 'aw',
  AU = 'au',
  AT = 'at',
  AZ = 'az',
  BS = 'bs',
  BH = 'bh',
  BD = 'bd',
  BB = 'bb',
  BY = 'by',
  BE = 'be',
  BZ = 'bz',
  BJ = 'bj',
  BM = 'bm',
  BT = 'bt',
  BO = 'bo',
  BQ = 'bq',
  BA = 'ba',
  BW = 'bw',
  BV = 'bv',
  BR = 'br',
  IO = 'io',
  BN = 'bn',
  BG = 'bg',
  BF = 'bf',
  BI = 'bi',
  CV = 'cv',
  KH = 'kh',
  CM = 'cm',
  CA = 'ca',
  KY = 'ky',
  CF = 'cf',
  TD = 'td',
  CL = 'cl',
  CN = 'cn',
  CX = 'cx',
  CC = 'cc',
  CO = 'co',
  KM = 'km',
  CG = 'cg',
  CD = 'cd',
  CK = 'ck',
  CR = 'cr',
  CI = 'ci',
  HR = 'hr',
  CU = 'cu',
  CW = 'cw',
  CY = 'cy',
  CZ = 'cz',
  DK = 'dk',
  DJ = 'dj',
  DM = 'dm',
  DO = 'do',
  EC = 'ec',
  EG = 'eg',
  SV = 'sv',
  GQ = 'gq',
  ER = 'er',
  EE = 'ee',
  SZ = 'sz',
  ET = 'et',
  FK = 'fk',
  FO = 'fo',
  FJ = 'fj',
  FI = 'fi',
  FR = 'fr',
  GF = 'gf',
  PF = 'pf',
  TF = 'tf',
  GA = 'ga',
  GM = 'gm',
  GE = 'ge',
  DE = 'de',
  GH = 'gh',
  GI = 'gi',
  GR = 'gr',
  GL = 'gl',
  GD = 'gd',
  GP = 'gp',
  GU = 'gu',
  GT = 'gt',
  GG = 'gg',
  GN = 'gn',
  GW = 'gw',
  GY = 'gy',
  HT = 'ht',
  HM = 'hm',
  VA = 'va',
  HN = 'hn',
  HU = 'hu',
  IS = 'is',
  IN = 'in',
  ID = 'id',
  IR = 'ir',
  IQ = 'iq',
  IE = 'ie',
  IM = 'im',
  IL = 'il',
  IT = 'it',
  JM = 'jm',
  JE = 'je',
  JO = 'jo',
  KZ = 'kz',
  KE = 'ke',
  KI = 'ki',
  KP = 'kp',
  KW = 'kw',
  KG = 'kg',
  LA = 'la',
  LV = 'lv',
  LB = 'lb',
  LS = 'ls',
  LR = 'lr',
  LY = 'ly',
  LI = 'li',
  LT = 'lt',
  LU = 'lu',
  MO = 'mo',
  MG = 'mg',
  MW = 'mw',
  MV = 'mv',
  ML = 'ml',
  MT = 'mt',
  MH = 'mh',
  MQ = 'mq',
  MR = 'mr',
  MU = 'mu',
  YT = 'yt',
  MX = 'mx',
  FM = 'fm',
  MD = 'md',
  MC = 'mc',
  MN = 'mn',
  ME = 'me',
  MS = 'ms',
  MA = 'ma',
  MZ = 'mz',
  MM = 'mm',
  NA = 'na',
  NR = 'nr',
  NP = 'np',
  NL = 'nl',
  NC = 'nc',
  NZ = 'nz',
  NI = 'ni',
  NE = 'ne',
  NG = 'ng',
  NU = 'nu',
  NF = 'nf',
  MK = 'mk',
  MP = 'mp',
  NO = 'no',
  OM = 'om',
  PK = 'pk',
  PW = 'pw',
  PS = 'ps',
  PA = 'pa',
  PG = 'pg',
  PY = 'py',
  PE = 'pe',
  PH = 'ph',
  PN = 'pn',
  PL = 'pl',
  PT = 'pt',
  PR = 'pr',
  QA = 'qa',
  RE = 're',
  RO = 'ro',
  RU = 'ru',
  RW = 'rw',
  BL = 'bl',
  SH = 'sh',
  KN = 'kn',
  LC = 'lc',
  MF = 'mf',
  PM = 'pm',
  VC = 'vc',
  WS = 'ws',
  SM = 'sm',
  ST = 'st',
  SA = 'sa',
  SN = 'sn',
  RS = 'rs',
  SC = 'sc',
  SL = 'sl',
  SX = 'sx',
  SK = 'sk',
  SI = 'si',
  SB = 'sb',
  SO = 'so',
  ZA = 'za',
  GS = 'gs',
  SS = 'ss',
  ES = 'es',
  LK = 'lk',
  SD = 'sd',
  SR = 'sr',
  SJ = 'sj',
  SE = 'se',
  CH = 'ch',
  SY = 'sy',
  TJ = 'tj',
  TZ = 'tz',
  TL = 'tl',
  TG = 'tg',
  TK = 'tk',
  TO = 'to',
  TT = 'tt',
  TN = 'tn',
  TR = 'tr',
  TM = 'tm',
  TC = 'tc',
  TV = 'tv',
  UG = 'ug',
  UA = 'ua',
  AE = 'ae',
  GB = 'gb',
  US = 'us',
  UM = 'um',
  UY = 'uy',
  UZ = 'uz',
  VU = 'vu',
  VE = 've',
  VG = 'vg',
  VI = 'vi',
  WF = 'wf',
  EH = 'eh',
  YE = 'ye',
  ZM = 'zm',
  ZW = 'zw',
}

export const UNLIMITED_COUNTRY_CODE = 'unlimit'

export const countryCodeEnumSchema = z.nativeEnum(CountryCodeEnum)

export const unlimitedCountryCodeEnumSchema = countryCodeEnumSchema.or(
  z.literal(UNLIMITED_COUNTRY_CODE),
)
export type UnlimitedCountryCode = z.infer<
  typeof unlimitedCountryCodeEnumSchema
>

type AllCountryCodeConst = (typeof allCountries)[number]
export type AllCountryCode =
  | AllCountryCodeConst['value']
  | typeof UNLIMITED_COUNTRY_CODE
export type AllCountryCurrency = AllCountryCodeConst['currency']

export const AllCountryCodeParam = createEnumParam([
  UNLIMITED_COUNTRY_CODE,
  ...allCountries.map((country) => country.value),
])

export const COUNTRY_I18N_MAP: Record<AllCountryCode, I18nId> = {
  tw: 'search:TW',
  jp: 'search:JP',
  hk: 'search:HK',
  my: 'search:MY',
  th: 'search:TH',
  vn: 'search:VN',
  sg: 'search:SG',
  kr: 'search:KR',
  af: 'search:AF',
  ax: 'search:AX',
  al: 'search:AL',
  dz: 'search:DZ',
  as: 'search:AS',
  ad: 'search:AD',
  ao: 'search:AO',
  ai: 'search:AI',
  aq: 'search:AQ',
  ag: 'search:AG',
  ar: 'search:AR',
  am: 'search:AM',
  aw: 'search:AW',
  au: 'search:AU',
  at: 'search:AT',
  az: 'search:AZ',
  bs: 'search:BS',
  bh: 'search:BH',
  bd: 'search:BD',
  bb: 'search:BB',
  by: 'search:BY',
  be: 'search:BE',
  bz: 'search:BZ',
  bj: 'search:BJ',
  bm: 'search:BM',
  bt: 'search:BT',
  bo: 'search:BO',
  bq: 'search:BQ',
  ba: 'search:BA',
  bw: 'search:BW',
  bv: 'search:BV',
  br: 'search:BR',
  io: 'search:IO',
  bn: 'search:BN',
  bg: 'search:BG',
  bf: 'search:BF',
  bi: 'search:BI',
  cv: 'search:CV',
  kh: 'search:KH',
  cm: 'search:CM',
  ca: 'search:CA',
  ky: 'search:KY',
  cf: 'search:CF',
  td: 'search:TD',
  cl: 'search:CL',
  cn: 'search:CN',
  cx: 'search:CX',
  cc: 'search:CC',
  co: 'search:CO',
  km: 'search:KM',
  cg: 'search:CG',
  cd: 'search:CD',
  ck: 'search:CK',
  cr: 'search:CR',
  ci: 'search:CI',
  hr: 'search:HR',
  cu: 'search:CU',
  cw: 'search:CW',
  cy: 'search:CY',
  cz: 'search:CZ',
  dk: 'search:DK',
  dj: 'search:DJ',
  dm: 'search:DM',
  do: 'search:DO',
  ec: 'search:EC',
  eg: 'search:EG',
  sv: 'search:SV',
  gq: 'search:GQ',
  er: 'search:ER',
  ee: 'search:EE',
  sz: 'search:SZ',
  et: 'search:ET',
  fk: 'search:FK',
  fo: 'search:FO',
  fj: 'search:FJ',
  fi: 'search:FI',
  fr: 'search:FR',
  gf: 'search:GF',
  pf: 'search:PF',
  tf: 'search:TF',
  ga: 'search:GA',
  gm: 'search:GM',
  ge: 'search:GE',
  de: 'search:DE',
  gh: 'search:GH',
  gi: 'search:GI',
  gr: 'search:GR',
  gl: 'search:GL',
  gd: 'search:GD',
  gp: 'search:GP',
  gu: 'search:GU',
  gt: 'search:GT',
  gg: 'search:GG',
  gn: 'search:GN',
  gw: 'search:GW',
  gy: 'search:GY',
  ht: 'search:HT',
  hm: 'search:HM',
  va: 'search:VA',
  hn: 'search:HN',
  hu: 'search:HU',
  is: 'search:IS',
  in: 'search:IN',
  id: 'search:ID',
  ir: 'search:IR',
  iq: 'search:IQ',
  ie: 'search:IE',
  im: 'search:IM',
  il: 'search:IL',
  it: 'search:IT',
  jm: 'search:JM',
  je: 'search:JE',
  jo: 'search:JO',
  kz: 'search:KZ',
  ke: 'search:KE',
  ki: 'search:KI',
  kp: 'search:KP',
  kw: 'search:KW',
  kg: 'search:KG',
  la: 'search:LA',
  lv: 'search:LV',
  lb: 'search:LB',
  ls: 'search:LS',
  lr: 'search:LR',
  ly: 'search:LY',
  li: 'search:LI',
  lt: 'search:LT',
  lu: 'search:LU',
  mo: 'search:MO',
  mg: 'search:MG',
  mw: 'search:MW',
  mv: 'search:MV',
  ml: 'search:ML',
  mt: 'search:MT',
  mh: 'search:MH',
  mq: 'search:MQ',
  mr: 'search:MR',
  mu: 'search:MU',
  yt: 'search:YT',
  mx: 'search:MX',
  fm: 'search:FM',
  md: 'search:MD',
  mc: 'search:MC',
  mn: 'search:MN',
  me: 'search:ME',
  ms: 'search:MS',
  ma: 'search:MA',
  mz: 'search:MZ',
  mm: 'search:MM',
  na: 'search:NA',
  nr: 'search:NR',
  np: 'search:NP',
  nl: 'search:NL',
  nc: 'search:NC',
  nz: 'search:NZ',
  ni: 'search:NI',
  ne: 'search:NE',
  ng: 'search:NG',
  nu: 'search:NU',
  nf: 'search:NF',
  mk: 'search:MK',
  mp: 'search:MP',
  no: 'search:NO',
  om: 'search:OM',
  pk: 'search:PK',
  pw: 'search:PW',
  ps: 'search:PS',
  pa: 'search:PA',
  pg: 'search:PG',
  py: 'search:PY',
  pe: 'search:PE',
  ph: 'search:PH',
  pn: 'search:PN',
  pl: 'search:PL',
  pt: 'search:PT',
  pr: 'search:PR',
  qa: 'search:QA',
  re: 'search:RE',
  ro: 'search:RO',
  ru: 'search:RU',
  rw: 'search:RW',
  bl: 'search:BL',
  sh: 'search:SH',
  kn: 'search:KN',
  lc: 'search:LC',
  mf: 'search:MF',
  pm: 'search:PM',
  vc: 'search:VC',
  ws: 'search:WS',
  sm: 'search:SM',
  st: 'search:ST',
  sa: 'search:SA',
  sn: 'search:SN',
  rs: 'search:RS',
  sc: 'search:SC',
  sl: 'search:SL',
  sx: 'search:SX',
  sk: 'search:SK',
  si: 'search:SI',
  sb: 'search:SB',
  so: 'search:SO',
  za: 'search:ZA',
  gs: 'search:GS',
  ss: 'search:SS',
  es: 'search:ES',
  lk: 'search:LK',
  sd: 'search:SD',
  sr: 'search:SR',
  sj: 'search:SJ',
  se: 'search:SE',
  ch: 'search:CH',
  sy: 'search:SY',
  tj: 'search:TJ',
  tz: 'search:TZ',
  tl: 'search:TL',
  tg: 'search:TG',
  tk: 'search:TK',
  to: 'search:TO',
  tt: 'search:TT',
  tn: 'search:TN',
  tr: 'search:TR',
  tm: 'search:TM',
  tc: 'search:TC',
  tv: 'search:TV',
  ug: 'search:UG',
  ua: 'search:UA',
  ae: 'search:AE',
  gb: 'search:GB',
  um: 'search:UM',
  us: 'search:US',
  uy: 'search:UY',
  uz: 'search:UZ',
  vu: 'search:VU',
  ve: 'search:VE',
  vg: 'search:VG',
  vi: 'search:VI',
  wf: 'search:WF',
  eh: 'search:EH',
  ye: 'search:YE',
  zm: 'search:ZM',
  zw: 'search:ZW',
  unlimit: 'search:unlimited',
}

export const AllowedDefaultCountryCodes = [
  'tw',
  'jp',
  'hk',
  'my',
  'th',
  'vn',
  'sg',
  'kr',
]
