/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull client'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 163
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/kolradar/kolradar-production/implementation/client)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  production: '28f187abae200168089c56e398448427',
  development: 'f5daff674a808ad98678c870d5515ac8'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '163',
    branch: 'main',
    source: 'client',
    versionId: '4414db47-d396-4fe9-a408-b5ea9af35f87'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * user's company
   */
  company?: string;
  /**
   * user's email
   */
  email?: string;
  fbclid?: string;
  gclid?: string;
  industry?: string;
  initial_dclid?: string;
  initial_fbclid?: string;
  initial_gbraid?: string;
  initial_gclid?: string;
  initial_ko_click_id?: string;
  initial_msclkid?: string;
  initial_referrer?: any;
  initial_referring_domain?: any;
  initial_ttclid?: string;
  initial_twclid?: string;
  initial_utm_campaign?: string;
  initial_utm_content?: string;
  initial_utm_id?: string;
  initial_utm_medium?: string;
  initial_utm_source?: string;
  initial_utm_term?: string;
  initial_wbraid?: string;
  lang?: string;
  /**
   * nationality
   */
  nationality?: string;
  referrer?: any;
  referring_domain?: any;
  region?: string;
  twclid?: string;
  /**
   * user id
   */
  userId?: string;
  userType?: string;
  utm_campaign?: string;
  utm_content?: string;
  utm_medium?: string;
  utm_source?: string;
  utm_term?: string;
  wbraid?: string;
  /**
   * workspace 選擇的幣別
   */
  workspaceCurrency?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  workspaceId?: number;
  workspaceName?: string;
  /**
   * workspace 選擇的國家/幣別
   */
  workspaceNational?: string;
  /**
   * 2024.08.27後會新增
   *
   * 1. premium_202408
   *
   * 2. advanced_202408
   *
   * 3. **trial_202408 (後台的新試用版，也就是跟corporate拆開了)**
   */
  workspacePlan?: string;
}

export interface AccountCreatedProperties {
  /**
   * 1. topNav
   *
   * 2. campaign
   *
   * 3. pricing
   *
   * 4. customers
   *
   * 5. kolradarstar
   */
  from?: string;
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  registeredUserType?: string;
  /**
   * google, facebook, instagram
   */
  ssoPlatform?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | email, social |
   */
  type?: "email" | "social";
}

export interface AccountCreatedAdProperties {
  /**
   * 1. topNav
   *
   * 2. campaign
   *
   * 3. pricing
   *
   * 4. customers
   *
   * 5. kolradarstar
   */
  from?: string;
  /**
   * google, facebook, instagram
   */
  ssoPlatform?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | email, social |
   */
  type: "email" | "social";
}

export interface AccountCreatedKolProperties {
  /**
   * 1. topNav
   *
   * 2. campaign
   *
   * 3. pricing
   *
   * 4. customers
   *
   * 5. kolradarstar
   */
  from?: string;
  /**
   * google, facebook, instagram
   */
  ssoPlatform?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | email, social |
   */
  type: "email" | "social";
}

export interface AccountCreatedKolAgencyProperties {
  /**
   * 1. topNav
   *
   * 2. campaign
   *
   * 3. pricing
   *
   * 4. customers
   *
   * 5. kolradarstar
   */
  from?: string;
  /**
   * google, facebook, instagram
   */
  ssoPlatform?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | email, social |
   */
  type: "email" | "social";
}

export interface AccountCreatingFailedProperties {
  path?: string;
  /**
   * google, facebook, instagram
   */
  ssoPlatform?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | email, social |
   */
  type: "email" | "social";
}

export interface AddCampaignInfluencersFailedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  /**
   * 是否有潛力網紅標籤
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | boolean |
   */
  kolPotential?: boolean[];
}

export interface AddCampaignInfluencersSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   *
   * 35. AI Keyword Change
   */
  from?: string;
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolNames?: string[];
  /**
   * 是否有潛力網紅標籤
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | boolean |
   */
  kolPotential?: boolean[];
  /**
   * the way users operate
   *
   * * add-influencers from Campaign: `collection`, `quick search`, `search result`, `community`, `campaign` , `report`, `kol detail`
   *
   *
   * * Add new Campaign: `Campaign List` , `Campaign Setting`, `recommendation`
   *
   *
   * * Preview Campaign: `new campaign`, `Setting`
   *
   * * verify email: `sso`, `email`
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | collection, quick search, search result, community, campaign, report, kol detail, campaign list, campaign setting, new campaign, setting, email, sso, recommendation |
   */
  method:
    | "collection"
    | "quick search"
    | "search result"
    | "community"
    | "campaign"
    | "report"
    | "kol detail"
    | "campaign list"
    | "campaign setting"
    | "new campaign"
    | "setting"
    | "email"
    | "sso"
    | "recommendation";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface AddCooperationConditionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
  /**
   * 選擇合作的平台選項 IG, FB, YT
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  cooperationSocialPlatform: string[];
  /**
   * 選擇合作的發文方式
   *  Image, Live, Video
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  cooperationSocialType: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolNames?: string[];
  promotionLink?: string;
  /**
   * 合作總酬勞
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  totalCompensation: number;
}

export interface AddKeywordToCompetitiveAnalysisProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  keywords?: string[];
  /**
   * 主要關鍵字
   */
  mainKeyword?: string;
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  projectName?: string;
}

export interface AddKeywordToCompetitiveAnalysisFailedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  keywords?: string[];
  /**
   * 主要關鍵字
   */
  mainKeyword?: string;
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  projectName?: string;
}

export interface AddKolToCommunityProperties {
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   *
   * 35. AI Keyword Change
   */
  from?: string;
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolName: string;
  path?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface AddKolToCompetitiveAnalysisProperties {
  /**
   * 位於哪頁執行行為
   *
   * 1. Hashtag Ranking
   *
   * 2. Single Hashtag
   *
   * 3. KOL Detail
   *
   * 4. Pricing
   *
   * 5. Chrome Extension
   *
   * 6. Brand Analysis
   *
   * 7. Keyword Analysis
   *
   * 8. Quick Search
   *
   * 9. Search Result
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Hashtag Ranking, Single Hashtag, KOL Detail, Pricing, Chrome Extension, Brand Analysis, Mention Analysis, Keyword Analysis, Quick Search, Search Result |
   */
  at?:
    | "Hashtag Ranking"
    | "Single Hashtag"
    | "KOL Detail"
    | "Pricing"
    | "Chrome Extension"
    | "Brand Analysis"
    | "Mention Analysis"
    | "Keyword Analysis"
    | "Quick Search"
    | "Search Result";
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolNames?: string[];
  mainKolId?: string;
  mainKolName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  projectName?: string;
}

export interface AddKolToCompetitiveAnalysisFailedProperties {
  /**
   * 位於哪頁執行行為
   *
   * 1. Hashtag Ranking
   *
   * 2. Single Hashtag
   *
   * 3. KOL Detail
   *
   * 4. Pricing
   *
   * 5. Chrome Extension
   *
   * 6. Brand Analysis
   *
   * 7. Keyword Analysis
   *
   * 8. Quick Search
   *
   * 9. Search Result
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Hashtag Ranking, Single Hashtag, KOL Detail, Pricing, Chrome Extension, Brand Analysis, Mention Analysis, Keyword Analysis, Quick Search, Search Result |
   */
  at?:
    | "Hashtag Ranking"
    | "Single Hashtag"
    | "KOL Detail"
    | "Pricing"
    | "Chrome Extension"
    | "Brand Analysis"
    | "Mention Analysis"
    | "Keyword Analysis"
    | "Quick Search"
    | "Search Result";
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolNames?: string[];
  mainKolId?: string;
  mainKolName?: string;
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  projectName?: string;
}

export interface AddMemberOfWorkspaceProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  memberEmails: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | admin, editor, viewer |
   */
  role: "admin" | "editor" | "viewer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  workspaceID: number;
  workspaceName: string;
}

export interface AddPromotionLinkProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | number |
   */
  kolIds: number[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolNames?: string[];
  promotionLink: string;
}

export interface AddReportKolProperties {
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   *
   * 35. AI Keyword Change
   */
  from?: string;
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportId: number;
  reportKolAdded: any[];
  reportName: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface AddToProjectProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | KOL, Post |
   */
  collectionType: "KOL" | "Post";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | KOL Detail, KOL Search, Community, socialAccountRanking, Hashtag, Competitor |
   */
  from?: "KOL Detail" | "KOL Search" | "Community" | "socialAccountRanking" | "Hashtag" | "Competitor";
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolCategory?: string[];
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  kolId?: any;
  kolName?: string;
  /**
   * 是否有潛力網紅標籤
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | boolean |
   */
  kolPotential?: boolean[];
  path?: string;
  platform?: string;
  postLink?: string;
  postType?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ApplyChangePlanProperties {
  periodAfter?: string;
  periodBefore?: string;
  planAfter?: string;
  planBefore?: string;
}

export interface AuthorizeFailProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Facebook, Instagram |
   */
  platform?: "Facebook" | "Instagram";
}

export interface AuthorizeSuccessfulProperties {
  isSelectAllPermission: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  permission?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Facebook, Instagram |
   */
  platform: "Facebook" | "Instagram";
}

export interface BindSocialAccountFailProperties {
  /**
   * google, facebook, instagram
   */
  ssoPlatform: string;
}

export interface BindSocialAccountSuccessProperties {
  /**
   * google, facebook, instagram
   */
  ssoPlatform: string;
}

export interface CancelCooperationProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolNames?: string[];
}

export interface CancelToCreateWorkspaceProperties {
  /**
   * audience
   */
  section: string;
}

export interface CanceledSubscriptionProperties {
  currency?: string;
  /**
   * Users use which database
   */
  database?: string;
  /**
   * 2024.08.27後會新增
   *
   * 1. premium_202408
   *
   * 2. advanced_202408
   *
   * 3. **trial_202408 (後台的新試用版，也就是跟corporate拆開了)**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | internal, free, enterprise, premium, premiumTrial, advanced, enterpriseTrail |
   */
  plan?: "internal" | "free" | "enterprise" | "premium" | "premiumTrial" | "advanced" | "enterpriseTrail";
  /**
   * The onyDay value will not available on production
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | month, quarter, year, oneDay, halfYear |
   */
  planPeriod?: "month" | "quarter" | "year" | "oneDay" | "halfYear";
  /**
   * 原幣別含稅價格
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  value?: number;
}

export interface CanceledSubscriptionCompletedProperties {
  currency?: string;
  /**
   * Users use which database
   */
  database?: string;
  /**
   * 2024.08.27後會新增
   *
   * 1. premium_202408
   *
   * 2. advanced_202408
   *
   * 3. **trial_202408 (後台的新試用版，也就是跟corporate拆開了)**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | internal, free, enterprise, premium, premiumTrial, advanced, enterpriseTrail |
   */
  plan?: "internal" | "free" | "enterprise" | "premium" | "premiumTrial" | "advanced" | "enterpriseTrail";
  /**
   * The onyDay value will not available on production
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | month, quarter, year, oneDay, halfYear |
   */
  planPeriod?: "month" | "quarter" | "year" | "oneDay" | "halfYear";
  /**
   * 原幣別含稅價格
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  value?: number;
}

export interface ChangeAiActionProperties {
  /**
   * AI Action 模組顯示的 KOL DC ID 們
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  aiActionKolDcIds?: string[];
  /**
   * ai 行為模組出現的搜尋結果
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  aiActionResult?: string[];
  /**
   * 實際點擊的關鍵字
   */
  keywordClick: string;
  /**
   * 原本輸入的關鍵字
   */
  keywordOriginal: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ChangeCollectionFilterProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | KOL, Post |
   */
  collectionType: "KOL" | "Post";
  next: string;
  period?: string;
  platform?: string;
  prev: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | platform, period |
   */
  type: "platform" | "period";
}

export interface ChangeCollectionViewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | KOL, Post |
   */
  collectionType: "KOL" | "Post";
  next: string;
  prev: string;
}

export interface ChangeCompetitiveChartDataProperties {
  /**
   * 位於哪頁執行行為
   *
   * 1. Hashtag Ranking
   *
   * 2. Single Hashtag
   *
   * 3. KOL Detail
   *
   * 4. Pricing
   *
   * 5. Chrome Extension
   *
   * 6. Brand Analysis
   *
   * 7. Keyword Analysis
   *
   * 8. Quick Search
   *
   * 9. Search Result
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Hashtag Ranking, Single Hashtag, KOL Detail, Pricing, Chrome Extension, Brand Analysis, Mention Analysis, Keyword Analysis, Quick Search, Search Result |
   */
  at?:
    | "Hashtag Ranking"
    | "Single Hashtag"
    | "KOL Detail"
    | "Pricing"
    | "Chrome Extension"
    | "Brand Analysis"
    | "Mention Analysis"
    | "Keyword Analysis"
    | "Quick Search"
    | "Search Result";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Brand Performance Trends, Other Performance Comparisons, Hashtag Analysis, Mentioned Performance Trends, Mention Content Ranking, Mention Hashtag Analysis, Mention KOL List |
   */
  chart:
    | "Brand Performance Trends"
    | "Other Performance Comparisons"
    | "Hashtag Analysis"
    | "Mentioned Performance Trends"
    | "Mention Content Ranking"
    | "Mention Hashtag Analysis"
    | "Mention KOL List";
  data: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  projectName?: string;
}

export interface ChangeCompetitiveFilterProperties {
  /**
   * 位於哪頁執行行為
   *
   * 1. Hashtag Ranking
   *
   * 2. Single Hashtag
   *
   * 3. KOL Detail
   *
   * 4. Pricing
   *
   * 5. Chrome Extension
   *
   * 6. Brand Analysis
   *
   * 7. Keyword Analysis
   *
   * 8. Quick Search
   *
   * 9. Search Result
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Hashtag Ranking, Single Hashtag, KOL Detail, Pricing, Chrome Extension, Brand Analysis, Mention Analysis, Keyword Analysis, Quick Search, Search Result |
   */
  at?:
    | "Hashtag Ranking"
    | "Single Hashtag"
    | "KOL Detail"
    | "Pricing"
    | "Chrome Extension"
    | "Brand Analysis"
    | "Mention Analysis"
    | "Keyword Analysis"
    | "Quick Search"
    | "Search Result";
  checkMainKeyword?: boolean;
  /**
   * 勾選我的品牌
   */
  checkMainKol?: boolean;
  /**
   * 使用者選擇的篩選器
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | country, platform, period, kolNames, keyword |
   */
  clickFilter: "country" | "platform" | "period" | "kolNames" | "keyword";
  /**
   * 依據使用者選擇的國家
   */
  countryFilter?: string;
  /**
   * 依據使用者篩選的關鍵字名稱，只有【關鍵字提及分析】會有
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | number |
   */
  keywordFilter?: number[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  keywords?: string[];
  /**
   * 依據使用者篩選的 KOL ID，只有【品牌帳號分析】會有
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIdFilter?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  /**
   * 依據使用者篩選的 KOL 名稱，只有【品牌帳號分析】會有
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolNameFilter?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolNames?: string[];
  path?: string;
  period?: string;
  platform?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  projectName?: string;
  sort?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Marked KOL, Collaboration KOL, Search KOL, AI Search, Brand Comparison, Brand Analysis, Mention Data, Mention Analysis, Keyword Data, Keyword Analysis |
   */
  tabType:
    | "Marked KOL"
    | "Collaboration KOL"
    | "Search KOL"
    | "AI Search"
    | "Brand Comparison"
    | "Brand Analysis"
    | "Mention Data"
    | "Mention Analysis"
    | "Keyword Data"
    | "Keyword Analysis";
}

export interface ChangeCompetitiveOrMainBrandProperties {
  /**
   * 位於哪頁執行行為
   *
   * 1. Hashtag Ranking
   *
   * 2. Single Hashtag
   *
   * 3. KOL Detail
   *
   * 4. Pricing
   *
   * 5. Chrome Extension
   *
   * 6. Brand Analysis
   *
   * 7. Keyword Analysis
   *
   * 8. Quick Search
   *
   * 9. Search Result
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Hashtag Ranking, Single Hashtag, KOL Detail, Pricing, Chrome Extension, Brand Analysis, Mention Analysis, Keyword Analysis, Quick Search, Search Result |
   */
  at?:
    | "Hashtag Ranking"
    | "Single Hashtag"
    | "KOL Detail"
    | "Pricing"
    | "Chrome Extension"
    | "Brand Analysis"
    | "Mention Analysis"
    | "Keyword Analysis"
    | "Quick Search"
    | "Search Result";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Brand Performance Trends, Other Performance Comparisons, Hashtag Analysis, Mentioned Performance Trends, Mention Content Ranking, Mention Hashtag Analysis, Mention KOL List |
   */
  chart:
    | "Brand Performance Trends"
    | "Other Performance Comparisons"
    | "Hashtag Analysis"
    | "Mentioned Performance Trends"
    | "Mention Content Ranking"
    | "Mention Hashtag Analysis"
    | "Mention KOL List";
  isCompetitiveBrand: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  projectName?: string;
}

export interface ChangeContentPlatformProperties {
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   */
  from?: string;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  path?: string;
  platform: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ChangeFollowerScaleProperties {
  /**
   * 位於哪頁執行行為
   *
   * 1. Hashtag Ranking
   *
   * 2. Single Hashtag
   *
   * 3. KOL Detail
   *
   * 4. Pricing
   *
   * 5. Chrome Extension
   *
   * 6. Brand Analysis
   *
   * 7. Keyword Analysis
   *
   * 8. Quick Search
   *
   * 9. Search Result
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Hashtag Ranking, Single Hashtag, KOL Detail, Pricing, Chrome Extension, Brand Analysis, Mention Analysis, Keyword Analysis, Quick Search, Search Result |
   */
  at?:
    | "Hashtag Ranking"
    | "Single Hashtag"
    | "KOL Detail"
    | "Pricing"
    | "Chrome Extension"
    | "Brand Analysis"
    | "Mention Analysis"
    | "Keyword Analysis"
    | "Quick Search"
    | "Search Result";
  /**
   * * **於** `Search for KOL` , `Search for KOL Content` 時，新增屬性記錄用戶選的 KOL 區間
   *
   *   * 只有在用戶選了以後沒有再調整數量才記錄，也就是最後搜尋的條件要等於選取條件 ( 怕有用戶點了以後又改條件 )
   *
   *   * 如果用戶選擇區間不在這區間，屬性傳空值即可，我們只分析直接選擇區間的
   *
   *   * 屬性
   *
   *     * **filterFollowerRange :**
   *
   *       * **Small (1K - 10K)**
   *
   *       * **Small (10K - 30K)**
   *
   *       * **Small (30K - 50K)**
   *
   *       * Medium (5**0K - 100K**)
   *
   *       * Medium (10**0K - 300K**)
   *
   *       * Medium (30**0K - 500K**)
   *
   *       * Large (500K - 1M)
   *
   *       * Large (1M+)
   *
   *       * https://www.notion.so/ikala/Sprint-123-b36281d03dc94c20a97c9bf6f5f825d3?p=53e7a41a804840cd8c8424d4df4ef9ca&pm=s
   *
   *   * 競品分析的圖表分析，改變粉絲級距時記錄於原事件 Change Follower Scale
   *
   *   * 網紅關係經營圖表篩選 : Filter in Community
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Small (1K - 10K), Small (10K - 30K), Small (30K - 50K), Medium (50K - 100K), Medium (100K - 300K), Medium (300K - 500K), Large (500K - 1M), Large (1M+) |
   */
  filterFollowerRange?:
    | "Small (1K - 10K)"
    | "Small (10K - 30K)"
    | "Small (30K - 50K)"
    | "Medium (50K - 100K)"
    | "Medium (100K - 300K)"
    | "Medium (300K - 500K)"
    | "Large (500K - 1M)"
    | "Large (1M+)";
}

export interface ChangeHashtagFilterProperties {
  /**
   * 位於哪頁執行行為
   *
   * 1. Hashtag Ranking
   *
   * 2. Single Hashtag
   *
   * 3. KOL Detail
   *
   * 4. Pricing
   *
   * 5. Chrome Extension
   *
   * 6. Brand Analysis
   *
   * 7. Keyword Analysis
   *
   * 8. Quick Search
   *
   * 9. Search Result
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Hashtag Ranking, Single Hashtag, KOL Detail, Pricing, Chrome Extension, Brand Analysis, Mention Analysis, Keyword Analysis, Quick Search, Search Result |
   */
  at?:
    | "Hashtag Ranking"
    | "Single Hashtag"
    | "KOL Detail"
    | "Pricing"
    | "Chrome Extension"
    | "Brand Analysis"
    | "Mention Analysis"
    | "Keyword Analysis"
    | "Quick Search"
    | "Search Result";
  country: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolCategory?: string[];
  period: string;
}

export interface ChangeHashtagPlatformProperties {
  path?: string;
  platform: string;
}

export interface ChangeLanguageProperties {
  /**
   * 位於哪頁執行行為
   *
   * 1. Hashtag Ranking
   *
   * 2. Single Hashtag
   *
   * 3. KOL Detail
   *
   * 4. Pricing
   *
   * 5. Chrome Extension
   *
   * 6. Brand Analysis
   *
   * 7. Keyword Analysis
   *
   * 8. Quick Search
   *
   * 9. Search Result
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Hashtag Ranking, Single Hashtag, KOL Detail, Pricing, Chrome Extension, Brand Analysis, Mention Analysis, Keyword Analysis, Quick Search, Search Result |
   */
  at?:
    | "Hashtag Ranking"
    | "Single Hashtag"
    | "KOL Detail"
    | "Pricing"
    | "Chrome Extension"
    | "Brand Analysis"
    | "Mention Analysis"
    | "Keyword Analysis"
    | "Quick Search"
    | "Search Result";
  language: string;
}

export interface ChangeMentionContentRankingProperties {
  /**
   * 位於哪頁執行行為
   *
   * 1. Hashtag Ranking
   *
   * 2. Single Hashtag
   *
   * 3. KOL Detail
   *
   * 4. Pricing
   *
   * 5. Chrome Extension
   *
   * 6. Brand Analysis
   *
   * 7. Keyword Analysis
   *
   * 8. Quick Search
   *
   * 9. Search Result
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Hashtag Ranking, Single Hashtag, KOL Detail, Pricing, Chrome Extension, Brand Analysis, Mention Analysis, Keyword Analysis, Quick Search, Search Result |
   */
  at?:
    | "Hashtag Ranking"
    | "Single Hashtag"
    | "KOL Detail"
    | "Pricing"
    | "Chrome Extension"
    | "Brand Analysis"
    | "Mention Analysis"
    | "Keyword Analysis"
    | "Quick Search"
    | "Search Result";
  projectId: string;
  projectName?: string;
}

export interface ChangePlatformProperties {
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   *
   * 35. AI Keyword Change
   */
  from?: string;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  platform?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ChangeRankingFilterProperties {
  /**
   * 有無開啟過濾官方帳號
   */
  brandFilter: boolean;
  country: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolCategory: string[];
  sortBy?: string;
}

export interface ChangeRankingPlatformProperties {
  path: string;
  platform: string;
}

export interface ChangeResultViewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | list, grid, table |
   */
  view: "list" | "grid" | "table";
}

export interface ChangeSortingProperties {
  sortBy?: string;
}

export interface ChangeSortingInCollectionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  projectName?: string;
  sortBy?: string;
}

export interface ChooseIdentityProperties {
  identity: string;
  path?: string;
}

export interface ChooseKolDetailPeriodFilterProperties {
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolCategory?: string[];
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Overview, Followers, Engagements, View Analysis, Post Frequency Analysis, Tag Distribution, Sponsored Posts, HashTag Posts |
   */
  kolSection:
    | "Overview"
    | "Followers"
    | "Engagements"
    | "View Analysis"
    | "Post Frequency Analysis"
    | "Tag Distribution"
    | "Sponsored Posts"
    | "HashTag Posts";
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Recent 3 months, Recent 6 months |
   */
  periodRangeType: "Recent 3 months" | "Recent 6 months";
  platform?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ChooseKolDetailSectionFromPublicProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolCategory?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Overview, Followers, Engagements, View Analysis, Post Frequency Analysis, Tag Distribution, Sponsored Posts, HashTag Posts, Content, Audience, Collaboration and Value, Similar influencers |
   */
  kolSection:
    | "Overview"
    | "Followers"
    | "Engagements"
    | "View Analysis"
    | "Post Frequency Analysis"
    | "Tag Distribution"
    | "Sponsored Posts"
    | "HashTag Posts"
    | "Content"
    | "Audience"
    | "Collaboration and Value"
    | "Similar influencers";
  path?: string;
}

export interface ChoosePlanProperties {
  currency?: string;
  /**
   * show whether the event is emitted by default
   */
  isDefault: boolean;
  /**
   * 2024.08.27後會新增
   *
   * 1. premium_202408
   *
   * 2. advanced_202408
   *
   * 3. **trial_202408 (後台的新試用版，也就是跟corporate拆開了)**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | internal, free, enterprise, premium, premiumTrial, advanced, enterpriseTrail |
   */
  plan?: "internal" | "free" | "enterprise" | "premium" | "premiumTrial" | "advanced" | "enterpriseTrail";
  /**
   * The onyDay value will not available on production
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | month, quarter, year, oneDay, halfYear |
   */
  planPeriod?: "month" | "quarter" | "year" | "oneDay" | "halfYear";
  /**
   * 原幣別含稅價格
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  value?: number;
}

export interface ChooseSearchResultProperties {
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | kol, generalSearch |
   */
  resultType: "kol" | "generalSearch";
}

export interface ClickAddMonitoringHashtagProperties {
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   *
   * 35. AI Keyword Change
   */
  from?: string;
}

export interface ClickAdvancedFilterOnAllContentProperties {
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  path?: string;
}

export interface ClickAdvancedTrialProperties {
  path?: string;
}

export interface ClickAiKeywordChangeProperties {
  /**
   * 實際點擊的關鍵字
   */
  keywordClick: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  keywordOptionList: string[];
  /**
   * 原本輸入的關鍵字
   */
  keywordOriginal: string;
  /**
   * 第幾個結果
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  rank: number;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ClickAiSearchFeedbackProperties {
  /**
   * 點選的結果是來自哪個 ai 模型
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | aiFilter, aiAction, aiKeyword |
   */
  aiType?: "aiFilter" | "aiAction" | "aiKeyword";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | good, bad |
   */
  feedback: "good" | "bad";
  feedbackContent: string;
}

export interface ClickAiSearchToggleProperties {
  /**
   * describing status after related event
   */
  after: string;
}

export interface ClickCampaignPaymentProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
  /**
   * Campaign
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignPaidPrice: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  kolAmount: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | number |
   */
  kolIds: number[];
}

export interface ClickChangeCardInfoProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
}

export interface ClickDemoProperties {
  /**
   * * clickDemoSource : 紀錄在哪裡點擊的，**Enum**
   *
   *   * 首頁最上面 : Header
   *
   *   * 競品分析的聯絡我們 : Competitor Analysis
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Header, Competitor Analysis |
   */
  clickDemoSource: "Header" | "Competitor Analysis";
  path?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ClickKScoreDetailProperties {
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ClickKeywordConnectionProperties {
  keyword?: string;
}

export interface ClickKolInfoFromMentionTrendsProperties {
  /**
   * 位於哪頁執行行為
   *
   * 1. Hashtag Ranking
   *
   * 2. Single Hashtag
   *
   * 3. KOL Detail
   *
   * 4. Pricing
   *
   * 5. Chrome Extension
   *
   * 6. Brand Analysis
   *
   * 7. Keyword Analysis
   *
   * 8. Quick Search
   *
   * 9. Search Result
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Hashtag Ranking, Single Hashtag, KOL Detail, Pricing, Chrome Extension, Brand Analysis, Mention Analysis, Keyword Analysis, Quick Search, Search Result |
   */
  at?:
    | "Hashtag Ranking"
    | "Single Hashtag"
    | "KOL Detail"
    | "Pricing"
    | "Chrome Extension"
    | "Brand Analysis"
    | "Mention Analysis"
    | "Keyword Analysis"
    | "Quick Search"
    | "Search Result";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId: string;
  kolName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | social_volume_count, mention_post_count, mention_kol_count, mention_kol_avg_follower_count, like_count, comment_count, share_count, view_count |
   */
  option:
    | "social_volume_count"
    | "mention_post_count"
    | "mention_kol_count"
    | "mention_kol_avg_follower_count"
    | "like_count"
    | "comment_count"
    | "share_count"
    | "view_count";
  postId: string;
  projectId: string;
  projectName?: string;
}

export interface ClickMoreRecommendSimilarKolProperties {
  /**
   * 位於哪頁執行行為
   *
   * 1. Hashtag Ranking
   *
   * 2. Single Hashtag
   *
   * 3. KOL Detail
   *
   * 4. Pricing
   *
   * 5. Chrome Extension
   *
   * 6. Brand Analysis
   *
   * 7. Keyword Analysis
   *
   * 8. Quick Search
   *
   * 9. Search Result
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Hashtag Ranking, Single Hashtag, KOL Detail, Pricing, Chrome Extension, Brand Analysis, Mention Analysis, Keyword Analysis, Quick Search, Search Result |
   */
  at?:
    | "Hashtag Ranking"
    | "Single Hashtag"
    | "KOL Detail"
    | "Pricing"
    | "Chrome Extension"
    | "Brand Analysis"
    | "Mention Analysis"
    | "Keyword Analysis"
    | "Quick Search"
    | "Search Result";
  path?: string;
  projectId: string;
  projectName?: string;
}

export interface ClickPremiumTrialProperties {
  path: string;
}

export interface ClickPricingProperties {
  path?: string;
}

export interface ClickPurchaseQuotaProperties {
  /**
   * 位於哪頁執行行為
   *
   * 1. Hashtag Ranking
   *
   * 2. Single Hashtag
   *
   * 3. KOL Detail
   *
   * 4. Pricing
   *
   * 5. Chrome Extension
   *
   * 6. Brand Analysis
   *
   * 7. Keyword Analysis
   *
   * 8. Quick Search
   *
   * 9. Search Result
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Hashtag Ranking, Single Hashtag, KOL Detail, Pricing, Chrome Extension, Brand Analysis, Mention Analysis, Keyword Analysis, Quick Search, Search Result |
   */
  at?:
    | "Hashtag Ranking"
    | "Single Hashtag"
    | "KOL Detail"
    | "Pricing"
    | "Chrome Extension"
    | "Brand Analysis"
    | "Mention Analysis"
    | "Keyword Analysis"
    | "Quick Search"
    | "Search Result";
}

export interface ClickRecommendSimilarKolProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  projectId: string;
  projectName?: string;
}

export interface ClickRelatedKolProperties {
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   *
   * 35. AI Keyword Change
   */
  from?: string;
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  path?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ClickReportAudienceAuthenticityAccuracyProperties {
  isAccurate: boolean;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  path?: string;
  platform?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ClickReportContentTypeAccuracyProperties {
  isAccurate: boolean;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  path?: string;
  platform?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ClickReportKolAudienceAccuracyProperties {
  isAccurate: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  platform?: string;
}

export interface ClickReportNonSponsoredPostProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  platform?: string;
  postLink?: string;
}

export interface ClickReportSponsoredPostProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  platform?: string;
  postLink?: string;
}

export interface ClickReportSponsoredPostAccuracyProperties {
  isAccurate: boolean;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  path?: string;
  platform?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ClickSearchResultProperties {
  /**
   * 針對既有 AI filter 模組作細分，若沒使用到這模組則為 null
   *  當篩選器有選擇預估合作費用/受眾，aiFilterModel = ‘budget’
   *
   * * 當沒選以上篩選器，而選擇了**類型/性別/追蹤/互動/觀看**
   *
   *   * 推該類型且為潛力網紅，aiFilterModel = ‘potential’
   *
   *   * 推該類型口碑價值前三名的網紅，aiFilterModel = ‘reputation’
   *
   *   * 推該類型互動率前三名，aiFilterModel = ‘interactive’
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | potential, reputation, interactive |
   */
  aiFilterType?: "potential" | "reputation" | "interactive";
  /**
   * show whether the event is emitted by default
   */
  isDefault: boolean;
  /**
   * 判斷是否有篩選合作費用/受眾，isFilterBudget = True / False
   */
  isFilterBudget?: boolean;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId: string;
  kolName: string;
  /**
   * 第幾個結果
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  rank: number;
  /**
   * 記錄點擊時產生結果方式是來自 ai 哪個模組還是 normal
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | aiFilterResult, aiActionResult, aiKeywordResult, normal |
   */
  resultCategory: "aiFilterResult" | "aiActionResult" | "aiKeywordResult" | "normal";
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ClickSearchTypeFilterProperties {
  keyword?: string;
  /**
   * 紀錄是由於點選下拉選單觸發 (start filter)，還是完成篩選時觸發 (complete filter)
   */
  triggerReason: string;
}

export interface ClickSideMenuListProperties {
  /**
   * 平台上點擊的功能
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Search, Collection, Campaign, DeepReport, IRM, OldCampaign, HashtagRanking, HashtagMonitoring, Threads, SocialAccountRanking, CompetitiveBrandAnalysis, CompetitiveMentionAnalysis, CompetitiveKeywordAnalysis |
   */
  feature:
    | "Search"
    | "Collection"
    | "Campaign"
    | "DeepReport"
    | "IRM"
    | "OldCampaign"
    | "HashtagRanking"
    | "HashtagMonitoring"
    | "Threads"
    | "SocialAccountRanking"
    | "CompetitiveBrandAnalysis"
    | "CompetitiveMentionAnalysis"
    | "CompetitiveKeywordAnalysis";
}

export interface ClickSideNavigatorProperties {
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  /**
   * KD 頁停留的指標區塊
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Overview, Similar influencers, Followers, Audience, Engagements, View Analysis, Post Frequency, Tag Distribution, Hashtag Analysis, Sponsored post analysis, Contents, Related Brands, Collaboration and Value, Related influencers, Post time analysis, Audience Authenticity, Key Metrics Summary, Rating Comparison |
   */
  kolMetrics:
    | "Overview"
    | "Similar influencers"
    | "Followers"
    | "Audience"
    | "Engagements"
    | "View Analysis"
    | "Post Frequency"
    | "Tag Distribution"
    | "Hashtag Analysis"
    | "Sponsored post analysis"
    | "Contents"
    | "Related Brands"
    | "Collaboration and Value"
    | "Related influencers"
    | "Post time analysis"
    | "Audience Authenticity"
    | "Key Metrics Summary"
    | "Rating Comparison";
}

export interface ClickSimilarInfluencerProperties {
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId: string;
  kolName: string;
  path?: string;
  /**
   * 第幾個結果
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  rank: number;
  /**
   * 相似 kol 的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7#4204808875754aa1911f922e36c25b57
   */
  similarKolDcId?: string;
  /**
   * dc id，顯示的相似網紅，有多組
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolDcIds?: string[];
  /**
   * (被點擊的目標網紅，單數)
   */
  similarKolId: string;
  /**
   * 顯示 KD 相似的網紅內的 KOL ID，若有多組顯示多組。
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolIds?: string[];
  /**
   * (被點擊的目標網紅，單數)
   */
  similarKolName: string;
  /**
   * 顯示在 KD 中相似的網紅的名稱，若有 10 個則顯示 10 個。
   *  e.g. 珍妮弗, Yellow, Aaaa, Bbb, Ccc
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolNames?: string[];
}

export interface ClickSingleHashtagProperties {
  hashtagName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  hashtagRanking: number;
}

export interface ClickSingleMonitoringHashtagProperties {
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   *
   * 35. AI Keyword Change
   */
  from?: string;
  hashtagName: string;
}

export interface ClickSingleMonitoringHashtagFromCompetitorProperties {
  hashtagName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Marked KOL, Collaboration KOL, Search KOL, AI Search, Brand Comparison, Brand Analysis, Mention Data, Mention Analysis, Keyword Data, Keyword Analysis |
   */
  tabType:
    | "Marked KOL"
    | "Collaboration KOL"
    | "Search KOL"
    | "AI Search"
    | "Brand Comparison"
    | "Brand Analysis"
    | "Mention Data"
    | "Mention Analysis"
    | "Keyword Data"
    | "Keyword Analysis";
}

export interface ClickTheNodeOnTrendAnalysisProperties {
  /**
   * 位於哪頁執行行為
   *
   * 1. Hashtag Ranking
   *
   * 2. Single Hashtag
   *
   * 3. KOL Detail
   *
   * 4. Pricing
   *
   * 5. Chrome Extension
   *
   * 6. Brand Analysis
   *
   * 7. Keyword Analysis
   *
   * 8. Quick Search
   *
   * 9. Search Result
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Hashtag Ranking, Single Hashtag, KOL Detail, Pricing, Chrome Extension, Brand Analysis, Mention Analysis, Keyword Analysis, Quick Search, Search Result |
   */
  at?:
    | "Hashtag Ranking"
    | "Single Hashtag"
    | "KOL Detail"
    | "Pricing"
    | "Chrome Extension"
    | "Brand Analysis"
    | "Mention Analysis"
    | "Keyword Analysis"
    | "Quick Search"
    | "Search Result";
  data: string;
  kolName?: string;
  month: string;
}

export interface ClickTheTypeFromQuickSearchProperties {
  type: string;
}

export interface ClickToAddBooleanSyntaxProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 完全符合, 聯集, 交集, 排除, 群組 |
   */
  booleanSyntax: "完全符合" | "聯集" | "交集" | "排除" | "群組";
  keyword?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ClickToReportUnlistedInfluencerProperties {
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   *
   * 35. AI Keyword Change
   */
  from?: string;
}

export interface ClickToSendEmailProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  emails: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  kolAmount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolNames?: string[];
  multiSelected: boolean;
}

export interface ClickUnlockKolDataProperties {
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   *
   * 35. AI Keyword Change
   */
  from?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolCategory?: string[];
  kolCountry?: string;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
}

export interface ClickUpgradePlanProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Search KOL limit, AI Search limit, Countdown hint, Audience filter |
   */
  section?: "Search KOL limit" | "AI Search limit" | "Countdown hint" | "Audience filter";
}

export interface ClickWommSettingProperties {
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  path?: string;
  platform?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Engagement Analysis, View Analysis, Collaboration and Value |
   */
  section: "Engagement Analysis" | "View Analysis" | "Collaboration and Value";
}

export interface ClickWorkspacePlanProperties {
  /**
   * 進入到查看方案內容時，記錄從哪個頁面點選
   *
   * https://www.notion.so/ikala/d5fe8f69b3564d3e8c4dc06f58b28e30?pvs=23
   *
   * * 直接點選右上角方案內容 : Menu
   *
   * ![](https://prod-files-secure.s3.us-west-2.amazonaws.com/83b3c994-2b4a-485a-9a11-3653b8f41ce3/d135d01c-68e7-48f0-93e4-7e33eb095e7f/Untitled.png)
   *
   * * [競品分析使用權](https://www.notion.so/d5fe8f69b3564d3e8c4dc06f58b28e30?pvs=21) : Competitor Permission ( 針對無使用權者，在 Demo 或點選競品分析頁面點選 )
   *
   * * [競品分析報表上限](https://www.notion.so/d5fe8f69b3564d3e8c4dc06f58b28e30?pvs=21) : Competitor Count Limit
   *
   * * [競品分析網紅上限](https://www.notion.so/d5fe8f69b3564d3e8c4dc06f58b28e30?pvs=21) : Competitor KOL Limit
   *
   * * [Hashtag 監測](https://www.notion.so/d5fe8f69b3564d3e8c4dc06f58b28e30?pvs=21) : Hashtag Monitoring
   *
   * * [Hashtag 排行](https://www.notion.so/d5fe8f69b3564d3e8c4dc06f58b28e30?pvs=21) : Hashtag Raking
   *
   * * [收藏庫上限](https://www.notion.so/d5fe8f69b3564d3e8c4dc06f58b28e30?pvs=21) : Collection Count Limit
   *
   * * [收藏庫網紅上限](https://www.notion.so/d5fe8f69b3564d3e8c4dc06f58b28e30?pvs=21) : Collection KOL Limit
   *
   * * [洞察報告上限](https://www.notion.so/d5fe8f69b3564d3e8c4dc06f58b28e30?pvs=21) : Deep Report Count Limit
   *
   * * [洞察報告網紅上限](https://www.notion.so/d5fe8f69b3564d3e8c4dc06f58b28e30?pvs=21) : Deep Report KOL Limit
   *
   * * 搜尋頁最下面 : Search Count Limit
   *
   * * KD 頁解鎖圖表 : Unlock KD Data
   *
   * * 直接點選側邊欄 : Sider
   *
   * * 洞察報告使用權 : Deep Report Permission
   *
   * * 最上方的申請試用/試用中前往升級 : Header
   *
   * * 信用卡綁定畫面: Verify Credit Card
   *
   * * 付款驗證畫面 : Verify Payment
   *
   * * 團隊成員管理 : Member Management
   *
   * * IRM 使用權 : IRM Permisson
   *
   * * Hashtag 時間限制 : Hashtag Duration Limit
   *
   * * 競品分析關鍵字上限 : Competitor Keyword Limit
   *
   * * 右上角新建工作區 : Create Workspace
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Menu, Competitor Permission, Competitor Count Limit, Competitor KOL Limit, Hashtag Monitoring, Hashtag Raking, Collection Count Limit, Collection KOL Limit, Deep Report Count Limit, Deep Report KOL Limit, Search Count Limit, Unlock KD Data, Sider, Header, Verify Credit Card, Verify Payment, Member Management, IRM Permisson, Deep Report Permission, Hashtag Duration Limit, Competitor Keyword Limit, Create Workspace |
   */
  clickPlanSource:
    | "Menu"
    | "Competitor Permission"
    | "Competitor Count Limit"
    | "Competitor KOL Limit"
    | "Hashtag Monitoring"
    | "Hashtag Raking"
    | "Collection Count Limit"
    | "Collection KOL Limit"
    | "Deep Report Count Limit"
    | "Deep Report KOL Limit"
    | "Search Count Limit"
    | "Unlock KD Data"
    | "Sider"
    | "Header"
    | "Verify Credit Card"
    | "Verify Payment"
    | "Member Management"
    | "IRM Permisson"
    | "Deep Report Permission"
    | "Hashtag Duration Limit"
    | "Competitor Keyword Limit"
    | "Create Workspace";
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path: string;
  /**
   * audience
   */
  section?: string;
}

export interface ClicksRegisterProperties {
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   *
   * 35. AI Keyword Change
   */
  from?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  path?: string;
}

export interface CloseKScoreDetailProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface CloseOnboardingTutorialProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Search Influencer, Influencer Detail, Competitor Analysis, Hashtag, Deep Report |
   */
  onboardingItem: "Search Influencer" | "Influencer Detail" | "Competitor Analysis" | "Hashtag" | "Deep Report";
}

export interface CloseTutorialProperties {
  /**
   * * audience data
   *
   * * search
   *
   * * ...etc.
   */
  tutorialTopic?: string;
  /**
   * * product update
   *
   * * onbaording
   */
  tutorialType: string;
}

export interface CompleteTrialActivationProperties {
  isVIP: boolean;
}

export interface CompleteTutorialProperties {
  /**
   * * audience data
   *
   * * search
   *
   * * ...etc.
   */
  tutorialTopic?: string;
  /**
   * * product update
   *
   * * onbaording
   */
  tutorialType: string;
}

export interface CompletedOnboardingProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Search Influencer, Influencer Detail, Competitor Analysis, Hashtag, Deep Report |
   */
  onboardingItem: "Search Influencer" | "Influencer Detail" | "Competitor Analysis" | "Hashtag" | "Deep Report";
}

export interface ConfirmIdentityChoiceProperties {
  identity: string;
}

export interface CreateCampaignProperties {
  /**
   * 建立活動時的預期每人合作金額
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignBudgetPerPerson: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
  campaignPeriod: string;
  /**
   * the way users operate
   *
   * * add-influencers from Campaign: `collection`, `quick search`, `search result`, `community`, `campaign` , `report`, `kol detail`
   *
   *
   * * Add new Campaign: `Campaign List` , `Campaign Setting`, `recommendation`
   *
   *
   * * Preview Campaign: `new campaign`, `Setting`
   *
   * * verify email: `sso`, `email`
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | collection, quick search, search result, community, campaign, report, kol detail, campaign list, campaign setting, new campaign, setting, email, sso, recommendation |
   */
  method:
    | "collection"
    | "quick search"
    | "search result"
    | "community"
    | "campaign"
    | "report"
    | "kol detail"
    | "campaign list"
    | "campaign setting"
    | "new campaign"
    | "setting"
    | "email"
    | "sso"
    | "recommendation";
  /**
   * 活動是否公開顯示於我要接案列表
   */
  public: boolean;
  /**
   * 最終回覆日期 (Internal Campaign 沒有）
   */
  replyDate?: string;
}

export interface CreateCollectionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  collectionId: number;
  collectionName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | KOL, Post |
   */
  collectionType: "KOL" | "Post";
  /**
   * show whether the event is emitted by default
   */
  isDefault: boolean;
}

export interface CreateCompetitiveAnalysisProperties {
  /**
   * 位於哪頁執行行為
   *
   * 1. Hashtag Ranking
   *
   * 2. Single Hashtag
   *
   * 3. KOL Detail
   *
   * 4. Pricing
   *
   * 5. Chrome Extension
   *
   * 6. Brand Analysis
   *
   * 7. Keyword Analysis
   *
   * 8. Quick Search
   *
   * 9. Search Result
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Hashtag Ranking, Single Hashtag, KOL Detail, Pricing, Chrome Extension, Brand Analysis, Mention Analysis, Keyword Analysis, Quick Search, Search Result |
   */
  at?:
    | "Hashtag Ranking"
    | "Single Hashtag"
    | "KOL Detail"
    | "Pricing"
    | "Chrome Extension"
    | "Brand Analysis"
    | "Mention Analysis"
    | "Keyword Analysis"
    | "Quick Search"
    | "Search Result";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  projectName?: string;
}

export interface CreateCompetitiveAnalysisFailedProperties {
  /**
   * 位於哪頁執行行為
   *
   * 1. Hashtag Ranking
   *
   * 2. Single Hashtag
   *
   * 3. KOL Detail
   *
   * 4. Pricing
   *
   * 5. Chrome Extension
   *
   * 6. Brand Analysis
   *
   * 7. Keyword Analysis
   *
   * 8. Quick Search
   *
   * 9. Search Result
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Hashtag Ranking, Single Hashtag, KOL Detail, Pricing, Chrome Extension, Brand Analysis, Mention Analysis, Keyword Analysis, Quick Search, Search Result |
   */
  at?:
    | "Hashtag Ranking"
    | "Single Hashtag"
    | "KOL Detail"
    | "Pricing"
    | "Chrome Extension"
    | "Brand Analysis"
    | "Mention Analysis"
    | "Keyword Analysis"
    | "Quick Search"
    | "Search Result";
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  projectName?: string;
}

export interface CreateContractProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
  /**
   * contract name on the operation of the target event
   */
  contractName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
}

export interface CreateDeepReportProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportBudget?: number;
  reportEndDate: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  reportKeyword?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  reportKol?: string[];
  reportName?: string;
  reportStartDate: string;
}

export interface CreateWorkspaceProperties {
  /**
   * Possible Values:
   *
   * * `setName`
   *
   * * `aboutTeam`
   *
   * * `choosePlan`
   *
   * New Campaign Reporting
   *
   * * ScoreCard
   *
   * * KOL Analysis
   *
   * * Platform Analysis
   *
   * * Post Analysis
   */
  section?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  workspaceID?: number;
  workspaceName?: string;
}

export interface DeleteCampaignProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
}

export interface DeleteProjectProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | KOL, Post |
   */
  collectionType: "KOL" | "Post";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  projectName?: string;
}

export interface DeleteReportKolProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportId: number;
  reportKolDeleted: {
    id: string;
    name: string;
  };
  reportName: string;
}

export interface DeleteWorkspaceProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  workspaceID?: number;
  workspaceName?: string;
}

export interface DraftContractProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
  /**
   * contract name on the operation of the target event
   */
  contractName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
}

export interface DuplicateCollectionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | KOL, Post |
   */
  collectionType: "KOL" | "Post";
}

export interface EditIrmTagsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface EditMemberOfWorkspaceProperties {
  memberEmail: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | admin, editor, viewer |
   */
  role: "admin" | "editor" | "viewer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  workspaceID?: number;
  workspaceName?: string;
}

export interface EditWorkspaceProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  workspaceID?: number;
  workspaceName?: string;
}

export interface ExitKolDetailProperties {
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   *
   * 35. AI Keyword Change
   */
  from?: string;
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  keyword?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolCategory?: string[];
  kolCountry?: string;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  platform?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
  /**
   * Describing the time in seconds for the events.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  second: number;
}

export interface ExitSearchResultProperties {
  /**
   * 有任何篩選條件時為 True，其他為 False。排除官方帳號不算
   *  https://www.notion.so/ikala/f306551001564b74bd3aed1763962f0b?v=be1df2d9d1144434805ad38578b12cc2&p=600a9485fd5949f8878675870bf5efd6&pm=s
   */
  isAnyFilter: boolean;
  keyword?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
  /**
   * Describing the time in seconds for the events.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  second: number;
}

export interface ExportCampaignReportContentProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  kolAmount?: number;
}

export interface ExportCollectionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  collectionId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  collectionKolCount?: number;
  collectionName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  collectionPostCount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | KOL, Post |
   */
  collectionType: "KOL" | "Post";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | csv, pdf, ppt |
   */
  format: "csv" | "pdf" | "ppt";
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  period?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  platforms?: string[];
}

export interface ExportCollectionFailedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  collectionId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  collectionKolCount?: number;
  collectionName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  collectionPostCount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | KOL, Post |
   */
  collectionType: "KOL" | "Post";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | csv, pdf, ppt |
   */
  format: "csv" | "pdf" | "ppt";
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  period?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  platforms?: string[];
}

export interface ExportCollectionOverLimitProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  collectionId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  collectionKolCount?: number;
  collectionName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | KOL, Post |
   */
  collectionType: "KOL" | "Post";
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | csv, pdf, ppt |
   */
  format: "csv" | "pdf" | "ppt";
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  period?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  platforms?: string[];
}

export interface ExportCommunityKolFailProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | csv, pdf, ppt |
   */
  format: "csv" | "pdf" | "ppt";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  kolAmount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolNames?: string[];
  multiSelected: boolean;
}

export interface ExportKolDataProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | csv, pdf, ppt |
   */
  format: "csv" | "pdf" | "ppt";
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  period: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  platforms?: string[];
}

export interface ExportMentionPostAndKolProperties {
  /**
   * 位於哪頁執行行為
   *
   * 1. Hashtag Ranking
   *
   * 2. Single Hashtag
   *
   * 3. KOL Detail
   *
   * 4. Pricing
   *
   * 5. Chrome Extension
   *
   * 6. Brand Analysis
   *
   * 7. Keyword Analysis
   *
   * 8. Quick Search
   *
   * 9. Search Result
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Hashtag Ranking, Single Hashtag, KOL Detail, Pricing, Chrome Extension, Brand Analysis, Mention Analysis, Keyword Analysis, Quick Search, Search Result |
   */
  at?:
    | "Hashtag Ranking"
    | "Single Hashtag"
    | "KOL Detail"
    | "Pricing"
    | "Chrome Extension"
    | "Brand Analysis"
    | "Mention Analysis"
    | "Keyword Analysis"
    | "Quick Search"
    | "Search Result";
}

export interface ExportReportContentProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportContentCount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportKolCount?: number;
  reportName?: string;
}

export interface FailToPayAfter3DProperties {
  message?: any;
}

export interface FailToPayBefore3DProperties {
  currency?: string;
  /**
   * 2024.08.27後會新增
   *
   * 1. premium_202408
   *
   * 2. advanced_202408
   *
   * 3. **trial_202408 (後台的新試用版，也就是跟corporate拆開了)**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | internal, free, enterprise, premium, premiumTrial, advanced, enterpriseTrail |
   */
  plan?: "internal" | "free" | "enterprise" | "premium" | "premiumTrial" | "advanced" | "enterpriseTrail";
  /**
   * The onyDay value will not available on production
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | month, quarter, year, oneDay, halfYear |
   */
  planPeriod?: "month" | "quarter" | "year" | "oneDay" | "halfYear";
  /**
   * 原幣別含稅價格
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  value?: number;
}

export interface FansSelectorProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | slider, input |
   */
  type: "slider" | "input";
}

export interface FilterInCommunityProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  campaignNames?: string[];
  /**
   * 若是一般搜尋或快速搜尋，不限區域則傳 unlimit
   */
  countryCode?: string;
  /**
   * * **於** `Search for KOL` , `Search for KOL Content` 時，新增屬性記錄用戶選的 KOL 區間
   *
   *   * 只有在用戶選了以後沒有再調整數量才記錄，也就是最後搜尋的條件要等於選取條件 ( 怕有用戶點了以後又改條件 )
   *
   *   * 如果用戶選擇區間不在這區間，屬性傳空值即可，我們只分析直接選擇區間的
   *
   *   * 屬性
   *
   *     * **filterFollowerRange :**
   *
   *       * **Small (1K - 10K)**
   *
   *       * **Small (10K - 30K)**
   *
   *       * **Small (30K - 50K)**
   *
   *       * Medium (5**0K - 100K**)
   *
   *       * Medium (10**0K - 300K**)
   *
   *       * Medium (30**0K - 500K**)
   *
   *       * Large (500K - 1M)
   *
   *       * Large (1M+)
   *
   *       * https://www.notion.so/ikala/Sprint-123-b36281d03dc94c20a97c9bf6f5f825d3?p=53e7a41a804840cd8c8424d4df4ef9ca&pm=s
   *
   *   * 競品分析的圖表分析，改變粉絲級距時記錄於原事件 Change Follower Scale
   *
   *   * 網紅關係經營圖表篩選 : Filter in Community
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Small (1K - 10K), Small (10K - 30K), Small (30K - 50K), Medium (50K - 100K), Medium (100K - 300K), Medium (300K - 500K), Large (500K - 1M), Large (1M+) |
   */
  filterFollowerRange?:
    | "Small (1K - 10K)"
    | "Small (10K - 30K)"
    | "Small (30K - 50K)"
    | "Medium (50K - 100K)"
    | "Medium (100K - 300K)"
    | "Medium (300K - 500K)"
    | "Large (500K - 1M)"
    | "Large (1M+)";
  followerEndTo: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  followerRateEndTo?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  followerRateStartFrom?: number;
  followerStartFrom: string;
  gender?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  interactiveRateBegin?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  interactiveRateEnd?: number;
  kolName?: string;
  platform?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  projectNames?: string[];
  recommendType?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  reportNames?: string[];
  tags?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  viewRateEnd?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  viewRateStart?: number;
}

export interface FilterInCommunityFailedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  campaignNames?: string[];
  /**
   * 若是一般搜尋或快速搜尋，不限區域則傳 unlimit
   */
  countryCode?: string;
  followerEndTo: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  followerRateEndTo?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  followerRateStartFrom?: number;
  followerStartFrom: string;
  gender?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  interactiveRateBegin?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  interactiveRateEnd?: number;
  kolName?: string;
  platform?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  projectNames?: string[];
  recommendType?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  reportNames?: string[];
  tags?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  viewRateEnd?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  viewRateStart?: number;
}

export interface FilterInCommunityNoResultProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  campaignNames?: string[];
  countryCode?: any;
  followerEndTo: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  followerRateEndTo?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  followerRateStartFrom?: number;
  followerStartFrom: string;
  gender?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  interactiveRateBegin?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  interactiveRateEnd?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolName?: string;
  platform?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  projectNames?: string[];
  recommendType?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  reportNames?: string[];
  tags?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  viewRateEnd?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  viewRateStart?: number;
}

export interface FilterReportingKolProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolNames?: string[];
  /**
   * Possible Values:
   *
   * * `setName`
   *
   * * `aboutTeam`
   *
   * * `choosePlan`
   *
   * New Campaign Reporting
   *
   * * ScoreCard
   *
   * * KOL Analysis
   *
   * * Platform Analysis
   *
   * * Post Analysis
   */
  section?: string;
}

export interface FilterReportingPlatformProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  platforms?: string[];
  /**
   * Possible Values:
   *
   * * `setName`
   *
   * * `aboutTeam`
   *
   * * `choosePlan`
   *
   * New Campaign Reporting
   *
   * * ScoreCard
   *
   * * KOL Analysis
   *
   * * Platform Analysis
   *
   * * Post Analysis
   */
  section?: string;
}

export interface HideReportKolContentProperties {
  kolIId: string;
  kolName?: string;
  postLink?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportId: number;
  reportName?: string;
}

export interface ImportKolFailedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | URL, KOL Radar, uuid |
   */
  importMethod: "URL" | "KOL Radar" | "uuid";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  kolAmount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  urlLinks?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  uuids?: string[];
}

export interface ImportKolSuccessProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | URL, KOL Radar, uuid |
   */
  importMethod: "URL" | "KOL Radar" | "uuid";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  kolAmount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  urlLinks?: string[];
}

export interface KolAcceptContractProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId?: number;
  campaignName?: string;
  /**
   * the id recorded in database of specific contract
   */
  contractId: string;
  /**
   * contract name on the operation of the target event
   */
  contractName?: string;
  contractType: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
}

export interface KolVisitCampaignProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
}

export interface KolVisitPublicCampaignProperties {
  /**
   * 建立活動時的預期每人合作金額
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignBudgetPerPerson: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
  campaignPeriod: string;
}

export interface LeaveReportPreviewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportContentCount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportKolCount?: number;
  reportName?: string;
}

export interface LeaveWorkspaceProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  workspaceID?: number;
  workspaceName?: string;
}

export interface LoginFailProperties {
  /**
   * google, facebook, instagram
   */
  ssoPlatform?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | social, email |
   */
  type: "social" | "email";
}

export interface LoginSuccessProperties {
  /**
   * google, facebook, instagram
   */
  ssoPlatform?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | email, social |
   */
  type: "email" | "social";
}

export interface LogoutSuccessProperties {
  isSingleLogin: boolean;
}

export interface MoveCompetitorOrderProperties {
  /**
   * 位於哪頁執行行為
   *
   * 1. Hashtag Ranking
   *
   * 2. Single Hashtag
   *
   * 3. KOL Detail
   *
   * 4. Pricing
   *
   * 5. Chrome Extension
   *
   * 6. Brand Analysis
   *
   * 7. Keyword Analysis
   *
   * 8. Quick Search
   *
   * 9. Search Result
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Hashtag Ranking, Single Hashtag, KOL Detail, Pricing, Chrome Extension, Brand Analysis, Mention Analysis, Keyword Analysis, Quick Search, Search Result |
   */
  at?:
    | "Hashtag Ranking"
    | "Single Hashtag"
    | "KOL Detail"
    | "Pricing"
    | "Chrome Extension"
    | "Brand Analysis"
    | "Mention Analysis"
    | "Keyword Analysis"
    | "Quick Search"
    | "Search Result";
  path?: string;
}

export interface PopUpDetectCrawlerNotifyProperties {
  path?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Search KOL Limit |
   */
  section: "Search KOL Limit";
}

export interface PopUpUpgradeNotifyProperties {
  path?: string;
  /**
   * Possible Values:
   *
   * * `setName`
   *
   * * `aboutTeam`
   *
   * * `choosePlan`
   *
   * New Campaign Reporting
   *
   * * ScoreCard
   *
   * * KOL Analysis
   *
   * * Platform Analysis
   *
   * * Post Analysis
   */
  section?: string;
}

export interface PreviewCampaignProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
  /**
   * the way users operate
   *
   * * add-influencers from Campaign: `collection`, `quick search`, `search result`, `community`, `campaign` , `report`, `kol detail`
   *
   *
   * * Add new Campaign: `Campaign List` , `Campaign Setting`, `recommendation`
   *
   *
   * * Preview Campaign: `new campaign`, `Setting`
   *
   * * verify email: `sso`, `email`
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | collection, quick search, search result, community, campaign, report, kol detail, campaign list, campaign setting, new campaign, setting, email, sso, recommendation |
   */
  method:
    | "collection"
    | "quick search"
    | "search result"
    | "community"
    | "campaign"
    | "report"
    | "kol detail"
    | "campaign list"
    | "campaign setting"
    | "new campaign"
    | "setting"
    | "email"
    | "sso"
    | "recommendation";
}

export interface PreviewKeywordPostProperties {
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  /**
   * 第幾個結果
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  rank: number;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface QuickSearchForKolProperties {
  /**
   * 若是一般搜尋或快速搜尋，不限區域則傳 unlimit
   */
  countryCode?: string;
  keyword?: string;
  /**
   * KOL UUID
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolResult?: string[];
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ReadTutorialProperties {
  /**
   * user stop at tutorial's page
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  page: number;
  /**
   * * audience data
   *
   * * search
   *
   * * ...etc.
   */
  tutorialTopic?: string;
  /**
   * * product update
   *
   * * onbaording
   */
  tutorialType: string;
}

export interface RedeemCouponFailedProperties {
  couponCode: string;
  path?: string;
}

export interface RedeemCouponSuccessProperties {
  couponCode: string;
  /**
   * **XX%off**
   */
  couponEffect: string;
}

export interface RefreshAiActionProperties {
  /**
   * AI Action 模組顯示的 KOL DC ID 們
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  aiActionKolDcIds?: string[];
  /**
   * ai 行為模組出現的搜尋結果
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  aiActionResult?: string[];
  /**
   * 原本輸入的關鍵字
   */
  keywordOriginal: string;
  path?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface RefreshPageProperties {
  path?: string;
}

export interface RemoveCollectionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolCategory?: string[];
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  kolId?: any;
  kolName?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface RemoveKolProperties {
  kolId?: string;
  kolName?: string;
}

export interface RemoveMemberOfWorkspaceProperties {
  memberEmail: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | admin, editor, viewer |
   */
  role: "admin" | "editor" | "viewer";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  workspaceID?: number;
  workspaceName?: string;
}

export interface RemovePostFromCollectionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  collectionId: number;
  collectionName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  platform?: string;
  postLink?: string;
  postType?: string;
}

export interface ReplayOnboardingProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Search Influencer, Influencer Detail, Competitor Analysis, Hashtag, Deep Report |
   */
  onboardingItem: "Search Influencer" | "Influencer Detail" | "Competitor Analysis" | "Hashtag" | "Deep Report";
}

export interface ReportContentTypeIsIncorrectProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  path?: string;
  platform?: string;
  postLink?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface RequestMoreHashtagInfluencersProperties {
  hashtagName: string;
}

export interface ResendVerifyEmailProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  emails: string[];
}

export interface ReturnUnlistedKolProperties {
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   *
   * 35. AI Keyword Change
   */
  from?: string;
  kolUrl: string;
}

export interface SaveEditingReportPreviewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportContentCount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportKolCount?: number;
  reportName?: string;
}

export interface SaveThePostToCollectionFailedProperties {
  postLink?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  projectName?: string;
}

export interface ScrollKolDetailProperties {
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  /**
   * KD 頁停留的指標區塊
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Overview, Similar influencers, Followers, Audience, Engagements, View Analysis, Post Frequency, Tag Distribution, Hashtag Analysis, Sponsored post analysis, Contents, Related Brands, Collaboration and Value, Related influencers, Post time analysis, Audience Authenticity, Key Metrics Summary, Rating Comparison |
   */
  kolMetrics:
    | "Overview"
    | "Similar influencers"
    | "Followers"
    | "Audience"
    | "Engagements"
    | "View Analysis"
    | "Post Frequency"
    | "Tag Distribution"
    | "Hashtag Analysis"
    | "Sponsored post analysis"
    | "Contents"
    | "Related Brands"
    | "Collaboration and Value"
    | "Related influencers"
    | "Post time analysis"
    | "Audience Authenticity"
    | "Key Metrics Summary"
    | "Rating Comparison";
  path?: string;
  platform: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
  /**
   * dc id，顯示的相似網紅，有多組
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolDcIds?: string[];
  /**
   * 顯示 KD 相似的網紅內的 KOL ID，若有多組顯示多組。
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolIds?: string[];
  /**
   * 顯示在 KD 中相似的網紅的名稱，若有 10 個則顯示 10 個。
   *  e.g. 珍妮弗, Yellow, Aaaa, Bbb, Ccc
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolNames?: string[];
}

export interface ScrollSearchResultProperties {
  keyword?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface SearchForCreativeContentInKdProperties {
  /**
   * 是否打開 AI 功能
   *  布林值，‘True : 打開’ ‘False : 關閉’
   */
  aiEnabled: boolean;
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  keyword?: string;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  path?: string;
  platform?: string;
  postEndDate?: string;
  postStartDate?: string;
  /**
   * 創作內容進行搜尋時，貼文類型。Array。image / live / video
   *  https://www.notion.so/ikala/UIUX-UI-ff2d2d3b7c944f259a4731e7265635bc?d=d64e1ee62cd34737a3f9a1171c88664b#a0da4945239743e3a8a003840a59e789
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  postTypes?: string[];
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
  /**
   * 題材類型。Array。相關值同 Search for KOL 的 kolType
   *  https://www.notion.so/ikala/Sprint-123-b36281d03dc94c20a97c9bf6f5f825d3?p=ff2d2d3b7c944f259a4731e7265635bc&pm=s
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  topicTypes?: string[];
}

export interface SearchForKolProperties {
  /**
   * 是否打開 AI 功能
   *  布林值，‘True : 打開’ ‘False : 關閉’
   */
  aiEnabled: boolean;
  /**
   * 受眾年齡
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 13-17, 18-24, 25-34, 35-44, 45-54, 55-64, 65+ |
   */
  audienceAge?: "13-17" | "18-24" | "25-34" | "35-44" | "45-54" | "55-64" | "65+";
  /**
   * 受眾年齡％數區間的起始
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  audienceAgeRateBegin?: number;
  /**
   * 受眾年齡％數區間的終值
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  audienceAgeRateEnd?: number;
  /**
   * 受眾性別
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Male, Female |
   */
  audienceGender?: "Male" | "Female";
  /**
   * 受眾性別％數區間的起始值
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  audienceGenderRateBegin?: number;
  /**
   * 受眾性別％數區間的終值
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  audienceGenderRateEnd?: number;
  /**
   * 受眾地區
   */
  audienceGeo?: string;
  /**
   * 受眾地區％數區間的起始值
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  audienceGeoRateBegin?: number;
  /**
   * 受眾地區％數區間的終值
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  audienceGeoRateEnd?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  avgPvRateBegin?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  avgPvRateEnd?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  budgetBegin?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  budgetEnd?: number;
  /**
   * 若是一般搜尋或快速搜尋，不限區域則傳 unlimit
   */
  countryCode?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fansUpRateBegin?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fansUpRateEnd?: number;
  filterCollection?: boolean;
  filterCooperation?: boolean;
  /**
   * * **於** `Search for KOL` , `Search for KOL Content` 時，新增屬性記錄用戶選的 KOL 區間
   *
   *   * 只有在用戶選了以後沒有再調整數量才記錄，也就是最後搜尋的條件要等於選取條件 ( 怕有用戶點了以後又改條件 )
   *
   *   * 如果用戶選擇區間不在這區間，屬性傳空值即可，我們只分析直接選擇區間的
   *
   *   * 屬性
   *
   *     * **filterFollowerRange :**
   *
   *       * **Small (1K - 10K)**
   *
   *       * **Small (10K - 30K)**
   *
   *       * **Small (30K - 50K)**
   *
   *       * Medium (5**0K - 100K**)
   *
   *       * Medium (10**0K - 300K**)
   *
   *       * Medium (30**0K - 500K**)
   *
   *       * Large (500K - 1M)
   *
   *       * Large (1M+)
   *
   *       * https://www.notion.so/ikala/Sprint-123-b36281d03dc94c20a97c9bf6f5f825d3?p=53e7a41a804840cd8c8424d4df4ef9ca&pm=s
   *
   *   * 競品分析的圖表分析，改變粉絲級距時記錄於原事件 Change Follower Scale
   *
   *   * 網紅關係經營圖表篩選 : Filter in Community
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Small (1K - 10K), Small (10K - 30K), Small (30K - 50K), Medium (50K - 100K), Medium (100K - 300K), Medium (300K - 500K), Large (500K - 1M), Large (1M+) |
   */
  filterFollowerRange?:
    | "Small (1K - 10K)"
    | "Small (10K - 30K)"
    | "Small (30K - 50K)"
    | "Medium (50K - 100K)"
    | "Medium (100K - 300K)"
    | "Medium (300K - 500K)"
    | "Large (500K - 1M)"
    | "Large (1M+)";
  /**
   * 找網紅時可以篩選網紅與語言（多選）
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filterKolLanguage?: string[];
  /**
   * 選擇篩選掉哪些官方帳號
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filterOfficialAccount?: (
    | "Restaurants"
    | "Professional Services"
    | "Grocery & Convenience Stores"
    | "Non-Profits & Religious Organizations"
    | "Local Events"
    | "Publishers"
    | "Business & Utility Services"
    | "Transportation & Accommodation Services"
    | "Home Goods Stores"
    | "Content & Apps"
    | "Auto Dealers"
    | "Government Agencies"
    | "Entities"
    | "Networking"
    | "Geography"
    | "Politician"
    | "All"
    | "Media"
    | "Brand"
  )[];
  filterUnrecommend?: boolean;
  /**
   * 認證網紅篩選
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | verified, unverified |
   */
  filterVerified?: "verified" | "unverified";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  followerEndTo?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  followerStartFrom?: number;
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   *
   * 35. AI Keyword Change
   */
  from?: string;
  gender?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | high, normal, decline |
   */
  growthPotentialType?: "high" | "normal" | "decline";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  interactiveRateBegin?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  interactiveRateEnd?: number;
  /**
   * 1. isAIFilterTrigger : 布林值，有任何可能觸發 AI 篩選器模組的條件時為 True，其他為 False ( 無論是否會回傳結果 )，已知條件如下
   *
   *    1. **kolType**
   *
   *    2. **gender**
   *
   *    3. **followerStartFrom**
   *
   *    4. **interactiveRateBegin**
   *
   *    5. **avgPvRateBegin**
   *
   *    6. **budgetBegin**
   *
   *    7. **audienceAge**
   *
   *    8. **audienceGeo**
   *
   *       https://www.notion.so/ikala/Amp-600a9485fd5949f8878675870bf5efd6?pm=c
   */
  isAIFilterTrigger: boolean;
  /**
   * 有任何篩選條件時為 True，其他為 False。排除官方帳號不算
   *  https://www.notion.so/ikala/f306551001564b74bd3aed1763962f0b?v=be1df2d9d1144434805ad38578b12cc2&p=600a9485fd5949f8878675870bf5efd6&pm=s
   */
  isAnyFilter: boolean;
  /**
   * show whether the event is emitted by default
   */
  isDefault: boolean;
  keyword?: string;
  /**
   * 關鍵字從哪裡來
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | keywordConnection |
   */
  keywordFrom?: "keywordConnection";
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolType?: string[];
  platform: string;
  postEndDate?: string;
  postStartDate?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
  sort?: string;
  view?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  workspaceID?: number;
  workspaceName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  workspacePlan?: string;
}

export interface SearchForKolContentProperties {
  /**
   * 若是一般搜尋或快速搜尋，不限區域則傳 unlimit
   */
  countryCode?: string;
  /**
   * * **於** `Search for KOL` , `Search for KOL Content` 時，新增屬性記錄用戶選的 KOL 區間
   *
   *   * 只有在用戶選了以後沒有再調整數量才記錄，也就是最後搜尋的條件要等於選取條件 ( 怕有用戶點了以後又改條件 )
   *
   *   * 如果用戶選擇區間不在這區間，屬性傳空值即可，我們只分析直接選擇區間的
   *
   *   * 屬性
   *
   *     * **filterFollowerRange :**
   *
   *       * **Small (1K - 10K)**
   *
   *       * **Small (10K - 30K)**
   *
   *       * **Small (30K - 50K)**
   *
   *       * Medium (5**0K - 100K**)
   *
   *       * Medium (10**0K - 300K**)
   *
   *       * Medium (30**0K - 500K**)
   *
   *       * Large (500K - 1M)
   *
   *       * Large (1M+)
   *
   *       * https://www.notion.so/ikala/Sprint-123-b36281d03dc94c20a97c9bf6f5f825d3?p=53e7a41a804840cd8c8424d4df4ef9ca&pm=s
   *
   *   * 競品分析的圖表分析，改變粉絲級距時記錄於原事件 Change Follower Scale
   *
   *   * 網紅關係經營圖表篩選 : Filter in Community
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Small (1K - 10K), Small (10K - 30K), Small (30K - 50K), Medium (50K - 100K), Medium (100K - 300K), Medium (300K - 500K), Large (500K - 1M), Large (1M+) |
   */
  filterFollowerRange?:
    | "Small (1K - 10K)"
    | "Small (10K - 30K)"
    | "Small (30K - 50K)"
    | "Medium (50K - 100K)"
    | "Medium (100K - 300K)"
    | "Medium (300K - 500K)"
    | "Large (500K - 1M)"
    | "Large (1M+)";
  /**
   * 選擇篩選掉哪些官方帳號
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filterOfficialAccount?: (
    | "Restaurants"
    | "Professional Services"
    | "Grocery & Convenience Stores"
    | "Non-Profits & Religious Organizations"
    | "Local Events"
    | "Publishers"
    | "Business & Utility Services"
    | "Transportation & Accommodation Services"
    | "Home Goods Stores"
    | "Content & Apps"
    | "Auto Dealers"
    | "Government Agencies"
    | "Entities"
    | "Networking"
    | "Geography"
    | "Politician"
    | "All"
    | "Media"
    | "Brand"
  )[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  followerEndTo?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  followerStartFrom?: number;
  /**
   * show whether the event is emitted by default
   */
  isDefault: boolean;
  /**
   * 是否為商業合作，篩選商業合作貼文時為 True，反之
   */
  isSponsored?: boolean;
  keyword?: string;
  platform?: string;
  postEndDate?: string;
  /**
   * 題材類型，每篇貼文被貼的類型
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  postProperty?: string[];
  postStartDate?: string;
  postType?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
  view?: string;
}

export interface SearchForKolContentFailProperties {
  /**
   * 若是一般搜尋或快速搜尋，不限區域則傳 unlimit
   */
  countryCode?: string;
  /**
   * 選擇篩選掉哪些官方帳號
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filterOfficialAccount?: (
    | "Restaurants"
    | "Professional Services"
    | "Grocery & Convenience Stores"
    | "Non-Profits & Religious Organizations"
    | "Local Events"
    | "Publishers"
    | "Business & Utility Services"
    | "Transportation & Accommodation Services"
    | "Home Goods Stores"
    | "Content & Apps"
    | "Auto Dealers"
    | "Government Agencies"
    | "Entities"
    | "Networking"
    | "Geography"
    | "Politician"
    | "All"
    | "Media"
    | "Brand"
  )[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  followerEndTo?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  followerStartFrom?: number;
  /**
   * show whether the event is emitted by default
   */
  isDefault: boolean;
  /**
   * 是否為商業合作，篩選商業合作貼文時為 True，反之
   */
  isSponsored: boolean;
  keyword?: string;
  platform?: string;
  postEndDate?: string;
  /**
   * 題材類型，每篇貼文被貼的類型
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  postProperty?: string[];
  postStartDate?: string;
  postType?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
  view?: string;
}

export interface SearchForKolContentNoResultProperties {
  /**
   * 若是一般搜尋或快速搜尋，不限區域則傳 unlimit
   */
  countryCode?: string;
  /**
   * 選擇篩選掉哪些官方帳號
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filterOfficialAccount?: (
    | "Restaurants"
    | "Professional Services"
    | "Grocery & Convenience Stores"
    | "Non-Profits & Religious Organizations"
    | "Local Events"
    | "Publishers"
    | "Business & Utility Services"
    | "Transportation & Accommodation Services"
    | "Home Goods Stores"
    | "Content & Apps"
    | "Auto Dealers"
    | "Government Agencies"
    | "Entities"
    | "Networking"
    | "Geography"
    | "Politician"
    | "All"
    | "Media"
    | "Brand"
  )[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  followerEndTo?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  followerStartFrom?: number;
  /**
   * show whether the event is emitted by default
   */
  isDefault: boolean;
  /**
   * 是否為商業合作，篩選商業合作貼文時為 True，反之
   */
  isSponsored: boolean;
  keyword?: string;
  path?: string;
  platform?: string;
  postEndDate?: string;
  /**
   * 題材類型，每篇貼文被貼的類型
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  postProperty?: string[];
  postStartDate?: string;
  postType?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
  view?: string;
}

export interface SearchForKolFailProperties {
  /**
   * 受眾年齡
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 13-17, 18-24, 25-34, 35-44, 45-54, 55-64, 65+ |
   */
  audienceAge?: "13-17" | "18-24" | "25-34" | "35-44" | "45-54" | "55-64" | "65+";
  /**
   * 受眾年齡％數區間的起始
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  audienceAgeRateBegin?: number;
  /**
   * 受眾年齡％數區間的終值
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  audienceAgeRateEnd?: number;
  /**
   * 受眾性別
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Male, Female |
   */
  audienceGender?: "Male" | "Female";
  /**
   * 受眾性別％數區間的起始值
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  audienceGenderRateBegin?: number;
  /**
   * 受眾性別％數區間的終值
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  audienceGenderRateEnd?: number;
  /**
   * 受眾地區
   */
  audienceGeo?: string;
  /**
   * 受眾地區％數區間的起始值
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  audienceGeoRateBegin?: number;
  /**
   * 受眾地區％數區間的終值
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  audienceGeoRateEnd?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  avgPvRateBegin?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  avgPvRateEnd?: number;
  /**
   * 若是一般搜尋或快速搜尋，不限區域則傳 unlimit
   */
  countryCode?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fansUpRateBegin?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fansUpRateEnd?: number;
  filterCollection?: boolean;
  filterCooperation?: boolean;
  /**
   * 找網紅時可以篩選網紅與語言（多選）
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filterKolLanguage?: string[];
  /**
   * 選擇篩選掉哪些官方帳號
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filterOfficialAccount?: (
    | "Restaurants"
    | "Professional Services"
    | "Grocery & Convenience Stores"
    | "Non-Profits & Religious Organizations"
    | "Local Events"
    | "Publishers"
    | "Business & Utility Services"
    | "Transportation & Accommodation Services"
    | "Home Goods Stores"
    | "Content & Apps"
    | "Auto Dealers"
    | "Government Agencies"
    | "Entities"
    | "Networking"
    | "Geography"
    | "Politician"
    | "All"
    | "Media"
    | "Brand"
  )[];
  filterUnrecommend?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | verified, unverified |
   */
  filterVerified?: "verified" | "unverified";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  followerEndTo?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  followerStartFrom?: number;
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   *
   * 35. AI Keyword Change
   */
  from?: string;
  gender?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | high, normal, decline |
   */
  growthPotentialType?: "high" | "normal" | "decline";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  interactiveRateBegin?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  interactiveRateEnd?: number;
  keyword?: string;
  /**
   * 關鍵字從哪裡來
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | keywordConnection |
   */
  keywordFrom?: "keywordConnection";
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolType?: string[];
  platform: string;
  postEndDate?: string;
  postStartDate?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
  sort?: string;
  view?: string;
}

export interface SearchForKolInCollectionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  projectName?: string;
}

export interface SearchForKolNoResultProperties {
  /**
   * 受眾年齡
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 13-17, 18-24, 25-34, 35-44, 45-54, 55-64, 65+ |
   */
  audienceAge?: "13-17" | "18-24" | "25-34" | "35-44" | "45-54" | "55-64" | "65+";
  /**
   * 受眾年齡％數區間的起始
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  audienceAgeRateBegin?: number;
  /**
   * 受眾年齡％數區間的終值
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  audienceAgeRateEnd?: number;
  /**
   * 受眾性別
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Male, Female |
   */
  audienceGender?: "Male" | "Female";
  /**
   * 受眾性別％數區間的起始值
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  audienceGenderRateBegin?: number;
  /**
   * 受眾性別％數區間的終值
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  audienceGenderRateEnd?: number;
  /**
   * 受眾地區
   */
  audienceGeo?: string;
  /**
   * 受眾地區％數區間的起始值
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  audienceGeoRateBegin?: number;
  /**
   * 受眾地區％數區間的終值
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  audienceGeoRateEnd?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  avgPvRateBegin?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  avgPvRateEnd?: number;
  /**
   * 若是一般搜尋或快速搜尋，不限區域則傳 unlimit
   */
  countryCode?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fansUpRateBegin?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  fansUpRateEnd?: number;
  filterCollection?: boolean;
  filterCooperation?: boolean;
  /**
   * 找網紅時可以篩選網紅與語言（多選）
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filterKolLanguage?: string[];
  /**
   * 選擇篩選掉哪些官方帳號
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  filterOfficialAccount?: (
    | "Restaurants"
    | "Professional Services"
    | "Grocery & Convenience Stores"
    | "Non-Profits & Religious Organizations"
    | "Local Events"
    | "Publishers"
    | "Business & Utility Services"
    | "Transportation & Accommodation Services"
    | "Home Goods Stores"
    | "Content & Apps"
    | "Auto Dealers"
    | "Government Agencies"
    | "Entities"
    | "Networking"
    | "Geography"
    | "Politician"
    | "All"
    | "Media"
    | "Brand"
  )[];
  filterUnrecommend?: boolean;
  /**
   * 認證網紅篩選
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | verified, unverified |
   */
  filterVerified?: "verified" | "unverified";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  followerEndTo?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  followerStartFrom?: number;
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   *
   * 35. AI Keyword Change
   */
  from?: string;
  gender?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | high, normal, decline |
   */
  growthPotentialType?: "high" | "normal" | "decline";
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  interactiveRateBegin?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  interactiveRateEnd?: number;
  keyword?: string;
  /**
   * 關鍵字從哪裡來
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | keywordConnection |
   */
  keywordFrom?: "keywordConnection";
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolType?: string[];
  platform: string;
  postEndDate?: string;
  postStartDate?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
  sort?: string;
  view?: string;
}

export interface SearchForKolWithAiProperties {
  keyword: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface SearchForKolWithAiFailProperties {
  keyword: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface SearchForKolWithAiNoResultProperties {
  keyword: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface SearchSimilarKolProperties {
  /**
   * 位於哪頁執行行為
   *
   * 1. Hashtag Ranking
   *
   * 2. Single Hashtag
   *
   * 3. KOL Detail
   *
   * 4. Pricing
   *
   * 5. Chrome Extension
   *
   * 6. Brand Analysis
   *
   * 7. Keyword Analysis
   *
   * 8. Quick Search
   *
   * 9. Search Result
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Hashtag Ranking, Single Hashtag, KOL Detail, Pricing, Chrome Extension, Brand Analysis, Mention Analysis, Keyword Analysis, Quick Search, Search Result |
   */
  at:
    | "Hashtag Ranking"
    | "Single Hashtag"
    | "KOL Detail"
    | "Pricing"
    | "Chrome Extension"
    | "Brand Analysis"
    | "Mention Analysis"
    | "Keyword Analysis"
    | "Quick Search"
    | "Search Result";
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId: string;
  path: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface SendKolInvitationProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolNames?: string[];
}

export interface SendMessagesProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolNames?: string[];
}

export interface SendReportInvitationProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  invitee: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportContentCount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportKolCount?: number;
  reportName?: string;
}

export interface ShowCurrentStatusByVipTrialProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Get Trial, In Trial, In Payment, Already used in Trial |
   */
  status: "Get Trial" | "In Trial" | "In Payment" | "Already used in Trial";
}

export interface ShowMoreKolProperties {
  /**
   * 新的 dc id array
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolDcIdResult?: string[];
  /**
   * KOL UUID
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolResult?: string[];
  path?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
  showMoreOnWhat?: string;
}

export interface ShowTutorialProperties {
  /**
   * * audience data
   *
   * * search
   *
   * * ...etc.
   */
  tutorialTopic?: string;
  /**
   * * product update
   *
   * * onbaording
   */
  tutorialType: string;
  /**
   * * 9.10.0
   *
   * * 9.11.0
   */
  version?: string;
}

export interface SingleLoginAlertProperties {
  firebaseRef: string;
  lastLoginTime: string;
  newestLoginTime: string;
}

export interface SomethingWentWrongProperties {
  path?: string;
}

export interface StartOnboardingTutorialProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Search Influencer, Influencer Detail, Competitor Analysis, Hashtag, Deep Report |
   */
  onboardingItem: "Search Influencer" | "Influencer Detail" | "Competitor Analysis" | "Hashtag" | "Deep Report";
}

export interface StayKolDetailProperties {
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  /**
   * KD 頁停留的指標區塊
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Overview, Similar influencers, Followers, Audience, Engagements, View Analysis, Post Frequency, Tag Distribution, Hashtag Analysis, Sponsored post analysis, Contents, Related Brands, Collaboration and Value, Related influencers, Post time analysis, Audience Authenticity, Key Metrics Summary, Rating Comparison |
   */
  kolMetrics:
    | "Overview"
    | "Similar influencers"
    | "Followers"
    | "Audience"
    | "Engagements"
    | "View Analysis"
    | "Post Frequency"
    | "Tag Distribution"
    | "Hashtag Analysis"
    | "Sponsored post analysis"
    | "Contents"
    | "Related Brands"
    | "Collaboration and Value"
    | "Related influencers"
    | "Post time analysis"
    | "Audience Authenticity"
    | "Key Metrics Summary"
    | "Rating Comparison";
  path?: string;
  platform: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
  /**
   * dc id，顯示的相似網紅，有多組
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolDcIds?: string[];
  /**
   * 顯示 KD 相似的網紅內的 KOL ID，若有多組顯示多組。
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolIds?: string[];
  /**
   * 顯示在 KD 中相似的網紅的名稱，若有 10 個則顯示 10 個。
   *  e.g. 珍妮弗, Yellow, Aaaa, Bbb, Ccc
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolNames?: string[];
}

export interface StayKolDetailSectionProperties {
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  /**
   * KD 頁停留的指標區塊
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Overview, Similar influencers, Followers, Audience, Engagements, View Analysis, Post Frequency, Tag Distribution, Hashtag Analysis, Sponsored post analysis, Contents, Related Brands, Collaboration and Value, Related influencers, Post time analysis, Audience Authenticity, Key Metrics Summary, Rating Comparison |
   */
  kolMetrics:
    | "Overview"
    | "Similar influencers"
    | "Followers"
    | "Audience"
    | "Engagements"
    | "View Analysis"
    | "Post Frequency"
    | "Tag Distribution"
    | "Hashtag Analysis"
    | "Sponsored post analysis"
    | "Contents"
    | "Related Brands"
    | "Collaboration and Value"
    | "Related influencers"
    | "Post time analysis"
    | "Audience Authenticity"
    | "Key Metrics Summary"
    | "Rating Comparison";
  kolName?: string;
  path?: string;
  platform?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
  /**
   * Describing the time in seconds for the events.
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  second: number;
  /**
   * Possible Values:
   *
   * * `setName`
   *
   * * `aboutTeam`
   *
   * * `choosePlan`
   *
   * New Campaign Reporting
   *
   * * ScoreCard
   *
   * * KOL Analysis
   *
   * * Platform Analysis
   *
   * * Post Analysis
   */
  section?: string;
  /**
   * dc id，顯示的相似網紅，有多組
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolDcIds?: string[];
  /**
   * 顯示 KD 相似的網紅內的 KOL ID，若有多組顯示多組。
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolIds?: string[];
  /**
   * 顯示在 KD 中相似的網紅的名稱，若有 10 個則顯示 10 個。
   *  e.g. 珍妮弗, Yellow, Aaaa, Bbb, Ccc
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolNames?: string[];
}

export interface StayKolDetailSection10SProperties {
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  /**
   * KD 頁停留的指標區塊
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Overview, Similar influencers, Followers, Audience, Engagements, View Analysis, Post Frequency, Tag Distribution, Hashtag Analysis, Sponsored post analysis, Contents, Related Brands, Collaboration and Value, Related influencers, Post time analysis, Audience Authenticity, Key Metrics Summary, Rating Comparison |
   */
  kolMetrics:
    | "Overview"
    | "Similar influencers"
    | "Followers"
    | "Audience"
    | "Engagements"
    | "View Analysis"
    | "Post Frequency"
    | "Tag Distribution"
    | "Hashtag Analysis"
    | "Sponsored post analysis"
    | "Contents"
    | "Related Brands"
    | "Collaboration and Value"
    | "Related influencers"
    | "Post time analysis"
    | "Audience Authenticity"
    | "Key Metrics Summary"
    | "Rating Comparison";
  kolName?: string;
  path?: string;
  platform?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
  /**
   * dc id，顯示的相似網紅，有多組
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolDcIds?: string[];
  /**
   * 顯示 KD 相似的網紅內的 KOL ID，若有多組顯示多組。
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolIds?: string[];
  /**
   * 顯示在 KD 中相似的網紅的名稱，若有 10 個則顯示 10 個。
   *  e.g. 珍妮弗, Yellow, Aaaa, Bbb, Ccc
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolNames?: string[];
}

export interface StayKolDetailSection30SProperties {
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  /**
   * KD 頁停留的指標區塊
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Overview, Similar influencers, Followers, Audience, Engagements, View Analysis, Post Frequency, Tag Distribution, Hashtag Analysis, Sponsored post analysis, Contents, Related Brands, Collaboration and Value, Related influencers, Post time analysis, Audience Authenticity, Key Metrics Summary, Rating Comparison |
   */
  kolMetrics:
    | "Overview"
    | "Similar influencers"
    | "Followers"
    | "Audience"
    | "Engagements"
    | "View Analysis"
    | "Post Frequency"
    | "Tag Distribution"
    | "Hashtag Analysis"
    | "Sponsored post analysis"
    | "Contents"
    | "Related Brands"
    | "Collaboration and Value"
    | "Related influencers"
    | "Post time analysis"
    | "Audience Authenticity"
    | "Key Metrics Summary"
    | "Rating Comparison";
  kolName?: string;
  path?: string;
  platform?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
  /**
   * dc id，顯示的相似網紅，有多組
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolDcIds?: string[];
  /**
   * 顯示 KD 相似的網紅內的 KOL ID，若有多組顯示多組。
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolIds?: string[];
  /**
   * 顯示在 KD 中相似的網紅的名稱，若有 10 個則顯示 10 個。
   *  e.g. 珍妮弗, Yellow, Aaaa, Bbb, Ccc
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolNames?: string[];
}

export interface StayKolDetailSection60SProperties {
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  /**
   * KD 頁停留的指標區塊
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Overview, Similar influencers, Followers, Audience, Engagements, View Analysis, Post Frequency, Tag Distribution, Hashtag Analysis, Sponsored post analysis, Contents, Related Brands, Collaboration and Value, Related influencers, Post time analysis, Audience Authenticity, Key Metrics Summary, Rating Comparison |
   */
  kolMetrics:
    | "Overview"
    | "Similar influencers"
    | "Followers"
    | "Audience"
    | "Engagements"
    | "View Analysis"
    | "Post Frequency"
    | "Tag Distribution"
    | "Hashtag Analysis"
    | "Sponsored post analysis"
    | "Contents"
    | "Related Brands"
    | "Collaboration and Value"
    | "Related influencers"
    | "Post time analysis"
    | "Audience Authenticity"
    | "Key Metrics Summary"
    | "Rating Comparison";
  kolName?: string;
  path?: string;
  platform?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
  /**
   * dc id，顯示的相似網紅，有多組
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolDcIds?: string[];
  /**
   * 顯示 KD 相似的網紅內的 KOL ID，若有多組顯示多組。
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolIds?: string[];
  /**
   * 顯示在 KD 中相似的網紅的名稱，若有 10 個則顯示 10 個。
   *  e.g. 珍妮弗, Yellow, Aaaa, Bbb, Ccc
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolNames?: string[];
}

export interface StopPaymentProperties {
  currency?: string;
  /**
   * 2024.08.27後會新增
   *
   * 1. premium_202408
   *
   * 2. advanced_202408
   *
   * 3. **trial_202408 (後台的新試用版，也就是跟corporate拆開了)**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | internal, free, enterprise, premium, premiumTrial, advanced, enterpriseTrail |
   */
  plan?: "internal" | "free" | "enterprise" | "premium" | "premiumTrial" | "advanced" | "enterpriseTrail";
  /**
   * The onyDay value will not available on production
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | month, quarter, year, oneDay, halfYear |
   */
  planPeriod?: "month" | "quarter" | "year" | "oneDay" | "halfYear";
  /**
   * 原幣別含稅價格
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  value?: number;
}

export interface SubmitEnterprisePlanProperties {
  currency?: string;
  /**
   * 2024.08.27後會新增
   *
   * 1. premium_202408
   *
   * 2. advanced_202408
   *
   * 3. **trial_202408 (後台的新試用版，也就是跟corporate拆開了)**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | internal, free, enterprise, premium, premiumTrial, advanced, enterpriseTrail |
   */
  plan?: "internal" | "free" | "enterprise" | "premium" | "premiumTrial" | "advanced" | "enterpriseTrail";
}

export interface SubmitForgetPasswordProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  emails: string[];
}

export interface SubmitForgetPasswordFailedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  emails: string[];
}

export interface ToggleSearchFilterProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | on, off |
   */
  switch: "on" | "off";
}

export interface ToggleToWorkspaceProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  workspaceID?: number;
  workspaceName?: string;
}

export interface TryAddCampaignInfluencersProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolNames?: string[];
  /**
   * the way users operate
   *
   * * add-influencers from Campaign: `collection`, `quick search`, `search result`, `community`, `campaign` , `report`, `kol detail`
   *
   *
   * * Add new Campaign: `Campaign List` , `Campaign Setting`, `recommendation`
   *
   *
   * * Preview Campaign: `new campaign`, `Setting`
   *
   * * verify email: `sso`, `email`
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | collection, quick search, search result, community, campaign, report, kol detail, campaign list, campaign setting, new campaign, setting, email, sso, recommendation |
   */
  method:
    | "collection"
    | "quick search"
    | "search result"
    | "community"
    | "campaign"
    | "report"
    | "kol detail"
    | "campaign list"
    | "campaign setting"
    | "new campaign"
    | "setting"
    | "email"
    | "sso"
    | "recommendation";
}

export interface TryEditingReportPreviewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportContentCount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportKolCount?: number;
  reportName?: string;
}

export interface TryExportCommunityKolProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  kolAmount?: number;
  multiSelected: boolean;
}

export interface TryGrantSnsPermissionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | Facebook, Instagram |
   */
  platform: "Facebook" | "Instagram";
}

export interface TryRemoveKolProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  kolId: number;
  kolName?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  projectName?: string;
}

export interface TrySearchForKolProperties {
  path?: string;
}

export interface TrySharingReportProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportContentCount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportKolCount?: number;
  reportName?: string;
}

export interface TryToExportKolDataProperties {
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
}

export interface UnbindSocialAccountFailProperties {
  /**
   * google, facebook, instagram
   */
  ssoPlatform?: string;
}

export interface UnbindSocialAccountSuccessProperties {
  /**
   * google, facebook, instagram
   */
  ssoPlatform?: string;
}

export interface UndoCanceledSubscriptionProperties {
  /**
   * 2024.08.27後會新增
   *
   * 1. premium_202408
   *
   * 2. advanced_202408
   *
   * 3. **trial_202408 (後台的新試用版，也就是跟corporate拆開了)**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | internal, free, enterprise, premium, premiumTrial, advanced, enterpriseTrail |
   */
  plan?: "internal" | "free" | "enterprise" | "premium" | "premiumTrial" | "advanced" | "enterpriseTrail";
  /**
   * The onyDay value will not available on production
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | month, quarter, year, oneDay, halfYear |
   */
  planPeriod?: "month" | "quarter" | "year" | "oneDay" | "halfYear";
}

export interface UnhideReportKolContentProperties {
  kolIId: string;
  kolName?: string;
  postLink?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportId: number;
  reportName?: string;
}

export interface UpdateCampaignProperties {
  /**
   * 建立活動時的預期每人合作金額
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignBudgetPerPerson: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
  campaignPeriod: string;
  /**
   * 活動是否公開顯示於我要接案列表
   */
  public: boolean;
  /**
   * 最終回覆日期 (Internal Campaign 沒有）
   */
  replyDate?: string;
}

export interface UpdateReportProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  reportKol?: string[];
  reportName?: string;
}

export interface UpdateReportSettingProperties {
  /**
   * * 在洞察報告的報告設定中，當【貼文上線期間】或【追蹤活動關鍵字/標籤】任一條件有變動時，在原本 `Update Report Setting` 事件
   *
   *   * 新增 dateKeywordChange 屬性
   *
   *     * 資料型態 : 布林值
   *
   *     * 若有變動為 True，若沒變動為 False
   */
  dateKeywordChange: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportBudget?: number;
  reportEndDate: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  reportKeyword?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  reportKol?: string[];
  reportName: string;
  reportStartDate: string;
}

export interface VerifyEmailSuccessfullyProperties {
  /**
   * the way users operate
   *
   * * add-influencers from Campaign: `collection`, `quick search`, `search result`, `community`, `campaign` , `report`, `kol detail`
   *
   *
   * * Add new Campaign: `Campaign List` , `Campaign Setting`, `recommendation`
   *
   *
   * * Preview Campaign: `new campaign`, `Setting`
   *
   * * verify email: `sso`, `email`
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | collection, quick search, search result, community, campaign, report, kol detail, campaign list, campaign setting, new campaign, setting, email, sso, recommendation |
   */
  method:
    | "collection"
    | "quick search"
    | "search result"
    | "community"
    | "campaign"
    | "report"
    | "kol detail"
    | "campaign list"
    | "campaign setting"
    | "new campaign"
    | "setting"
    | "email"
    | "sso"
    | "recommendation";
  /**
   * google, facebook, instagram
   */
  ssoPlatform?: string;
}

export interface ViewAiActionSearchResultProperties {
  /**
   * AI Action 模組顯示的 KOL DC ID 們
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  aiActionKolDcIds?: string[];
  /**
   * ai 行為模組出現的搜尋結果
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  aiActionResult?: string[];
  /**
   * 是否為這次搜尋的第一批結果
   *  True : 是這次搜尋的第一批結果 ( 這次搜尋第一次觸發的 `View AI Action Search Result`、`View AI Keyword Search Result` )
   *
   * False : 非第一批結果，例如點了換一組推薦結果，或在 keyword 模組按下右邊箭頭看更多 https://www.notion.so/ikala/Amp-bug-AI-e072322bb53a40c68729baa1ce66ff13?pvs=4#ff1187951d3942e4b65e89384bb4f00a
   */
  isFirstBatchResult: boolean;
  path?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ViewAiFilterSearchResultProperties {
  /**
   * AI Filter 模組提供的 KOL DC ID 們
   *
   * 只呈現用戶看的到的
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  aiFilterKolDcIds?: string[];
  /**
   * ai 篩選器模組出現的搜尋結果
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  aiFilterResult?: string[];
  /**
   * 針對既有 AI filter 模組作細分，若沒使用到這模組則為 null
   *  當篩選器有選擇預估合作費用/受眾，aiFilterModel = ‘budget’
   *
   * * 當沒選以上篩選器，而選擇了**類型/性別/追蹤/互動/觀看**
   *
   *   * 推該類型且為潛力網紅，aiFilterModel = ‘potential’
   *
   *   * 推該類型口碑價值前三名的網紅，aiFilterModel = ‘reputation’
   *
   *   * 推該類型互動率前三名，aiFilterModel = ‘interactive’
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | potential, reputation, interactive |
   */
  aiFilterType?: "potential" | "reputation" | "interactive";
  /**
   * 判斷是否有篩選合作費用/受眾，isFilterBudget = True / False
   */
  isFilterBudget?: boolean;
  path?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ViewAiKeywordSearchResultProperties {
  /**
   * AI Keyword 模組提供的 KOL DC ID 們
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  aiKeywordKolDcIds?: string[];
  /**
   * ai 關鍵字模組出現的搜尋結果
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  aiKeywordResult?: string[];
  /**
   * 是否為這次搜尋的第一批結果
   *  True : 是這次搜尋的第一批結果 ( 這次搜尋第一次觸發的 `View AI Action Search Result`、`View AI Keyword Search Result` )
   *
   * False : 非第一批結果，例如點了換一組推薦結果，或在 keyword 模組按下右邊箭頭看更多 https://www.notion.so/ikala/Amp-bug-AI-e072322bb53a40c68729baa1ce66ff13?pvs=4#ff1187951d3942e4b65e89384bb4f00a
   */
  isFirstBatchResult: boolean;
  path?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ViewLoginScreenProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | kol, ad |
   */
  userGroup: "kol" | "ad";
}

export interface ViewOriginalPostFromEmbeddedPostProperties {
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  postLink?: string;
}

export interface ViewReportingProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  campaignId: number;
  campaignName: string;
}

export interface ViewSearchContentResultProperties {
  /**
   * 新的 dc id array
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolDcIdResult?: string[];
  /**
   * KOL UUID
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolResult?: string[];
  /**
   * 提供 post id 的 array
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  postResult: string[];
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ViewSearchResultProperties {
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   *
   * 35. AI Keyword Change
   */
  from?: string;
  /**
   * show whether the event is emitted by default
   */
  isDefault: boolean;
  /**
   * 新的 dc id array
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolDcIdResult?: string[];
  /**
   * KOL UUID
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolResult?: string[];
  path?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface ViewSignupScreenProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | kol, ad |
   */
  userGroup?: "kol" | "ad";
}

export interface ViewSnsPostProperties {
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  path?: string;
  platform?: string;
  postLink?: string;
  postType?: string;
}

export interface VisitBrandAnalysisProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  projectKolCount?: number;
  projectName?: string;
}

export interface VisitBrandComparisonAnalysisProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  projectKolCount?: number;
  projectName?: string;
}

export interface VisitChromeExtensionWebstoreProperties {
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   *
   * 35. AI Keyword Change
   */
  from?: string;
  path?: string;
}

export interface VisitCollectionProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | KOL, Post |
   */
  collectionType: "KOL" | "Post";
  period?: string;
  platform?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  projectName?: string;
  view?: string;
}

export interface VisitKeywordAnalysisProperties {
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectKeywordCount: number;
  projectName?: string;
}

export interface VisitKeywordDataProperties {
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectKeywordCount: number;
  projectName?: string;
}

export interface VisitKolDetailProperties {
  /**
   * 針對既有 AI filter 模組作細分，若沒使用到這模組則為 null
   *  當篩選器有選擇預估合作費用/受眾，aiFilterModel = ‘budget’
   *
   * * 當沒選以上篩選器，而選擇了**類型/性別/追蹤/互動/觀看**
   *
   *   * 推該類型且為潛力網紅，aiFilterModel = ‘potential’
   *
   *   * 推該類型口碑價值前三名的網紅，aiFilterModel = ‘reputation’
   *
   *   * 推該類型互動率前三名，aiFilterModel = ‘interactive’
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | potential, reputation, interactive |
   */
  aiFilterType?: "potential" | "reputation" | "interactive";
  /**
   * 點選的結果是來自哪個 ai 模型
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | aiFilter, aiAction, aiKeyword |
   */
  aiType?: "aiFilter" | "aiAction" | "aiKeyword";
  /**
   * 紀錄拜訪 KOL KD 的來源
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | similarInfluencer, relatedInfluencer, socialAccountRanking, Landing Page, Deep Report, Checkout, Chatroom, Campaign, Collection, Competitor, hashtag, Quick Search, General Search, chrome-extension, Public KOL Search, Other Country, AI Module in General |
   */
  from?:
    | "similarInfluencer"
    | "relatedInfluencer"
    | "socialAccountRanking"
    | "Landing Page"
    | "Deep Report"
    | "Checkout"
    | "Chatroom"
    | "Campaign"
    | "Collection"
    | "Competitor"
    | "hashtag"
    | "Quick Search"
    | "General Search"
    | "chrome-extension"
    | "Public KOL Search"
    | "Other Country"
    | "AI Module in General";
  /**
   * 新版 dc iid，從哪個 kol 頁面來的
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  fromKolDcId?: string;
  /**
   * showing the origin of the traffic -- who generates the visit
   */
  fromKolId?: string;
  /**
   * name of the `fromKolId`
   */
  fromKolName?: string;
  /**
   * show whether the event is emitted by default
   */
  isDefault: boolean;
  /**
   * 判斷是否有篩選合作費用/受眾，isFilterBudget = True / False
   */
  isFilterBudget?: boolean;
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  keyword?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolCategory?: string[];
  kolCountry?: string;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolName: string;
  /**
   * 1. 新增 level 屬性，代表第幾層。若是從 `Search for KOL` 進來，透過 AI Action, AI Filter, AI Keyword, General Search 模組進入到 KD 頁，level = 1
   *
   *    1. 點擊「相似網紅」、「提及網紅」進到其他 KD 頁，level = 2，依此類推 3, 4, 5 .…
   *  https://www.notion.so/ikala/Amp-600a9485fd5949f8878675870bf5efd6
   *
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  level?: number;
  path?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  platform: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
  /**
   * dc id，顯示的相似網紅，有多組
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolDcIds?: string[];
  /**
   * 顯示 KD 相似的網紅內的 KOL ID，若有多組顯示多組。
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolIds?: string[];
  /**
   * 顯示在 KD 中相似的網紅的名稱，若有 10 個則顯示 10 個。
   *  e.g. 珍妮弗, Yellow, Aaaa, Bbb, Ccc
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  similarKolNames?: string[];
}

export interface VisitKolDetailFromPublicProperties {
  /**
   * 1. similarInfluencer
   *
   *  2. relatedInfluencer
   *
   *  3. downloadReport
   *
   *  4. downloadBillboard
   *
   *  5. KOL Search
   *
   *  6. AI Search
   *
   *  7. Public KOL Search
   *
   *  8. socialAccountRanking
   *
   *  9. Primary content types
   *
   * 10. KOL Sponsored Rate
   *
   * 11. WOMM Value Evaluation
   *
   * 12. Main audience gender
   *
   * 13. Engagement Analysis
   *
   * 14. browser bar
   *
   * 15. side button
   *
   * 16. chrome-extension
   *
   * 17. Brand Analysis
   *
   * 18. Mention Analysis
   *
   * 19. Landing Page
   *
   * 20. Billboard list
   *
   * 21. Billboard ranking
   *
   * 22. Deep Report
   *
   * 23. Checkout
   *
   * 24. Chatroom
   *
   * 25. Campaign
   *
   * 26. Collection
   *
   * 27. Competitor
   *
   * 28. hashtag
   *
   * 29. Quick Search
   *
   * 30. Keyword Analysis
   *
   * 31. General Search
   *
   * 32. banner
   *
   * 33. KOL Detail
   *
   * 34. Search Similar Influencer
   *
   * 35. AI Keyword Change
   */
  from?: string;
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  keyword?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolCategory?: string[];
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  kolName?: string;
  path?: string;
  platform?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface VisitKolSocialMediaProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | KD button, description, irm |
   */
  from: "KD button" | "description" | "irm";
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
  path?: string;
  platform?: string;
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export interface VisitMentionAnalysisProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  projectKolCount?: number;
  projectName?: string;
}

export interface VisitMentionDataProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  kolIds?: string[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  projectId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  projectKolCount?: number;
  projectName?: string;
}

export interface VisitReportOverviewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportContentCount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportId: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportKolCount?: number;
  reportName?: string;
}

export interface VisitReportPreviewProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportContentCount?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportId?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  reportKolCount?: number;
  reportName?: string;
}

export interface VisitSocialAccountRankingProperties {
  /**
   * 1. searchNoResult
   *
   * 2. moreResult
   *
   * 3. sideMenu
   */
  from?: string;
  /**
   * ranking type
   *
   * 1. IG
   *
   * 2. FB
   *
   * 3. YT
   */
  rankingType?: string;
}

export interface AnyPageAutoProperties {
  path?: string;
}

export interface KolDetailAutoProperties {
  /**
   * 表示這個 KOL Data 是否有解鎖
   */
  isUnlocked?: boolean;
  /**
   * 新的 dc id
   *  https://www.notion.so/ikala/FE-Search-KD-V3-Feature-flag-61cb9e4ca08f41bead6c92e84470ebc7?d=5891291c584a4f5a9d2eb9bb3b1a9aad#f936680600c74aab99f6a008947b0eff
   */
  kolDcId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  kolId?: string;
}

export interface SearchRelatedAutoProperties {
  /**
   * 希望可以對應到每次不同的搜尋，反之，Search for KOL 事件也希望可以回傳一個 searchId，這樣兩邊才可以對到。
   */
  searchId?: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountCreated implements BaseEvent {
  event_type = 'Account Created';

  constructor(
    public event_properties?: AccountCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountCreatedAd implements BaseEvent {
  event_type = 'Account Created AD';

  constructor(
    public event_properties: AccountCreatedAdProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountCreatedKol implements BaseEvent {
  event_type = 'Account Created KOL';

  constructor(
    public event_properties: AccountCreatedKolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountCreatedKolAgency implements BaseEvent {
  event_type = 'Account Created KOL Agency';

  constructor(
    public event_properties: AccountCreatedKolAgencyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountCreatingFailed implements BaseEvent {
  event_type = 'Account Creating Failed';

  constructor(
    public event_properties: AccountCreatingFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddCampaignInfluencersFailed implements BaseEvent {
  event_type = 'Add Campaign Influencers Failed';

  constructor(
    public event_properties: AddCampaignInfluencersFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddCampaignInfluencersSuccess implements BaseEvent {
  event_type = 'Add Campaign Influencers Success';

  constructor(
    public event_properties: AddCampaignInfluencersSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddCooperationCondition implements BaseEvent {
  event_type = 'Add Cooperation Condition';

  constructor(
    public event_properties: AddCooperationConditionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddCreditCard implements BaseEvent {
  event_type = 'Add Credit Card';
}

export class AddKeywordToCompetitiveAnalysis implements BaseEvent {
  event_type = 'Add Keyword to Competitive Analysis';

  constructor(
    public event_properties?: AddKeywordToCompetitiveAnalysisProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddKeywordToCompetitiveAnalysisFailed implements BaseEvent {
  event_type = 'Add Keyword to Competitive Analysis Failed';

  constructor(
    public event_properties?: AddKeywordToCompetitiveAnalysisFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddKolToCommunity implements BaseEvent {
  event_type = 'Add KOL to Community';

  constructor(
    public event_properties: AddKolToCommunityProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddKolToCompetitiveAnalysis implements BaseEvent {
  event_type = 'Add KOL to Competitive Analysis';

  constructor(
    public event_properties?: AddKolToCompetitiveAnalysisProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddKolToCompetitiveAnalysisFailed implements BaseEvent {
  event_type = 'Add KOL to Competitive Analysis Failed';

  constructor(
    public event_properties?: AddKolToCompetitiveAnalysisFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddMemberOfWorkspace implements BaseEvent {
  event_type = 'Add Member of Workspace';

  constructor(
    public event_properties: AddMemberOfWorkspaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddPromotionLink implements BaseEvent {
  event_type = 'Add Promotion Link';

  constructor(
    public event_properties: AddPromotionLinkProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddReportKol implements BaseEvent {
  event_type = 'Add Report KOL';

  constructor(
    public event_properties: AddReportKolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AddReportKolContent implements BaseEvent {
  event_type = 'Add Report KOL Content';
}

export class AddToProject implements BaseEvent {
  event_type = 'Add to Project';

  constructor(
    public event_properties: AddToProjectProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ApplyChangePlan implements BaseEvent {
  event_type = 'Apply Change Plan';

  constructor(
    public event_properties?: ApplyChangePlanProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AuthorizeFail implements BaseEvent {
  event_type = 'Authorize Fail';

  constructor(
    public event_properties?: AuthorizeFailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AuthorizeSuccessful implements BaseEvent {
  event_type = 'Authorize Successful';

  constructor(
    public event_properties: AuthorizeSuccessfulProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BindSocialAccountFail implements BaseEvent {
  event_type = 'Bind Social Account Fail';

  constructor(
    public event_properties: BindSocialAccountFailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BindSocialAccountSuccess implements BaseEvent {
  event_type = 'Bind Social Account Success';

  constructor(
    public event_properties: BindSocialAccountSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BlockedVipOnly implements BaseEvent {
  event_type = 'Blocked VIP Only';
}

export class CancelCooperation implements BaseEvent {
  event_type = 'Cancel Cooperation';

  constructor(
    public event_properties: CancelCooperationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CancelToCreateWorkspace implements BaseEvent {
  event_type = 'Cancel to Create Workspace';

  constructor(
    public event_properties: CancelToCreateWorkspaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CanceledSubscription implements BaseEvent {
  event_type = 'Canceled Subscription';

  constructor(
    public event_properties?: CanceledSubscriptionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CanceledSubscriptionCompleted implements BaseEvent {
  event_type = 'Canceled Subscription Completed';

  constructor(
    public event_properties?: CanceledSubscriptionCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeAiAction implements BaseEvent {
  event_type = 'Change AI Action';

  constructor(
    public event_properties: ChangeAiActionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeCollectionFilter implements BaseEvent {
  event_type = 'Change Collection Filter';

  constructor(
    public event_properties: ChangeCollectionFilterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeCollectionView implements BaseEvent {
  event_type = 'Change Collection View';

  constructor(
    public event_properties: ChangeCollectionViewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeCompetitiveChartData implements BaseEvent {
  event_type = 'Change Competitive Chart Data';

  constructor(
    public event_properties: ChangeCompetitiveChartDataProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeCompetitiveFilter implements BaseEvent {
  event_type = 'Change Competitive Filter';

  constructor(
    public event_properties: ChangeCompetitiveFilterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeCompetitiveOrMainBrand implements BaseEvent {
  event_type = 'Change Competitive or Main Brand';

  constructor(
    public event_properties: ChangeCompetitiveOrMainBrandProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeContentPlatform implements BaseEvent {
  event_type = 'Change Content Platform';

  constructor(
    public event_properties: ChangeContentPlatformProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeCreditCard implements BaseEvent {
  event_type = 'Change Credit Card';
}

export class ChangeFollowerScale implements BaseEvent {
  event_type = 'Change Follower Scale';

  constructor(
    public event_properties?: ChangeFollowerScaleProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeHashtagFilter implements BaseEvent {
  event_type = 'Change Hashtag Filter';

  constructor(
    public event_properties: ChangeHashtagFilterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeHashtagPlatform implements BaseEvent {
  event_type = 'Change Hashtag Platform';

  constructor(
    public event_properties: ChangeHashtagPlatformProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeLanguage implements BaseEvent {
  event_type = 'Change language';

  constructor(
    public event_properties: ChangeLanguageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeMentionContentRanking implements BaseEvent {
  event_type = 'Change Mention Content Ranking';

  constructor(
    public event_properties: ChangeMentionContentRankingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangePlatform implements BaseEvent {
  event_type = 'Change Platform';

  constructor(
    public event_properties?: ChangePlatformProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeRankingFilter implements BaseEvent {
  event_type = 'Change Ranking Filter';

  constructor(
    public event_properties: ChangeRankingFilterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeRankingPlatform implements BaseEvent {
  event_type = 'Change Ranking Platform';

  constructor(
    public event_properties: ChangeRankingPlatformProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeResultView implements BaseEvent {
  event_type = 'Change Result View';

  constructor(
    public event_properties: ChangeResultViewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeSorting implements BaseEvent {
  event_type = 'Change Sorting';

  constructor(
    public event_properties?: ChangeSortingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChangeSortingInCollection implements BaseEvent {
  event_type = 'Change Sorting in Collection';

  constructor(
    public event_properties?: ChangeSortingInCollectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChooseIdentity implements BaseEvent {
  event_type = 'Choose Identity';

  constructor(
    public event_properties: ChooseIdentityProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChooseKolDetailPeriodFilter implements BaseEvent {
  event_type = 'Choose KOL Detail Period Filter';

  constructor(
    public event_properties: ChooseKolDetailPeriodFilterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChooseKolDetailSectionFromPublic implements BaseEvent {
  event_type = 'Choose KOL Detail Section from Public';

  constructor(
    public event_properties: ChooseKolDetailSectionFromPublicProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChoosePlan implements BaseEvent {
  event_type = 'Choose Plan';

  constructor(
    public event_properties: ChoosePlanProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChooseSearchResult implements BaseEvent {
  event_type = 'Choose Search Result';

  constructor(
    public event_properties: ChooseSearchResultProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickAddMonitoringHashtag implements BaseEvent {
  event_type = 'Click Add Monitoring Hashtag';

  constructor(
    public event_properties?: ClickAddMonitoringHashtagProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickAdvancedFilterOnAllContent implements BaseEvent {
  event_type = 'Click Advanced Filter on All Content';

  constructor(
    public event_properties?: ClickAdvancedFilterOnAllContentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickAdvancedTrial implements BaseEvent {
  event_type = 'Click Advanced Trial';

  constructor(
    public event_properties?: ClickAdvancedTrialProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickAiKeywordChange implements BaseEvent {
  event_type = 'Click AI Keyword Change';

  constructor(
    public event_properties: ClickAiKeywordChangeProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickAiSearchFeedback implements BaseEvent {
  event_type = 'Click AI Search Feedback';

  constructor(
    public event_properties: ClickAiSearchFeedbackProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickAiSearchToggle implements BaseEvent {
  event_type = 'Click AI Search Toggle';

  constructor(
    public event_properties: ClickAiSearchToggleProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickCampaignPayment implements BaseEvent {
  event_type = 'Click Campaign Payment';

  constructor(
    public event_properties: ClickCampaignPaymentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickChangeCardInfo implements BaseEvent {
  event_type = 'Click Change Card Info';

  constructor(
    public event_properties: ClickChangeCardInfoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickDemo implements BaseEvent {
  event_type = 'Click Demo';

  constructor(
    public event_properties: ClickDemoProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickKScoreDetail implements BaseEvent {
  event_type = 'Click K-Score Detail';

  constructor(
    public event_properties?: ClickKScoreDetailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickKeywordConnection implements BaseEvent {
  event_type = 'Click Keyword Connection';

  constructor(
    public event_properties?: ClickKeywordConnectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickKolInfoFromMentionTrends implements BaseEvent {
  event_type = 'Click Kol Info From Mention Trends';

  constructor(
    public event_properties: ClickKolInfoFromMentionTrendsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickMarketplaceTrial implements BaseEvent {
  event_type = 'Click Marketplace Trial';
}

export class ClickMoreRecommendSimilarKol implements BaseEvent {
  event_type = 'Click More Recommend Similar Kol';

  constructor(
    public event_properties: ClickMoreRecommendSimilarKolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickPremiumTrial implements BaseEvent {
  event_type = 'Click Premium Trial';

  constructor(
    public event_properties: ClickPremiumTrialProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickPricing implements BaseEvent {
  event_type = 'Click Pricing';

  constructor(
    public event_properties?: ClickPricingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickPurchaseQuota implements BaseEvent {
  event_type = 'Click Purchase Quota';

  constructor(
    public event_properties?: ClickPurchaseQuotaProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickRecommendSimilarKol implements BaseEvent {
  event_type = 'Click Recommend Similar KOL';

  constructor(
    public event_properties: ClickRecommendSimilarKolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickRelatedKol implements BaseEvent {
  event_type = 'Click Related KOL';

  constructor(
    public event_properties?: ClickRelatedKolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickReportAudienceAuthenticityAccuracy implements BaseEvent {
  event_type = 'Click Report Audience authenticity Accuracy';

  constructor(
    public event_properties: ClickReportAudienceAuthenticityAccuracyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickReportContentTypeAccuracy implements BaseEvent {
  event_type = 'Click Report Content Type Accuracy';

  constructor(
    public event_properties: ClickReportContentTypeAccuracyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickReportKolAudienceAccuracy implements BaseEvent {
  event_type = 'Click Report KOL Audience Accuracy';

  constructor(
    public event_properties: ClickReportKolAudienceAccuracyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickReportNonSponsoredPost implements BaseEvent {
  event_type = 'Click Report Non Sponsored Post';

  constructor(
    public event_properties?: ClickReportNonSponsoredPostProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickReportSponsoredPost implements BaseEvent {
  event_type = 'Click Report Sponsored Post';

  constructor(
    public event_properties?: ClickReportSponsoredPostProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickReportSponsoredPostAccuracy implements BaseEvent {
  event_type = 'Click Report Sponsored Post Accuracy';

  constructor(
    public event_properties: ClickReportSponsoredPostAccuracyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickSearchResult implements BaseEvent {
  event_type = 'Click Search Result';

  constructor(
    public event_properties: ClickSearchResultProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickSearchTypeFilter implements BaseEvent {
  event_type = 'Click Search Type Filter';

  constructor(
    public event_properties: ClickSearchTypeFilterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickSideMenuList implements BaseEvent {
  event_type = 'Click Side Menu List';

  constructor(
    public event_properties: ClickSideMenuListProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickSideNavigator implements BaseEvent {
  event_type = 'Click Side Navigator';

  constructor(
    public event_properties: ClickSideNavigatorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickSimilarInfluencer implements BaseEvent {
  event_type = 'Click Similar Influencer';

  constructor(
    public event_properties: ClickSimilarInfluencerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickSingleHashtag implements BaseEvent {
  event_type = 'Click Single Hashtag';

  constructor(
    public event_properties: ClickSingleHashtagProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickSingleMonitoringHashtag implements BaseEvent {
  event_type = 'Click Single Monitoring Hashtag';

  constructor(
    public event_properties: ClickSingleMonitoringHashtagProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickSingleMonitoringHashtagFromCompetitor implements BaseEvent {
  event_type = 'Click Single Monitoring Hashtag From Competitor';

  constructor(
    public event_properties: ClickSingleMonitoringHashtagFromCompetitorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickTheNodeOnTrendAnalysis implements BaseEvent {
  event_type = 'Click the Node on Trend Analysis';

  constructor(
    public event_properties: ClickTheNodeOnTrendAnalysisProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickTheTypeFromQuickSearch implements BaseEvent {
  event_type = 'Click the Type From Quick Search';

  constructor(
    public event_properties: ClickTheTypeFromQuickSearchProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickToAddBooleanSyntax implements BaseEvent {
  event_type = 'Click to Add Boolean Syntax';

  constructor(
    public event_properties: ClickToAddBooleanSyntaxProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickToAddCreditCard implements BaseEvent {
  event_type = 'Click to Add Credit Card';
}

export class ClickToChangeCreditCard implements BaseEvent {
  event_type = 'Click to Change Credit Card';
}

export class ClickToDeleteCreditCard implements BaseEvent {
  event_type = 'Click to Delete Credit Card';
}

export class ClickToReportUnlistedInfluencer implements BaseEvent {
  event_type = 'Click to Report Unlisted Influencer';

  constructor(
    public event_properties?: ClickToReportUnlistedInfluencerProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickToSendEmail implements BaseEvent {
  event_type = 'Click to Send Email';

  constructor(
    public event_properties: ClickToSendEmailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickUnlockKolData implements BaseEvent {
  event_type = 'Click Unlock KOL Data';

  constructor(
    public event_properties?: ClickUnlockKolDataProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickUpgradePlan implements BaseEvent {
  event_type = 'Click Upgrade Plan';

  constructor(
    public event_properties: ClickUpgradePlanProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickWommSetting implements BaseEvent {
  event_type = 'Click WOMM Setting';

  constructor(
    public event_properties: ClickWommSettingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClickWorkspacePlan implements BaseEvent {
  event_type = 'Click Workspace Plan';

  constructor(
    public event_properties: ClickWorkspacePlanProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ClicksRegister implements BaseEvent {
  event_type = 'Clicks Register';

  constructor(
    public event_properties?: ClicksRegisterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CloseKScoreDetail implements BaseEvent {
  event_type = 'Close K-Score Detail';

  constructor(
    public event_properties?: CloseKScoreDetailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CloseOnboardingTutorial implements BaseEvent {
  event_type = 'Close Onboarding Tutorial';

  constructor(
    public event_properties: CloseOnboardingTutorialProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CloseTutorial implements BaseEvent {
  event_type = 'Close Tutorial';

  constructor(
    public event_properties: CloseTutorialProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CompleteMarketplaceTrialForm implements BaseEvent {
  event_type = 'Complete Marketplace Trial Form';
}

export class CompletePremiumTrialForm implements BaseEvent {
  event_type = 'Complete Premium Trial Form';
}

export class CompleteTrialActivation implements BaseEvent {
  event_type = 'Complete Trial Activation';

  constructor(
    public event_properties: CompleteTrialActivationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CompleteTutorial implements BaseEvent {
  event_type = 'Complete Tutorial';

  constructor(
    public event_properties: CompleteTutorialProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CompletedOnboarding implements BaseEvent {
  event_type = 'Completed onboarding';

  constructor(
    public event_properties: CompletedOnboardingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ConfirmIdentityChoice implements BaseEvent {
  event_type = 'Confirm Identity Choice';

  constructor(
    public event_properties: ConfirmIdentityChoiceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContinueToViewOnMobile implements BaseEvent {
  event_type = 'Continue to View on Mobile';
}

export class CreateCampaign implements BaseEvent {
  event_type = 'Create Campaign';

  constructor(
    public event_properties: CreateCampaignProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateCollection implements BaseEvent {
  event_type = 'Create Collection';

  constructor(
    public event_properties: CreateCollectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateCompetitiveAnalysis implements BaseEvent {
  event_type = 'Create Competitive Analysis';

  constructor(
    public event_properties?: CreateCompetitiveAnalysisProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateCompetitiveAnalysisFailed implements BaseEvent {
  event_type = 'Create Competitive Analysis Failed';

  constructor(
    public event_properties?: CreateCompetitiveAnalysisFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateContract implements BaseEvent {
  event_type = 'Create Contract';

  constructor(
    public event_properties: CreateContractProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateDeepReport implements BaseEvent {
  event_type = 'Create Deep Report';

  constructor(
    public event_properties: CreateDeepReportProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CreateWorkspace implements BaseEvent {
  event_type = 'Create Workspace';

  constructor(
    public event_properties?: CreateWorkspaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeepReportRedirect implements BaseEvent {
  event_type = 'Deep Report Redirect';
}

export class DeleteCampaign implements BaseEvent {
  event_type = 'Delete Campaign';

  constructor(
    public event_properties: DeleteCampaignProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeleteCreditCard implements BaseEvent {
  event_type = 'Delete Credit Card';
}

export class DeleteProject implements BaseEvent {
  event_type = 'Delete Project';

  constructor(
    public event_properties: DeleteProjectProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeleteReportKol implements BaseEvent {
  event_type = 'Delete Report KOL';

  constructor(
    public event_properties: DeleteReportKolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DeleteReportKolContent implements BaseEvent {
  event_type = 'Delete Report KOL Content';
}

export class DeleteWorkspace implements BaseEvent {
  event_type = 'Delete Workspace';

  constructor(
    public event_properties?: DeleteWorkspaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DraftContract implements BaseEvent {
  event_type = 'Draft Contract';

  constructor(
    public event_properties: DraftContractProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class DuplicateCollection implements BaseEvent {
  event_type = 'Duplicate Collection';

  constructor(
    public event_properties: DuplicateCollectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditIrmTags implements BaseEvent {
  event_type = 'Edit IRM Tags';

  constructor(
    public event_properties?: EditIrmTagsProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditMemberOfWorkspace implements BaseEvent {
  event_type = 'Edit Member of Workspace';

  constructor(
    public event_properties: EditMemberOfWorkspaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class EditReportKolContent implements BaseEvent {
  event_type = 'Edit Report KOL Content';
}

export class EditWorkspace implements BaseEvent {
  event_type = 'Edit Workspace';

  constructor(
    public event_properties?: EditWorkspaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExitKolDetail implements BaseEvent {
  event_type = 'Exit KOL Detail';

  constructor(
    public event_properties: ExitKolDetailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExitSearchResult implements BaseEvent {
  event_type = 'Exit Search Result';

  constructor(
    public event_properties: ExitSearchResultProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExportCampaignReportContent implements BaseEvent {
  event_type = 'Export Campaign Report Content';

  constructor(
    public event_properties: ExportCampaignReportContentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExportCollection implements BaseEvent {
  event_type = 'Export Collection';

  constructor(
    public event_properties: ExportCollectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExportCollectionFailed implements BaseEvent {
  event_type = 'Export Collection Failed';

  constructor(
    public event_properties: ExportCollectionFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExportCollectionOverLimit implements BaseEvent {
  event_type = 'Export Collection Over Limit';

  constructor(
    public event_properties: ExportCollectionOverLimitProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExportCommunityKolFail implements BaseEvent {
  event_type = 'Export Community KOL Fail';

  constructor(
    public event_properties: ExportCommunityKolFailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExportKolData implements BaseEvent {
  event_type = 'Export KOL Data';

  constructor(
    public event_properties: ExportKolDataProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExportMentionPostAndKol implements BaseEvent {
  event_type = 'Export Mention Post and KOL';

  constructor(
    public event_properties?: ExportMentionPostAndKolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ExportReportContent implements BaseEvent {
  event_type = 'Export Report Content';

  constructor(
    public event_properties: ExportReportContentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FailToPayAfter3D implements BaseEvent {
  event_type = 'Fail to Pay After 3D';

  constructor(
    public event_properties?: FailToPayAfter3DProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FailToPayBefore3D implements BaseEvent {
  event_type = 'Fail to Pay Before 3D';

  constructor(
    public event_properties?: FailToPayBefore3DProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FansSelector implements BaseEvent {
  event_type = 'Fans Selector';

  constructor(
    public event_properties: FansSelectorProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FilterInCommunity implements BaseEvent {
  event_type = 'Filter in Community';

  constructor(
    public event_properties: FilterInCommunityProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FilterInCommunityFailed implements BaseEvent {
  event_type = 'Filter in Community Failed';

  constructor(
    public event_properties: FilterInCommunityFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FilterInCommunityNoResult implements BaseEvent {
  event_type = 'Filter in Community No Result';

  constructor(
    public event_properties: FilterInCommunityNoResultProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FilterReportingKol implements BaseEvent {
  event_type = 'Filter Reporting KOL';

  constructor(
    public event_properties: FilterReportingKolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FilterReportingPlatform implements BaseEvent {
  event_type = 'Filter Reporting Platform';

  constructor(
    public event_properties: FilterReportingPlatformProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GoToPrivacy implements BaseEvent {
  event_type = 'Go to privacy';
}

export class GoToTerms implements BaseEvent {
  event_type = 'Go to Terms';
}

export class HideReportKolContent implements BaseEvent {
  event_type = 'Hide Report KOL Content';

  constructor(
    public event_properties: HideReportKolContentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ImportKolFailed implements BaseEvent {
  event_type = 'Import KOL Failed';

  constructor(
    public event_properties: ImportKolFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ImportKolSuccess implements BaseEvent {
  event_type = 'Import KOL Success';

  constructor(
    public event_properties: ImportKolSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class InterestedInCommentSentimentAnalysis implements BaseEvent {
  event_type = 'Interested in Comment Sentiment Analysis';
}

export class KolAcceptContract implements BaseEvent {
  event_type = 'KOL Accept Contract';

  constructor(
    public event_properties: KolAcceptContractProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class KolVisitCampaign implements BaseEvent {
  event_type = 'KOL Visit Campaign';

  constructor(
    public event_properties: KolVisitCampaignProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class KolVisitCampaignList implements BaseEvent {
  event_type = 'KOL Visit Campaign List';
}

export class KolVisitPublicCampaign implements BaseEvent {
  event_type = 'KOL Visit Public Campaign';

  constructor(
    public event_properties: KolVisitPublicCampaignProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class KolVisitPublicCampaignList implements BaseEvent {
  event_type = 'KOL Visit Public Campaign List';
}

export class LeaveMarketplaceTrialForm implements BaseEvent {
  event_type = 'Leave Marketplace Trial Form';
}

export class LeavePremiumTrialForm implements BaseEvent {
  event_type = 'Leave Premium Trial Form';
}

export class LeaveReportPreview implements BaseEvent {
  event_type = 'Leave Report Preview';

  constructor(
    public event_properties: LeaveReportPreviewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LeaveWorkspace implements BaseEvent {
  event_type = 'Leave Workspace';

  constructor(
    public event_properties?: LeaveWorkspaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoginFail implements BaseEvent {
  event_type = 'Login Fail';

  constructor(
    public event_properties: LoginFailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoginSuccess implements BaseEvent {
  event_type = 'Login Success';

  constructor(
    public event_properties: LoginSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LogoutSuccess implements BaseEvent {
  event_type = 'Logout Success';

  constructor(
    public event_properties: LogoutSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MoveCompetitorOrder implements BaseEvent {
  event_type = 'Move Competitor Order';

  constructor(
    public event_properties?: MoveCompetitorOrderProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PopUpDetectCrawlerNotify implements BaseEvent {
  event_type = 'Pop Up Detect Crawler Notify';

  constructor(
    public event_properties: PopUpDetectCrawlerNotifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PopUpPurchaseQuotaNotify implements BaseEvent {
  event_type = 'Pop Up Purchase Quota Notify';
}

export class PopUpUpgradeNotify implements BaseEvent {
  event_type = 'Pop Up Upgrade Notify';

  constructor(
    public event_properties?: PopUpUpgradeNotifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PreviewCampaign implements BaseEvent {
  event_type = 'Preview Campaign';

  constructor(
    public event_properties: PreviewCampaignProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PreviewKeywordPost implements BaseEvent {
  event_type = 'Preview Keyword Post';

  constructor(
    public event_properties: PreviewKeywordPostProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class QuickSearchForKol implements BaseEvent {
  event_type = 'Quick Search for KOL';

  constructor(
    public event_properties?: QuickSearchForKolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReadTutorial implements BaseEvent {
  event_type = 'Read Tutorial';

  constructor(
    public event_properties: ReadTutorialProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RedeemCouponFailed implements BaseEvent {
  event_type = 'Redeem Coupon Failed';

  constructor(
    public event_properties: RedeemCouponFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RedeemCouponSuccess implements BaseEvent {
  event_type = 'Redeem Coupon Success';

  constructor(
    public event_properties: RedeemCouponSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RefreshAiAction implements BaseEvent {
  event_type = 'Refresh AI Action';

  constructor(
    public event_properties: RefreshAiActionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RefreshPage implements BaseEvent {
  event_type = 'Refresh Page';

  constructor(
    public event_properties?: RefreshPageProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RemoveCollection implements BaseEvent {
  event_type = 'Remove Collection';

  constructor(
    public event_properties?: RemoveCollectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RemoveKol implements BaseEvent {
  event_type = 'Remove KOL';

  constructor(
    public event_properties?: RemoveKolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RemoveMemberOfWorkspace implements BaseEvent {
  event_type = 'Remove Member of Workspace';

  constructor(
    public event_properties: RemoveMemberOfWorkspaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RemovePostFromCollection implements BaseEvent {
  event_type = 'Remove Post from Collection';

  constructor(
    public event_properties: RemovePostFromCollectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RenameProject implements BaseEvent {
  event_type = 'Rename Project';
}

export class ReplayOnboarding implements BaseEvent {
  event_type = 'Replay Onboarding';

  constructor(
    public event_properties: ReplayOnboardingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReplayOnboardingTutorial implements BaseEvent {
  event_type = 'Replay Onboarding Tutorial';
}

export class ReportContentTypeIsIncorrect implements BaseEvent {
  event_type = 'Report Content Type Is Incorrect';

  constructor(
    public event_properties?: ReportContentTypeIsIncorrectProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RequestMoreHashtagInfluencers implements BaseEvent {
  event_type = 'Request More Hashtag Influencers';

  constructor(
    public event_properties: RequestMoreHashtagInfluencersProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ResendVerifyEmail implements BaseEvent {
  event_type = 'Resend verify email';

  constructor(
    public event_properties: ResendVerifyEmailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ReturnUnlistedKol implements BaseEvent {
  event_type = 'Return Unlisted KOL';

  constructor(
    public event_properties: ReturnUnlistedKolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SaveEditingReportPreview implements BaseEvent {
  event_type = 'Save Editing Report Preview';

  constructor(
    public event_properties: SaveEditingReportPreviewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SaveThePostToCollectionFailed implements BaseEvent {
  event_type = 'Save the Post to Collection Failed';

  constructor(
    public event_properties?: SaveThePostToCollectionFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ScrollKolDetail implements BaseEvent {
  event_type = 'Scroll KOL Detail';

  constructor(
    public event_properties: ScrollKolDetailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ScrollSearchResult implements BaseEvent {
  event_type = 'Scroll Search Result';

  constructor(
    public event_properties?: ScrollSearchResultProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchForCreativeContentInKd implements BaseEvent {
  event_type = 'Search for Creative Content in KD';

  constructor(
    public event_properties: SearchForCreativeContentInKdProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchForKol implements BaseEvent {
  event_type = 'Search for KOL';

  constructor(
    public event_properties: SearchForKolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchForKolContent implements BaseEvent {
  event_type = 'Search for KOL Content';

  constructor(
    public event_properties: SearchForKolContentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchForKolContentFail implements BaseEvent {
  event_type = 'Search for KOL Content Fail';

  constructor(
    public event_properties: SearchForKolContentFailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchForKolContentNoResult implements BaseEvent {
  event_type = 'Search for KOL Content No Result';

  constructor(
    public event_properties: SearchForKolContentNoResultProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchForKolFail implements BaseEvent {
  event_type = 'Search for KOL Fail';

  constructor(
    public event_properties: SearchForKolFailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchForKolInCollection implements BaseEvent {
  event_type = 'Search for KOL in Collection';

  constructor(
    public event_properties?: SearchForKolInCollectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchForKolNoResult implements BaseEvent {
  event_type = 'Search for KOL No Result';

  constructor(
    public event_properties: SearchForKolNoResultProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchForKolWithAi implements BaseEvent {
  event_type = 'Search for KOL with AI';

  constructor(
    public event_properties: SearchForKolWithAiProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchForKolWithAiFail implements BaseEvent {
  event_type = 'Search for KOL with AI Fail';

  constructor(
    public event_properties: SearchForKolWithAiFailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchForKolWithAiNoResult implements BaseEvent {
  event_type = 'Search for KOL with AI No Result';

  constructor(
    public event_properties: SearchForKolWithAiNoResultProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchSimilarKol implements BaseEvent {
  event_type = 'Search Similar KOL';

  constructor(
    public event_properties: SearchSimilarKolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendKolInvitation implements BaseEvent {
  event_type = 'Send KOL Invitation';

  constructor(
    public event_properties: SendKolInvitationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendMessages implements BaseEvent {
  event_type = 'Send Messages';

  constructor(
    public event_properties: SendMessagesProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SendReportInvitation implements BaseEvent {
  event_type = 'Send Report Invitation';

  constructor(
    public event_properties: SendReportInvitationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShowCurrentStatusByVipTrial implements BaseEvent {
  event_type = 'Show Current Status by VIP Trial';

  constructor(
    public event_properties: ShowCurrentStatusByVipTrialProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShowMoreKol implements BaseEvent {
  event_type = 'Show More KOL';

  constructor(
    public event_properties?: ShowMoreKolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ShowSearchGuide implements BaseEvent {
  event_type = 'Show Search Guide';
}

export class ShowTutorial implements BaseEvent {
  event_type = 'Show Tutorial';

  constructor(
    public event_properties: ShowTutorialProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SingleLoginAlert implements BaseEvent {
  event_type = 'Single Login Alert';

  constructor(
    public event_properties: SingleLoginAlertProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SomethingWentWrong implements BaseEvent {
  event_type = 'Something Went Wrong';

  constructor(
    public event_properties?: SomethingWentWrongProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StartOnboardingTutorial implements BaseEvent {
  event_type = 'Start Onboarding Tutorial';

  constructor(
    public event_properties: StartOnboardingTutorialProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StartPurchase implements BaseEvent {
  event_type = 'Start Purchase';
}

export class StayKolDetail implements BaseEvent {
  event_type = 'Stay KOL Detail';

  constructor(
    public event_properties: StayKolDetailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StayKolDetailSection implements BaseEvent {
  event_type = 'Stay KOL Detail Section';

  constructor(
    public event_properties: StayKolDetailSectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StayKolDetailSection10S implements BaseEvent {
  event_type = 'Stay KOL Detail Section 10S';

  constructor(
    public event_properties: StayKolDetailSection10SProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StayKolDetailSection30S implements BaseEvent {
  event_type = 'Stay KOL Detail Section 30S';

  constructor(
    public event_properties: StayKolDetailSection30SProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StayKolDetailSection60S implements BaseEvent {
  event_type = 'Stay KOL Detail Section 60S';

  constructor(
    public event_properties: StayKolDetailSection60SProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class StopPayment implements BaseEvent {
  event_type = 'Stop Payment';

  constructor(
    public event_properties?: StopPaymentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitEnterprisePlan implements BaseEvent {
  event_type = 'Submit Enterprise Plan';

  constructor(
    public event_properties?: SubmitEnterprisePlanProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitForgetPassword implements BaseEvent {
  event_type = 'Submit Forget Password';

  constructor(
    public event_properties: SubmitForgetPasswordProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubmitForgetPasswordFailed implements BaseEvent {
  event_type = 'Submit Forget Password Failed';

  constructor(
    public event_properties: SubmitForgetPasswordFailedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ToggleSearchFilter implements BaseEvent {
  event_type = 'Toggle Search Filter';

  constructor(
    public event_properties: ToggleSearchFilterProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ToggleToWorkspace implements BaseEvent {
  event_type = 'Toggle to Workspace';

  constructor(
    public event_properties?: ToggleToWorkspaceProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TryAddCampaignInfluencers implements BaseEvent {
  event_type = 'Try Add Campaign Influencers';

  constructor(
    public event_properties: TryAddCampaignInfluencersProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TryEditingReportPreview implements BaseEvent {
  event_type = 'Try Editing Report Preview';

  constructor(
    public event_properties: TryEditingReportPreviewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TryExportCommunityKol implements BaseEvent {
  event_type = 'Try Export Community KOL';

  constructor(
    public event_properties: TryExportCommunityKolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TryGrantSnsPermission implements BaseEvent {
  event_type = 'Try Grant SNS Permission';

  constructor(
    public event_properties: TryGrantSnsPermissionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TryImportKol implements BaseEvent {
  event_type = 'Try Import KOL';
}

export class TryRemoveKol implements BaseEvent {
  event_type = 'Try Remove KOL';

  constructor(
    public event_properties: TryRemoveKolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TrySearchForKol implements BaseEvent {
  event_type = 'Try Search for KOL';

  constructor(
    public event_properties?: TrySearchForKolProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TrySearchForKolContent implements BaseEvent {
  event_type = 'Try Search for KOL Content';
}

export class TrySharingReport implements BaseEvent {
  event_type = 'Try Sharing Report';

  constructor(
    public event_properties?: TrySharingReportProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class TryToExportKolData implements BaseEvent {
  event_type = 'Try to Export KOL Data';

  constructor(
    public event_properties?: TryToExportKolDataProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UnbindSocialAccountFail implements BaseEvent {
  event_type = 'Unbind Social Account Fail';

  constructor(
    public event_properties?: UnbindSocialAccountFailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UnbindSocialAccountSuccess implements BaseEvent {
  event_type = 'Unbind Social Account Success';

  constructor(
    public event_properties?: UnbindSocialAccountSuccessProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UndoCanceledSubscription implements BaseEvent {
  event_type = 'Undo Canceled Subscription';

  constructor(
    public event_properties?: UndoCanceledSubscriptionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UnhideReportKolContent implements BaseEvent {
  event_type = 'Unhide Report KOL Content';

  constructor(
    public event_properties: UnhideReportKolContentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UpdateCampaign implements BaseEvent {
  event_type = 'Update Campaign';

  constructor(
    public event_properties: UpdateCampaignProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UpdateReport implements BaseEvent {
  event_type = 'Update Report';

  constructor(
    public event_properties: UpdateReportProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UpdateReportSetting implements BaseEvent {
  event_type = 'Update Report Setting';

  constructor(
    public event_properties: UpdateReportSettingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VerifyEmailSuccessfully implements BaseEvent {
  event_type = 'Verify email successfully';

  constructor(
    public event_properties: VerifyEmailSuccessfullyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewAiActionSearchResult implements BaseEvent {
  event_type = 'View AI Action Search Result';

  constructor(
    public event_properties: ViewAiActionSearchResultProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewAiFilterSearchResult implements BaseEvent {
  event_type = 'View AI Filter Search Result';

  constructor(
    public event_properties?: ViewAiFilterSearchResultProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewAiKeywordSearchResult implements BaseEvent {
  event_type = 'View AI Keyword Search Result';

  constructor(
    public event_properties: ViewAiKeywordSearchResultProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewLoginScreen implements BaseEvent {
  event_type = 'View Login Screen';

  constructor(
    public event_properties: ViewLoginScreenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewOriginalPostFromEmbeddedPost implements BaseEvent {
  event_type = 'View Original Post From Embedded Post';

  constructor(
    public event_properties?: ViewOriginalPostFromEmbeddedPostProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewReporting implements BaseEvent {
  event_type = 'View Reporting';

  constructor(
    public event_properties: ViewReportingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewSearchContentResult implements BaseEvent {
  event_type = 'View Search Content Result';

  constructor(
    public event_properties: ViewSearchContentResultProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewSearchResult implements BaseEvent {
  event_type = 'View Search Result';

  constructor(
    public event_properties: ViewSearchResultProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewSignupScreen implements BaseEvent {
  event_type = 'View Signup Screen';

  constructor(
    public event_properties?: ViewSignupScreenProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ViewSnsPost implements BaseEvent {
  event_type = 'View SNS Post';

  constructor(
    public event_properties?: ViewSnsPostProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VisitBrandAnalysis implements BaseEvent {
  event_type = 'Visit Brand Analysis';

  constructor(
    public event_properties?: VisitBrandAnalysisProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VisitBrandComparisonAnalysis implements BaseEvent {
  event_type = 'Visit Brand Comparison Analysis';

  constructor(
    public event_properties?: VisitBrandComparisonAnalysisProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VisitCampaignList implements BaseEvent {
  event_type = 'Visit Campaign List';
}

export class VisitChromeExtensionWebstore implements BaseEvent {
  event_type = 'Visit Chrome Extension Webstore';

  constructor(
    public event_properties?: VisitChromeExtensionWebstoreProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VisitCollection implements BaseEvent {
  event_type = 'Visit Collection';

  constructor(
    public event_properties: VisitCollectionProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VisitCommunityPage implements BaseEvent {
  event_type = 'Visit Community Page';
}

export class VisitDeepReportList implements BaseEvent {
  event_type = 'Visit Deep Report List';
}

export class VisitKeywordAnalysis implements BaseEvent {
  event_type = 'Visit Keyword Analysis';

  constructor(
    public event_properties: VisitKeywordAnalysisProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VisitKeywordData implements BaseEvent {
  event_type = 'Visit Keyword Data';

  constructor(
    public event_properties: VisitKeywordDataProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VisitKolDetail implements BaseEvent {
  event_type = 'Visit KOL Detail';

  constructor(
    public event_properties: VisitKolDetailProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VisitKolDetailFromPublic implements BaseEvent {
  event_type = 'Visit KOL Detail from Public';

  constructor(
    public event_properties?: VisitKolDetailFromPublicProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VisitKolSocialMedia implements BaseEvent {
  event_type = 'Visit KOL Social Media';

  constructor(
    public event_properties: VisitKolSocialMediaProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VisitMentionAnalysis implements BaseEvent {
  event_type = 'Visit Mention Analysis';

  constructor(
    public event_properties?: VisitMentionAnalysisProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VisitMentionData implements BaseEvent {
  event_type = 'Visit Mention Data';

  constructor(
    public event_properties?: VisitMentionDataProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VisitReportOverview implements BaseEvent {
  event_type = 'Visit Report Overview';

  constructor(
    public event_properties: VisitReportOverviewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VisitReportPreview implements BaseEvent {
  event_type = 'Visit Report Preview';

  constructor(
    public event_properties?: VisitReportPreviewProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VisitSocialAccountRanking implements BaseEvent {
  event_type = 'Visit Social Account Ranking';

  constructor(
    public event_properties?: VisitSocialAccountRankingProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VisitUnlockQuotaLog implements BaseEvent {
  event_type = 'Visit Unlock Quota Log';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Account Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Account%20Created)
   *
   * when user successfully creates their account
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  accountCreated(
    properties?: AccountCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountCreated(properties), options);
  }

  /**
   * Account Created AD
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Account%20Created%20AD)
   *
   * When ad successfully creates their account
   *
   * Owner: Retso Huang
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  accountCreatedAd(
    properties: AccountCreatedAdProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountCreatedAd(properties), options);
  }

  /**
   * Account Created KOL
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Account%20Created%20KOL)
   *
   * When KOL successfully creates their account
   *
   * Owner: Retso Huang
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  accountCreatedKol(
    properties: AccountCreatedKolProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountCreatedKol(properties), options);
  }

  /**
   * Account Created KOL Agency
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Account%20Created%20KOL%20Agency)
   *
   * When KOL agency successfully creates their account
   *
   * Owner: Retso Huang
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  accountCreatedKolAgency(
    properties: AccountCreatedKolAgencyProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountCreatedKolAgency(properties), options);
  }

  /**
   * Account Creating Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Account%20Creating%20Failed)
   *
   * when user failed to create their account
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. path)
   * @param options Amplitude event options.
   */
  accountCreatingFailed(
    properties: AccountCreatingFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AccountCreatingFailed(properties), options);
  }

  /**
   * Add Campaign Influencers Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Add%20Campaign%20Influencers%20Failed)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  addCampaignInfluencersFailed(
    properties: AddCampaignInfluencersFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddCampaignInfluencersFailed(properties), options);
  }

  /**
   * Add Campaign Influencers Success
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Add%20Campaign%20Influencers%20Success)
   *
   * when users add influencers to the campaign successfully
   *
   *
   * may add from Campaign page or other page, e.g. KOL Detail or Collection, etc.
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  addCampaignInfluencersSuccess(
    properties: AddCampaignInfluencersSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddCampaignInfluencersSuccess(properties), options);
  }

  /**
   * Add Cooperation Condition
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Add%20Cooperation%20Condition)
   *
   *
   *
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  addCooperationCondition(
    properties: AddCooperationConditionProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddCooperationCondition(properties), options);
  }

  /**
   * Add Credit Card
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Add%20Credit%20Card)
   *
   * 新增信用卡
   *
   * @param options Amplitude event options.
   */
  addCreditCard(
    options?: EventOptions,
  ) {
    return this.track(new AddCreditCard(), options);
  }

  /**
   * Add Keyword to Competitive Analysis
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Add%20Keyword%20to%20Competitive%20Analysis)
   *
   * 在關鍵字報表新增關鍵字
   *
   * @param properties The event's properties (e.g. keywords)
   * @param options Amplitude event options.
   */
  addKeywordToCompetitiveAnalysis(
    properties?: AddKeywordToCompetitiveAnalysisProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddKeywordToCompetitiveAnalysis(properties), options);
  }

  /**
   * Add Keyword to Competitive Analysis Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Add%20Keyword%20to%20Competitive%20Analysis%20Failed)
   *
   * **在關鍵字報表新增關鍵字時失敗**
   *
   * @param properties The event's properties (e.g. keywords)
   * @param options Amplitude event options.
   */
  addKeywordToCompetitiveAnalysisFailed(
    properties?: AddKeywordToCompetitiveAnalysisFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddKeywordToCompetitiveAnalysisFailed(properties), options);
  }

  /**
   * Add KOL to Community
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Add%20KOL%20to%20Community)
   *
   * Owner: Retso Huang
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  addKolToCommunity(
    properties: AddKolToCommunityProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddKolToCommunity(properties), options);
  }

  /**
   * Add KOL to Competitive Analysis
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Add%20KOL%20to%20Competitive%20Analysis)
   *
   * 成功新增網紅或品牌到品牌分析 or 提及分析
   *
   * @param properties The event's properties (e.g. at)
   * @param options Amplitude event options.
   */
  addKolToCompetitiveAnalysis(
    properties?: AddKolToCompetitiveAnalysisProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddKolToCompetitiveAnalysis(properties), options);
  }

  /**
   * Add KOL to Competitive Analysis Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Add%20KOL%20to%20Competitive%20Analysis%20Failed)
   *
   * **新增網紅或品牌到品牌分析 or 提及分析失敗**
   *
   * @param properties The event's properties (e.g. at)
   * @param options Amplitude event options.
   */
  addKolToCompetitiveAnalysisFailed(
    properties?: AddKolToCompetitiveAnalysisFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddKolToCompetitiveAnalysisFailed(properties), options);
  }

  /**
   * Add Member of Workspace
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Add%20Member%20of%20Workspace)
   *
   * * 登入品牌主並前往 `/workspace-member`
   *
   *   * 輸入 Email 後點擊傳送邀請
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. memberEmails)
   * @param options Amplitude event options.
   */
  addMemberOfWorkspace(
    properties: AddMemberOfWorkspaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddMemberOfWorkspace(properties), options);
  }

  /**
   * Add Promotion Link
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Add%20Promotion%20Link)
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  addPromotionLink(
    properties: AddPromotionLinkProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddPromotionLink(properties), options);
  }

  /**
   * Add Report KOL
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Add%20Report%20KOL)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  addReportKol(
    properties: AddReportKolProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddReportKol(properties), options);
  }

  /**
   * Add Report KOL Content
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Add%20Report%20KOL%20Content)
   *
   * Owner: Finn Yeh
   *
   * @param options Amplitude event options.
   */
  addReportKolContent(
    options?: EventOptions,
  ) {
    return this.track(new AddReportKolContent(), options);
  }

  /**
   * Add to Project
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Add%20to%20Project)
   *
   * This event tracks when a user save the influencer to the target collection, which may take place in different pages, e.g. in Search Result table, KOL Detail page, etc.
   *
   *
   * In KOL Detail page
   *
   *
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. collectionType)
   * @param options Amplitude event options.
   */
  addToProject(
    properties: AddToProjectProperties,
    options?: EventOptions,
  ) {
    return this.track(new AddToProject(properties), options);
  }

  /**
   * Apply Change Plan
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Apply%20Change%20Plan)
   *
   * 申請完成變更方案
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. periodAfter)
   * @param options Amplitude event options.
   */
  applyChangePlan(
    properties?: ApplyChangePlanProperties,
    options?: EventOptions,
  ) {
    return this.track(new ApplyChangePlan(properties), options);
  }

  /**
   * Authorize Fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Authorize%20Fail)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. platform)
   * @param options Amplitude event options.
   */
  authorizeFail(
    properties?: AuthorizeFailProperties,
    options?: EventOptions,
  ) {
    return this.track(new AuthorizeFail(properties), options);
  }

  /**
   * Authorize Successful
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Authorize%20Successful)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. isSelectAllPermission)
   * @param options Amplitude event options.
   */
  authorizeSuccessful(
    properties: AuthorizeSuccessfulProperties,
    options?: EventOptions,
  ) {
    return this.track(new AuthorizeSuccessful(properties), options);
  }

  /**
   * Bind Social Account Fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Bind%20Social%20Account%20Fail)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. ssoPlatform)
   * @param options Amplitude event options.
   */
  bindSocialAccountFail(
    properties: BindSocialAccountFailProperties,
    options?: EventOptions,
  ) {
    return this.track(new BindSocialAccountFail(properties), options);
  }

  /**
   * Bind Social Account Success
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Bind%20Social%20Account%20Success)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. ssoPlatform)
   * @param options Amplitude event options.
   */
  bindSocialAccountSuccess(
    properties: BindSocialAccountSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new BindSocialAccountSuccess(properties), options);
  }

  /**
   * Blocked VIP Only
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Blocked%20VIP%20Only)
   *
   * Owner: Retso Huang
   *
   * @param options Amplitude event options.
   */
  blockedVipOnly(
    options?: EventOptions,
  ) {
    return this.track(new BlockedVipOnly(), options);
  }

  /**
   * Cancel Cooperation
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Cancel%20Cooperation)
   *
   *
   *
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  cancelCooperation(
    properties: CancelCooperationProperties,
    options?: EventOptions,
  ) {
    return this.track(new CancelCooperation(properties), options);
  }

  /**
   * Cancel to Create Workspace
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Cancel%20to%20Create%20Workspace)
   *
   * Owner: abbie.lin@ikala.tv
   *
   * @param properties The event's properties (e.g. section)
   * @param options Amplitude event options.
   */
  cancelToCreateWorkspace(
    properties: CancelToCreateWorkspaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new CancelToCreateWorkspace(properties), options);
  }

  /**
   * Canceled Subscription
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Canceled%20Subscription)
   *
   *
   *
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  canceledSubscription(
    properties?: CanceledSubscriptionProperties,
    options?: EventOptions,
  ) {
    return this.track(new CanceledSubscription(properties), options);
  }

  /**
   * Canceled Subscription Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Canceled%20Subscription%20Completed)
   *
   * 最終完成取消訂閱觸發
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  canceledSubscriptionCompleted(
    properties?: CanceledSubscriptionCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CanceledSubscriptionCompleted(properties), options);
  }

  /**
   * Change AI Action
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20AI%20Action)
   *
   * 點擊 AI 行為模組的 【推薦我常分享超商零食的網紅】等 
   *  https://www.notion.so/ikala/AI-Search-AI-c974d2bae3684cbea91305e8a96fe2b7
   *
   * @param properties The event's properties (e.g. aiActionKolDcIds)
   * @param options Amplitude event options.
   */
  changeAiAction(
    properties: ChangeAiActionProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeAiAction(properties), options);
  }

  /**
   * Change Collection Filter
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20Collection%20Filter)
   *
   * * 前往 `/projects`
   *
   *   * 檢視任一收藏庫
   *
   *   * 切換至列表模式
   *
   *   * 切換任一平台或週期即觸發此事件
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. collectionType)
   * @param options Amplitude event options.
   */
  changeCollectionFilter(
    properties: ChangeCollectionFilterProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeCollectionFilter(properties), options);
  }

  /**
   * Change Collection View
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20Collection%20View)
   *
   * * 前往 `/projects`
   *
   *   * 檢視任一收藏庫
   *
   *   * 切換至列表模式
   *
   *   * 切換任一檢視模式即觸發此事件
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. collectionType)
   * @param options Amplitude event options.
   */
  changeCollectionView(
    properties: ChangeCollectionViewProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeCollectionView(properties), options);
  }

  /**
   * Change Competitive Chart Data
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20Competitive%20Chart%20Data)
   *
   * 切換競品品牌圖表數據
   *
   * @param properties The event's properties (e.g. at)
   * @param options Amplitude event options.
   */
  changeCompetitiveChartData(
    properties: ChangeCompetitiveChartDataProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeCompetitiveChartData(properties), options);
  }

  /**
   * Change Competitive Filter
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20Competitive%20Filter)
   *
   * 切換競品分析報表平台、時間、品牌帳號與我的品牌
   *
   * @param properties The event's properties (e.g. at)
   * @param options Amplitude event options.
   */
  changeCompetitiveFilter(
    properties: ChangeCompetitiveFilterProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeCompetitiveFilter(properties), options);
  }

  /**
   * Change Competitive or Main Brand
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20Competitive%20or%20Main%20Brand)
   *
   * 在圖表中切換競品或我的品牌
   *
   * @param properties The event's properties (e.g. at)
   * @param options Amplitude event options.
   */
  changeCompetitiveOrMainBrand(
    properties: ChangeCompetitiveOrMainBrandProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeCompetitiveOrMainBrand(properties), options);
  }

  /**
   * Change Content Platform
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20Content%20Platform)
   *
   * 在 KD 頁面的貼文區塊，提供下拉選單選平台
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  changeContentPlatform(
    properties: ChangeContentPlatformProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeContentPlatform(properties), options);
  }

  /**
   * Change Credit Card
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20Credit%20Card)
   *
   * 成功變更信用卡
   *
   * @param options Amplitude event options.
   */
  changeCreditCard(
    options?: EventOptions,
  ) {
    return this.track(new ChangeCreditCard(), options);
  }

  /**
   * Change Follower Scale
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20Follower%20Scale)
   *
   * 在競品分析中推薦網紅中切換網紅量級
   *
   * @param properties The event's properties (e.g. at)
   * @param options Amplitude event options.
   */
  changeFollowerScale(
    properties?: ChangeFollowerScaleProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeFollowerScale(properties), options);
  }

  /**
   * Change Hashtag Filter
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20Hashtag%20Filter)
   *
   * 調整篩選器
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. at)
   * @param options Amplitude event options.
   */
  changeHashtagFilter(
    properties: ChangeHashtagFilterProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeHashtagFilter(properties), options);
  }

  /**
   * Change Hashtag Platform
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20Hashtag%20Platform)
   *
   * Hashtag 頁面調整平台
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. path)
   * @param options Amplitude event options.
   */
  changeHashtagPlatform(
    properties: ChangeHashtagPlatformProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeHashtagPlatform(properties), options);
  }

  /**
   * Change language
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20language)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. at)
   * @param options Amplitude event options.
   */
  changeLanguage(
    properties: ChangeLanguageProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeLanguage(properties), options);
  }

  /**
   * Change Mention Content Ranking
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20Mention%20Content%20Ranking)
   *
   * 切換提及內容的排名
   *
   * @param properties The event's properties (e.g. at)
   * @param options Amplitude event options.
   */
  changeMentionContentRanking(
    properties: ChangeMentionContentRankingProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeMentionContentRanking(properties), options);
  }

  /**
   * Change Platform
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20Platform)
   *
   * * 前往任一 KD 頁
   *
   *   * 點擊切換平台
   *
   *   * 
   *
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  changePlatform(
    properties?: ChangePlatformProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangePlatform(properties), options);
  }

  /**
   * Change Ranking Filter
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20Ranking%20Filter)
   *
   * 調整排行榜篩選器
   *
   *
   * @param properties The event's properties (e.g. brandFilter)
   * @param options Amplitude event options.
   */
  changeRankingFilter(
    properties: ChangeRankingFilterProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeRankingFilter(properties), options);
  }

  /**
   * Change Ranking Platform
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20Ranking%20Platform)
   *
   * 調整排行榜平台
   *
   *
   *
   *
   * @param properties The event's properties (e.g. path)
   * @param options Amplitude event options.
   */
  changeRankingPlatform(
    properties: ChangeRankingPlatformProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeRankingPlatform(properties), options);
  }

  /**
   * Change Result View
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20Result%20View)
   *
   * * 前往 `/search` 的 `探索網紅` Tab
   *
   *   * 切換任一檢視模式
   *
   *   * 
   *
   *
   * * 前往 `/search` 的 `合作過的網紅` Tab
   *
   *   * 切換任一檢視模式
   *
   *   * 
   *
   *
   * * 前往 `/search` 的 `標記的網紅` Tab
   *
   *   * 切換任一檢視模式
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. view)
   * @param options Amplitude event options.
   */
  changeResultView(
    properties: ChangeResultViewProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeResultView(properties), options);
  }

  /**
   * Change Sorting
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20Sorting)
   *
   * * 前往 `/search`
   *
   *   * 切換任一排序
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. sortBy)
   * @param options Amplitude event options.
   */
  changeSorting(
    properties?: ChangeSortingProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeSorting(properties), options);
  }

  /**
   * Change Sorting in Collection
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Change%20Sorting%20in%20Collection)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. projectId)
   * @param options Amplitude event options.
   */
  changeSortingInCollection(
    properties?: ChangeSortingInCollectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChangeSortingInCollection(properties), options);
  }

  /**
   * Choose Identity
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Choose%20Identity)
   *
   * * 前往 `/register`
   *
   *   * 點擊任一身份後觸發
   *
   *   * 
   *
   *
   * Owner: abbie.lin@ikala.tv
   *
   * @param properties The event's properties (e.g. identity)
   * @param options Amplitude event options.
   */
  chooseIdentity(
    properties: ChooseIdentityProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChooseIdentity(properties), options);
  }

  /**
   * Choose KOL Detail Period Filter
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Choose%20KOL%20Detail%20Period%20Filter)
   *
   * When User clicks the period filter and chooses the time range.
   *
   *
   *
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. isUnlocked)
   * @param options Amplitude event options.
   */
  chooseKolDetailPeriodFilter(
    properties: ChooseKolDetailPeriodFilterProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChooseKolDetailPeriodFilter(properties), options);
  }

  /**
   * Choose KOL Detail Section from Public
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Choose%20KOL%20Detail%20Section%20from%20Public)
   *
   * 點擊網紅詳細頁面的區塊
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. kolCategory)
   * @param options Amplitude event options.
   */
  chooseKolDetailSectionFromPublic(
    properties: ChooseKolDetailSectionFromPublicProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChooseKolDetailSectionFromPublic(properties), options);
  }

  /**
   * Choose Plan
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Choose%20Plan)
   *
   * when user click to choose 3-1 or 3-2.
   *
   * ![](https://s3.us-west-2.amazonaws.com/secure.notion-static.com/d740f9a1-0766-48a1-8749-5d341d88c54c/Screen\_Shot\_2022-06-28\_at\_9.52.12\_PM.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220704%2Fus-west-2%2Fs3%2Faws4\_request&X-Amz-Date=20220704T065216Z&X-Amz-Expires=86400&X-Amz-Signature=e45c90c9329d804cb03168806108de88c54399f04ce798aaa07521ffe388b7d6&X-Amz-SignedHeaders=host&response-content-disposition=filename%20%3D%22Screen%2520Shot%25202022-06-28%2520at%25209.52.12%2520PM.png%22&x-id=GetObject)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  choosePlan(
    properties: ChoosePlanProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChoosePlan(properties), options);
  }

  /**
   * Choose Search Result
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Choose%20Search%20Result)
   *
   * * 前往 `/search`
   *
   *   * 於輸入匡輸入關鍵字
   *
   *   * 
   *
   *
   *   * 點擊任一種搜尋結果 （KOL or 以關鍵字搜尋）
   *
   *   * 
   *
   *
   * * 前往非 `/search` 功能頁
   *
   *   * 於左上角搜尋匡輸入關鍵字
   *
   *   * 
   *
   *
   *   * 點擊任一種搜尋結果 （KOL or 以關鍵字搜尋）
   *
   *   * ![](https://com-amplitude-orbit-media-prod.s3.us-west-2.amazonaws.com/50203/a90847a3-cd19-4e43-87d0-3101b3ee1aa6?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIAVGZURZG7VRPHN6LC%2F20230721%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20230721T032310Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjECoaCXVzLXdlc3QtMiJIMEYCIQDXAwT69YbDXl%2Beg5IocQhu%2Fg1FWhGSe43HWKQnbD3bUQIhALbQmXQU7GPHU73P1hDsFBo8lGFW%2FXz5DZdaw7155h5hKr4FCLP%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMzU4MjAzMTE1OTY3IgxyTkVmyuG1FRzk5JIqkgWraqSuv7r%2FXtP1ZG6D9DOAgVQNPrJfDhxTbmh17YN7aYdtkv%2Fn%2BsbznX8Giwe9kGwEz6gm6R%2BE6p1oz%2BBioVuGHce03Fs4%2FYGn7cuWoRriQbl71U2ygJ9j4d1pJBf6qqR6kn31cZVkvc%2FdIJmA%2BPJEVXlOpxnXpSX8hNHQ1niIKo9%2FUTaFdqDNUmOm2xAKID%2FOIefPOV%2FA1CtZSuAiY7iJIK8F7klI9uqOqumWbVc3AgyEyg8gfmvpdgXSR%2B9vB46EfRo3J3iV8aj%2FR0a8hMnvDdR%2Fjpkz23dHHkk3zqgHHoSR05vqLCggqi0kuAbX8jrfoCRMr6GYMROBjZm%2FvWSqSGavY2mYZGk8U%2BxR3SxmPMdPXT3TrIrpr1gEVX3PomyGmsQpMHv0S%2FOXxUgEUPf%2F1GRtKdZZSNe2x9eSDeAg7na8cWhrtSPAVfa%2BWEkW0NWcjyl87hd4b2bRJtLy7uQwKRXDyzB3b6rsXHGvWeiEt83W0aFFjvUpIggc%2FQOuBtbk0Wq0ajUSL8O4QGPx%2Fs6YKPYnBauJRPZ8OPnkjs7bGGEn8vDuB6XvjEpt4EMV6oJDVERMy7vq9zDQOJuRRuN7McjuIv0TEQCCQm03zXUqBLAP6rGu1dYV32yNoEK96MuttjKG6rmI3bAfJvhgnlHcsI3bFwixzoqnlOtF6zWkIW6%2B%2Fh8ZQVr7NAVB2xFkGCjOxYByCFFVWuKGyAVhGVi7VcNQWDk5sLLyv0XmLwwtDKgukEUXFLUOXbO65GvDIkAbJ87lONSP08V5xjvmumfTD6BlMrM859FkHjml9KtjrFO3hH0bCmEDFd9c9X0qfC%2FZ0ava5MZ5pWjnAeJoAU23h9zXvFYF9PowzLdEqMktHdEgML3C56UGOrABm5tM6jN86J1FClNapVxwiaZWPPmv%2BCGEXcV6pYwlkDD7KLz%2FVtbk5WkJwQhODK0oKw4mFW6weWxoZGkyr0ubOmavI2xyZs3u3cYnxudq18wcg%2ByaCiUaQfxi1qdp0ZURX0fYFE8O45QClU7yKWjhVl407RmwTmNfU0KeYl0VIfcu5R%2Fof4hdFbjFHtKTvvRLTDOWnh%2BCBH599l1%2FNsM3sf3U74z6tab5hLbglVn9zJs%3D&X-Amz-Signature=465c92603256044c97b10fc20874fc0e08e4997b5692ed1e8e63881a3f9df7e1&X-Amz-SignedHeaders=host&x-id=GetObject)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. path)
   * @param options Amplitude event options.
   */
  chooseSearchResult(
    properties: ChooseSearchResultProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChooseSearchResult(properties), options);
  }

  /**
   * Click Add Monitoring Hashtag
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Add%20Monitoring%20Hashtag)
   *
   * 點擊新增關鍵字（前端按鈕，尚未成功新增）
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  clickAddMonitoringHashtag(
    properties?: ClickAddMonitoringHashtagProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickAddMonitoringHashtag(properties), options);
  }

  /**
   * Click Advanced Filter on All Content
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Advanced%20Filter%20on%20All%20Content)
   *
   *
   *
   *
   * 在創作內容點擊進階篩選按鈕
   *
   * @param properties The event's properties (e.g. isUnlocked)
   * @param options Amplitude event options.
   */
  clickAdvancedFilterOnAllContent(
    properties?: ClickAdvancedFilterOnAllContentProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickAdvancedFilterOnAllContent(properties), options);
  }

  /**
   * Click Advanced Trial
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Advanced%20Trial)
   *
   * 申請 Advanced 試用
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. path)
   * @param options Amplitude event options.
   */
  clickAdvancedTrial(
    properties?: ClickAdvancedTrialProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickAdvancedTrial(properties), options);
  }

  /**
   * Click AI Keyword Change
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20AI%20Keyword%20Change)
   *
   * 點擊 AI 關鍵字模組的【你可能還想找這些關鍵字】
   *
   * @param properties The event's properties (e.g. keywordClick)
   * @param options Amplitude event options.
   */
  clickAiKeywordChange(
    properties: ClickAiKeywordChangeProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickAiKeywordChange(properties), options);
  }

  /**
   * Click AI Search Feedback
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20AI%20Search%20Feedback)
   *
   * 點擊 AI 搜尋結果的回饋意見時
   *
   * @param properties The event's properties (e.g. aiType)
   * @param options Amplitude event options.
   */
  clickAiSearchFeedback(
    properties: ClickAiSearchFeedbackProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickAiSearchFeedback(properties), options);
  }

  /**
   * Click AI Search Toggle
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20AI%20Search%20Toggle)
   *
   * 當點擊 AI 搜尋模組的開啟或關閉按鈕時觸發
   *
   * @param properties The event's properties (e.g. after)
   * @param options Amplitude event options.
   */
  clickAiSearchToggle(
    properties: ClickAiSearchToggleProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickAiSearchToggle(properties), options);
  }

  /**
   * Click Campaign Payment
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Campaign%20Payment)
   *
   * 使用者於活動媒合中點擊付款
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  clickCampaignPayment(
    properties: ClickCampaignPaymentProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickCampaignPayment(properties), options);
  }

  /**
   * Click Change Card Info
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Change%20Card%20Info)
   *
   * 業主於付款流程點擊更換卡片
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  clickChangeCardInfo(
    properties: ClickChangeCardInfoProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickChangeCardInfo(properties), options);
  }

  /**
   * Click Demo
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Demo)
   *
   * 當使用者點擊預約線上 Demo 時，傳送新事件 Click Demo 
   *  [\[Amp\] 點擊預約線上 Demo 事件 (notion.so)](https://www.notion.so/ikala/Amp-Demo-2a606ab2799548a5ace017ab4793349b?d=8efb3096fd8e4eda966db478e22294a5#976bf6c9aeee4939aa330fd39468bd6c)
   *
   * @param properties The event's properties (e.g. clickDemoSource)
   * @param options Amplitude event options.
   */
  clickDemo(
    properties: ClickDemoProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickDemo(properties), options);
  }

  /**
   * Click K-Score Detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20K-Score%20Detail)
   *
   * 點擊查看 K-Score 分部細節
   *
   *
   *
   *
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. kolDcId)
   * @param options Amplitude event options.
   */
  clickKScoreDetail(
    properties?: ClickKScoreDetailProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickKScoreDetail(properties), options);
  }

  /**
   * Click Keyword Connection
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Keyword%20Connection)
   *
   * 點擊推薦關鍵字時
   *
   * @param properties The event's properties (e.g. keyword)
   * @param options Amplitude event options.
   */
  clickKeywordConnection(
    properties?: ClickKeywordConnectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickKeywordConnection(properties), options);
  }

  /**
   * Click Kol Info From Mention Trends
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Kol%20Info%20From%20Mention%20Trends)
   *
   * 從提及成效趨勢點擊去看網紅數據或是貼文內容
   *
   * @param properties The event's properties (e.g. at)
   * @param options Amplitude event options.
   */
  clickKolInfoFromMentionTrends(
    properties: ClickKolInfoFromMentionTrendsProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickKolInfoFromMentionTrends(properties), options);
  }

  /**
   * Click Marketplace Trial
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Marketplace%20Trial)
   *
   * 點擊商案媒合申請搶先試用按鈕
   *
   *
   * Owner: PeiYing Tsai
   *
   * @param options Amplitude event options.
   */
  clickMarketplaceTrial(
    options?: EventOptions,
  ) {
    return this.track(new ClickMarketplaceTrial(), options);
  }

  /**
   * Click More Recommend Similar Kol
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20More%20Recommend%20Similar%20Kol)
   *
   * 點擊還想看更多相似網紅，並跳到搜尋
   *
   * @param properties The event's properties (e.g. at)
   * @param options Amplitude event options.
   */
  clickMoreRecommendSimilarKol(
    properties: ClickMoreRecommendSimilarKolProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickMoreRecommendSimilarKol(properties), options);
  }

  /**
   * Click Premium Trial
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Premium%20Trial)
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. path)
   * @param options Amplitude event options.
   */
  clickPremiumTrial(
    properties: ClickPremiumTrialProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickPremiumTrial(properties), options);
  }

  /**
   * Click Pricing
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Pricing)
   *
   * when user click top navigator - Pricing on root page
   *
   * Owner: abbie.lin@ikala.tv
   *
   * @param properties The event's properties (e.g. path)
   * @param options Amplitude event options.
   */
  clickPricing(
    properties?: ClickPricingProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickPricing(properties), options);
  }

  /**
   * Click Purchase Quota
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Purchase%20Quota)
   *
   * 點擊加購次數 (popup and 方案內容）
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. at)
   * @param options Amplitude event options.
   */
  clickPurchaseQuota(
    properties?: ClickPurchaseQuotaProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickPurchaseQuota(properties), options);
  }

  /**
   * Click Recommend Similar KOL
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Recommend%20Similar%20KOL)
   *
   * 從提及分析中點擊推薦的相似網紅
   *
   * @param properties The event's properties (e.g. kolId)
   * @param options Amplitude event options.
   */
  clickRecommendSimilarKol(
    properties: ClickRecommendSimilarKolProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickRecommendSimilarKol(properties), options);
  }

  /**
   * Click Related KOL
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Related%20KOL)
   *
   * Click related KOL to see ranking list on KD
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  clickRelatedKol(
    properties?: ClickRelatedKolProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickRelatedKol(properties), options);
  }

  /**
   * Click Report Audience authenticity Accuracy
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Report%20Audience%20authenticity%20Accuracy)
   *
   * 回報網紅受眾真實性的準確性，可以點讚跟倒讚
   *
   * @param properties The event's properties (e.g. isAccurate)
   * @param options Amplitude event options.
   */
  clickReportAudienceAuthenticityAccuracy(
    properties: ClickReportAudienceAuthenticityAccuracyProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickReportAudienceAuthenticityAccuracy(properties), options);
  }

  /**
   * Click Report Content Type Accuracy
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Report%20Content%20Type%20Accuracy)
   *
   * 回報貼文類型的準確性，可以點讚跟倒讚
   *
   * @param properties The event's properties (e.g. isAccurate)
   * @param options Amplitude event options.
   */
  clickReportContentTypeAccuracy(
    properties: ClickReportContentTypeAccuracyProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickReportContentTypeAccuracy(properties), options);
  }

  /**
   * Click Report KOL Audience Accuracy
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Report%20KOL%20Audience%20Accuracy)
   *
   * When users try to report and open the report form for the influencer audience accurary
   *
   * @param properties The event's properties (e.g. isAccurate)
   * @param options Amplitude event options.
   */
  clickReportKolAudienceAccuracy(
    properties: ClickReportKolAudienceAccuracyProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickReportKolAudienceAccuracy(properties), options);
  }

  /**
   * Click Report Non Sponsored Post
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Report%20Non%20Sponsored%20Post)
   *
   *
   *
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. kolId)
   * @param options Amplitude event options.
   */
  clickReportNonSponsoredPost(
    properties?: ClickReportNonSponsoredPostProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickReportNonSponsoredPost(properties), options);
  }

  /**
   * Click Report Sponsored Post
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Report%20Sponsored%20Post)
   *
   *
   *
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. kolId)
   * @param options Amplitude event options.
   */
  clickReportSponsoredPost(
    properties?: ClickReportSponsoredPostProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickReportSponsoredPost(properties), options);
  }

  /**
   * Click Report Sponsored Post Accuracy
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Report%20Sponsored%20Post%20Accuracy)
   *
   * 回報商業合作分析區塊的準確性，可以點讚跟倒讚
   *
   * @param properties The event's properties (e.g. isAccurate)
   * @param options Amplitude event options.
   */
  clickReportSponsoredPostAccuracy(
    properties: ClickReportSponsoredPostAccuracyProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickReportSponsoredPostAccuracy(properties), options);
  }

  /**
   * Click Search Result
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Search%20Result)
   *
   * * Objecitve: 目的是想知道搜尋後點擊了哪一個結果，因此需要向前知道搜尋的情況，向後知道搜尋的行為
   *
   * * Definition: 當使用者點擊任一搜尋網紅結果
   *
   * Steps
   *
   * * 前往 KOL 搜尋頁 `/search`
   *
   * * 在任一檢視模式點擊搜尋結果時觸發
   *
   *   * Table View 區點擊特定區塊
   *
   *   * 
   *
   *
   * @param properties The event's properties (e.g. aiFilterType)
   * @param options Amplitude event options.
   */
  clickSearchResult(
    properties: ClickSearchResultProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickSearchResult(properties), options);
  }

  /**
   * Click Search Type Filter
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Search%20Type%20Filter)
   *
   * 於搜尋頁點擊【類型】或完成【類型】篩選時觸發
   *
   * @param properties The event's properties (e.g. keyword)
   * @param options Amplitude event options.
   */
  clickSearchTypeFilter(
    properties: ClickSearchTypeFilterProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickSearchTypeFilter(properties), options);
  }

  /**
   * Click Side Menu List
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Side%20Menu%20List)
   *
   * 點擊 app 右方功能按鈕
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. feature)
   * @param options Amplitude event options.
   */
  clickSideMenuList(
    properties: ClickSideMenuListProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickSideMenuList(properties), options);
  }

  /**
   * Click Side Navigator
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Side%20Navigator)
   *
   * * 前往任一 KD 頁
   *
   *   * 點擊側邊欄
   *
   *   * 
   *
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. kolDcId)
   * @param options Amplitude event options.
   */
  clickSideNavigator(
    properties: ClickSideNavigatorProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickSideNavigator(properties), options);
  }

  /**
   * Click Similar Influencer
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Similar%20Influencer)
   *
   * * Objective: 分析 funnel 時可以更直觀歸因
   *
   * * Definition: 當使用者點擊相似網紅任一結果
   *
   *
   *
   *
   * @param properties The event's properties (e.g. kolDcId)
   * @param options Amplitude event options.
   */
  clickSimilarInfluencer(
    properties: ClickSimilarInfluencerProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickSimilarInfluencer(properties), options);
  }

  /**
   * Click Single Hashtag
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Single%20Hashtag)
   *
   * 點擊查看單一 hashtag 頁面
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. hashtagName)
   * @param options Amplitude event options.
   */
  clickSingleHashtag(
    properties: ClickSingleHashtagProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickSingleHashtag(properties), options);
  }

  /**
   * Click Single Monitoring Hashtag
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Single%20Monitoring%20Hashtag)
   *
   * 點擊查看單一 監測 hashtag 頁面
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  clickSingleMonitoringHashtag(
    properties: ClickSingleMonitoringHashtagProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickSingleMonitoringHashtag(properties), options);
  }

  /**
   * Click Single Monitoring Hashtag From Competitor
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Single%20Monitoring%20Hashtag%20From%20Competitor)
   *
   * 在競品分析 Hashtag 區域點擊前往查看關鍵字
   *
   * @param properties The event's properties (e.g. hashtagName)
   * @param options Amplitude event options.
   */
  clickSingleMonitoringHashtagFromCompetitor(
    properties: ClickSingleMonitoringHashtagFromCompetitorProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickSingleMonitoringHashtagFromCompetitor(properties), options);
  }

  /**
   * Click the Node on Trend Analysis
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20the%20Node%20on%20Trend%20Analysis)
   *
   * 提及成效分析上點擊 node
   *
   * @param properties The event's properties (e.g. at)
   * @param options Amplitude event options.
   */
  clickTheNodeOnTrendAnalysis(
    properties: ClickTheNodeOnTrendAnalysisProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickTheNodeOnTrendAnalysis(properties), options);
  }

  /**
   * Click the Type From Quick Search
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20the%20Type%20From%20Quick%20Search)
   *
   * 多少人點擊 quick search 的類型建議
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  clickTheTypeFromQuickSearch(
    properties: ClickTheTypeFromQuickSearchProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickTheTypeFromQuickSearch(properties), options);
  }

  /**
   * Click to Add Boolean Syntax
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20to%20Add%20Boolean%20Syntax)
   *
   * 點擊插入語法時觸發
   *
   *
   * @param properties The event's properties (e.g. booleanSyntax)
   * @param options Amplitude event options.
   */
  clickToAddBooleanSyntax(
    properties: ClickToAddBooleanSyntaxProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickToAddBooleanSyntax(properties), options);
  }

  /**
   * Click to Add Credit Card
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20to%20Add%20Credit%20Card)
   *
   * 點擊想要新增信用卡
   *
   * @param options Amplitude event options.
   */
  clickToAddCreditCard(
    options?: EventOptions,
  ) {
    return this.track(new ClickToAddCreditCard(), options);
  }

  /**
   * Click to Change Credit Card
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20to%20Change%20Credit%20Card)
   *
   * 點擊變更付款方式
   *
   * @param options Amplitude event options.
   */
  clickToChangeCreditCard(
    options?: EventOptions,
  ) {
    return this.track(new ClickToChangeCreditCard(), options);
  }

  /**
   * Click to Delete Credit Card
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20to%20Delete%20Credit%20Card)
   *
   * 點擊想要刪除信用卡
   *
   * @param options Amplitude event options.
   */
  clickToDeleteCreditCard(
    options?: EventOptions,
  ) {
    return this.track(new ClickToDeleteCreditCard(), options);
  }

  /**
   * Click to Report Unlisted Influencer
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20to%20Report%20Unlisted%20Influencer)
   *
   * 點擊前往回報網紅按鈕於
   *
   * 1. General Search
   *
   * 2. AI Search
   *
   * 3. Competitor
   *
   * 4. chrome-extension
   *
   *
   *
   *
   * 競品分析
   *
   *
   *
   *
   * （此時還沒填寫 URL，只是記錄點擊行為）
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  clickToReportUnlistedInfluencer(
    properties?: ClickToReportUnlistedInfluencerProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickToReportUnlistedInfluencer(properties), options);
  }

  /**
   * Click to Send Email
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20to%20Send%20Email)
   *
   * * `/community`
   *
   * ![](https://com-amplitude-orbit-media-prod.s3.us-west-2.amazonaws.com/50203/1b5193da-20a9-46e1-b9d6-677cc9c35b16.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIAVGZURZG72ZK6SO4A%2F20230720%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20230720T131230Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEBwaCXVzLXdlc3QtMiJGMEQCIB0Uc7ddsGszJAW6dTk%2F54y0n4Wr9MxeUaL1qxJPp0KmAiA57IbJIEkT7IsbaPWQhLPEC2NWM9eGWFwqZgTFp%2FM7eiq9BQil%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAAaDDM1ODIwMzExNTk2NyIMKoQbjaiyobdUrchpKpEF2fZkCmvMbANJWvd0LSb1iCTZskAuVa5kARUaObi9dIrjq6%2FjIN%2B%2BdoTM3e2WTcnc%2BvVqktlp28CLpzppIQ2d7Q2DRc7wqGzSd0oZlfVUsJJC1SR4Pg6XLhEAGTx52HH3b80zjcgdz8go%2BP1TOQHDYMTBlDzofFpE3T0iQKyAOFQyoii6HV0E2G7JFHWM3e5CzEvOVFYUOVkE1fREMyMjlsbp88Qqj5PwcPOU0ZyN5U8rfzOzkkengzPrb84i4M0sA2f8UGs97Y6SX3wHhTRY%2FV5Wy7%2F%2FYm06Y9OvlnAniA2z5fYtuR63NBoMucMjrNheGYNERgjLk4zSk1MJCXvFp%2FoqP0JOuF9g4azPJ%2BCqGtJcCkxb1Xwk9SfEgPnDfJReM1i%2BKFv3E%2B3TOJ94WulVvFf6wq1OdN3myhyvH%2FriPQImuFPTma%2BmWJzSLnFcEoCzMxQfiov36%2BiCZXGlteTnPh%2B6EL4Vdyp7E6RJ%2Fgv%2FzjtCAMljBl80STbbZnQzTuEu4yCPix72baoqg3UYHFzf6Gf%2FRWPLxzsMG9SiY5C%2BXaMlSWgYMTvzs5o69yxmIrkbcLZI11ydj2hNhfE6nz1D5raxX9kG57JP%2BK5js%2BybNRoS%2BTVld7lElUM%2Fb39yaakqECFnJJR9weDhvMzZ6h7HmuuAX6stfnZWqbCRFqelnf8J3CFy1%2B3ideW9bTZCrux8IhNijBpV4rr9TJFvrFAobBCJB5mhq2RYDqhHlF%2FwGeBkB9Eh0aBFhf%2B0wOVL2Y7OIRrxKATuWjTrlR8xbor3n693d1AQNdkVDRluw14W2OGBcpEWtpNOl8g7rsP2tZq0%2B5d8gFKyJ3ULzZyMM427lS13MdagCY2VVrOr925b%2Buq7MJe95KUGOrIBmGkiCR%2BgAEyy1edi7SLqJlsrllOrO1fKYMfWlWZJMwdJsYRStSxdWnf%2FyZNJKzi7n19XuKGKNimiBTIErgjGIcPxFDuzefbUGmx%2BGReRxTiq04fNRU%2Be5Fhu2seMv1PjKczYayxv3UjFX84Hvllei3L7nw9%2Btoseifo4nR0kYHI0J5bMTcXGcqtpi0bjy4xnp%2BvE%2BNaJTI4eWqNLYn5UWxlH6O2%2Fp29MkJPZj2E8OCKpLg%3D%3D&X-Amz-Signature=468613afb41b9b8dc8e039e72fadc4258c65389bd78b0e03644fd6c40c97a883&X-Amz-SignedHeaders=host&x-id=GetObject)
   *
   * * `/community`
   *
   * ![](https://com-amplitude-orbit-media-prod.s3.us-west-2.amazonaws.com/50203/a94f33a6-3d94-4cdf-9eb2-db3ac7f12b10?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIAVGZURZG7WNKG6TGJ%2F20230720%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20230720T131513Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEB0aCXVzLXdlc3QtMiJIMEYCIQDrSUcjzfqjtwjonAYNI0f9tJvum0uVwbqr%2FCZBn1Yc%2FQIhAKJktrJaTXq7UCfKpGBQUqm%2Bpa64d900%2Fahf9ZZ9%2BwgZKr4FCKb%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEQABoMMzU4MjAzMTE1OTY3IgzhwpY9DdSW4tIh72UqkgVr3E5j03t11z6UdvEb5gmPvZTOYMN4vytgZQ3OhLFqwIYUxYfSsjcIg6%2Fa9KhIG0GgpiZi%2FeWTaPnve4YODirxzEPJymZKRHhjljJ7q5M0FBYPxG2cO6c%2BML0Xl1H75QoO4CnJBOginawb8cl1my9JknSURMsVmtnOFl9OEMWROHnxpQhOqvylrkz3yZrz3FvBPvaQNFI1kIBLsp%2BP1zY%2Fw8Z2hFzKktqe5RlY1hFeegZrMwhgen3ltC8vecKTMEU%2FkNJ2AUE4fab9ORW1sMoo%2FDfnYuQdhZNetzifhCtl7wQIFw9%2FVnzN81hXWYoNTFsJGISR2CELm8vgmaPs4GaFGPcEa1t13DiChIimPcDOnYXdCoZ2uWDzJItM2f35GkKpl%2FOXptpHNeG1Rm3Qgv3Ciw%2F510w%2FLZEipACPEQ%2FWevVATwrAPSEgwyog1Y5M8JINROctcA0UXsl5%2FRHdg0qskjkp1AfKKsbrXCrS%2FhZssaclw1tso0AVE%2Fx7awJEIErKpsIfi%2F1NSliFYyfVdIPnIsUk4dIm9PV5FRMxVyvOKMwSnQ1St%2B5cODZBQK4peQ1LF8ou2NnXw5qFzaDtg1tAt4v1T5D6XKFIupqR8%2B%2F3zwLykbcI%2BEroQ%2Fb%2ByoG7wu1QACeb0aQ9qQU0LXGNd57pPwMAh1Jaf0uAndyhxJNcEUvKqy8FQB8FqwiiKUHEyQ27Vf6e2TO%2FcgOy6scaCDIUBxFNf3AexS45UU4tII8s5aOy%2FOiyfgG1rnmWmkY%2B3IwH77vatX%2BxRIR5gZgoAyeZ563KMm8gCZ86DZve7qLQVruFZ7Mz7BEcU5p9HXBpx25EUHuwShNj%2F470DNs4QJar6H0Qa4zk4nd9pCtkcekTBQknMPzX5KUGOrABwlu23jlXZQu5A1V9%2BadHnY8gdTRqDY8yQo0mlq9mc9VR5FvLvsNt2InQmgKF6gbIcKm7QUwOwif90u5KXL1L3%2B1j%2BW8k5TKSaXppGdAndxIuSF52KSpyQrBrJUho5ioWwGz1iWejyCDd8eAaLbumeI5hy7h0uRnrsRwudvUQjb164UpSmQ4MFhHmeFwoGLOQZdes6298CLUBzu7M0fVc6thBc0SM8zwaC5kQiVFF7xQ%3D&X-Amz-Signature=2e10c247b3075a434e914cf265b7152d99e106f77383c3903483a11fac750469&X-Amz-SignedHeaders=host&x-id=GetObject)
   *
   * Owner: Retso Huang
   *
   * @param properties The event's properties (e.g. emails)
   * @param options Amplitude event options.
   */
  clickToSendEmail(
    properties: ClickToSendEmailProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickToSendEmail(properties), options);
  }

  /**
   * Click Unlock KOL Data
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Unlock%20KOL%20Data)
   *
   * 點擊解鎖 KOL。這個事件並不保證真的解鎖，如果要分析真的解鎖，請參考「Unlock KOL Data Success」
   *
   *
   *
   *
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  clickUnlockKolData(
    properties?: ClickUnlockKolDataProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickUnlockKolData(properties), options);
  }

  /**
   * Click Upgrade Plan
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Upgrade%20Plan)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. path)
   * @param options Amplitude event options.
   */
  clickUpgradePlan(
    properties: ClickUpgradePlanProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickUpgradePlan(properties), options);
  }

  /**
   * Click WOMM Setting
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20WOMM%20Setting)
   *
   * * Objective: 分析多少人點擊查看口碑價值設定
   *
   * * Definition: 當使用者點擊 KD 頁的口碑價值設定連結
   *
   * Steps
   *
   * * 前往任一 KOL Detail 頁
   *
   * * 檢視 `互動分析` 或 `觀看分析`
   *
   * * 點擊 `口碑價值測算後台`
   *
   *
   *
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. kolDcId)
   * @param options Amplitude event options.
   */
  clickWommSetting(
    properties: ClickWommSettingProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickWommSetting(properties), options);
  }

  /**
   * Click Workspace Plan
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Click%20Workspace%20Plan)
   *
   * 進入查看售價方案頁面
   *
   * Owner: abbie.lin@ikala.tv
   *
   * @param properties The event's properties (e.g. clickPlanSource)
   * @param options Amplitude event options.
   */
  clickWorkspacePlan(
    properties: ClickWorkspacePlanProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClickWorkspacePlan(properties), options);
  }

  /**
   * Clicks Register
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Clicks%20Register)
   *
   * * 前往 `/login`
   *
   *   * 點擊任一註冊 Button
   *
   *   * 
   *
   *
   * * 前往公開 KD 頁
   *
   *   * 點擊 `免費註冊`
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  clicksRegister(
    properties?: ClicksRegisterProperties,
    options?: EventOptions,
  ) {
    return this.track(new ClicksRegister(properties), options);
  }

  /**
   * Close K-Score Detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Close%20K-Score%20Detail)
   *
   * 關閉 K-Score 分佈
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. kolId)
   * @param options Amplitude event options.
   */
  closeKScoreDetail(
    properties?: CloseKScoreDetailProperties,
    options?: EventOptions,
  ) {
    return this.track(new CloseKScoreDetail(properties), options);
  }

  /**
   * Close Onboarding Tutorial
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Close%20Onboarding%20Tutorial)
   *
   * 1. 使用者略過導覽
   *
   * 2. 使用者點擊右上角關閉導覽
   *
   * @param properties The event's properties (e.g. onboardingItem)
   * @param options Amplitude event options.
   */
  closeOnboardingTutorial(
    properties: CloseOnboardingTutorialProperties,
    options?: EventOptions,
  ) {
    return this.track(new CloseOnboardingTutorial(properties), options);
  }

  /**
   * Close Tutorial
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Close%20Tutorial)
   *
   * * 切換至任一尚未看過教學的工作區
   *
   *   * 關閉教學視窗
   *
   *   * 
   *
   *
   * Owner: abbie.lin@ikala.tv
   *
   * @param properties The event's properties (e.g. tutorialTopic)
   * @param options Amplitude event options.
   */
  closeTutorial(
    properties: CloseTutorialProperties,
    options?: EventOptions,
  ) {
    return this.track(new CloseTutorial(properties), options);
  }

  /**
   * Complete Marketplace Trial Form
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Complete%20Marketplace%20Trial%20Form)
   *
   * 完成申請商案媒合填寫表單
   *
   *
   * Owner: PeiYing Tsai
   *
   * @param options Amplitude event options.
   */
  completeMarketplaceTrialForm(
    options?: EventOptions,
  ) {
    return this.track(new CompleteMarketplaceTrialForm(), options);
  }

  /**
   * Complete Premium Trial Form
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Complete%20Premium%20Trial%20Form)
   *
   * when user finish to fill up the premium trial form
   *
   * Owner: abbie.lin@ikala.tv
   *
   * @param options Amplitude event options.
   */
  completePremiumTrialForm(
    options?: EventOptions,
  ) {
    return this.track(new CompletePremiumTrialForm(), options);
  }

  /**
   * Complete Trial Activation
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Complete%20Trial%20Activation)
   *
   * 1. 當完成 VIP 試用流程，並實際獲得試用時
   *
   * 2. 線上自動申請試用
   *
   * @param properties The event's properties (e.g. isVIP)
   * @param options Amplitude event options.
   */
  completeTrialActivation(
    properties: CompleteTrialActivationProperties,
    options?: EventOptions,
  ) {
    return this.track(new CompleteTrialActivation(properties), options);
  }

  /**
   * Complete Tutorial
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Complete%20Tutorial)
   *
   * * 切換至任一尚未看過教學的工作區
   *
   *   * 看完所有教學
   *
   *   * 
   *
   *
   * Owner: Retso Huang
   *
   * @param properties The event's properties (e.g. tutorialTopic)
   * @param options Amplitude event options.
   */
  completeTutorial(
    properties: CompleteTutorialProperties,
    options?: EventOptions,
  ) {
    return this.track(new CompleteTutorial(properties), options);
  }

  /**
   * Completed onboarding
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Completed%20onboarding)
   *
   * 1. 完整走完功能導覽
   *
   * 2. 回去重看任一功能導覽且完整看完也要紀錄
   *
   * @param properties The event's properties (e.g. onboardingItem)
   * @param options Amplitude event options.
   */
  completedOnboarding(
    properties: CompletedOnboardingProperties,
    options?: EventOptions,
  ) {
    return this.track(new CompletedOnboarding(properties), options);
  }

  /**
   * Confirm Identity Choice
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Confirm%20Identity%20Choice)
   *
   * * 前往 `/register`
   *
   *   * 點擊任一身份並點擊`下一步`後觸發
   *
   *   * 
   *
   *
   * Owner: abbie.lin@ikala.tv
   *
   * @param properties The event's properties (e.g. identity)
   * @param options Amplitude event options.
   */
  confirmIdentityChoice(
    properties: ConfirmIdentityChoiceProperties,
    options?: EventOptions,
  ) {
    return this.track(new ConfirmIdentityChoice(properties), options);
  }

  /**
   * Continue to View on Mobile
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Continue%20to%20View%20on%20Mobile)
   *
   * 用手機開 app 時，跳出建議用電腦，點擊“繼續使用手機瀏覽”
   *
   * @param options Amplitude event options.
   */
  continueToViewOnMobile(
    options?: EventOptions,
  ) {
    return this.track(new ContinueToViewOnMobile(), options);
  }

  /**
   * Create Campaign
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Create%20Campaign)
   *
   *
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. campaignBudgetPerPerson)
   * @param options Amplitude event options.
   */
  createCampaign(
    properties: CreateCampaignProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateCampaign(properties), options);
  }

  /**
   * Create Collection
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Create%20Collection)
   *
   * 在收藏庫中成功創建網紅收藏庫或是貼文收藏庫
   *
   * @param properties The event's properties (e.g. collectionId)
   * @param options Amplitude event options.
   */
  createCollection(
    properties: CreateCollectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateCollection(properties), options);
  }

  /**
   * Create Competitive Analysis
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Create%20Competitive%20Analysis)
   *
   * 新增品牌分析或是提及分析的競品列表
   *
   * @param properties The event's properties (e.g. at)
   * @param options Amplitude event options.
   */
  createCompetitiveAnalysis(
    properties?: CreateCompetitiveAnalysisProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateCompetitiveAnalysis(properties), options);
  }

  /**
   * Create Competitive Analysis Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Create%20Competitive%20Analysis%20Failed)
   *
   * 建立競品失敗
   *
   * @param properties The event's properties (e.g. at)
   * @param options Amplitude event options.
   */
  createCompetitiveAnalysisFailed(
    properties?: CreateCompetitiveAnalysisFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateCompetitiveAnalysisFailed(properties), options);
  }

  /**
   * Create Contract
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Create%20Contract)
   *
   * when brands users created contracts![](https://com-amplitude-orbit-media-prod.s3.us-west-2.amazonaws.com/50203/039ede85-214c-49a8-825b-9d94d8141a72.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=ASIAVGZURZG755PFJKOH%2F20221226%2Fus-west-2%2Fs3%2Faws4_request&X-Amz-Date=20221226T014751Z&X-Amz-Expires=3600&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEL3%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FwEaCXVzLXdlc3QtMiJHMEUCIQDLeSEP9Fk9vVLVPla42aT96%2F6s2b3FixvmRSUsmFERtwIgXJod9G3GJ5XZxQur3Q4zQ7hZx06rWx2lQx43pDy0USYq1QQI9v%2F%2F%2F%2F%2F%2F%2F%2F%2F%2FARAAGgwzNTgyMDMxMTU5NjciDJrgdo1%2BzffKmS%2By3CqpBB2CrM3T7otp0%2FqZj5CQarjdAxbpxVdu2B5EDSd8QtBU8WoIZfckExyJCwwYt47vpRa3iZYt6upilvQbbGd9myaYfjZYk89szBNk7OW%2BUVETqWmGqvtjyq15AQ8I08OhMl6Iz1nzby3N6FGgF9o0%2BVEM%2BeXHMA6lRuAgeWzG5ooW6wHDLNsgH4yW8ywugwV%2BU67RySw4X1IOqC7Y100kMytl4pzCIyDsT7Q%2BUe%2FsXydfk%2B3TXoNmneXYeKXWOIIkxr64oe5ise8HcPx9orem5sH9dARnds0lZP0npJ%2BGzwnvR0WxdgzocoEDinnxZjDY6DyyWcvePuQYxSi%2FTT3ipwyGLF6bZ7gh92BoHbKZ8pxCxY6qu%2BVpXPxIZ6uXhj7EUprWnKzdxiYx60H7Z9NLxPVp9pEIJStwrOY7At3DgmbGLvDypqxNrWjbh%2FWsl0EYLHESPS9jxLNutHSI4GcAqJjTlf8D1pJvtHUwmvShx9nRNZW3VhFur13QH3t3epdmh2CnZ%2BYSQKzSNy9x5Pbc3FUytNnIfIW3IBsWYW073yZ82eXmwkm7HiMFVqBRBcT0uuUl4lvv%2BbcJAdTis3Xe%2FjrhYihWmkyz8kFCGL5ietWQd%2F2%2F65griTizr%2Br%2F87WXd9Gf0WVjK3PYImQV8x5wFjQmzvaMSTxMlW854ST1SoCVII%2BD6Vs0WvXTIqALJMpQGwf062DbavctqIGV6UMYAMRx14ZCPy2RLkcw5PKinQY6qQGCvEpUOfVx1yzOpNOQMriKDLcCz5usQaZ6O%2FKY0Y6wAK4kY8C3J13kL63S2%2F5E8calRljQ8y4HoKu%2Fa0RMplaGvQYwQIBWhZv8ULVLCKFI%2FSawZjL6Y3oJ5n7vNbZa9I%2BlHK%2F%2BCK34rnOFw9plg95dwppxGzGOkygTH4bMq5sGlyGM%2F1ZUEDYNHXQ6yJnPor00ND2EOiR%2F%2FqVPePLrZ0hfYYB9UidKR6sa&X-Amz-Signature=397d85336e0a999e5ad7d6243026871d07eadb2fcbfb99fb3a5a6be8d47ff526&X-Amz-SignedHeaders=host&x-id=GetObject)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  createContract(
    properties: CreateContractProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateContract(properties), options);
  }

  /**
   * Create Deep Report
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Create%20Deep%20Report)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. reportBudget)
   * @param options Amplitude event options.
   */
  createDeepReport(
    properties: CreateDeepReportProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateDeepReport(properties), options);
  }

  /**
   * Create Workspace
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Create%20Workspace)
   *
   * when a user created a workspace successfully
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. section)
   * @param options Amplitude event options.
   */
  createWorkspace(
    properties?: CreateWorkspaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new CreateWorkspace(properties), options);
  }

  /**
   * Deep Report Redirect
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Deep%20Report%20Redirect)
   *
   * Owner: Finn Yeh
   *
   * @param options Amplitude event options.
   */
  deepReportRedirect(
    options?: EventOptions,
  ) {
    return this.track(new DeepReportRedirect(), options);
  }

  /**
   * Delete Campaign
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Delete%20Campaign)
   *
   * when the user delete a campaign
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  deleteCampaign(
    properties: DeleteCampaignProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteCampaign(properties), options);
  }

  /**
   * Delete Credit Card
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Delete%20Credit%20Card)
   *
   * 成功刪除信用卡
   *
   * @param options Amplitude event options.
   */
  deleteCreditCard(
    options?: EventOptions,
  ) {
    return this.track(new DeleteCreditCard(), options);
  }

  /**
   * Delete Project
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Delete%20Project)
   *
   * Delete project
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. collectionType)
   * @param options Amplitude event options.
   */
  deleteProject(
    properties: DeleteProjectProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteProject(properties), options);
  }

  /**
   * Delete Report KOL
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Delete%20Report%20KOL)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. reportId)
   * @param options Amplitude event options.
   */
  deleteReportKol(
    properties: DeleteReportKolProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteReportKol(properties), options);
  }

  /**
   * Delete Report KOL Content
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Delete%20Report%20KOL%20Content)
   *
   * Owner: Finn Yeh
   *
   * @param options Amplitude event options.
   */
  deleteReportKolContent(
    options?: EventOptions,
  ) {
    return this.track(new DeleteReportKolContent(), options);
  }

  /**
   * Delete Workspace
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Delete%20Workspace)
   *
   * * 登入品牌主並前往 `/workspace-setting`
   *
   *   * 點擊 `刪除工作區`
   *
   *   * 
   *
   *
   *   * 輸入工作區名稱後點擊刪除
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. workspaceID)
   * @param options Amplitude event options.
   */
  deleteWorkspace(
    properties?: DeleteWorkspaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new DeleteWorkspace(properties), options);
  }

  /**
   * Draft Contract
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Draft%20Contract)
   *
   * when users are drafting a contract
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  draftContract(
    properties: DraftContractProperties,
    options?: EventOptions,
  ) {
    return this.track(new DraftContract(properties), options);
  }

  /**
   * Duplicate Collection
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Duplicate%20Collection)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. collectionType)
   * @param options Amplitude event options.
   */
  duplicateCollection(
    properties: DuplicateCollectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new DuplicateCollection(properties), options);
  }

  /**
   * Edit IRM Tags
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Edit%20IRM%20Tags)
   *
   * * 前往 `/community`
   *
   *   * 查看任一 KOL 合作資訊
   *
   *   * 
   *
   *
   *   * 編輯標籤並送出
   *
   *   * 
   *
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. kolId)
   * @param options Amplitude event options.
   */
  editIrmTags(
    properties?: EditIrmTagsProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditIrmTags(properties), options);
  }

  /**
   * Edit Member of Workspace
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Edit%20Member%20of%20Workspace)
   *
   * * 登入品牌主並前往 `/workspace-member`
   *
   *   * 調整任一成員角色
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. memberEmail)
   * @param options Amplitude event options.
   */
  editMemberOfWorkspace(
    properties: EditMemberOfWorkspaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditMemberOfWorkspace(properties), options);
  }

  /**
   * Edit Report KOL Content
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Edit%20Report%20KOL%20Content)
   *
   * Owner: Finn Yeh
   *
   * @param options Amplitude event options.
   */
  editReportKolContent(
    options?: EventOptions,
  ) {
    return this.track(new EditReportKolContent(), options);
  }

  /**
   * Edit Workspace
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Edit%20Workspace)
   *
   * * 登入品牌主並前往 `/workspace-setting`
   *
   *   * 按 `儲存變更`，成功後觸發
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. workspaceID)
   * @param options Amplitude event options.
   */
  editWorkspace(
    properties?: EditWorkspaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new EditWorkspace(properties), options);
  }

  /**
   * Exit KOL Detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Exit%20KOL%20Detail)
   *
   * 更精確統計使用者在一個 KD 上的停留時間，因此希望在使用者於 KD 頁按下關閉網頁 / 分頁時傳送事件，紀錄這次在 KD 上停留多久 
   *  https://www.notion.so/ikala/Amp-0a889ef0ec3b49058235005922eb324e
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  exitKolDetail(
    properties: ExitKolDetailProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExitKolDetail(properties), options);
  }

  /**
   * Exit Search Result
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Exit%20Search%20Result)
   *
   * 希望記錄搜尋後的瀏覽結果停留時間，關閉時傳送事件 
   *  https://www.notion.so/ikala/Amp-0a889ef0ec3b49058235005922eb324e
   *
   * @param properties The event's properties (e.g. isAnyFilter)
   * @param options Amplitude event options.
   */
  exitSearchResult(
    properties: ExitSearchResultProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExitSearchResult(properties), options);
  }

  /**
   * Export Campaign Report Content
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Export%20Campaign%20Report%20Content)
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  exportCampaignReportContent(
    properties: ExportCampaignReportContentProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportCampaignReportContent(properties), options);
  }

  /**
   * Export Collection
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Export%20Collection)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. collectionId)
   * @param options Amplitude event options.
   */
  exportCollection(
    properties: ExportCollectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportCollection(properties), options);
  }

  /**
   * Export Collection Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Export%20Collection%20Failed)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. collectionId)
   * @param options Amplitude event options.
   */
  exportCollectionFailed(
    properties: ExportCollectionFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportCollectionFailed(properties), options);
  }

  /**
   * Export Collection Over Limit
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Export%20Collection%20Over%20Limit)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. collectionId)
   * @param options Amplitude event options.
   */
  exportCollectionOverLimit(
    properties: ExportCollectionOverLimitProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportCollectionOverLimit(properties), options);
  }

  /**
   * Export Community KOL Fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Export%20Community%20KOL%20Fail)
   *
   * * `/community` 匯出失敗
   *
   *
   *
   *
   * * `/community` 匯出失敗
   *
   *
   *
   *
   * @param properties The event's properties (e.g. format)
   * @param options Amplitude event options.
   */
  exportCommunityKolFail(
    properties: ExportCommunityKolFailProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportCommunityKolFail(properties), options);
  }

  /**
   * Export KOL Data
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Export%20KOL%20Data)
   *
   * Objective: 分析多少人點擊匯出該 KOL 數據
   *
   *
   * Steps:
   *
   * * 前往任一 KOL Detail 頁面
   *
   * * 點擊下載圖示
   *
   *
   *
   *
   * * 勾選資料後點擊 `匯出`
   *
   *
   *
   *
   * @param properties The event's properties (e.g. format)
   * @param options Amplitude event options.
   */
  exportKolData(
    properties: ExportKolDataProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportKolData(properties), options);
  }

  /**
   * Export Mention Post and KOL
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Export%20Mention%20Post%20and%20KOL)
   *
   * 在競品分析中成功匯出提及報表
   *
   * @param properties The event's properties (e.g. at)
   * @param options Amplitude event options.
   */
  exportMentionPostAndKol(
    properties?: ExportMentionPostAndKolProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportMentionPostAndKol(properties), options);
  }

  /**
   * Export Report Content
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Export%20Report%20Content)
   *
   *
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. reportContentCount)
   * @param options Amplitude event options.
   */
  exportReportContent(
    properties: ExportReportContentProperties,
    options?: EventOptions,
  ) {
    return this.track(new ExportReportContent(properties), options);
  }

  /**
   * Fail to Pay After 3D
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Fail%20to%20Pay%20After%203D)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. message)
   * @param options Amplitude event options.
   */
  failToPayAfter3D(
    properties?: FailToPayAfter3DProperties,
    options?: EventOptions,
  ) {
    return this.track(new FailToPayAfter3D(properties), options);
  }

  /**
   * Fail to Pay Before 3D
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Fail%20to%20Pay%20Before%203D)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  failToPayBefore3D(
    properties?: FailToPayBefore3DProperties,
    options?: EventOptions,
  ) {
    return this.track(new FailToPayBefore3D(properties), options);
  }

  /**
   * Fans Selector
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Fans%20Selector)
   *
   * * 前往 `/search`
   *
   *   * 輸入或拖拉區間
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. type)
   * @param options Amplitude event options.
   */
  fansSelector(
    properties: FansSelectorProperties,
    options?: EventOptions,
  ) {
    return this.track(new FansSelector(properties), options);
  }

  /**
   * Filter in Community
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Filter%20in%20Community)
   *
   * * 登入品牌主
   *
   *   * 前往 `/community`
   *
   *   * 點擊篩選
   *
   *   * 
   *
   *
   *   * 篩選任一欄位
   *
   *   * 
   *
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. campaignNames)
   * @param options Amplitude event options.
   */
  filterInCommunity(
    properties: FilterInCommunityProperties,
    options?: EventOptions,
  ) {
    return this.track(new FilterInCommunity(properties), options);
  }

  /**
   * Filter in Community Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Filter%20in%20Community%20Failed)
   *
   * * 登入品牌主
   *
   *   * 前往 `/community`
   *
   *   * 點擊篩選
   *
   *   * 
   *
   *
   *   * 篩選任一欄位
   *
   *   * 
   *
   *
   *   * 失敗時觸發
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. campaignNames)
   * @param options Amplitude event options.
   */
  filterInCommunityFailed(
    properties: FilterInCommunityFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FilterInCommunityFailed(properties), options);
  }

  /**
   * Filter in Community No Result
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Filter%20in%20Community%20No%20Result)
   *
   * * 登入品牌主
   *
   *   * 前往 `/community`
   *
   *   * 點擊篩選
   *
   *   * 
   *
   *
   *   * 篩選任一欄位
   *
   *   * 
   *
   *
   *   * 無結果時觸發
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. campaignNames)
   * @param options Amplitude event options.
   */
  filterInCommunityNoResult(
    properties: FilterInCommunityNoResultProperties,
    options?: EventOptions,
  ) {
    return this.track(new FilterInCommunityNoResult(properties), options);
  }

  /**
   * Filter Reporting KOL
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Filter%20Reporting%20KOL)
   *
   * Filter KOL Name in New Campaign Reporting
   *
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  filterReportingKol(
    properties: FilterReportingKolProperties,
    options?: EventOptions,
  ) {
    return this.track(new FilterReportingKol(properties), options);
  }

  /**
   * Filter Reporting Platform
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Filter%20Reporting%20Platform)
   *
   * Filter Platform in New Campaign Reporting (eg, Facebook, Instagram, Youtube
   * )
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  filterReportingPlatform(
    properties: FilterReportingPlatformProperties,
    options?: EventOptions,
  ) {
    return this.track(new FilterReportingPlatform(properties), options);
  }

  /**
   * Go to privacy
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Go%20to%20privacy)
   *
   * Owner: Finn Yeh
   *
   * @param options Amplitude event options.
   */
  goToPrivacy(
    options?: EventOptions,
  ) {
    return this.track(new GoToPrivacy(), options);
  }

  /**
   * Go to Terms
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Go%20to%20Terms)
   *
   * * 前往`/register` 選任一身份
   *
   *   * 點擊`使用條款`
   *
   *   * 
   *
   *
   * @param options Amplitude event options.
   */
  goToTerms(
    options?: EventOptions,
  ) {
    return this.track(new GoToTerms(), options);
  }

  /**
   * Hide Report KOL Content
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Hide%20Report%20KOL%20Content)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. kolIId)
   * @param options Amplitude event options.
   */
  hideReportKolContent(
    properties: HideReportKolContentProperties,
    options?: EventOptions,
  ) {
    return this.track(new HideReportKolContent(properties), options);
  }

  /**
   * Import KOL Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Import%20KOL%20Failed)
   *
   * * `/community`
   *
   *   * 先點擊 `加入 KOL`
   *
   *   * 
   *
   *
   *   * 選任一種匯入方式，匯入失敗時即觸發此事件
   *
   *   * 
   *
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. importMethod)
   * @param options Amplitude event options.
   */
  importKolFailed(
    properties: ImportKolFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ImportKolFailed(properties), options);
  }

  /**
   * Import KOL Success
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Import%20KOL%20Success)
   *
   * * `/community`
   *
   *   * 先點擊 `加入 KOL`
   *
   *   * 
   *
   *
   *   * 選任一種匯入方式，匯入成功時即觸發此事件
   *
   *   * 
   *
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. importMethod)
   * @param options Amplitude event options.
   */
  importKolSuccess(
    properties: ImportKolSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new ImportKolSuccess(properties), options);
  }

  /**
   * Interested in Comment Sentiment Analysis
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Interested%20in%20Comment%20Sentiment%20Analysis)
   *
   * 對於留言情緒分析有興趣
   *
   * @param options Amplitude event options.
   */
  interestedInCommentSentimentAnalysis(
    options?: EventOptions,
  ) {
    return this.track(new InterestedInCommentSentimentAnalysis(), options);
  }

  /**
   * KOL Accept Contract
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/KOL%20Accept%20Contract)
   *
   * when kol-type users accepted the contract, including normal contracts or labor remittance only for TW
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  kolAcceptContract(
    properties: KolAcceptContractProperties,
    options?: EventOptions,
  ) {
    return this.track(new KolAcceptContract(properties), options);
  }

  /**
   * KOL Visit Campaign
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/KOL%20Visit%20Campaign)
   *
   * when the influecner visits a campaign
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  kolVisitCampaign(
    properties: KolVisitCampaignProperties,
    options?: EventOptions,
  ) {
    return this.track(new KolVisitCampaign(properties), options);
  }

  /**
   * KOL Visit Campaign List
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/KOL%20Visit%20Campaign%20List)
   *
   * Owner: Finn Yeh
   *
   * @param options Amplitude event options.
   */
  kolVisitCampaignList(
    options?: EventOptions,
  ) {
    return this.track(new KolVisitCampaignList(), options);
  }

  /**
   * KOL Visit Public Campaign
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/KOL%20Visit%20Public%20Campaign)
   *
   * 查看我要報名活動列表的其中一個活動詳情（還沒報名）
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. campaignBudgetPerPerson)
   * @param options Amplitude event options.
   */
  kolVisitPublicCampaign(
    properties: KolVisitPublicCampaignProperties,
    options?: EventOptions,
  ) {
    return this.track(new KolVisitPublicCampaign(properties), options);
  }

  /**
   * KOL Visit Public Campaign List
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/KOL%20Visit%20Public%20Campaign%20List)
   *
   * KOL 查看我要報名活動列表
   *
   * Owner: Belinda Chen
   *
   * @param options Amplitude event options.
   */
  kolVisitPublicCampaignList(
    options?: EventOptions,
  ) {
    return this.track(new KolVisitPublicCampaignList(), options);
  }

  /**
   * Leave Marketplace Trial Form
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Leave%20Marketplace%20Trial%20Form)
   *
   * 跳離 Marketplace 填寫表單
   *
   * Owner: PeiYing Tsai
   *
   * @param options Amplitude event options.
   */
  leaveMarketplaceTrialForm(
    options?: EventOptions,
  ) {
    return this.track(new LeaveMarketplaceTrialForm(), options);
  }

  /**
   * Leave Premium Trial Form
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Leave%20Premium%20Trial%20Form)
   *
   * Owner: abbie.lin@ikala.tv
   *
   * @param options Amplitude event options.
   */
  leavePremiumTrialForm(
    options?: EventOptions,
  ) {
    return this.track(new LeavePremiumTrialForm(), options);
  }

  /**
   * Leave Report Preview
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Leave%20Report%20Preview)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. reportContentCount)
   * @param options Amplitude event options.
   */
  leaveReportPreview(
    properties: LeaveReportPreviewProperties,
    options?: EventOptions,
  ) {
    return this.track(new LeaveReportPreview(properties), options);
  }

  /**
   * Leave Workspace
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Leave%20Workspace)
   *
   * * 登入品牌主
   *
   *   * 切換至被邀請的工作區
   *
   *   * 前往 `/workspace-setting`
   *
   *   * 點擊 `退出工作區`
   *
   *   * 
   *
   *
   *   * 點擊`退出`，成功後觸發
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. workspaceID)
   * @param options Amplitude event options.
   */
  leaveWorkspace(
    properties?: LeaveWorkspaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new LeaveWorkspace(properties), options);
  }

  /**
   * Login Fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Login%20Fail)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. ssoPlatform)
   * @param options Amplitude event options.
   */
  loginFail(
    properties: LoginFailProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoginFail(properties), options);
  }

  /**
   * Login Success
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Login%20Success)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. ssoPlatform)
   * @param options Amplitude event options.
   */
  loginSuccess(
    properties: LoginSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoginSuccess(properties), options);
  }

  /**
   * Logout Success
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Logout%20Success)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. isSingleLogin)
   * @param options Amplitude event options.
   */
  logoutSuccess(
    properties: LogoutSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new LogoutSuccess(properties), options);
  }

  /**
   * Move Competitor Order
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Move%20Competitor%20Order)
   *
   * 在加競品中移動順序
   *
   * @param properties The event's properties (e.g. at)
   * @param options Amplitude event options.
   */
  moveCompetitorOrder(
    properties?: MoveCompetitorOrderProperties,
    options?: EventOptions,
  ) {
    return this.track(new MoveCompetitorOrder(properties), options);
  }

  /**
   * Pop Up Detect Crawler Notify
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Pop%20Up%20Detect%20Crawler%20Notify)
   *
   * when users exceed search limit in a hour
   *
   *
   *
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. path)
   * @param options Amplitude event options.
   */
  popUpDetectCrawlerNotify(
    properties: PopUpDetectCrawlerNotifyProperties,
    options?: EventOptions,
  ) {
    return this.track(new PopUpDetectCrawlerNotify(properties), options);
  }

  /**
   * Pop Up Purchase Quota Notify
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Pop%20Up%20Purchase%20Quota%20Notify)
   *
   * 解鎖時 quota 為零跳通知
   *
   * Owner: Belinda Chen
   *
   * @param options Amplitude event options.
   */
  popUpPurchaseQuotaNotify(
    options?: EventOptions,
  ) {
    return this.track(new PopUpPurchaseQuotaNotify(), options);
  }

  /**
   * Pop Up Upgrade Notify
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Pop%20Up%20Upgrade%20Notify)
   *
   * When user click some features which would pop up upgrade notify
   *
   * Owner: abbie.lin@ikala.tv
   *
   * @param properties The event's properties (e.g. path)
   * @param options Amplitude event options.
   */
  popUpUpgradeNotify(
    properties?: PopUpUpgradeNotifyProperties,
    options?: EventOptions,
  ) {
    return this.track(new PopUpUpgradeNotify(properties), options);
  }

  /**
   * Preview Campaign
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Preview%20Campaign)
   *
   *
   *
   *
   *
   * 創建活動 or 編輯活動設定時預覽
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  previewCampaign(
    properties: PreviewCampaignProperties,
    options?: EventOptions,
  ) {
    return this.track(new PreviewCampaign(properties), options);
  }

  /**
   * Preview Keyword Post
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Preview%20Keyword%20Post)
   *
   * 在搜尋結果頁，當滑鼠移動過查看關鍵字貼文時 
   *  https://www.notion.so/ikala/Amp-0a889ef0ec3b49058235005922eb324e
   *
   * @param properties The event's properties (e.g. kolDcId)
   * @param options Amplitude event options.
   */
  previewKeywordPost(
    properties: PreviewKeywordPostProperties,
    options?: EventOptions,
  ) {
    return this.track(new PreviewKeywordPost(properties), options);
  }

  /**
   * Quick Search for KOL
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Quick%20Search%20for%20KOL)
   *
   * * 前往 `/search`
   *
   *   * 於輸入匡輸入關鍵字
   *
   *   * 
   *
   *
   * * 前往非 `/search` 功能頁
   *
   *   * 於左上角搜尋匡輸入關鍵字
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. countryCode)
   * @param options Amplitude event options.
   */
  quickSearchForKol(
    properties?: QuickSearchForKolProperties,
    options?: EventOptions,
  ) {
    return this.track(new QuickSearchForKol(properties), options);
  }

  /**
   * Read Tutorial
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Read%20Tutorial)
   *
   * * 切換至任一尚未看過教學的工作區
   *
   *   * 點擊上、下則觀看教學
   *
   *   * 
   *
   *
   * Owner: Retso Huang
   *
   * @param properties The event's properties (e.g. page)
   * @param options Amplitude event options.
   */
  readTutorial(
    properties: ReadTutorialProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReadTutorial(properties), options);
  }

  /**
   * Redeem Coupon Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Redeem%20Coupon%20Failed)
   *
   * when user failed to redeem coupon 
   *  ![](https://s3.us-west-2.amazonaws.com/secure.notion-static.com/866a0139-2d4c-413f-be21-5d9bf0fba4df/Screen\_Shot\_2022-06-28\_at\_9.54.38\_PM.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220704%2Fus-west-2%2Fs3%2Faws4\_request&X-Amz-Date=20220704T065734Z&X-Amz-Expires=86400&X-Amz-Signature=10bf8eadf7ab2ba58a57ff7abdd6cde1ea8022b78b6feda8808b77f4127df98d&X-Amz-SignedHeaders=host&response-content-disposition=filename%20%3D%22Screen%2520Shot%25202022-06-28%2520at%25209.54.38%2520PM.png%22&x-id=GetObject)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. couponCode)
   * @param options Amplitude event options.
   */
  redeemCouponFailed(
    properties: RedeemCouponFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RedeemCouponFailed(properties), options);
  }

  /**
   * Redeem Coupon Success
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Redeem%20Coupon%20Success)
   *
   * when user redeemed the coupon successfully 
   *  ![](https://s3.us-west-2.amazonaws.com/secure.notion-static.com/866a0139-2d4c-413f-be21-5d9bf0fba4df/Screen\_Shot\_2022-06-28\_at\_9.54.38\_PM.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=AKIAT73L2G45EIPT3X45%2F20220704%2Fus-west-2%2Fs3%2Faws4\_request&X-Amz-Date=20220704T065734Z&X-Amz-Expires=86400&X-Amz-Signature=10bf8eadf7ab2ba58a57ff7abdd6cde1ea8022b78b6feda8808b77f4127df98d&X-Amz-SignedHeaders=host&response-content-disposition=filename%20%3D%22Screen%2520Shot%25202022-06-28%2520at%25209.54.38%2520PM.png%22&x-id=GetObject)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. couponCode)
   * @param options Amplitude event options.
   */
  redeemCouponSuccess(
    properties: RedeemCouponSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new RedeemCouponSuccess(properties), options);
  }

  /**
   * Refresh AI Action
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Refresh%20AI%20Action)
   *
   * 點擊 AI 行為模組的 【換一組推薦結果】 
   *  https://www.notion.so/ikala/AI-Search-AI-c974d2bae3684cbea91305e8a96fe2b7
   *
   * @param properties The event's properties (e.g. aiActionKolDcIds)
   * @param options Amplitude event options.
   */
  refreshAiAction(
    properties: RefreshAiActionProperties,
    options?: EventOptions,
  ) {
    return this.track(new RefreshAiAction(properties), options);
  }

  /**
   * Refresh Page
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Refresh%20Page)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. path)
   * @param options Amplitude event options.
   */
  refreshPage(
    properties?: RefreshPageProperties,
    options?: EventOptions,
  ) {
    return this.track(new RefreshPage(properties), options);
  }

  /**
   * Remove Collection
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Remove%20Collection)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. kolCategory)
   * @param options Amplitude event options.
   */
  removeCollection(
    properties?: RemoveCollectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new RemoveCollection(properties), options);
  }

  /**
   * Remove KOL
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Remove%20KOL)
   *
   * * 前往任一收藏庫並切換至列表顯示
   *
   *   * 點擊任一 KOL 列表上的刪除
   *
   *   * 
   *
   *
   *   * 點擊 Dialog 的刪除，成功後觸發
   *
   *   * 
   *
   *
   * * 前往任一收藏庫並切換至列表顯示
   *
   *   * 展開任一 KOL 資料
   *
   *   * 
   *
   *
   *   * 點擊展開的列表上的刪除
   *
   *   * 
   *
   *
   *   * 點擊 Dialog 的刪除
   *
   *   * 
   *
   *
   * * 前往任一收藏庫並切換至卡片模式
   *
   *   * 點擊垃圾桶 icon
   *
   *   * 
   *
   *
   *   * 點擊 Dialog 的刪除
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. kolId)
   * @param options Amplitude event options.
   */
  removeKol(
    properties?: RemoveKolProperties,
    options?: EventOptions,
  ) {
    return this.track(new RemoveKol(properties), options);
  }

  /**
   * Remove Member of Workspace
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Remove%20Member%20of%20Workspace)
   *
   * * 登入品牌主並前往 `/workspace-member`
   *
   *   * 移除任一成員
   *
   *   * 
   *
   *
   *   * 點擊移除
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. memberEmail)
   * @param options Amplitude event options.
   */
  removeMemberOfWorkspace(
    properties: RemoveMemberOfWorkspaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new RemoveMemberOfWorkspace(properties), options);
  }

  /**
   * Remove Post from Collection
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Remove%20Post%20from%20Collection)
   *
   * when users removed posts from the collection
   *
   * @param properties The event's properties (e.g. collectionId)
   * @param options Amplitude event options.
   */
  removePostFromCollection(
    properties: RemovePostFromCollectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new RemovePostFromCollection(properties), options);
  }

  /**
   * Rename Project
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Rename%20Project)
   *
   * * 前往 `/projects`
   *
   *   * 檢視任一收藏庫
   *
   *   * 
   *
   *
   *   * 點擊編輯收藏庫名稱
   *
   *   * 
   *
   *
   *   * 點擊送出
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param options Amplitude event options.
   */
  renameProject(
    options?: EventOptions,
  ) {
    return this.track(new RenameProject(), options);
  }

  /**
   * Replay Onboarding
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Replay%20Onboarding)
   *
   * 從引導教學進來，且選擇一個功能開始看介紹
   *
   * @param properties The event's properties (e.g. onboardingItem)
   * @param options Amplitude event options.
   */
  replayOnboarding(
    properties: ReplayOnboardingProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReplayOnboarding(properties), options);
  }

  /**
   * Replay Onboarding Tutorial
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Replay%20Onboarding%20Tutorial)
   *
   * 使用者於右上角 menu 點擊重看導覽
   *
   * @param options Amplitude event options.
   */
  replayOnboardingTutorial(
    options?: EventOptions,
  ) {
    return this.track(new ReplayOnboardingTutorial(), options);
  }

  /**
   * Report Content Type Is Incorrect
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Report%20Content%20Type%20Is%20Incorrect)
   *
   * 回報貼文類型不準確
   *
   * @param properties The event's properties (e.g. kolId)
   * @param options Amplitude event options.
   */
  reportContentTypeIsIncorrect(
    properties?: ReportContentTypeIsIncorrectProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReportContentTypeIsIncorrect(properties), options);
  }

  /**
   * Request More Hashtag Influencers
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Request%20More%20Hashtag%20Influencers)
   *
   * user 點擊 Hashtag 頁面內的提及網紅想查看更多的提及網紅
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. hashtagName)
   * @param options Amplitude event options.
   */
  requestMoreHashtagInfluencers(
    properties: RequestMoreHashtagInfluencersProperties,
    options?: EventOptions,
  ) {
    return this.track(new RequestMoreHashtagInfluencers(properties), options);
  }

  /**
   * Resend verify email
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Resend%20verify%20email)
   *
   * * 成功註冊帳號
   *
   *   * 點擊成功頁面的重新寄送驗證信
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. emails)
   * @param options Amplitude event options.
   */
  resendVerifyEmail(
    properties: ResendVerifyEmailProperties,
    options?: EventOptions,
  ) {
    return this.track(new ResendVerifyEmail(properties), options);
  }

  /**
   * Return Unlisted KOL
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Return%20Unlisted%20KOL)
   *
   *
   *
   *
   * 成功回報網紅 
   *   1. General Search
   *
   * 2. AI Search
   *
   * 3. Competitor
   *
   * 4. chrome-extension
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  returnUnlistedKol(
    properties: ReturnUnlistedKolProperties,
    options?: EventOptions,
  ) {
    return this.track(new ReturnUnlistedKol(properties), options);
  }

  /**
   * Save Editing Report Preview
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Save%20Editing%20Report%20Preview)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. reportContentCount)
   * @param options Amplitude event options.
   */
  saveEditingReportPreview(
    properties: SaveEditingReportPreviewProperties,
    options?: EventOptions,
  ) {
    return this.track(new SaveEditingReportPreview(properties), options);
  }

  /**
   * Save the Post to Collection Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Save%20the%20Post%20to%20Collection%20Failed)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. postLink)
   * @param options Amplitude event options.
   */
  saveThePostToCollectionFailed(
    properties?: SaveThePostToCollectionFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SaveThePostToCollectionFailed(properties), options);
  }

  /**
   * Scroll KOL Detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Scroll%20KOL%20Detail)
   *
   * 偵測使用者在 KD 滑過個別區塊，包含點擊 sidebar 後快速跳轉該區塊也會觸發
   *
   * @param properties The event's properties (e.g. isUnlocked)
   * @param options Amplitude event options.
   */
  scrollKolDetail(
    properties: ScrollKolDetailProperties,
    options?: EventOptions,
  ) {
    return this.track(new ScrollKolDetail(properties), options);
  }

  /**
   * Scroll Search Result
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Scroll%20Search%20Result)
   *
   * 當用戶在搜尋結果頁有任意往下滑動 ( 可以抓一個最小 Pixel )，為了處理多次篩選反覆觸發 Search for KOL 問題 
   *  https://www.notion.so/ikala/Amp-0a889ef0ec3b49058235005922eb324e
   *
   * @param properties The event's properties (e.g. keyword)
   * @param options Amplitude event options.
   */
  scrollSearchResult(
    properties?: ScrollSearchResultProperties,
    options?: EventOptions,
  ) {
    return this.track(new ScrollSearchResult(properties), options);
  }

  /**
   * Search for Creative Content in KD
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Search%20for%20Creative%20Content%20in%20KD)
   *
   * 創作內容進行搜尋時，新增事件記錄 ( 預設進入那次不算，只記錄主動搜尋 ) 
   *  https://www.notion.so/ikala/Sprint-123-b36281d03dc94c20a97c9bf6f5f825d3?p=ff2d2d3b7c944f259a4731e7265635bc&pm=s
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  searchForCreativeContentInKd(
    properties: SearchForCreativeContentInKdProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchForCreativeContentInKd(properties), options);
  }

  /**
   * Search for KOL
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Search%20for%20KOL)
   *
   * This event tracks when a user performs a search for a Key Opinion Leader (KOL) on our platform
   *
   * Including
   *
   * 1. Click or use the keyboard "enter" the search button 
   *
   *
   * 2. Edit the filtering condition and save 
   *
   *
   * 3. Removal of any or all existing filters 
   *
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. aiEnabled)
   * @param options Amplitude event options.
   */
  searchForKol(
    properties: SearchForKolProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchForKol(properties), options);
  }

  /**
   * Search for KOL Content
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Search%20for%20KOL%20Content)
   *
   * 點擊送出以關鍵字搜文
   *
   * @param properties The event's properties (e.g. countryCode)
   * @param options Amplitude event options.
   */
  searchForKolContent(
    properties: SearchForKolContentProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchForKolContent(properties), options);
  }

  /**
   * Search for KOL Content Fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Search%20for%20KOL%20Content%20Fail)
   *
   * 點擊送出以關鍵字搜文失敗
   *
   * @param properties The event's properties (e.g. countryCode)
   * @param options Amplitude event options.
   */
  searchForKolContentFail(
    properties: SearchForKolContentFailProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchForKolContentFail(properties), options);
  }

  /**
   * Search for KOL Content No Result
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Search%20for%20KOL%20Content%20No%20Result)
   *
   * 點擊送出以關鍵字搜文，結果沒結果
   *
   * @param properties The event's properties (e.g. countryCode)
   * @param options Amplitude event options.
   */
  searchForKolContentNoResult(
    properties: SearchForKolContentNoResultProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchForKolContentNoResult(properties), options);
  }

  /**
   * Search for KOL Fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Search%20for%20KOL%20Fail)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. audienceAge)
   * @param options Amplitude event options.
   */
  searchForKolFail(
    properties: SearchForKolFailProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchForKolFail(properties), options);
  }

  /**
   * Search for KOL in Collection
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Search%20for%20KOL%20in%20Collection)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. kolId)
   * @param options Amplitude event options.
   */
  searchForKolInCollection(
    properties?: SearchForKolInCollectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchForKolInCollection(properties), options);
  }

  /**
   * Search for KOL No Result
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Search%20for%20KOL%20No%20Result)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. audienceAge)
   * @param options Amplitude event options.
   */
  searchForKolNoResult(
    properties: SearchForKolNoResultProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchForKolNoResult(properties), options);
  }

  /**
   * Search for KOL with AI
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Search%20for%20KOL%20with%20AI)
   *
   * 使用 AI 搜尋，成功送出
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. keyword)
   * @param options Amplitude event options.
   */
  searchForKolWithAi(
    properties: SearchForKolWithAiProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchForKolWithAi(properties), options);
  }

  /**
   * Search for KOL with AI Fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Search%20for%20KOL%20with%20AI%20Fail)
   *
   * 使用 AI 搜尋，並點擊送出結果失敗
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. keyword)
   * @param options Amplitude event options.
   */
  searchForKolWithAiFail(
    properties: SearchForKolWithAiFailProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchForKolWithAiFail(properties), options);
  }

  /**
   * Search for KOL with AI No Result
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Search%20for%20KOL%20with%20AI%20No%20Result)
   *
   * 使用 AI 搜尋，沒有搜尋結果產生
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. keyword)
   * @param options Amplitude event options.
   */
  searchForKolWithAiNoResult(
    properties: SearchForKolWithAiNoResultProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchForKolWithAiNoResult(properties), options);
  }

  /**
   * Search Similar KOL
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Search%20Similar%20KOL)
   *
   * Event triggered when a user browses for similar KOL after performing a search 
   *  使用者點擊找相似時觸發
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. at)
   * @param options Amplitude event options.
   */
  searchSimilarKol(
    properties: SearchSimilarKolProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchSimilarKol(properties), options);
  }

  /**
   * Send KOL Invitation
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Send%20KOL%20Invitation)
   *
   *
   *
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  sendKolInvitation(
    properties: SendKolInvitationProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendKolInvitation(properties), options);
  }

  /**
   * Send Messages
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Send%20Messages)
   *
   *
   *
   *
   * KOL side or Brand side click Send Messages
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  sendMessages(
    properties: SendMessagesProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendMessages(properties), options);
  }

  /**
   * Send Report Invitation
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Send%20Report%20Invitation)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. invitee)
   * @param options Amplitude event options.
   */
  sendReportInvitation(
    properties: SendReportInvitationProperties,
    options?: EventOptions,
  ) {
    return this.track(new SendReportInvitation(properties), options);
  }

  /**
   * Show Current Status by VIP Trial
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Show%20Current%20Status%20by%20VIP%20Trial)
   *
   *
   * 當使用 VIP 試用註冊後，到平台跳出目前 workspace 狀態
   *
   * @param properties The event's properties (e.g. status)
   * @param options Amplitude event options.
   */
  showCurrentStatusByVipTrial(
    properties: ShowCurrentStatusByVipTrialProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShowCurrentStatusByVipTrial(properties), options);
  }

  /**
   * Show More KOL
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Show%20More%20KOL)
   *
   * This event is triggered when a user interacts with the "Show More KOL" button while browsing
   *
   *
   *
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. kolDcIdResult)
   * @param options Amplitude event options.
   */
  showMoreKol(
    properties?: ShowMoreKolProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShowMoreKol(properties), options);
  }

  /**
   * Show Search Guide
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Show%20Search%20Guide)
   *
   * * 前往 `/search` 探索網紅 Tab
   *
   *   * 點擊搜尋指引
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param options Amplitude event options.
   */
  showSearchGuide(
    options?: EventOptions,
  ) {
    return this.track(new ShowSearchGuide(), options);
  }

  /**
   * Show Tutorial
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Show%20Tutorial)
   *
   * * 建立新工作區
   *
   *   * 點擊開始探索
   *
   *   * 
   *
   *
   * Owner: abbie.lin@ikala.tv
   *
   * @param properties The event's properties (e.g. tutorialTopic)
   * @param options Amplitude event options.
   */
  showTutorial(
    properties: ShowTutorialProperties,
    options?: EventOptions,
  ) {
    return this.track(new ShowTutorial(properties), options);
  }

  /**
   * Single Login Alert
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Single%20Login%20Alert)
   *
   * 觸發重複登入
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. firebaseRef)
   * @param options Amplitude event options.
   */
  singleLoginAlert(
    properties: SingleLoginAlertProperties,
    options?: EventOptions,
  ) {
    return this.track(new SingleLoginAlert(properties), options);
  }

  /**
   * Something Went Wrong
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Something%20Went%20Wrong)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. path)
   * @param options Amplitude event options.
   */
  somethingWentWrong(
    properties?: SomethingWentWrongProperties,
    options?: EventOptions,
  ) {
    return this.track(new SomethingWentWrong(properties), options);
  }

  /**
   * Start Onboarding Tutorial
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Start%20Onboarding%20Tutorial)
   *
   * 1. 使用者看到主動跳出的導覽（不包含 點擊 menu replay）
   *
   * @param properties The event's properties (e.g. onboardingItem)
   * @param options Amplitude event options.
   */
  startOnboardingTutorial(
    properties: StartOnboardingTutorialProperties,
    options?: EventOptions,
  ) {
    return this.track(new StartOnboardingTutorial(properties), options);
  }

  /**
   * Start Purchase
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Start%20Purchase)
   *
   * Owner: Finn Yeh
   *
   * @param options Amplitude event options.
   */
  startPurchase(
    options?: EventOptions,
  ) {
    return this.track(new StartPurchase(), options);
  }

  /**
   * Stay KOL Detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Stay%20KOL%20Detail)
   *
   * * 停留一個區塊超過 3 秒
   *
   *   * 在造訪 KD 頁後，一個區塊最多只發送一次。
   *
   *   * 例如停留三十秒不會發送十次
   *
   * * 停留的細節定義：將一個看不見的元件放在區塊**正中間**，點開元件停留於視窗內三秒即發送。
   *
   * * 重整畫面後會重新計算 3 秒，因為使用者重新瀏覽一遍 KD 頁。
   *
   * * 欲分析停留哪個區塊，請參考 property "kolMetrics"
   *
   * @param properties The event's properties (e.g. isUnlocked)
   * @param options Amplitude event options.
   */
  stayKolDetail(
    properties: StayKolDetailProperties,
    options?: EventOptions,
  ) {
    return this.track(new StayKolDetail(properties), options);
  }

  /**
   * Stay KOL Detail Section
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Stay%20KOL%20Detail%20Section)
   *
   * When user stay on particular section in KOL Detail page.  
   *
   *
   * Start tracking when the section shown more thant 50% and emit the event once the section leave the window wiht less than 50%.
   *
   * @param properties The event's properties (e.g. kolDcId)
   * @param options Amplitude event options.
   */
  stayKolDetailSection(
    properties: StayKolDetailSectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new StayKolDetailSection(properties), options);
  }

  /**
   * Stay KOL Detail Section 10S
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Stay%20KOL%20Detail%20Section%2010S)
   *
   * When user stay on particular section in KOL Detail page with more than 10 seconds.
   *
   * @param properties The event's properties (e.g. isUnlocked)
   * @param options Amplitude event options.
   */
  stayKolDetailSection10S(
    properties: StayKolDetailSection10SProperties,
    options?: EventOptions,
  ) {
    return this.track(new StayKolDetailSection10S(properties), options);
  }

  /**
   * Stay KOL Detail Section 30S
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Stay%20KOL%20Detail%20Section%2030S)
   *
   * When user stay on particular section in KOL Detail page with more than 30 seconds.
   *
   * @param properties The event's properties (e.g. isUnlocked)
   * @param options Amplitude event options.
   */
  stayKolDetailSection30S(
    properties: StayKolDetailSection30SProperties,
    options?: EventOptions,
  ) {
    return this.track(new StayKolDetailSection30S(properties), options);
  }

  /**
   * Stay KOL Detail Section 60S
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Stay%20KOL%20Detail%20Section%2060S)
   *
   * When user stay on particular section in KOL Detail page with more than 60 seconds.
   *
   * @param properties The event's properties (e.g. isUnlocked)
   * @param options Amplitude event options.
   */
  stayKolDetailSection60S(
    properties: StayKolDetailSection60SProperties,
    options?: EventOptions,
  ) {
    return this.track(new StayKolDetailSection60S(properties), options);
  }

  /**
   * Stop Payment
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Stop%20Payment)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  stopPayment(
    properties?: StopPaymentProperties,
    options?: EventOptions,
  ) {
    return this.track(new StopPayment(properties), options);
  }

  /**
   * Submit Enterprise Plan
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Submit%20Enterprise%20Plan)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. currency)
   * @param options Amplitude event options.
   */
  submitEnterprisePlan(
    properties?: SubmitEnterprisePlanProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitEnterprisePlan(properties), options);
  }

  /**
   * Submit Forget Password
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Submit%20Forget%20Password)
   *
   * * 前往 `/reset-password`
   *
   *   * 填寫 Email 後按確認
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. emails)
   * @param options Amplitude event options.
   */
  submitForgetPassword(
    properties: SubmitForgetPasswordProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitForgetPassword(properties), options);
  }

  /**
   * Submit Forget Password Failed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Submit%20Forget%20Password%20Failed)
   *
   * * 前往 `/reset-password`
   *
   *   * 填寫 Email 後按確認，失敗時觸發
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. emails)
   * @param options Amplitude event options.
   */
  submitForgetPasswordFailed(
    properties: SubmitForgetPasswordFailedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubmitForgetPasswordFailed(properties), options);
  }

  /**
   * Toggle Search Filter
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Toggle%20Search%20Filter)
   *
   * * `/community`
   *
   *
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. switch)
   * @param options Amplitude event options.
   */
  toggleSearchFilter(
    properties: ToggleSearchFilterProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToggleSearchFilter(properties), options);
  }

  /**
   * Toggle to Workspace
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Toggle%20to%20Workspace)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. workspaceID)
   * @param options Amplitude event options.
   */
  toggleToWorkspace(
    properties?: ToggleToWorkspaceProperties,
    options?: EventOptions,
  ) {
    return this.track(new ToggleToWorkspace(properties), options);
  }

  /**
   * Try Add Campaign Influencers
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Try%20Add%20Campaign%20Influencers)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  tryAddCampaignInfluencers(
    properties: TryAddCampaignInfluencersProperties,
    options?: EventOptions,
  ) {
    return this.track(new TryAddCampaignInfluencers(properties), options);
  }

  /**
   * Try Editing Report Preview
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Try%20Editing%20Report%20Preview)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. reportContentCount)
   * @param options Amplitude event options.
   */
  tryEditingReportPreview(
    properties: TryEditingReportPreviewProperties,
    options?: EventOptions,
  ) {
    return this.track(new TryEditingReportPreview(properties), options);
  }

  /**
   * Try Export Community KOL
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Try%20Export%20Community%20KOL)
   *
   * * `/community`
   *
   *
   *
   *
   * * `/community`
   *
   *
   *
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. kolAmount)
   * @param options Amplitude event options.
   */
  tryExportCommunityKol(
    properties: TryExportCommunityKolProperties,
    options?: EventOptions,
  ) {
    return this.track(new TryExportCommunityKol(properties), options);
  }

  /**
   * Try Grant SNS Permission
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Try%20Grant%20SNS%20Permission)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. platform)
   * @param options Amplitude event options.
   */
  tryGrantSnsPermission(
    properties: TryGrantSnsPermissionProperties,
    options?: EventOptions,
  ) {
    return this.track(new TryGrantSnsPermission(properties), options);
  }

  /**
   * Try Import KOL
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Try%20Import%20KOL)
   *
   * * `/community`
   *
   *   * 點擊 `加入 KOL`
   *
   *   * 
   *
   *
   * Owner: Belinda Chen
   *
   * @param options Amplitude event options.
   */
  tryImportKol(
    options?: EventOptions,
  ) {
    return this.track(new TryImportKol(), options);
  }

  /**
   * Try Remove KOL
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Try%20Remove%20KOL)
   *
   * * 前往任一收藏庫並切換至列表顯示
   *
   *   * 點擊任一 KOL 列表上的刪除
   *
   *   * 
   *
   *
   * * 前往任一收藏庫並切換至列表顯示
   *
   *   * 展開任一 KOL 資料
   *
   *   * 
   *
   *
   *   * 點擊展開的列表上的刪除
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. kolId)
   * @param options Amplitude event options.
   */
  tryRemoveKol(
    properties: TryRemoveKolProperties,
    options?: EventOptions,
  ) {
    return this.track(new TryRemoveKol(properties), options);
  }

  /**
   * Try Search for KOL
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Try%20Search%20for%20KOL)
   *
   * * 前往 `/search`
   *
   *   * 點擊輸入匡
   *
   *   * 
   *
   *
   * * 前往非 `/search` 功能頁
   *
   *   * 點擊輸入匡
   *
   *   * 
   *
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. path)
   * @param options Amplitude event options.
   */
  trySearchForKol(
    properties?: TrySearchForKolProperties,
    options?: EventOptions,
  ) {
    return this.track(new TrySearchForKol(properties), options);
  }

  /**
   * Try Search for KOL Content
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Try%20Search%20for%20KOL%20Content)
   *
   * 嘗試搜尋貼文
   *
   * @param options Amplitude event options.
   */
  trySearchForKolContent(
    options?: EventOptions,
  ) {
    return this.track(new TrySearchForKolContent(), options);
  }

  /**
   * Try Sharing Report
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Try%20Sharing%20Report)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. reportContentCount)
   * @param options Amplitude event options.
   */
  trySharingReport(
    properties?: TrySharingReportProperties,
    options?: EventOptions,
  ) {
    return this.track(new TrySharingReport(properties), options);
  }

  /**
   * Try to Export KOL Data
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Try%20to%20Export%20KOL%20Data)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. isUnlocked)
   * @param options Amplitude event options.
   */
  tryToExportKolData(
    properties?: TryToExportKolDataProperties,
    options?: EventOptions,
  ) {
    return this.track(new TryToExportKolData(properties), options);
  }

  /**
   * Unbind Social Account Fail
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Unbind%20Social%20Account%20Fail)
   *
   * * 登入品牌主並前往 `/settings`
   *
   *   * 解除任一社群帳號綁定，失敗時觸法
   *
   *     * 
   *
   *
   * * 登入品牌主並前往 `/settings`
   *
   *   * 在沒有設定密碼時解除任一社群帳號綁定
   *
   *   * 
   *
   *
   *   * 點擊設定密碼
   *
   *   * 
   *
   *
   *   * 點擊 `送出並解除綁定` 失敗時觸發
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. ssoPlatform)
   * @param options Amplitude event options.
   */
  unbindSocialAccountFail(
    properties?: UnbindSocialAccountFailProperties,
    options?: EventOptions,
  ) {
    return this.track(new UnbindSocialAccountFail(properties), options);
  }

  /**
   * Unbind Social Account Success
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Unbind%20Social%20Account%20Success)
   *
   * * 登入品牌主並前往 `/settings`
   *
   *   * 解除任一社群帳號綁定
   *
   *     * 
   *
   *
   * * 登入品牌主並前往 `/settings`
   *
   *   * 在沒有設定密碼時解除任一社群帳號綁定
   *
   *   * 
   *
   *
   *   * 點擊設定密碼
   *
   *   * 
   *
   *
   *   * 點擊 `送出並解除綁定` 成功後觸發
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. ssoPlatform)
   * @param options Amplitude event options.
   */
  unbindSocialAccountSuccess(
    properties?: UnbindSocialAccountSuccessProperties,
    options?: EventOptions,
  ) {
    return this.track(new UnbindSocialAccountSuccess(properties), options);
  }

  /**
   * Undo Canceled Subscription
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Undo%20Canceled%20Subscription)
   *
   * 恢復訂閱
   *
   * Owner: Belinda Chen
   *
   * @param properties The event's properties (e.g. plan)
   * @param options Amplitude event options.
   */
  undoCanceledSubscription(
    properties?: UndoCanceledSubscriptionProperties,
    options?: EventOptions,
  ) {
    return this.track(new UndoCanceledSubscription(properties), options);
  }

  /**
   * Unhide Report KOL Content
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Unhide%20Report%20KOL%20Content)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. kolIId)
   * @param options Amplitude event options.
   */
  unhideReportKolContent(
    properties: UnhideReportKolContentProperties,
    options?: EventOptions,
  ) {
    return this.track(new UnhideReportKolContent(properties), options);
  }

  /**
   * Update Campaign
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Update%20Campaign)
   *
   *
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. campaignBudgetPerPerson)
   * @param options Amplitude event options.
   */
  updateCampaign(
    properties: UpdateCampaignProperties,
    options?: EventOptions,
  ) {
    return this.track(new UpdateCampaign(properties), options);
  }

  /**
   * Update Report
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Update%20Report)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. reportId)
   * @param options Amplitude event options.
   */
  updateReport(
    properties: UpdateReportProperties,
    options?: EventOptions,
  ) {
    return this.track(new UpdateReport(properties), options);
  }

  /**
   * Update Report Setting
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Update%20Report%20Setting)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. dateKeywordChange)
   * @param options Amplitude event options.
   */
  updateReportSetting(
    properties: UpdateReportSettingProperties,
    options?: EventOptions,
  ) {
    return this.track(new UpdateReportSetting(properties), options);
  }

  /**
   * Verify email successfully
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Verify%20email%20successfully)
   *
   * when users verify email successfully, via any method
   *
   * * method: sso, email
   *
   * * ssoPlatform: facebook, instagram, google
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. method)
   * @param options Amplitude event options.
   */
  verifyEmailSuccessfully(
    properties: VerifyEmailSuccessfullyProperties,
    options?: EventOptions,
  ) {
    return this.track(new VerifyEmailSuccessfully(properties), options);
  }

  /**
   * View AI Action Search Result
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/View%20AI%20Action%20Search%20Result)
   *
   * 瀏覽 AI 行為模組結果 
   *  https://www.notion.so/ikala/AI-Search-AI-c974d2bae3684cbea91305e8a96fe2b7#68c15e60079a4c9cb1a4c0e1009091e3
   *
   * @param properties The event's properties (e.g. aiActionKolDcIds)
   * @param options Amplitude event options.
   */
  viewAiActionSearchResult(
    properties: ViewAiActionSearchResultProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewAiActionSearchResult(properties), options);
  }

  /**
   * View AI Filter Search Result
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/View%20AI%20Filter%20Search%20Result)
   *
   * 顯示 AI 篩選器模組的結果 
   *  https://www.notion.so/ikala/AI-Search-AI-c974d2bae3684cbea91305e8a96fe2b7#68c15e60079a4c9cb1a4c0e1009091e3
   *
   * @param properties The event's properties (e.g. aiFilterKolDcIds)
   * @param options Amplitude event options.
   */
  viewAiFilterSearchResult(
    properties?: ViewAiFilterSearchResultProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewAiFilterSearchResult(properties), options);
  }

  /**
   * View AI Keyword Search Result
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/View%20AI%20Keyword%20Search%20Result)
   *
   * 瀏覽 AI 關鍵字模組結果 
   *  https://www.notion.so/ikala/AI-Search-AI-c974d2bae3684cbea91305e8a96fe2b7#68c15e60079a4c9cb1a4c0e1009091e3
   *
   * @param properties The event's properties (e.g. aiKeywordKolDcIds)
   * @param options Amplitude event options.
   */
  viewAiKeywordSearchResult(
    properties: ViewAiKeywordSearchResultProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewAiKeywordSearchResult(properties), options);
  }

  /**
   * View Login Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/View%20Login%20Screen)
   *
   * when user lands on the login or register screen/page
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. userGroup)
   * @param options Amplitude event options.
   */
  viewLoginScreen(
    properties: ViewLoginScreenProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewLoginScreen(properties), options);
  }

  /**
   * View Original Post From Embedded Post
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/View%20Original%20Post%20From%20Embedded%20Post)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. kolDcId)
   * @param options Amplitude event options.
   */
  viewOriginalPostFromEmbeddedPost(
    properties?: ViewOriginalPostFromEmbeddedPostProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewOriginalPostFromEmbeddedPost(properties), options);
  }

  /**
   * View Reporting
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/View%20Reporting)
   *
   * Click New Campaign Reporting Tab
   *
   *
   *
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. campaignId)
   * @param options Amplitude event options.
   */
  viewReporting(
    properties: ViewReportingProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewReporting(properties), options);
  }

  /**
   * View Search Content Result
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/View%20Search%20Content%20Result)
   *
   * 以關鍵字搜文的結果
   *
   * @param properties The event's properties (e.g. kolDcIdResult)
   * @param options Amplitude event options.
   */
  viewSearchContentResult(
    properties: ViewSearchContentResultProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewSearchContentResult(properties), options);
  }

  /**
   * View Search Result
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/View%20Search%20Result)
   *
   * This event tracks when a user views the search results page after a search made.
   *
   *
   * Basically users would see up to 12 influencers in a search. If users would like to see more, they can click "Show more" for more influencer result.
   *
   *
   *
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  viewSearchResult(
    properties: ViewSearchResultProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewSearchResult(properties), options);
  }

  /**
   * View Signup Screen
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/View%20Signup%20Screen)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. userGroup)
   * @param options Amplitude event options.
   */
  viewSignupScreen(
    properties?: ViewSignupScreenProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewSignupScreen(properties), options);
  }

  /**
   * View SNS Post
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/View%20SNS%20Post)
   *
   * when user navigate to view the SNS post, usually by clicking the content listed
   *
   *
   *
   *
   * @param properties The event's properties (e.g. kolDcId)
   * @param options Amplitude event options.
   */
  viewSnsPost(
    properties?: ViewSnsPostProperties,
    options?: EventOptions,
  ) {
    return this.track(new ViewSnsPost(properties), options);
  }

  /**
   * Visit Brand Analysis
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Visit%20Brand%20Analysis)
   *
   * 點擊品牌帳號分析的各平台分析
   *
   * @param properties The event's properties (e.g. kolIds)
   * @param options Amplitude event options.
   */
  visitBrandAnalysis(
    properties?: VisitBrandAnalysisProperties,
    options?: EventOptions,
  ) {
    return this.track(new VisitBrandAnalysis(properties), options);
  }

  /**
   * Visit Brand Comparison Analysis
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Visit%20Brand%20Comparison%20Analysis)
   *
   * 查看品牌分析的綜合比較頁面
   *
   * @param properties The event's properties (e.g. kolIds)
   * @param options Amplitude event options.
   */
  visitBrandComparisonAnalysis(
    properties?: VisitBrandComparisonAnalysisProperties,
    options?: EventOptions,
  ) {
    return this.track(new VisitBrandComparisonAnalysis(properties), options);
  }

  /**
   * Visit Campaign List
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Visit%20Campaign%20List)
   *
   * 拜訪活動媒合活動列表
   *
   * Owner: Finn Yeh
   *
   * @param options Amplitude event options.
   */
  visitCampaignList(
    options?: EventOptions,
  ) {
    return this.track(new VisitCampaignList(), options);
  }

  /**
   * Visit Chrome Extension Webstore
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Visit%20Chrome%20Extension%20Webstore)
   *
   * 使用者點選各處 Extension Banner 前往商店頁面下載
   *
   * 1. 官網
   *
   * 2. APP 內
   *
   * 3. 報告書或排行榜頁面等等
   *
   *
   *
   *
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  visitChromeExtensionWebstore(
    properties?: VisitChromeExtensionWebstoreProperties,
    options?: EventOptions,
  ) {
    return this.track(new VisitChromeExtensionWebstore(properties), options);
  }

  /**
   * Visit Collection
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Visit%20Collection)
   *
   * * 前往 `/projects`
   *
   *   * 檢視任一收藏庫
   *
   *   * 
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. collectionType)
   * @param options Amplitude event options.
   */
  visitCollection(
    properties: VisitCollectionProperties,
    options?: EventOptions,
  ) {
    return this.track(new VisitCollection(properties), options);
  }

  /**
   * Visit Community Page
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Visit%20Community%20Page)
   *
   * * 前往 `/community`
   *
   * Owner: Belinda Chen
   *
   * @param options Amplitude event options.
   */
  visitCommunityPage(
    options?: EventOptions,
  ) {
    return this.track(new VisitCommunityPage(), options);
  }

  /**
   * Visit Deep Report List
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Visit%20Deep%20Report%20List)
   *
   * Owner: Finn Yeh
   *
   * @param options Amplitude event options.
   */
  visitDeepReportList(
    options?: EventOptions,
  ) {
    return this.track(new VisitDeepReportList(), options);
  }

  /**
   * Visit Keyword Analysis
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Visit%20Keyword%20Analysis)
   *
   * 使用者在關鍵字分析中，點擊關鍵字提及分析報表 Tab 時觸發
   *
   * @param properties The event's properties (e.g. path)
   * @param options Amplitude event options.
   */
  visitKeywordAnalysis(
    properties: VisitKeywordAnalysisProperties,
    options?: EventOptions,
  ) {
    return this.track(new VisitKeywordAnalysis(properties), options);
  }

  /**
   * Visit Keyword Data
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Visit%20Keyword%20Data)
   *
   * 拜訪關鍵字提及數據報表
   *
   * @param properties The event's properties (e.g. path)
   * @param options Amplitude event options.
   */
  visitKeywordData(
    properties: VisitKeywordDataProperties,
    options?: EventOptions,
  ) {
    return this.track(new VisitKeywordData(properties), options);
  }

  /**
   * Visit KOL Detail
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Visit%20KOL%20Detail)
   *
   * This event tracks when a user visits the detailed page of a Key Opinion Leader (KOL) on our platform.
   *
   * e.g. https://app.kolradar.com/kol/8960bb9e-177d-435b-8a79-65a62943645f
   *
   *
   *
   *
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. aiFilterType)
   * @param options Amplitude event options.
   */
  visitKolDetail(
    properties: VisitKolDetailProperties,
    options?: EventOptions,
  ) {
    return this.track(new VisitKolDetail(properties), options);
  }

  /**
   * Visit KOL Detail from Public
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Visit%20KOL%20Detail%20from%20Public)
   *
   * 1. 使用者在官網搜尋網紅後，點擊該網紅詳細頁面
   *
   * 2. 使用者在官網看到排行榜，點擊該網紅詳細頁面
   *
   * 3. 使用者從 Chrome extension 搜尋網紅，點擊該網紅詳細頁面
   *
   * Owner: PeiYing Tsai
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  visitKolDetailFromPublic(
    properties?: VisitKolDetailFromPublicProperties,
    options?: EventOptions,
  ) {
    return this.track(new VisitKolDetailFromPublic(properties), options);
  }

  /**
   * Visit KOL Social Media
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Visit%20KOL%20Social%20Media)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  visitKolSocialMedia(
    properties: VisitKolSocialMediaProperties,
    options?: EventOptions,
  ) {
    return this.track(new VisitKolSocialMedia(properties), options);
  }

  /**
   * Visit Mention Analysis
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Visit%20Mention%20Analysis)
   *
   * 拜訪提及分析
   *
   * @param properties The event's properties (e.g. kolIds)
   * @param options Amplitude event options.
   */
  visitMentionAnalysis(
    properties?: VisitMentionAnalysisProperties,
    options?: EventOptions,
  ) {
    return this.track(new VisitMentionAnalysis(properties), options);
  }

  /**
   * Visit Mention Data
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Visit%20Mention%20Data)
   *
   * 拜訪提及數據
   *
   * @param properties The event's properties (e.g. kolIds)
   * @param options Amplitude event options.
   */
  visitMentionData(
    properties?: VisitMentionDataProperties,
    options?: EventOptions,
  ) {
    return this.track(new VisitMentionData(properties), options);
  }

  /**
   * Visit Report Overview
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Visit%20Report%20Overview)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. reportContentCount)
   * @param options Amplitude event options.
   */
  visitReportOverview(
    properties: VisitReportOverviewProperties,
    options?: EventOptions,
  ) {
    return this.track(new VisitReportOverview(properties), options);
  }

  /**
   * Visit Report Preview
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Visit%20Report%20Preview)
   *
   * Owner: Finn Yeh
   *
   * @param properties The event's properties (e.g. reportContentCount)
   * @param options Amplitude event options.
   */
  visitReportPreview(
    properties?: VisitReportPreviewProperties,
    options?: EventOptions,
  ) {
    return this.track(new VisitReportPreview(properties), options);
  }

  /**
   * Visit Social Account Ranking
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Visit%20Social%20Account%20Ranking)
   *
   * 前往百大排行榜
   *
   * @param properties The event's properties (e.g. from)
   * @param options Amplitude event options.
   */
  visitSocialAccountRanking(
    properties?: VisitSocialAccountRankingProperties,
    options?: EventOptions,
  ) {
    return this.track(new VisitSocialAccountRanking(properties), options);
  }

  /**
   * Visit Unlock Quota Log
   *
   * [View in Tracking Plan](https://data.amplitude.com/kolradar/kolradar-production/events/main/latest/Visit%20Unlock%20Quota%20Log)
   *
   * 查看解鎖紀錄
   *
   * Owner: Belinda Chen
   *
   * @param options Amplitude event options.
   */
  visitUnlockQuotaLog(
    options?: EventOptions,
  ) {
    return this.track(new VisitUnlockQuotaLog(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
