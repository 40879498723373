import { isEqual, isUndefined, omit } from 'lodash-es'
import { api, WorkspacesAPITagType } from '@/api/api'
import { apiVersionSchema } from '@/api/utils/default-api-version-base-query-wrapper'
import getWorkspaceApiUrl from '@/api/utils/get-workspace-api-url'
import {
  KolRadarSearchRequest,
  KolRadarSearchResponse,
  KOLResponse,
  QuickSearchRequest,
  QuickSearchResponse,
  SearchSimilarKolResponse,
  SearchSimilarKolsRequest,
} from '@/types/api/search'
import { applyKolSchemaModel } from '@/types/schema/kol-schema'
import { camelizeKeys, decamelizeKeys } from '@/utils/humps'
import removeNil from '@/utils/remove-nil'

export const searchApi = api.injectEndpoints({
  endpoints: (builder) => ({
    searchSimilarKols: builder.query<
      SearchSimilarKolResponse,
      SearchSimilarKolsRequest
    >({
      query: ({ workspaceId, v3, ...params }) => {
        const version = v3 ? apiVersionSchema.enum.v3 : apiVersionSchema.enum.v2
        const url = v3
          ? `/${version}/workspaces/${workspaceId}/kol/search-similar-kols`
          : `/${version}/workspaces/${workspaceId}/kols/${params.radar_uuid}/search-similar-kols`
        return {
          url,
          params: removeNil(params),
        }
      },
      transformResponse: (response: object) => {
        const camelizeResponse = camelizeKeys(
          response,
        ) as unknown as SearchSimilarKolResponse

        return {
          ...camelizeResponse,
          list: camelizeResponse.list.map(applyKolSchemaModel),
        }
      },
      providesTags: [
        WorkspacesAPITagType.SearchSimilarKols,
        WorkspacesAPITagType.KolCollectionKol,
      ],
    }),
    kolRadarSearch: builder.query<
      KolRadarSearchResponse,
      KolRadarSearchRequest
    >({
      query: ({ workspaceId, v3, params, beforeQueryStarts }) => {
        const version = v3 ? apiVersionSchema.enum.v3 : apiVersionSchema.enum.v2
        return {
          url: `/${version}/workspaces/${workspaceId}/kol/radar-search`,
          params,
          beforeQueryStarts,
        }
      },
      transformResponse: (response: object) => {
        const camelizeResponse = camelizeKeys(
          response,
        ) as unknown as KOLResponse

        return {
          ...camelizeResponse,
          list: camelizeResponse.list.map(applyKolSchemaModel),
        }
      },
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName
      },
      merge: (currentCache, newResponse, { arg }): void => {
        const updateCache = {
          ...newResponse,
          list: isUndefined(arg.params.anchor)
            ? newResponse.list
            : [...currentCache.list, ...newResponse.list],
        }

        Object.assign(currentCache, updateCache)
      },
      forceRefetch({ currentArg, previousArg }): boolean {
        const omitEventKeys = ['beforeQueryStarts', 'onQueryFulfilled']

        return !isEqual(
          omit(currentArg, omitEventKeys),
          omit(previousArg, omitEventKeys),
        )
      },
      providesTags: [
        WorkspacesAPITagType.KolRadarSearch,
        WorkspacesAPITagType.KolCollectionKol,
      ],
    }),
    quickSearch: builder.query<QuickSearchResponse, QuickSearchRequest>({
      query: ({ limit = 60, ...params }) => {
        return {
          url: getWorkspaceApiUrl('/kol/quick-search'),
          params: removeNil(decamelizeKeys({ ...params, limit })),
        }
      },
      transformResponse: (response: object) => {
        return camelizeKeys(response) as unknown as QuickSearchResponse
      },
    }),
  }),
  overrideExisting: false,
})

export const {
  useSearchSimilarKolsQuery,
  useKolRadarSearchQuery,
  useQuickSearchQuery,
  useLazyQuickSearchQuery,
} = searchApi
