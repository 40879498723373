import demoCooperationSrc1 from '@/assets/images/auto-report/demo/cooperation/1.png'
import demoCooperationSrc2 from '@/assets/images/auto-report/demo/cooperation/2.png'
import demoCooperationSrc3 from '@/assets/images/auto-report/demo/cooperation/3.png'
import demoCooperationSrc4 from '@/assets/images/auto-report/demo/cooperation/4.png'
import demoCooperationSrc5 from '@/assets/images/auto-report/demo/cooperation/5.png'
import demoCooperationSrc6 from '@/assets/images/auto-report/demo/cooperation/6.png'
import {
  FetchCommentsResponse,
  FetchReportCrawlerStatusResponse,
  FetchTermsFrequencyResponse,
  FetchTermsResponse,
} from '@/types/api/report'
import { Statistics } from '@/types/schema/chart-data-schema'

const demoReport: unknown = {
  id: 'demo',
  createdAt: '2023-05-17T06:28:56Z',
  updatedAt: '2023-05-18T02:57:33Z',
  deletedAt: null,
  name: '報表範例',
  ownerID: 0,
  owner: {
    id: 0,
    createdAt: '2022-07-21T07:37:10Z',
    updatedAt: '2023-06-12T06:35:59Z',
    deletedAt: null,
    billingToUser: null,
    name: 'KOL Radar',
    email: 'kol.radar@ikala.ai',
    socialAccount: null,
    radarContractAcceptions: null,
    agencyRelation: null,
    kolRelations: null,
    kolRadarMainContract: null,
    agencyRadarMainContract: null,
    authAssignments: null,
    guestAssignments: null,
    currentWorkspaceID: 47933,
    type: 'ad',
    phone: '0911111111',
    isEnable: true,
    isVerified: true,
    group: '',
    company: '愛卡拉互動媒體股份有限公司',
    jobTitle: '',
    industry: '',
    kolID: null,
    language: 'zh',
    country: 'tw',
    companyPhone: '',
    companyAddress: '',
    incompleteRegistration: false,
    commissionPercentage: '10',
    accountingInfo: null,
    agentContactInfo: null,
    userCustomizedFunction: null,
    deprecatedMongoID: '62d902241d2d1b0015125df3',
    kOLs: null,
    targetRadarMainContract: null,
    targetRadarMainContractAcception: null,
    detailInfo: {
      userName: 'kol.radar',
      phone: '0911111111',
      instantMessagings: [],
      companyName: '愛卡拉互動媒體股份有限公司',
      companyPhone: null,
      companyAddr: null,
      linkUnderTheName: null,
    },
    roleInWorkspace: null,
  },
  workspaceID: 41602,
  reportPosts: [],
  reportKOLs: [
    {
      id: 10575,
      createdAt: '2023-05-17T06:28:56Z',
      updatedAt: '2023-05-17T06:28:56Z',
      deletedAt: null,
      reportID: 'demo',
      report: null,
      UUID: 'KOL-UUID-1',
      kolName: '王小明吃美食 Xiao Min Foodie',
      categories: ['Outfit', 'Music', 'BeautyCare', 'FortuneTelling', 'Makeup'],
      customizedTagsNames: ['Food', 'Food/Beverage'],
      followerCount: 572864,
      platform: 0,
      platforms: [
        {
          id: 31323,
          kid: 14654,
          url: 'https://www.instagram.com',
          type: 'IG',
          platformType: 3,
          platformID: '',
          crawledID: '2108944174',
          tags: null,
          permanentURL: 'https://www.instagram.com',
          source: null,
          crawlerTasks: null,
          latestCrawlerTask: null,
          image: null,
          createdAt: '2019-04-03T02:49:57Z',
          updatedAt: '2023-03-08T03:44:16Z',
          deletedAt: null,
          datahubKolUrlIdentityId: '162441',
          weight: 1,
          statistic: {
            id: 50356,
            createdAt: '2020-07-06T19:06:43Z',
            updatedAt: '2023-06-08T05:49:21Z',
            linkID: 31323,
            followerCount: 423962,
            followerCount1M: 426021,
            followerCount3M: 414492,
            followerCount6M: 399686,
            commentCount: 0,
            commentCount1M: 170,
            commentCount3M: 1010,
            commentCount6M: 2423,
            likeCount: 0,
            likeCount1M: 94104,
            likeCount3M: 492913,
            likeCount6M: 1156610,
            shareCount: 0,
            shareCount1M: 0,
            shareCount3M: 0,
            shareCount6M: 0,
            postCount: 0,
            postCount1M: 9,
            postCount3M: 47,
            postCount6M: 101,
            videoPostCount: 0,
            videoPostCount1M: 0,
            videoPostCount3M: 0,
            videoPostCount6M: 1,
            videoViewCount: 0,
            videoViewCount1M: 0,
            videoViewCount3M: 0,
            videoViewCount6M: 171946,
            livePostCount: 0,
            livePostCount1M: 0,
            livePostCount3M: 0,
            livePostCount6M: 0,
            liveViewCount: 0,
            liveViewCount1M: 0,
            liveViewCount3M: 0,
            liveViewCount6M: 0,
            followCountGrowth1M: -2059,
            followCountGrowth3M: 9470,
            followCountGrowth6M: 24276,
            followCountGrowthRate1M: -0.0048330952,
            followCountGrowthRate3M: 0.022847245,
            followCountGrowthRate6M: 0.06073768,
            link: null,
            shortPostCount: 0,
            shortPostCount1M: 0,
            shortPostCount3M: 0,
            shortPostCount6M: 1,
            shortViewCount: 0,
            shortViewCount1M: 0,
            shortViewCount3M: 0,
            shortViewCount6M: 59976,
            score: 95,
            score1M: 95,
            score3M: 95,
            score6M: 95,
            scoreGrowth: 0,
            scoreGrowth1M: 0,
            scoreGrowth3M: 0,
            scoreGrowth6M: 0,
            shortPlayCount1M: 0,
            shortPlayCount3M: 0,
            shortPlayCount6M: 171946,
          },
          audience: null,
        },
        {
          id: 31324,
          kid: 14654,
          url: 'https://twitter.com',
          type: 'Twitter',
          platformType: 5,
          platformID: '',
          crawledID: '2698276440',
          tags: null,
          permanentURL: 'https://twitter.com',
          source: null,
          crawlerTasks: null,
          latestCrawlerTask: null,
          image: null,
          createdAt: '2019-04-03T02:50:28Z',
          updatedAt: '2023-04-14T10:50:44Z',
          deletedAt: null,
          datahubKolUrlIdentityId: '2232835',
          weight: 1,
          statistic: {
            id: 10814095,
            createdAt: '2023-05-09T07:33:04Z',
            updatedAt: '2023-05-09T07:33:04Z',
            linkID: 31324,
            followerCount: 148902,
            followerCount1M: null,
            followerCount3M: null,
            followerCount6M: null,
            commentCount: 0,
            commentCount1M: 1,
            commentCount3M: 15,
            commentCount6M: 58,
            likeCount: 0,
            likeCount1M: 140,
            likeCount3M: 5248,
            likeCount6M: 36834,
            shareCount: 0,
            shareCount1M: 4,
            shareCount3M: 96,
            shareCount6M: 1031,
            postCount: 0,
            postCount1M: 1,
            postCount3M: 5,
            postCount6M: 12,
            videoPostCount: 0,
            videoPostCount1M: 0,
            videoPostCount3M: 0,
            videoPostCount6M: 0,
            videoViewCount: 0,
            videoViewCount1M: 0,
            videoViewCount3M: 0,
            videoViewCount6M: 0,
            livePostCount: 0,
            livePostCount1M: 0,
            livePostCount3M: 0,
            livePostCount6M: 0,
            liveViewCount: 0,
            liveViewCount1M: 0,
            liveViewCount3M: 0,
            liveViewCount6M: 0,
            followCountGrowth1M: null,
            followCountGrowth3M: null,
            followCountGrowth6M: null,
            followCountGrowthRate1M: 0,
            followCountGrowthRate3M: 0,
            followCountGrowthRate6M: 0,
            link: null,
            shortPostCount: 0,
            shortPostCount1M: 0,
            shortPostCount3M: 0,
            shortPostCount6M: 0,
            shortViewCount: 0,
            shortViewCount1M: 0,
            shortViewCount3M: 0,
            shortViewCount6M: 0,
            score: 0,
            score1M: 0,
            score3M: 0,
            score6M: 0,
            scoreGrowth: null,
            scoreGrowth1M: null,
            scoreGrowth3M: null,
            scoreGrowth6M: null,
            shortPlayCount1M: 0,
            shortPlayCount3M: 0,
            shortPlayCount6M: 0,
          },
          audience: null,
        },
      ],
      lastScannedAt: null,
      handfilleds: null,
      crawlerStatuses: [],
      crawlerTasks: null,
    },
    {
      id: 10576,
      createdAt: '2023-05-17T06:28:56Z',
      updatedAt: '2023-05-17T06:28:56Z',
      deletedAt: null,
      reportID: 'demo',
      report: null,
      UUID: 'KOL-UUID-2',
      kolName: 'Joy 喬伊吃吃喝喝',
      categories: ['Outfit', 'Makeup', 'BeautyCare', 'Food', 'Travel'],
      customizedTagsNames: ['Food', 'Food/Beverage'],
      followerCount: 1125273,
      platform: 0,
      platforms: [
        {
          id: 37988,
          kid: 16944,
          url: 'https://www.youtube.com',
          type: 'YouTube',
          platformType: 0,
          platformID: '',
          crawledID: 'UCkmQx9zSwidPupChljGjwGA',
          tags: null,
          permanentURL: 'https://www.youtube.com',
          source: null,
          crawlerTasks: null,
          latestCrawlerTask: null,
          image: null,
          createdAt: '2019-04-30T08:24:49Z',
          updatedAt: '2023-03-08T03:57:58Z',
          deletedAt: null,
          datahubKolUrlIdentityId: '35574',
          weight: 1,
          statistic: {
            id: 117215,
            createdAt: '2020-07-07T08:52:05Z',
            updatedAt: '2023-06-07T04:49:44Z',
            linkID: 37988,
            followerCount: 510000,
            followerCount1M: 509000,
            followerCount3M: 505400,
            followerCount6M: 499000,
            commentCount: 0,
            commentCount1M: 384,
            commentCount3M: 944,
            commentCount6M: 2519,
            likeCount: 0,
            likeCount1M: 9051,
            likeCount3M: 25734,
            likeCount6M: 62405,
            shareCount: 0,
            shareCount1M: 0,
            shareCount3M: 0,
            shareCount6M: 0,
            postCount: 0,
            postCount1M: 9,
            postCount3M: 25,
            postCount6M: 53,
            videoPostCount: 0,
            videoPostCount1M: 9,
            videoPostCount3M: 25,
            videoPostCount6M: 53,
            videoViewCount: 0,
            videoViewCount1M: 812644,
            videoViewCount3M: 2405129,
            videoViewCount6M: 6012218,
            livePostCount: 0,
            livePostCount1M: 0,
            livePostCount3M: 0,
            livePostCount6M: 0,
            liveViewCount: 0,
            liveViewCount1M: 0,
            liveViewCount3M: 0,
            liveViewCount6M: 0,
            followCountGrowth1M: 1000,
            followCountGrowth3M: 4600,
            followCountGrowth6M: 11000,
            followCountGrowthRate1M: 0.0019646366,
            followCountGrowthRate3M: 0.009101702,
            followCountGrowthRate6M: 0.022044089,
            link: null,
            shortPostCount: 0,
            shortPostCount1M: 0,
            shortPostCount3M: 0,
            shortPostCount6M: 0,
            shortViewCount: 0,
            shortViewCount1M: 812644,
            shortViewCount3M: 2405129,
            shortViewCount6M: 6012218,
            score: 91,
            score1M: 91,
            score3M: 91,
            score6M: 91,
            scoreGrowth: 0,
            scoreGrowth1M: 0,
            scoreGrowth3M: 0,
            scoreGrowth6M: 0,
            shortPlayCount1M: 0,
            shortPlayCount3M: 0,
            shortPlayCount6M: 0,
          },
          audience: null,
        },
        {
          id: 37989,
          kid: 16944,
          url: 'https://www.instagram.com',
          type: 'IG',
          platformType: 3,
          platformID: '',
          crawledID: '1240261626',
          tags: null,
          permanentURL: 'https://www.instagram.com',
          source: null,
          crawlerTasks: null,
          latestCrawlerTask: null,
          image: null,
          createdAt: '2019-04-30T08:25:02Z',
          updatedAt: '2023-03-08T03:44:12Z',
          deletedAt: null,
          datahubKolUrlIdentityId: '44602',
          weight: 1,
          statistic: {
            id: 51031,
            createdAt: '2020-07-06T19:12:18Z',
            updatedAt: '2023-06-08T05:33:49Z',
            linkID: 37989,
            followerCount: 615273,
            followerCount1M: 614720,
            followerCount3M: 611344,
            followerCount6M: 609076,
            commentCount: 0,
            commentCount1M: 495,
            commentCount3M: 1336,
            commentCount6M: 2937,
            likeCount: 0,
            likeCount1M: 284146,
            likeCount3M: 987415,
            likeCount6M: 2110332,
            shareCount: 0,
            shareCount1M: 0,
            shareCount3M: 0,
            shareCount6M: 0,
            postCount: 0,
            postCount1M: 23,
            postCount3M: 76,
            postCount6M: 154,
            videoPostCount: 0,
            videoPostCount1M: 2,
            videoPostCount3M: 7,
            videoPostCount6M: 13,
            videoViewCount: 0,
            videoViewCount1M: 914919,
            videoViewCount3M: 2791547,
            videoViewCount6M: 6070693,
            livePostCount: 0,
            livePostCount1M: 1,
            livePostCount3M: 1,
            livePostCount6M: 4,
            liveViewCount: 0,
            liveViewCount1M: 85071,
            liveViewCount3M: 85071,
            liveViewCount6M: 357513,
            followCountGrowth1M: 553,
            followCountGrowth3M: 3929,
            followCountGrowth6M: 6197,
            followCountGrowthRate1M: 0.00089959655,
            followCountGrowthRate3M: 0.0064268233,
            followCountGrowthRate6M: 0.010174428,
            link: null,
            shortPostCount: 0,
            shortPostCount1M: 2,
            shortPostCount3M: 7,
            shortPostCount6M: 13,
            shortViewCount: 0,
            shortViewCount1M: 421050,
            shortViewCount3M: 797821,
            shortViewCount6M: 1536174,
            score: 95,
            score1M: 95,
            score3M: 95,
            score6M: 95,
            scoreGrowth: 0,
            scoreGrowth1M: 0,
            scoreGrowth3M: 0,
            scoreGrowth6M: 0,
            shortPlayCount1M: 914919,
            shortPlayCount3M: 2791547,
            shortPlayCount6M: 6070693,
          },
          audience: null,
        },
        {
          id: 525322,
          kid: 16944,
          url: 'https://www.tiktok.com/@aoi_staff',
          type: 'TikTok',
          platformType: 4,
          platformID: '',
          crawledID: null,
          tags: null,
          permanentURL: 'https://www.tiktok.com/@aoi_staff',
          source: null,
          crawlerTasks: null,
          latestCrawlerTask: null,
          image: null,
          createdAt: '2022-11-25T09:16:53Z',
          updatedAt: '2022-11-25T09:16:53Z',
          deletedAt: null,
          datahubKolUrlIdentityId: null,
          weight: 0,
          statistic: {
            id: 0,
            createdAt: '0001-01-01T00:00:00Z',
            updatedAt: '0001-01-01T00:00:00Z',
            linkID: 0,
            followerCount: 0,
            followerCount1M: null,
            followerCount3M: null,
            followerCount6M: null,
            commentCount: null,
            commentCount1M: null,
            commentCount3M: null,
            commentCount6M: null,
            likeCount: null,
            likeCount1M: null,
            likeCount3M: null,
            likeCount6M: null,
            shareCount: null,
            shareCount1M: null,
            shareCount3M: null,
            shareCount6M: null,
            postCount: null,
            postCount1M: null,
            postCount3M: null,
            postCount6M: null,
            videoPostCount: null,
            videoPostCount1M: null,
            videoPostCount3M: null,
            videoPostCount6M: null,
            videoViewCount: null,
            videoViewCount1M: null,
            videoViewCount3M: null,
            videoViewCount6M: null,
            livePostCount: null,
            livePostCount1M: null,
            livePostCount3M: null,
            livePostCount6M: null,
            liveViewCount: null,
            liveViewCount1M: null,
            liveViewCount3M: null,
            liveViewCount6M: null,
            followCountGrowth1M: null,
            followCountGrowth3M: null,
            followCountGrowth6M: null,
            followCountGrowthRate1M: null,
            followCountGrowthRate3M: null,
            followCountGrowthRate6M: null,
            link: null,
            shortPostCount: null,
            shortPostCount1M: null,
            shortPostCount3M: null,
            shortPostCount6M: null,
            shortViewCount: null,
            shortViewCount1M: null,
            shortViewCount3M: null,
            shortViewCount6M: null,
            score: null,
            score1M: null,
            score3M: null,
            score6M: null,
            scoreGrowth: null,
            scoreGrowth1M: null,
            scoreGrowth3M: null,
            scoreGrowth6M: null,
            shortPlayCount1M: null,
            shortPlayCount3M: null,
            shortPlayCount6M: null,
          },
          audience: null,
        },
      ],
      lastScannedAt: null,
      handfilleds: null,
      crawlerStatuses: [],
      crawlerTasks: null,
    },
    {
      id: 10577,
      createdAt: '2023-05-17T06:28:56Z',
      updatedAt: '2023-05-17T06:28:56Z',
      deletedAt: null,
      reportID: 'demo',
      report: null,
      UUID: 'KOL-UUID-3',
      kolName: '花兒 Flower🌸',
      categories: ['BeautyCare', 'Makeup', 'Music', 'Campus', 'Education'],
      customizedTagsNames: ['Food', 'Food/Beverage'],
      followerCount: 355669,
      platform: 0,
      platforms: [
        {
          id: 207839,
          kid: 102173,
          url: 'https://www.twitter.com',
          type: 'Twitter',
          platformType: 5,
          platformID: '',
          crawledID: '242938647',
          tags: null,
          permanentURL: 'https://twitter.com',
          source: null,
          crawlerTasks: null,
          latestCrawlerTask: null,
          image: null,
          createdAt: '2021-05-31T12:35:48Z',
          updatedAt: '2023-04-14T10:50:44Z',
          deletedAt: null,
          datahubKolUrlIdentityId: '2240562',
          weight: 1,
          statistic: {
            id: 10814098,
            createdAt: '2023-05-09T07:35:06Z',
            updatedAt: '2023-05-09T07:35:06Z',
            linkID: 207839,
            followerCount: 3082,
            followerCount1M: null,
            followerCount3M: null,
            followerCount6M: null,
            commentCount: 0,
            commentCount1M: 0,
            commentCount3M: 0,
            commentCount6M: 5,
            likeCount: 0,
            likeCount1M: 0,
            likeCount3M: 47,
            likeCount6M: 562,
            shareCount: 0,
            shareCount1M: 0,
            shareCount3M: 3,
            shareCount6M: 88,
            postCount: 0,
            postCount1M: 0,
            postCount3M: 3,
            postCount6M: 11,
            videoPostCount: 0,
            videoPostCount1M: 0,
            videoPostCount3M: 0,
            videoPostCount6M: 0,
            videoViewCount: 0,
            videoViewCount1M: 0,
            videoViewCount3M: 0,
            videoViewCount6M: 0,
            livePostCount: 0,
            livePostCount1M: 0,
            livePostCount3M: 0,
            livePostCount6M: 0,
            liveViewCount: 0,
            liveViewCount1M: 0,
            liveViewCount3M: 0,
            liveViewCount6M: 0,
            followCountGrowth1M: null,
            followCountGrowth3M: null,
            followCountGrowth6M: null,
            followCountGrowthRate1M: 0,
            followCountGrowthRate3M: 0,
            followCountGrowthRate6M: 0,
            link: null,
            shortPostCount: 0,
            shortPostCount1M: 0,
            shortPostCount3M: 0,
            shortPostCount6M: 0,
            shortViewCount: 0,
            shortViewCount1M: 0,
            shortViewCount3M: 0,
            shortViewCount6M: 0,
            score: 0,
            score1M: 0,
            score3M: 0,
            score6M: 0,
            scoreGrowth: null,
            scoreGrowth1M: null,
            scoreGrowth3M: null,
            scoreGrowth6M: null,
            shortPlayCount1M: 0,
            shortPlayCount3M: 0,
            shortPlayCount6M: 0,
          },
          audience: null,
        },
        {
          id: 207840,
          kid: 102173,
          url: 'https://www.youtube.com',
          type: 'YouTube',
          platformType: 0,
          platformID: '',
          crawledID: 'UCgi1YyJdI4avyNy1ZP_OJNQ',
          tags: null,
          permanentURL: 'https://www.youtube.com',
          source: null,
          crawlerTasks: null,
          latestCrawlerTask: null,
          image: null,
          createdAt: '2021-05-31T12:35:48Z',
          updatedAt: '2023-03-08T03:58:20Z',
          deletedAt: null,
          datahubKolUrlIdentityId: '201240',
          weight: 1,
          statistic: {
            id: 9874430,
            createdAt: '2021-06-03T20:27:33Z',
            updatedAt: '2023-06-07T04:37:27Z',
            linkID: 207840,
            followerCount: 11400,
            followerCount1M: 11400,
            followerCount3M: 11500,
            followerCount6M: 11600,
            commentCount: 0,
            commentCount1M: 0,
            commentCount3M: 0,
            commentCount6M: 0,
            likeCount: 0,
            likeCount1M: 0,
            likeCount3M: 0,
            likeCount6M: 0,
            shareCount: 0,
            shareCount1M: 0,
            shareCount3M: 0,
            shareCount6M: 0,
            postCount: 0,
            postCount1M: 0,
            postCount3M: 0,
            postCount6M: 0,
            videoPostCount: 0,
            videoPostCount1M: 0,
            videoPostCount3M: 0,
            videoPostCount6M: 0,
            videoViewCount: 0,
            videoViewCount1M: 0,
            videoViewCount3M: 0,
            videoViewCount6M: 0,
            livePostCount: 0,
            livePostCount1M: 0,
            livePostCount3M: 0,
            livePostCount6M: 0,
            liveViewCount: 0,
            liveViewCount1M: 0,
            liveViewCount3M: 0,
            liveViewCount6M: 0,
            followCountGrowth1M: -100,
            followCountGrowth3M: -100,
            followCountGrowth6M: -200,
            followCountGrowthRate1M: 0,
            followCountGrowthRate3M: -0.008695652,
            followCountGrowthRate6M: -0.01724138,
            link: null,
            shortPostCount: 0,
            shortPostCount1M: 0,
            shortPostCount3M: 0,
            shortPostCount6M: 0,
            shortViewCount: 0,
            shortViewCount1M: 0,
            shortViewCount3M: 0,
            shortViewCount6M: 0,
            score: 67,
            score1M: 67,
            score3M: 67,
            score6M: 67,
            scoreGrowth: 0,
            scoreGrowth1M: 0,
            scoreGrowth3M: 0,
            scoreGrowth6M: 0,
            shortPlayCount1M: 0,
            shortPlayCount3M: 0,
            shortPlayCount6M: 0,
          },
          audience: null,
        },
        {
          id: 207841,
          kid: 102173,
          url: 'https://www.instagram.com',
          type: 'IG',
          platformType: 3,
          platformID: '',
          crawledID: '5955606049',
          tags: null,
          permanentURL: 'https://www.instagram.com',
          source: null,
          crawlerTasks: null,
          latestCrawlerTask: null,
          image: null,
          createdAt: '2021-05-31T12:35:48Z',
          updatedAt: '2023-03-08T03:44:14Z',
          deletedAt: null,
          datahubKolUrlIdentityId: '228625',
          weight: 1,
          statistic: {
            id: 9879228,
            createdAt: '2021-06-23T05:56:43Z',
            updatedAt: '2023-06-08T05:36:13Z',
            linkID: 207841,
            followerCount: 256587,
            followerCount1M: 255515,
            followerCount3M: 249628,
            followerCount6M: 247250,
            commentCount: 0,
            commentCount1M: 1565,
            commentCount3M: 5923,
            commentCount6M: 10761,
            likeCount: 0,
            likeCount1M: 92172,
            likeCount3M: 389226,
            likeCount6M: 794760,
            shareCount: 0,
            shareCount1M: 0,
            shareCount3M: 0,
            shareCount6M: 0,
            postCount: 0,
            postCount1M: 5,
            postCount3M: 27,
            postCount6M: 51,
            videoPostCount: 0,
            videoPostCount1M: 3,
            videoPostCount3M: 11,
            videoPostCount6M: 28,
            videoViewCount: 0,
            videoViewCount1M: 1678707,
            videoViewCount3M: 7320401,
            videoViewCount6M: 18405559,
            livePostCount: 0,
            livePostCount1M: 0,
            livePostCount3M: 0,
            livePostCount6M: 0,
            liveViewCount: 0,
            liveViewCount1M: 0,
            liveViewCount3M: 0,
            liveViewCount6M: 0,
            followCountGrowth1M: 1072,
            followCountGrowth3M: 6959,
            followCountGrowth6M: 9337,
            followCountGrowthRate1M: 0.0041954485,
            followCountGrowthRate3M: 0.027877482,
            followCountGrowthRate6M: 0.0377634,
            link: null,
            shortPostCount: 0,
            shortPostCount1M: 3,
            shortPostCount3M: 11,
            shortPostCount6M: 28,
            shortViewCount: 0,
            shortViewCount1M: 1076035,
            shortViewCount3M: 4746141,
            shortViewCount6M: 11672969,
            score: 85,
            score1M: 85,
            score3M: 85,
            score6M: 85,
            scoreGrowth: 0,
            scoreGrowth1M: 0,
            scoreGrowth3M: 0,
            scoreGrowth6M: 0,
            shortPlayCount1M: 1678707,
            shortPlayCount3M: 7320401,
            shortPlayCount6M: 18405559,
          },
          audience: null,
        },
        {
          id: 207842,
          kid: 102173,
          url: 'https://www.tiktok.com/@junpi92',
          type: 'TikTok',
          platformType: 4,
          platformID: '',
          crawledID: '6789034275678766082',
          tags: null,
          permanentURL: 'https://www.tiktok.com/@6789034275678766082',
          source: null,
          crawlerTasks: null,
          latestCrawlerTask: null,
          image: null,
          createdAt: '2021-05-31T12:35:48Z',
          updatedAt: '2023-03-08T03:51:24Z',
          deletedAt: null,
          datahubKolUrlIdentityId: '175343',
          weight: 0,
          statistic: {
            id: 9896821,
            createdAt: '2021-07-14T03:04:15Z',
            updatedAt: '2021-07-13T10:17:28Z',
            linkID: 207842,
            followerCount: 84600,
            followerCount1M: 84600,
            followerCount3M: null,
            followerCount6M: null,
            commentCount: 0,
            commentCount1M: null,
            commentCount3M: 0,
            commentCount6M: 0,
            likeCount: 0,
            likeCount1M: null,
            likeCount3M: 0,
            likeCount6M: 0,
            shareCount: 0,
            shareCount1M: null,
            shareCount3M: 0,
            shareCount6M: 0,
            postCount: 0,
            postCount1M: null,
            postCount3M: 0,
            postCount6M: 0,
            videoPostCount: 0,
            videoPostCount1M: null,
            videoPostCount3M: 0,
            videoPostCount6M: 0,
            videoViewCount: 0,
            videoViewCount1M: null,
            videoViewCount3M: 0,
            videoViewCount6M: 0,
            livePostCount: null,
            livePostCount1M: null,
            livePostCount3M: null,
            livePostCount6M: null,
            liveViewCount: null,
            liveViewCount1M: null,
            liveViewCount3M: null,
            liveViewCount6M: null,
            followCountGrowth1M: null,
            followCountGrowth3M: null,
            followCountGrowth6M: null,
            followCountGrowthRate1M: null,
            followCountGrowthRate3M: null,
            followCountGrowthRate6M: null,
            link: null,
            shortPostCount: null,
            shortPostCount1M: null,
            shortPostCount3M: null,
            shortPostCount6M: null,
            shortViewCount: null,
            shortViewCount1M: null,
            shortViewCount3M: null,
            shortViewCount6M: null,
            score: null,
            score1M: null,
            score3M: null,
            score6M: null,
            scoreGrowth: null,
            scoreGrowth1M: null,
            scoreGrowth3M: null,
            scoreGrowth6M: null,
            shortPlayCount1M: null,
            shortPlayCount3M: null,
            shortPlayCount6M: null,
          },
          audience: null,
        },
      ],
      lastScannedAt: null,
      handfilleds: null,
      crawlerStatuses: [],
      crawlerTasks: null,
    },
    {
      id: 10579,
      createdAt: '2023-05-17T06:28:56Z',
      updatedAt: '2023-05-17T06:28:56Z',
      deletedAt: null,
      reportID: 'demo',
      report: null,
      UUID: 'KOL-UUID-4',
      kolName: '小V🐳Channel',
      categories: [
        'BeautyCare',
        'Makeup',
        'FortuneTelling',
        'Campus',
        'Outfit',
      ],
      customizedTagsNames: ['Food', 'Food/Beverage'],
      followerCount: 248083,
      platform: 0,
      platforms: [
        {
          id: 194450,
          kid: 94565,
          url: 'https://www.youtube.com',
          type: 'YouTube',
          platformType: 0,
          platformID: '',
          crawledID: 'UC3UkKUU_Y7Nr3Hhhho_fQxw',
          tags: null,
          permanentURL: 'https://www.youtube.com',
          source: null,
          crawlerTasks: null,
          latestCrawlerTask: null,
          image: null,
          createdAt: '2021-05-03T11:36:01Z',
          updatedAt: '2023-03-08T03:51:26Z',
          deletedAt: null,
          datahubKolUrlIdentityId: '34286',
          weight: 1,
          statistic: {
            id: 9870217,
            createdAt: '2021-05-07T06:36:46Z',
            updatedAt: '2023-05-24T04:21:40Z',
            linkID: 194450,
            followerCount: 184000,
            followerCount1M: 183000,
            followerCount3M: 182000,
            followerCount6M: 175000,
            commentCount: 0,
            commentCount1M: 82,
            commentCount3M: 660,
            commentCount6M: 1149,
            likeCount: 0,
            likeCount1M: 830,
            likeCount3M: 12619,
            likeCount6M: 20011,
            shareCount: 0,
            shareCount1M: 0,
            shareCount3M: 0,
            shareCount6M: 0,
            postCount: 0,
            postCount1M: 1,
            postCount3M: 8,
            postCount6M: 15,
            videoPostCount: 0,
            videoPostCount1M: 1,
            videoPostCount3M: 8,
            videoPostCount6M: 15,
            videoViewCount: 0,
            videoViewCount1M: 26793,
            videoViewCount3M: 377109,
            videoViewCount6M: 831548,
            livePostCount: 0,
            livePostCount1M: 0,
            livePostCount3M: 0,
            livePostCount6M: 0,
            liveViewCount: 0,
            liveViewCount1M: 0,
            liveViewCount3M: 0,
            liveViewCount6M: 0,
            followCountGrowth1M: 1000,
            followCountGrowth3M: 2000,
            followCountGrowth6M: 9000,
            followCountGrowthRate1M: 0.0054644807,
            followCountGrowthRate3M: 0.010989011,
            followCountGrowthRate6M: 0.05142857,
            link: null,
            shortPostCount: 0,
            shortPostCount1M: 0,
            shortPostCount3M: 0,
            shortPostCount6M: 0,
            shortViewCount: 0,
            shortViewCount1M: 26793,
            shortViewCount3M: 377109,
            shortViewCount6M: 831548,
            score: 90,
            score1M: 90,
            score3M: 90,
            score6M: 90,
            scoreGrowth: 0,
            scoreGrowth1M: 0,
            scoreGrowth3M: 0,
            scoreGrowth6M: 0,
            shortPlayCount1M: 0,
            shortPlayCount3M: 0,
            shortPlayCount6M: 0,
          },
          audience: null,
        },
        {
          id: 194451,
          kid: 94565,
          url: 'https://www.instagram.com',
          type: 'IG',
          platformType: 3,
          platformID: '',
          crawledID: '1707555709',
          tags: null,
          permanentURL: 'https://www.instagram.com',
          source: null,
          crawlerTasks: null,
          latestCrawlerTask: null,
          image: null,
          createdAt: '2021-05-03T11:36:01Z',
          updatedAt: '2023-03-08T03:57:52Z',
          deletedAt: null,
          datahubKolUrlIdentityId: '43362',
          weight: 1,
          statistic: {
            id: 9871015,
            createdAt: '2021-05-26T04:10:20Z',
            updatedAt: '2023-06-08T05:29:02Z',
            linkID: 194451,
            followerCount: 64083,
            followerCount1M: 64123,
            followerCount3M: 64042,
            followerCount6M: 63061,
            commentCount: 0,
            commentCount1M: 99,
            commentCount3M: 196,
            commentCount6M: 477,
            likeCount: 0,
            likeCount1M: 50900,
            likeCount3M: 103189,
            likeCount6M: 283197,
            shareCount: 0,
            shareCount1M: 0,
            shareCount3M: 0,
            shareCount6M: 0,
            postCount: 0,
            postCount1M: 10,
            postCount3M: 19,
            postCount6M: 48,
            videoPostCount: 0,
            videoPostCount1M: 1,
            videoPostCount3M: 1,
            videoPostCount6M: 1,
            videoViewCount: 0,
            videoViewCount1M: 59483,
            videoViewCount3M: 59483,
            videoViewCount6M: 59483,
            livePostCount: 0,
            livePostCount1M: 0,
            livePostCount3M: 0,
            livePostCount6M: 0,
            liveViewCount: 0,
            liveViewCount1M: 0,
            liveViewCount3M: 0,
            liveViewCount6M: 0,
            followCountGrowth1M: -40,
            followCountGrowth3M: 41,
            followCountGrowth6M: 1022,
            followCountGrowthRate1M: -0.00062380114,
            followCountGrowthRate3M: 0.0006402049,
            followCountGrowthRate6M: 0.01620653,
            link: null,
            shortPostCount: 0,
            shortPostCount1M: 1,
            shortPostCount3M: 1,
            shortPostCount6M: 1,
            shortViewCount: 0,
            shortViewCount1M: 20119,
            shortViewCount3M: 20119,
            shortViewCount6M: 20119,
            score: 81,
            score1M: 81,
            score3M: 81,
            score6M: 81,
            scoreGrowth: 0,
            scoreGrowth1M: 0,
            scoreGrowth3M: 0,
            scoreGrowth6M: 0,
            shortPlayCount1M: 59483,
            shortPlayCount3M: 59483,
            shortPlayCount6M: 59483,
          },
          audience: null,
        },
      ],
      lastScannedAt: null,
      handfilleds: null,
      crawlerStatuses: [],
      crawlerTasks: null,
    },
    {
      id: 10581,
      createdAt: '2023-05-17T06:28:56Z',
      updatedAt: '2023-05-17T06:28:56Z',
      deletedAt: null,
      reportID: 'demo',
      report: null,
      UUID: 'KOL-UUID-5',
      kolName: '妞妞每天睡過頭',
      categories: ['Travel'],
      customizedTagsNames: ['Food', 'Food/Beverage'],
      followerCount: 861618,
      platform: 0,
      platforms: [
        {
          id: 203035,
          kid: 99470,
          url: 'https://twitter.com',
          type: 'Twitter',
          platformType: 5,
          platformID: '',
          crawledID: '1223850362748059648',
          tags: null,
          permanentURL: 'https://twitter.com',
          source: null,
          crawlerTasks: null,
          latestCrawlerTask: null,
          image: null,
          createdAt: '2021-05-31T12:27:48Z',
          updatedAt: '2023-04-14T10:50:44Z',
          deletedAt: null,
          datahubKolUrlIdentityId: '2239631',
          weight: 1,
          statistic: {
            id: 10814052,
            createdAt: '2023-05-08T09:28:09Z',
            updatedAt: '2023-05-31T10:15:08Z',
            linkID: 203035,
            followerCount: 6011,
            followerCount1M: 6044,
            followerCount3M: null,
            followerCount6M: null,
            commentCount: 0,
            commentCount1M: 0,
            commentCount3M: 7,
            commentCount6M: 29,
            likeCount: 0,
            likeCount1M: 0,
            likeCount3M: 186,
            likeCount6M: 1014,
            shareCount: 0,
            shareCount1M: 0,
            shareCount3M: 12,
            shareCount6M: 35,
            postCount: 0,
            postCount1M: 0,
            postCount3M: 6,
            postCount6M: 32,
            videoPostCount: 0,
            videoPostCount1M: 0,
            videoPostCount3M: 0,
            videoPostCount6M: 0,
            videoViewCount: 0,
            videoViewCount1M: 0,
            videoViewCount3M: 0,
            videoViewCount6M: 0,
            livePostCount: 0,
            livePostCount1M: 0,
            livePostCount3M: 0,
            livePostCount6M: 0,
            liveViewCount: 0,
            liveViewCount1M: 0,
            liveViewCount3M: 0,
            liveViewCount6M: 0,
            followCountGrowth1M: -33,
            followCountGrowth3M: null,
            followCountGrowth6M: null,
            followCountGrowthRate1M: 0,
            followCountGrowthRate3M: 0,
            followCountGrowthRate6M: 0,
            link: null,
            shortPostCount: 0,
            shortPostCount1M: 0,
            shortPostCount3M: 0,
            shortPostCount6M: 0,
            shortViewCount: 0,
            shortViewCount1M: 0,
            shortViewCount3M: 0,
            shortViewCount6M: 0,
            score: 0,
            score1M: 0,
            score3M: 0,
            score6M: 0,
            scoreGrowth: 0,
            scoreGrowth1M: 0,
            scoreGrowth3M: 0,
            scoreGrowth6M: 0,
            shortPlayCount1M: 0,
            shortPlayCount3M: 0,
            shortPlayCount6M: 0,
          },
          audience: null,
        },
        {
          id: 203036,
          kid: 99470,
          url: 'https://www.youtube.com',
          type: 'YouTube',
          platformType: 0,
          platformID: '',
          crawledID: 'UCv2K9FVAoQKT2I2IfsfiPOA',
          tags: null,
          permanentURL: 'https://www.youtube.com',
          source: null,
          crawlerTasks: null,
          latestCrawlerTask: null,
          image: null,
          createdAt: '2021-05-31T12:27:48Z',
          updatedAt: '2023-03-08T03:57:52Z',
          deletedAt: null,
          datahubKolUrlIdentityId: '103871',
          weight: 1,
          statistic: {
            id: 9951052,
            createdAt: '2022-05-23T07:29:39Z',
            updatedAt: '2023-06-07T04:57:59Z',
            linkID: 203036,
            followerCount: 35600,
            followerCount1M: 32800,
            followerCount3M: 29760,
            followerCount6M: 22900,
            commentCount: 0,
            commentCount1M: 0,
            commentCount3M: 0,
            commentCount6M: 36,
            likeCount: 0,
            likeCount1M: 0,
            likeCount3M: 0,
            likeCount6M: 2883,
            shareCount: 0,
            shareCount1M: 0,
            shareCount3M: 0,
            shareCount6M: 0,
            postCount: 0,
            postCount1M: 0,
            postCount3M: 0,
            postCount6M: 1,
            videoPostCount: 0,
            videoPostCount1M: 0,
            videoPostCount3M: 0,
            videoPostCount6M: 1,
            videoViewCount: 0,
            videoViewCount1M: 0,
            videoViewCount3M: 0,
            videoViewCount6M: 120824,
            livePostCount: 0,
            livePostCount1M: 0,
            livePostCount3M: 0,
            livePostCount6M: 0,
            liveViewCount: 0,
            liveViewCount1M: 0,
            liveViewCount3M: 0,
            liveViewCount6M: 0,
            followCountGrowth1M: 2800,
            followCountGrowth3M: 5840,
            followCountGrowth6M: 12700,
            followCountGrowthRate1M: 0.085365854,
            followCountGrowthRate3M: 0.19623657,
            followCountGrowthRate6M: 0.55458516,
            link: null,
            shortPostCount: 0,
            shortPostCount1M: 0,
            shortPostCount3M: 0,
            shortPostCount6M: 0,
            shortViewCount: 0,
            shortViewCount1M: 0,
            shortViewCount3M: 0,
            shortViewCount6M: 120824,
            score: 82,
            score1M: 82,
            score3M: 82,
            score6M: 82,
            scoreGrowth: 0,
            scoreGrowth1M: 0,
            scoreGrowth3M: 0,
            scoreGrowth6M: 0,
            shortPlayCount1M: 0,
            shortPlayCount3M: 0,
            shortPlayCount6M: 0,
          },
          audience: null,
        },
        {
          id: 203037,
          kid: 99470,
          url: 'https://www.instagram.com',
          type: 'IG',
          platformType: 3,
          platformID: '',
          crawledID: '21263849910',
          tags: null,
          permanentURL: 'https://www.instagram.com',
          source: null,
          crawlerTasks: null,
          latestCrawlerTask: null,
          image: null,
          createdAt: '2021-05-31T12:27:48Z',
          updatedAt: '2023-03-08T03:51:36Z',
          deletedAt: null,
          datahubKolUrlIdentityId: '95013',
          weight: 1,
          statistic: {
            id: 9876585,
            createdAt: '2021-06-23T05:55:45Z',
            updatedAt: '2023-06-08T05:37:55Z',
            linkID: 203037,
            followerCount: 90207,
            followerCount1M: 85435,
            followerCount3M: 72631,
            followerCount6M: 66314,
            commentCount: 0,
            commentCount1M: 1169,
            commentCount3M: 2884,
            commentCount6M: 6063,
            likeCount: 0,
            likeCount1M: 100166,
            likeCount3M: 262936,
            likeCount6M: 644770,
            shareCount: 0,
            shareCount1M: 0,
            shareCount3M: 0,
            shareCount6M: 0,
            postCount: 0,
            postCount1M: 29,
            postCount3M: 64,
            postCount6M: 138,
            videoPostCount: 0,
            videoPostCount1M: 20,
            videoPostCount3M: 37,
            videoPostCount6M: 63,
            videoViewCount: 0,
            videoViewCount1M: 11476139,
            videoViewCount3M: 37040468,
            videoViewCount6M: 81378005,
            livePostCount: 0,
            livePostCount1M: 0,
            livePostCount3M: 0,
            livePostCount6M: 3,
            liveViewCount: 0,
            liveViewCount1M: 0,
            liveViewCount3M: 0,
            liveViewCount6M: 42590,
            followCountGrowth1M: 4772,
            followCountGrowth3M: 17576,
            followCountGrowth6M: 23893,
            followCountGrowthRate1M: 0.05585533,
            followCountGrowthRate3M: 0.24199033,
            followCountGrowthRate6M: 0.360301,
            link: null,
            shortPostCount: 0,
            shortPostCount1M: 20,
            shortPostCount3M: 37,
            shortPostCount6M: 63,
            shortViewCount: 0,
            shortViewCount1M: 721591,
            shortViewCount3M: 2190307,
            shortViewCount6M: 4522435,
            score: 91,
            score1M: 91,
            score3M: 91,
            score6M: 91,
            scoreGrowth: 0,
            scoreGrowth1M: 0,
            scoreGrowth3M: 0,
            scoreGrowth6M: 0,
            shortPlayCount1M: 11476139,
            shortPlayCount3M: 37040468,
            shortPlayCount6M: 81378005,
          },
          audience: null,
        },
        {
          id: 203038,
          kid: 99470,
          url: 'https://www.tiktok.com/@noponopisu2',
          type: 'TikTok',
          platformType: 4,
          platformID: '',
          crawledID: '6714986900383466497',
          tags: null,
          permanentURL: 'https://www.tiktok.com/@6714986900383466497',
          source: null,
          crawlerTasks: null,
          latestCrawlerTask: null,
          image: null,
          createdAt: '2021-05-31T12:27:48Z',
          updatedAt: '2023-03-08T03:51:24Z',
          deletedAt: null,
          datahubKolUrlIdentityId: '112854',
          weight: 0,
          statistic: {
            id: 9896479,
            createdAt: '2021-07-14T03:00:13Z',
            updatedAt: '2021-07-13T10:19:38Z',
            linkID: 203038,
            followerCount: 729800,
            followerCount1M: 729800,
            followerCount3M: null,
            followerCount6M: null,
            commentCount: 0,
            commentCount1M: null,
            commentCount3M: 0,
            commentCount6M: 0,
            likeCount: 0,
            likeCount1M: null,
            likeCount3M: 0,
            likeCount6M: 0,
            shareCount: 0,
            shareCount1M: null,
            shareCount3M: 0,
            shareCount6M: 0,
            postCount: 0,
            postCount1M: null,
            postCount3M: 0,
            postCount6M: 0,
            videoPostCount: 0,
            videoPostCount1M: null,
            videoPostCount3M: 0,
            videoPostCount6M: 0,
            videoViewCount: 0,
            videoViewCount1M: null,
            videoViewCount3M: 0,
            videoViewCount6M: 0,
            livePostCount: null,
            livePostCount1M: null,
            livePostCount3M: null,
            livePostCount6M: null,
            liveViewCount: null,
            liveViewCount1M: null,
            liveViewCount3M: null,
            liveViewCount6M: null,
            followCountGrowth1M: null,
            followCountGrowth3M: null,
            followCountGrowth6M: null,
            followCountGrowthRate1M: null,
            followCountGrowthRate3M: null,
            followCountGrowthRate6M: null,
            link: null,
            shortPostCount: null,
            shortPostCount1M: null,
            shortPostCount3M: null,
            shortPostCount6M: null,
            shortViewCount: null,
            shortViewCount1M: null,
            shortViewCount3M: null,
            shortViewCount6M: null,
            score: null,
            score1M: null,
            score3M: null,
            score6M: null,
            scoreGrowth: null,
            scoreGrowth1M: null,
            scoreGrowth3M: null,
            scoreGrowth6M: null,
            shortPlayCount1M: null,
            shortPlayCount3M: null,
            shortPlayCount6M: null,
          },
          audience: null,
        },
      ],
      lastScannedAt: null,
      handfilleds: null,
      crawlerStatuses: [],
      crawlerTasks: null,
    },
  ],
  kols: null,
  reportPostKols: null,
  keywords: ['coffee'],
  terms: [
    {
      id: 7397,
      createdAt: '2023-05-19T09:33:14Z',
      updatedAt: '2023-05-19T09:33:14Z',
      deletedAt: null,
      reportID: 'demo',
      term: '好喝',
      isInclude: true,
    },
    {
      id: 7398,
      createdAt: '2023-05-19T09:33:20Z',
      updatedAt: '2023-05-19T09:33:20Z',
      deletedAt: null,
      reportID: 'demo',
      term: '清楚',
      isInclude: true,
    },
    {
      id: 7399,
      createdAt: '2023-05-19T09:33:42Z',
      updatedAt: '2023-05-19T09:33:42Z',
      deletedAt: null,
      reportID: 'demo',
      term: '誠實',
      isInclude: true,
    },
    {
      id: 7400,
      createdAt: '2023-05-19T09:33:42Z',
      updatedAt: '2023-05-19T09:33:42Z',
      deletedAt: null,
      reportID: 'demo',
      term: '品質',
      isInclude: true,
    },
    {
      id: 7401,
      createdAt: '2023-05-19T09:33:42Z',
      updatedAt: '2023-05-19T09:33:42Z',
      deletedAt: null,
      reportID: 'demo',
      term: '美味',
      isInclude: true,
    },
  ],
  valueEstimationWeight: {
    '0': {
      viewValue: 0.8,
      commentValue: 1500,
      likeValue: 20,
      shareValue: 0,
      onlineValue: 26,
      platformName: '',
    },
    '1': {
      viewValue: 0.7,
      commentValue: 175.6,
      likeValue: 20.3,
      shareValue: 446.5,
      onlineValue: 26,
      platformName: '',
    },
    '3': {
      viewValue: 0.7,
      commentValue: 165.3,
      likeValue: 11.9,
      shareValue: 264.6,
      onlineValue: 18.2,
      platformName: '',
    },
    '4': {
      viewValue: 0,
      commentValue: 0,
      likeValue: 0,
      shareValue: 0,
      onlineValue: 0,
      platformName: '',
    },
    '5': {
      viewValue: 0,
      commentValue: 0,
      likeValue: 0,
      shareValue: 0,
      onlineValue: 0,
      platformName: '',
    },
  },
  userDefinedIndustryData: {
    id: 1578,
    createdAt: '2023-05-17T06:28:56Z',
    updatedAt: '2023-05-17T06:28:56Z',
    deletedAt: null,
    reportID: 'demo',
    userDefined: false,
    industryType: null,
    avgEngagementNum: null,
    avgViewNum: null,
    avgCPE: null,
    avgCPV: null,
    avgCPEV: null,
  },
  category: '',
  budget: 48500,
  cost: 0,
  kolAmount: 7,
  kolsInfo: [
    {
      id: 'KOL-UUID-1',
      name: '王小明吃美食 Xiao Min Foodie',
      followerCount: null,
      isVerified: null,
      isRadarStar: null,
    },
    {
      id: 'KOL-UUID-2',
      name: 'Joy 喬伊吃吃喝喝',
      followerCount: null,
      isVerified: null,
      isRadarStar: null,
    },
    {
      id: 'KOL-UUID-3',
      name: '花兒 Flower🌸',
      followerCount: null,
      isVerified: null,
      isRadarStar: null,
    },
    {
      id: 'KOL-UUID-4',
      name: '小V🐳Channel',
      followerCount: null,
      isVerified: null,
      isRadarStar: null,
    },
    {
      id: 'KOL-UUID-5',
      name: '妞妞每天睡過頭',
      followerCount: null,
      isVerified: null,
      isRadarStar: null,
    },
  ],
  platforms: [
    {
      id: '0',
      name: 'yt',
    },
    {
      id: '3',
      name: 'ig',
    },
    {
      id: '5',
      name: 'twitter',
    },
  ],
  startAt: '2023-03-31T16:00:00Z',
  endAt: '2023-05-16T15:59:59Z',
  refreshUntil: null,
  reportStatistics: [
    {
      id: 10575,
      createdAt: '2023-05-17T06:28:56Z',
      updatedAt: '2023-05-17T06:28:56Z',
      deletedAt: null,
      reportID: 'demo',
      report: null,
      UUID: 'KOL-UUID-1',
      kolName: '王小明吃美食 Xiao Min Foodie',
      categories: ['Outfit', 'Music', 'BeautyCare', 'FortuneTelling', 'Makeup'],
      customizedTagsNames: ['Food', 'Food/Beverage'],
      followerCount: 423962,
      platform: 3,
      platforms: null,
      lastScannedAt: null,
      handfilleds: [
        {
          id: 11037,
          createdAt: '2023-05-18T02:58:11Z',
          updatedAt: '2023-05-18T02:58:11Z',
          deletedAt: null,
          reportKolID: 10575,
          reportKOL: null,
          reportID: 'demo',
          report: null,
          isHidden: null,
          url: 'https://www.instagram.com',
          budget: 3000,
          reachCount: 0,
          clickCount: 0,
          blogLink: null,
          viewCount: 0,
          pcu: 0,
          impressionCount: 0,
          likeCount: 7971,
          commentCount: 12,
          shareCount: 0,
          useHandfilled: false,
          articleViewCount: null,
        },
        {
          id: 11043,
          createdAt: '2023-05-18T02:59:35Z',
          updatedAt: '2023-05-18T02:59:35Z',
          deletedAt: null,
          reportKolID: 10575,
          reportKOL: null,
          reportID: 'demo',
          report: null,
          isHidden: null,
          url: 'https://www.instagram.com',
          budget: 5000,
          reachCount: 0,
          clickCount: 0,
          blogLink: null,
          viewCount: 0,
          pcu: 0,
          impressionCount: 0,
          likeCount: 20084,
          commentCount: 24,
          shareCount: 0,
          useHandfilled: false,
          articleViewCount: null,
        },
      ],
      crawlerStatuses: null,
      crawlerTasks: null,
      kol: {
        id: 14654,
        UUID: 'KOL-UUID-1',
        type: 'Person',
        name: ['王小明吃美食 Xiao Min Foodie'],
        links: [
          {
            id: 31323,
            kid: 14654,
            url: 'https://www.instagram.com',
            type: 'IG',
            platformType: 3,
            platformID: '',
            crawledID: '2108944174',
            tags: null,
            permanentURL: 'https://www.instagram.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2019-04-03T02:49:57Z',
            updatedAt: '2023-03-08T03:44:16Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '162441',
            weight: 1,
            statistic: {
              id: 50356,
              createdAt: '2020-07-06T19:06:43Z',
              updatedAt: '2023-06-08T05:49:21Z',
              linkID: 31323,
              followerCount: 423962,
              followerCount1M: 426021,
              followerCount3M: 414492,
              followerCount6M: 399686,
              commentCount: 0,
              commentCount1M: 170,
              commentCount3M: 1010,
              commentCount6M: 2423,
              likeCount: 0,
              likeCount1M: 94104,
              likeCount3M: 492913,
              likeCount6M: 1156610,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: 9,
              postCount3M: 47,
              postCount6M: 101,
              videoPostCount: 0,
              videoPostCount1M: 0,
              videoPostCount3M: 0,
              videoPostCount6M: 1,
              videoViewCount: 0,
              videoViewCount1M: 0,
              videoViewCount3M: 0,
              videoViewCount6M: 171946,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 0,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 0,
              followCountGrowth1M: -2059,
              followCountGrowth3M: 9470,
              followCountGrowth6M: 24276,
              followCountGrowthRate1M: -0.0048330952,
              followCountGrowthRate3M: 0.022847245,
              followCountGrowthRate6M: 0.06073768,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 0,
              shortPostCount3M: 0,
              shortPostCount6M: 1,
              shortViewCount: 0,
              shortViewCount1M: 0,
              shortViewCount3M: 0,
              shortViewCount6M: 59976,
              score: 95,
              score1M: 95,
              score3M: 95,
              score6M: 95,
              scoreGrowth: 0,
              scoreGrowth1M: 0,
              scoreGrowth3M: 0,
              scoreGrowth6M: 0,
              shortPlayCount1M: 0,
              shortPlayCount3M: 0,
              shortPlayCount6M: 171946,
            },
            audience: null,
          },
          {
            id: 31324,
            kid: 14654,
            url: 'https://twitter.com',
            type: 'Twitter',
            platformType: 5,
            platformID: '',
            crawledID: '2698276440',
            tags: null,
            permanentURL: 'https://twitter.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2019-04-03T02:50:28Z',
            updatedAt: '2023-04-14T10:50:44Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '2232835',
            weight: 1,
            statistic: {
              id: 10814095,
              createdAt: '2023-05-09T07:33:04Z',
              updatedAt: '2023-05-09T07:33:04Z',
              linkID: 31324,
              followerCount: 148902,
              followerCount1M: null,
              followerCount3M: null,
              followerCount6M: null,
              commentCount: 0,
              commentCount1M: 1,
              commentCount3M: 15,
              commentCount6M: 58,
              likeCount: 0,
              likeCount1M: 140,
              likeCount3M: 5248,
              likeCount6M: 36834,
              shareCount: 0,
              shareCount1M: 4,
              shareCount3M: 96,
              shareCount6M: 1031,
              postCount: 0,
              postCount1M: 1,
              postCount3M: 5,
              postCount6M: 12,
              videoPostCount: 0,
              videoPostCount1M: 0,
              videoPostCount3M: 0,
              videoPostCount6M: 0,
              videoViewCount: 0,
              videoViewCount1M: 0,
              videoViewCount3M: 0,
              videoViewCount6M: 0,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 0,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 0,
              followCountGrowth1M: null,
              followCountGrowth3M: null,
              followCountGrowth6M: null,
              followCountGrowthRate1M: 0,
              followCountGrowthRate3M: 0,
              followCountGrowthRate6M: 0,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 0,
              shortPostCount3M: 0,
              shortPostCount6M: 0,
              shortViewCount: 0,
              shortViewCount1M: 0,
              shortViewCount3M: 0,
              shortViewCount6M: 0,
              score: 0,
              score1M: 0,
              score3M: 0,
              score6M: 0,
              scoreGrowth: null,
              scoreGrowth1M: null,
              scoreGrowth3M: null,
              scoreGrowth6M: null,
              shortPlayCount1M: 0,
              shortPlayCount3M: 0,
              shortPlayCount6M: 0,
            },
            audience: null,
          },
        ],
        gender: 'Male',
        property: ['Outfit', 'Music', 'BeautyCare', 'FortuneTelling', 'Makeup'],
        specialty: ['藝人', '模特兒'],
        service: '圖文',
        location: '日本',
        countryCode: 'jp',
        description: '',
        customTag: null,
        isVisible: true,
        crawledDescription: '',
        image: null,
        createdAt: '2019-04-03T02:48:51Z',
        updatedAt: '2023-06-08T06:14:09Z',
        deletedAt: null,
        datahubKolIdentityId: '126091',
        isVerified: false,
        agencyUser: null,
        kolUser: null,
        cowork: null,
      },
      reportPostID: 0,
      reportKolID: 10575,
      handfilledID: 0,
      postCrawlID: null,
      unavailableAt: null,
      playCount: 0,
      likeCount: 335,
      commentCount: 20,
      shareCount: 5,
      viewCount: 4569,
      engagementCount: 7983,
      reachCount: 4569,
      clickCount: 23,
      pcu: 0,
      youTubeImpressionCount: 0,
      storyReachCount: 0,
      storyClickCount: 0,
      articleViewCount: 0,
      budget: 2000,
      likeValue: 189709.8,
      commentValue: 3967.2002,
      shareValue: 0,
      viewValue: 0,
      pCUValue: 0,
      mediaValue: 193677,
      time: '2023-05-17T08:38:05Z',
      url: 'https://www.instagram.com',
      postType: 'image',
      publishTime: '2023-05-10T11:15:49Z',
      platformType: 3,
      matchedKeywords: null,
      text: '嗨大家！最近我發現了一個超棒的咖啡沖泡工具組，那就是雷達手沖咖啡組！🔍👌',
      isHidden: null,
      uniqueKey: '9b4c5996-b199-42df-8c5d-af257a2b81de',
      blogLink: '',
      postID: '',
      thumbnailURL: demoCooperationSrc1,
      useHandfilled: false,
      isShort: false,
    },
    {
      id: 10575,
      createdAt: '2023-05-17T06:28:56Z',
      updatedAt: '2023-05-17T06:28:56Z',
      deletedAt: null,
      reportID: 'demo',
      report: null,
      UUID: 'KOL-UUID-1',
      kolName: '王小明吃美食 Xiao Min Foodie',
      categories: ['Outfit', 'Music', 'BeautyCare', 'FortuneTelling', 'Makeup'],
      customizedTagsNames: ['Food', 'Food/Beverage'],
      followerCount: 148902,
      platform: 5,
      platforms: null,
      lastScannedAt: null,
      handfilleds: [
        {
          id: 11037,
          createdAt: '2023-05-18T02:58:11Z',
          updatedAt: '2023-05-18T02:58:11Z',
          deletedAt: null,
          reportKolID: 10575,
          reportKOL: null,
          reportID: 'demo',
          report: null,
          isHidden: null,
          url: 'https://www.instagram.com',
          budget: 3000,
          reachCount: 0,
          clickCount: 0,
          blogLink: null,
          viewCount: 0,
          pcu: 0,
          impressionCount: 0,
          likeCount: 7971,
          commentCount: 12,
          shareCount: 0,
          useHandfilled: false,
          articleViewCount: null,
        },
        {
          id: 11043,
          createdAt: '2023-05-18T02:59:35Z',
          updatedAt: '2023-05-18T02:59:35Z',
          deletedAt: null,
          reportKolID: 10575,
          reportKOL: null,
          reportID: 'demo',
          report: null,
          isHidden: null,
          url: 'https://www.instagram.com',
          budget: 5000,
          reachCount: 0,
          clickCount: 0,
          blogLink: null,
          viewCount: 0,
          pcu: 0,
          impressionCount: 0,
          likeCount: 20084,
          commentCount: 24,
          shareCount: 0,
          useHandfilled: false,
          articleViewCount: null,
        },
      ],
      crawlerStatuses: null,
      crawlerTasks: null,
      kol: {
        id: 14654,
        UUID: 'KOL-UUID-1',
        type: 'Person',
        name: ['王小明吃美食 Xiao Min Foodie'],
        links: [
          {
            id: 31323,
            kid: 14654,
            url: 'https://www.instagram.com',
            type: 'IG',
            platformType: 3,
            platformID: '',
            crawledID: '2108944174',
            tags: null,
            permanentURL: 'https://www.instagram.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2019-04-03T02:49:57Z',
            updatedAt: '2023-03-08T03:44:16Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '162441',
            weight: 1,
            statistic: {
              id: 50356,
              createdAt: '2020-07-06T19:06:43Z',
              updatedAt: '2023-06-08T05:49:21Z',
              linkID: 31323,
              followerCount: 423962,
              followerCount1M: 426021,
              followerCount3M: 414492,
              followerCount6M: 399686,
              commentCount: 0,
              commentCount1M: 170,
              commentCount3M: 1010,
              commentCount6M: 2423,
              likeCount: 0,
              likeCount1M: 94104,
              likeCount3M: 492913,
              likeCount6M: 1156610,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: 9,
              postCount3M: 47,
              postCount6M: 101,
              videoPostCount: 0,
              videoPostCount1M: 0,
              videoPostCount3M: 0,
              videoPostCount6M: 1,
              videoViewCount: 0,
              videoViewCount1M: 0,
              videoViewCount3M: 0,
              videoViewCount6M: 171946,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 0,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 0,
              followCountGrowth1M: -2059,
              followCountGrowth3M: 9470,
              followCountGrowth6M: 24276,
              followCountGrowthRate1M: -0.0048330952,
              followCountGrowthRate3M: 0.022847245,
              followCountGrowthRate6M: 0.06073768,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 0,
              shortPostCount3M: 0,
              shortPostCount6M: 1,
              shortViewCount: 0,
              shortViewCount1M: 0,
              shortViewCount3M: 0,
              shortViewCount6M: 59976,
              score: 95,
              score1M: 95,
              score3M: 95,
              score6M: 95,
              scoreGrowth: 0,
              scoreGrowth1M: 0,
              scoreGrowth3M: 0,
              scoreGrowth6M: 0,
              shortPlayCount1M: 0,
              shortPlayCount3M: 0,
              shortPlayCount6M: 171946,
            },
            audience: null,
          },
          {
            id: 31324,
            kid: 14654,
            url: 'https://twitter.com',
            type: 'Twitter',
            platformType: 5,
            platformID: '',
            crawledID: '2698276440',
            tags: null,
            permanentURL: 'https://twitter.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2019-04-03T02:50:28Z',
            updatedAt: '2023-04-14T10:50:44Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '2232835',
            weight: 1,
            statistic: {
              id: 10814095,
              createdAt: '2023-05-09T07:33:04Z',
              updatedAt: '2023-05-09T07:33:04Z',
              linkID: 31324,
              followerCount: 148902,
              followerCount1M: null,
              followerCount3M: null,
              followerCount6M: null,
              commentCount: 0,
              commentCount1M: 1,
              commentCount3M: 15,
              commentCount6M: 58,
              likeCount: 0,
              likeCount1M: 140,
              likeCount3M: 5248,
              likeCount6M: 36834,
              shareCount: 0,
              shareCount1M: 4,
              shareCount3M: 96,
              shareCount6M: 1031,
              postCount: 0,
              postCount1M: 1,
              postCount3M: 5,
              postCount6M: 12,
              videoPostCount: 0,
              videoPostCount1M: 0,
              videoPostCount3M: 0,
              videoPostCount6M: 0,
              videoViewCount: 0,
              videoViewCount1M: 0,
              videoViewCount3M: 0,
              videoViewCount6M: 0,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 0,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 0,
              followCountGrowth1M: null,
              followCountGrowth3M: null,
              followCountGrowth6M: null,
              followCountGrowthRate1M: 0,
              followCountGrowthRate3M: 0,
              followCountGrowthRate6M: 0,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 0,
              shortPostCount3M: 0,
              shortPostCount6M: 0,
              shortViewCount: 0,
              shortViewCount1M: 0,
              shortViewCount3M: 0,
              shortViewCount6M: 0,
              score: 0,
              score1M: 0,
              score3M: 0,
              score6M: 0,
              scoreGrowth: null,
              scoreGrowth1M: null,
              scoreGrowth3M: null,
              scoreGrowth6M: null,
              shortPlayCount1M: 0,
              shortPlayCount3M: 0,
              shortPlayCount6M: 0,
            },
            audience: null,
          },
        ],
        gender: 'Male',
        property: ['Outfit', 'Music', 'BeautyCare', 'FortuneTelling', 'Makeup'],
        specialty: ['藝人', '模特兒'],
        service: '圖文',
        location: '日本',
        countryCode: 'jp',
        description: '',
        customTag: null,
        isVisible: true,
        crawledDescription: '姉 @mi0306chi',
        image: null,
        createdAt: '2019-04-03T02:48:51Z',
        updatedAt: '2023-06-08T06:14:09Z',
        deletedAt: null,
        datahubKolIdentityId: '126091',
        isVerified: false,
        agencyUser: null,
        kolUser: null,
        cowork: null,
      },
      reportPostID: 0,
      reportKolID: 10575,
      handfilledID: 0,
      postCrawlID: null,
      unavailableAt: null,
      playCount: 0,
      likeCount: 224,
      commentCount: 33,
      shareCount: 11,
      viewCount: 0,
      engagementCount: 145,
      reachCount: 2030,
      clickCount: 45,
      pcu: 0,
      youTubeImpressionCount: 0,
      storyReachCount: 0,
      storyClickCount: 0,
      articleViewCount: 0,
      budget: 2000,
      likeValue: 224,
      commentValue: 33,
      shareValue: 0,
      viewValue: 0,
      pCUValue: 0,
      mediaValue: 0,
      time: '2023-05-09T07:33:03Z',
      url: 'https://twitter.com',
      postType: 'image',
      publishTime: '2023-05-01T10:03:53Z',
      platformType: 5,
      matchedKeywords: null,
      text: '【自家沖咖啡的好幫手！雷達手沖咖啡組】☕️ 大家好！我最近開始愛上自家沖咖啡，而雷達手沖咖啡組成了我的最佳夥伴！🌟',
      isHidden: null,
      uniqueKey: 'f014cddc-8949-41b7-939d-3450a621e80c',
      blogLink: '',
      postID: '',
      thumbnailURL: demoCooperationSrc2,
      useHandfilled: false,
      isShort: false,
    },
    {
      id: 10576,
      createdAt: '2023-05-17T06:28:56Z',
      updatedAt: '2023-05-17T06:28:56Z',
      deletedAt: null,
      reportID: 'demo',
      report: null,
      UUID: 'KOL-UUID-2',
      kolName: 'Joy 喬伊吃吃喝喝',
      categories: ['Outfit', 'Makeup', 'BeautyCare', 'Food', 'Travel'],
      customizedTagsNames: ['Food', 'Food/Beverage'],
      followerCount: 161523,
      platform: 3,
      platforms: null,
      lastScannedAt: null,
      handfilleds: [
        {
          id: 11038,
          createdAt: '2023-05-18T02:58:39Z',
          updatedAt: '2023-05-18T02:58:48Z',
          deletedAt: null,
          reportKolID: 10576,
          reportKOL: null,
          reportID: 'demo',
          report: null,
          isHidden: null,
          url: 'https://www.youtube.com',
          budget: 50000,
          reachCount: 0,
          clickCount: 0,
          blogLink: null,
          viewCount: 53829,
          pcu: 0,
          impressionCount: 389000,
          likeCount: 631,
          commentCount: 38,
          shareCount: 0,
          useHandfilled: false,
          articleViewCount: null,
        },
      ],
      crawlerStatuses: null,
      crawlerTasks: null,
      kol: {
        id: 16944,
        UUID: 'KOL-UUID-2',
        type: 'Person',
        name: ['Joy 喬伊吃吃喝喝'],
        links: [
          {
            id: 37988,
            kid: 16944,
            url: 'https://www.youtube.com',
            type: 'YouTube',
            platformType: 0,
            platformID: '',
            crawledID: 'UCkmQx9zSwidPupChljGjwGA',
            tags: null,
            permanentURL: 'https://www.youtube.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2019-04-30T08:24:49Z',
            updatedAt: '2023-03-08T03:57:58Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '35574',
            weight: 1,
            statistic: {
              id: 117215,
              createdAt: '2020-07-07T08:52:05Z',
              updatedAt: '2023-06-07T04:49:44Z',
              linkID: 37988,
              followerCount: 510000,
              followerCount1M: 509000,
              followerCount3M: 505400,
              followerCount6M: 499000,
              commentCount: 0,
              commentCount1M: 384,
              commentCount3M: 944,
              commentCount6M: 2519,
              likeCount: 0,
              likeCount1M: 9051,
              likeCount3M: 25734,
              likeCount6M: 62405,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: 9,
              postCount3M: 25,
              postCount6M: 53,
              videoPostCount: 0,
              videoPostCount1M: 9,
              videoPostCount3M: 25,
              videoPostCount6M: 53,
              videoViewCount: 0,
              videoViewCount1M: 812644,
              videoViewCount3M: 2405129,
              videoViewCount6M: 6012218,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 0,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 0,
              followCountGrowth1M: 1000,
              followCountGrowth3M: 4600,
              followCountGrowth6M: 11000,
              followCountGrowthRate1M: 0.0019646366,
              followCountGrowthRate3M: 0.009101702,
              followCountGrowthRate6M: 0.022044089,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 0,
              shortPostCount3M: 0,
              shortPostCount6M: 0,
              shortViewCount: 0,
              shortViewCount1M: 812644,
              shortViewCount3M: 2405129,
              shortViewCount6M: 6012218,
              score: 91,
              score1M: 91,
              score3M: 91,
              score6M: 91,
              scoreGrowth: 0,
              scoreGrowth1M: 0,
              scoreGrowth3M: 0,
              scoreGrowth6M: 0,
              shortPlayCount1M: 0,
              shortPlayCount3M: 0,
              shortPlayCount6M: 0,
            },
            audience: null,
          },
          {
            id: 37989,
            kid: 16944,
            url: 'https://www.instagram.com',
            type: 'IG',
            platformType: 3,
            platformID: '',
            crawledID: '1240261626',
            tags: null,
            permanentURL: 'https://www.instagram.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2019-04-30T08:25:02Z',
            updatedAt: '2023-03-08T03:44:12Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '44602',
            weight: 1,
            statistic: {
              id: 51031,
              createdAt: '2020-07-06T19:12:18Z',
              updatedAt: '2023-06-08T05:33:49Z',
              linkID: 37989,
              followerCount: 615273,
              followerCount1M: 614720,
              followerCount3M: 611344,
              followerCount6M: 609076,
              commentCount: 0,
              commentCount1M: 495,
              commentCount3M: 1336,
              commentCount6M: 2937,
              likeCount: 0,
              likeCount1M: 284146,
              likeCount3M: 987415,
              likeCount6M: 2110332,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: 23,
              postCount3M: 76,
              postCount6M: 154,
              videoPostCount: 0,
              videoPostCount1M: 2,
              videoPostCount3M: 7,
              videoPostCount6M: 13,
              videoViewCount: 0,
              videoViewCount1M: 914919,
              videoViewCount3M: 2791547,
              videoViewCount6M: 6070693,
              livePostCount: 0,
              livePostCount1M: 1,
              livePostCount3M: 1,
              livePostCount6M: 4,
              liveViewCount: 0,
              liveViewCount1M: 85071,
              liveViewCount3M: 85071,
              liveViewCount6M: 357513,
              followCountGrowth1M: 553,
              followCountGrowth3M: 3929,
              followCountGrowth6M: 6197,
              followCountGrowthRate1M: 0.00089959655,
              followCountGrowthRate3M: 0.0064268233,
              followCountGrowthRate6M: 0.010174428,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 2,
              shortPostCount3M: 7,
              shortPostCount6M: 13,
              shortViewCount: 0,
              shortViewCount1M: 421050,
              shortViewCount3M: 797821,
              shortViewCount6M: 1536174,
              score: 95,
              score1M: 95,
              score3M: 95,
              score6M: 95,
              scoreGrowth: 0,
              scoreGrowth1M: 0,
              scoreGrowth3M: 0,
              scoreGrowth6M: 0,
              shortPlayCount1M: 914919,
              shortPlayCount3M: 2791547,
              shortPlayCount6M: 6070693,
            },
            audience: null,
          },
          {
            id: 525322,
            kid: 16944,
            url: 'https://www.tiktok.com/@aoi_staff',
            type: 'TikTok',
            platformType: 4,
            platformID: '',
            crawledID: null,
            tags: null,
            permanentURL: 'https://www.tiktok.com/@aoi_staff',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2022-11-25T09:16:53Z',
            updatedAt: '2022-11-25T09:16:53Z',
            deletedAt: null,
            datahubKolUrlIdentityId: null,
            weight: 0,
            statistic: {
              id: 0,
              createdAt: '0001-01-01T00:00:00Z',
              updatedAt: '0001-01-01T00:00:00Z',
              linkID: 0,
              followerCount: 0,
              followerCount1M: null,
              followerCount3M: null,
              followerCount6M: null,
              commentCount: null,
              commentCount1M: null,
              commentCount3M: null,
              commentCount6M: null,
              likeCount: null,
              likeCount1M: null,
              likeCount3M: null,
              likeCount6M: null,
              shareCount: null,
              shareCount1M: null,
              shareCount3M: null,
              shareCount6M: null,
              postCount: null,
              postCount1M: null,
              postCount3M: null,
              postCount6M: null,
              videoPostCount: null,
              videoPostCount1M: null,
              videoPostCount3M: null,
              videoPostCount6M: null,
              videoViewCount: null,
              videoViewCount1M: null,
              videoViewCount3M: null,
              videoViewCount6M: null,
              livePostCount: null,
              livePostCount1M: null,
              livePostCount3M: null,
              livePostCount6M: null,
              liveViewCount: null,
              liveViewCount1M: null,
              liveViewCount3M: null,
              liveViewCount6M: null,
              followCountGrowth1M: null,
              followCountGrowth3M: null,
              followCountGrowth6M: null,
              followCountGrowthRate1M: null,
              followCountGrowthRate3M: null,
              followCountGrowthRate6M: null,
              link: null,
              shortPostCount: null,
              shortPostCount1M: null,
              shortPostCount3M: null,
              shortPostCount6M: null,
              shortViewCount: null,
              shortViewCount1M: null,
              shortViewCount3M: null,
              shortViewCount6M: null,
              score: null,
              score1M: null,
              score3M: null,
              score6M: null,
              scoreGrowth: null,
              scoreGrowth1M: null,
              scoreGrowth3M: null,
              scoreGrowth6M: null,
              shortPlayCount1M: null,
              shortPlayCount3M: null,
              shortPlayCount6M: null,
            },
            audience: null,
          },
        ],
        gender: 'Female',
        property: ['Outfit', 'Makeup', 'BeautyCare', 'Food', 'Travel'],
        specialty: ['模特兒', '影片剪輯', 'YouTuber'],
        service: '短影音',
        location: '日本',
        countryCode: 'jp',
        description: '',
        customTag: null,
        isVisible: true,
        crawledDescription:
          'DM 返せません。お仕事のご依頼はメールへお願いします📩\nFilm account🎞️ @aoinofilms\nYoutube ▽',
        image: null,
        createdAt: '2019-04-30T08:23:54Z',
        updatedAt: '2023-06-08T06:11:49Z',
        deletedAt: null,
        datahubKolIdentityId: '171026',
        isVerified: false,
        agencyUser: null,
        kolUser: null,
        cowork: null,
      },
      reportPostID: 0,
      reportKolID: 10576,
      handfilledID: 0,
      postCrawlID: null,
      unavailableAt: null,
      playCount: 0,
      likeCount: 1006,
      commentCount: 43,
      shareCount: 0,
      viewCount: 0,
      engagementCount: 8887,
      reachCount: 5334,
      clickCount: 2,
      pcu: 0,
      youTubeImpressionCount: 0,
      storyReachCount: 0,
      storyClickCount: 0,
      articleViewCount: 0,
      budget: 1500,
      likeValue: 211320.19,
      commentValue: 2644.8,
      shareValue: 0,
      viewValue: 0,
      pCUValue: 0,
      mediaValue: 213964.98,
      time: '2023-05-17T08:38:05Z',
      url: 'https://www.instagram.com',
      postType: 'image',
      publishTime: '2023-05-16T12:00:00Z',
      platformType: 3,
      matchedKeywords: null,
      text: '咖啡愛好者們，你們是否曾經聽說過雷達手沖咖啡呢？它是一種獨特的沖煮方式，帶給你不一樣的咖啡體驗。',
      isHidden: null,
      uniqueKey: '2b72a769-549d-4804-b858-2af34f266178',
      blogLink: '',
      postID: '',
      thumbnailURL: demoCooperationSrc3,
      useHandfilled: false,
      isShort: false,
    },
    {
      id: 10576,
      createdAt: '2023-05-17T06:28:56Z',
      updatedAt: '2023-05-17T06:28:56Z',
      deletedAt: null,
      reportID: 'demo',
      report: null,
      UUID: 'KOL-UUID-2',
      kolName: 'Joy 喬伊吃吃喝喝',
      categories: ['Outfit', 'Makeup', 'BeautyCare', 'Food', 'Travel'],
      customizedTagsNames: ['Food', 'Food/Beverage'],
      followerCount: 510000,
      platform: 0,
      platforms: null,
      lastScannedAt: null,
      handfilleds: [
        {
          id: 11038,
          createdAt: '2023-05-18T02:58:39Z',
          updatedAt: '2023-05-18T02:58:48Z',
          deletedAt: null,
          reportKolID: 10576,
          reportKOL: null,
          reportID: 'demo',
          report: null,
          isHidden: null,
          url: 'https://www.youtube.com',
          budget: 50000,
          reachCount: 0,
          clickCount: 0,
          blogLink: null,
          viewCount: 53829,
          pcu: 0,
          impressionCount: 389000,
          likeCount: 631,
          commentCount: 38,
          shareCount: 0,
          useHandfilled: false,
          articleViewCount: null,
        },
      ],
      crawlerStatuses: null,
      crawlerTasks: null,
      kol: {
        id: 16944,
        UUID: 'KOL-UUID-2',
        type: 'Person',
        name: ['Joy 喬伊吃吃喝喝'],
        links: [
          {
            id: 37988,
            kid: 16944,
            url: 'https://www.youtube.com',
            type: 'YouTube',
            platformType: 0,
            platformID: '',
            crawledID: 'UCkmQx9zSwidPupChljGjwGA',
            tags: null,
            permanentURL: 'https://www.youtube.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2019-04-30T08:24:49Z',
            updatedAt: '2023-03-08T03:57:58Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '35574',
            weight: 1,
            statistic: {
              id: 117215,
              createdAt: '2020-07-07T08:52:05Z',
              updatedAt: '2023-06-07T04:49:44Z',
              linkID: 37988,
              followerCount: 510000,
              followerCount1M: 509000,
              followerCount3M: 505400,
              followerCount6M: 499000,
              commentCount: 0,
              commentCount1M: 384,
              commentCount3M: 944,
              commentCount6M: 2519,
              likeCount: 0,
              likeCount1M: 9051,
              likeCount3M: 25734,
              likeCount6M: 62405,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: 9,
              postCount3M: 25,
              postCount6M: 53,
              videoPostCount: 0,
              videoPostCount1M: 9,
              videoPostCount3M: 25,
              videoPostCount6M: 53,
              videoViewCount: 0,
              videoViewCount1M: 812644,
              videoViewCount3M: 2405129,
              videoViewCount6M: 6012218,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 0,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 0,
              followCountGrowth1M: 1000,
              followCountGrowth3M: 4600,
              followCountGrowth6M: 11000,
              followCountGrowthRate1M: 0.0019646366,
              followCountGrowthRate3M: 0.009101702,
              followCountGrowthRate6M: 0.022044089,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 0,
              shortPostCount3M: 0,
              shortPostCount6M: 0,
              shortViewCount: 0,
              shortViewCount1M: 812644,
              shortViewCount3M: 2405129,
              shortViewCount6M: 6012218,
              score: 91,
              score1M: 91,
              score3M: 91,
              score6M: 91,
              scoreGrowth: 0,
              scoreGrowth1M: 0,
              scoreGrowth3M: 0,
              scoreGrowth6M: 0,
              shortPlayCount1M: 0,
              shortPlayCount3M: 0,
              shortPlayCount6M: 0,
            },
            audience: null,
          },
          {
            id: 37989,
            kid: 16944,
            url: 'https://www.instagram.com',
            type: 'IG',
            platformType: 3,
            platformID: '',
            crawledID: '1240261626',
            tags: null,
            permanentURL: 'https://www.instagram.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2019-04-30T08:25:02Z',
            updatedAt: '2023-03-08T03:44:12Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '44602',
            weight: 1,
            statistic: {
              id: 51031,
              createdAt: '2020-07-06T19:12:18Z',
              updatedAt: '2023-06-08T05:33:49Z',
              linkID: 37989,
              followerCount: 615273,
              followerCount1M: 614720,
              followerCount3M: 611344,
              followerCount6M: 609076,
              commentCount: 0,
              commentCount1M: 495,
              commentCount3M: 1336,
              commentCount6M: 2937,
              likeCount: 0,
              likeCount1M: 284146,
              likeCount3M: 987415,
              likeCount6M: 2110332,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: 23,
              postCount3M: 76,
              postCount6M: 154,
              videoPostCount: 0,
              videoPostCount1M: 2,
              videoPostCount3M: 7,
              videoPostCount6M: 13,
              videoViewCount: 0,
              videoViewCount1M: 914919,
              videoViewCount3M: 2791547,
              videoViewCount6M: 6070693,
              livePostCount: 0,
              livePostCount1M: 1,
              livePostCount3M: 1,
              livePostCount6M: 4,
              liveViewCount: 0,
              liveViewCount1M: 85071,
              liveViewCount3M: 85071,
              liveViewCount6M: 357513,
              followCountGrowth1M: 553,
              followCountGrowth3M: 3929,
              followCountGrowth6M: 6197,
              followCountGrowthRate1M: 0.00089959655,
              followCountGrowthRate3M: 0.0064268233,
              followCountGrowthRate6M: 0.010174428,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 2,
              shortPostCount3M: 7,
              shortPostCount6M: 13,
              shortViewCount: 0,
              shortViewCount1M: 421050,
              shortViewCount3M: 797821,
              shortViewCount6M: 1536174,
              score: 95,
              score1M: 95,
              score3M: 95,
              score6M: 95,
              scoreGrowth: 0,
              scoreGrowth1M: 0,
              scoreGrowth3M: 0,
              scoreGrowth6M: 0,
              shortPlayCount1M: 914919,
              shortPlayCount3M: 2791547,
              shortPlayCount6M: 6070693,
            },
            audience: null,
          },
          {
            id: 525322,
            kid: 16944,
            url: 'https://www.tiktok.com/@aoi_staff',
            type: 'TikTok',
            platformType: 4,
            platformID: '',
            crawledID: null,
            tags: null,
            permanentURL: 'https://www.tiktok.com/@aoi_staff',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2022-11-25T09:16:53Z',
            updatedAt: '2022-11-25T09:16:53Z',
            deletedAt: null,
            datahubKolUrlIdentityId: null,
            weight: 0,
            statistic: {
              id: 0,
              createdAt: '0001-01-01T00:00:00Z',
              updatedAt: '0001-01-01T00:00:00Z',
              linkID: 0,
              followerCount: 0,
              followerCount1M: null,
              followerCount3M: null,
              followerCount6M: null,
              commentCount: null,
              commentCount1M: null,
              commentCount3M: null,
              commentCount6M: null,
              likeCount: null,
              likeCount1M: null,
              likeCount3M: null,
              likeCount6M: null,
              shareCount: null,
              shareCount1M: null,
              shareCount3M: null,
              shareCount6M: null,
              postCount: null,
              postCount1M: null,
              postCount3M: null,
              postCount6M: null,
              videoPostCount: null,
              videoPostCount1M: null,
              videoPostCount3M: null,
              videoPostCount6M: null,
              videoViewCount: null,
              videoViewCount1M: null,
              videoViewCount3M: null,
              videoViewCount6M: null,
              livePostCount: null,
              livePostCount1M: null,
              livePostCount3M: null,
              livePostCount6M: null,
              liveViewCount: null,
              liveViewCount1M: null,
              liveViewCount3M: null,
              liveViewCount6M: null,
              followCountGrowth1M: null,
              followCountGrowth3M: null,
              followCountGrowth6M: null,
              followCountGrowthRate1M: null,
              followCountGrowthRate3M: null,
              followCountGrowthRate6M: null,
              link: null,
              shortPostCount: null,
              shortPostCount1M: null,
              shortPostCount3M: null,
              shortPostCount6M: null,
              shortViewCount: null,
              shortViewCount1M: null,
              shortViewCount3M: null,
              shortViewCount6M: null,
              score: null,
              score1M: null,
              score3M: null,
              score6M: null,
              scoreGrowth: null,
              scoreGrowth1M: null,
              scoreGrowth3M: null,
              scoreGrowth6M: null,
              shortPlayCount1M: null,
              shortPlayCount3M: null,
              shortPlayCount6M: null,
            },
            audience: null,
          },
        ],
        gender: 'Female',
        property: ['Outfit', 'Makeup', 'BeautyCare', 'Food', 'Travel'],
        specialty: ['模特兒', '影片剪輯', 'YouTuber'],
        service: '短影音',
        location: '日本',
        countryCode: 'jp',
        description: '',
        customTag: null,
        isVisible: true,
        crawledDescription:
          'DM 返せません。お仕事のご依頼はメールへお願いします📩\nFilm account🎞️ @aoinofilms\nYoutube ▽',
        image: null,
        createdAt: '2019-04-30T08:23:54Z',
        updatedAt: '2023-06-08T06:11:49Z',
        deletedAt: null,
        datahubKolIdentityId: '171026',
        isVerified: false,
        agencyUser: null,
        kolUser: null,
        cowork: null,
      },
      reportPostID: 0,
      reportKolID: 10576,
      handfilledID: 0,
      postCrawlID: null,
      unavailableAt: null,
      playCount: 0,
      likeCount: 643,
      commentCount: 383,
      shareCount: 0,
      viewCount: 33542,
      engagementCount: 4567,
      reachCount: 33456,
      clickCount: 254,
      pcu: 0,
      youTubeImpressionCount: 389000,
      storyReachCount: 0,
      storyClickCount: 0,
      articleViewCount: 0,
      budget: 30000,
      likeValue: 25240,
      commentValue: 114000,
      shareValue: 0,
      viewValue: 86126.4,
      pCUValue: 0,
      mediaValue: 225366.4,
      time: '2023-05-17T08:29:08Z',
      url: 'https://www.youtube.com',
      postType: 'image',
      publishTime: '2023-05-14T12:00:12Z',
      platformType: 0,
      matchedKeywords: null,
      text: '咖啡愛好者們，你們是否曾經聽說過雷達手沖咖啡呢？它是一種獨特的沖煮方式，帶給你不一樣的咖啡體驗。',
      isHidden: null,
      uniqueKey: '57e3e0f5-6c46-485a-afd6-0f60b5ecdb17',
      blogLink: '',
      postID: '',
      thumbnailURL: demoCooperationSrc4,
      useHandfilled: false,
      isShort: false,
    },
    {
      id: 10577,
      createdAt: '2023-05-17T06:28:56Z',
      updatedAt: '2023-05-17T06:28:56Z',
      deletedAt: null,
      reportID: 'demo',
      report: null,
      UUID: 'KOL-UUID-3',
      kolName: '花兒 Flower🌸',
      categories: ['BeautyCare', 'Makeup', 'Music', 'Campus', 'Education'],
      customizedTagsNames: ['Food', 'Food/Beverage'],
      followerCount: 256587,
      platform: 3,
      platforms: null,
      lastScannedAt: null,
      handfilleds: [
        {
          id: 11039,
          createdAt: '2023-05-18T02:58:58Z',
          updatedAt: '2023-05-18T02:58:58Z',
          deletedAt: null,
          reportKolID: 10577,
          reportKOL: null,
          reportID: 'demo',
          report: null,
          isHidden: null,
          url: 'https://www.instagram.com',
          budget: 12000,
          reachCount: 0,
          clickCount: 0,
          blogLink: null,
          viewCount: 0,
          pcu: 0,
          impressionCount: 0,
          likeCount: 5400,
          commentCount: 66,
          shareCount: 0,
          useHandfilled: false,
          articleViewCount: null,
        },
      ],
      crawlerStatuses: null,
      crawlerTasks: null,
      kol: {
        id: 102173,
        UUID: 'KOL-UUID-3',
        type: 'Person',
        name: ['花兒 Flower🌸', 'a.k.a', 'jnp92119'],
        links: [
          {
            id: 207839,
            kid: 102173,
            url: 'https://www.twitter.com',
            type: 'Twitter',
            platformType: 5,
            platformID: '',
            crawledID: '242938647',
            tags: null,
            permanentURL: 'https://twitter.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2021-05-31T12:35:48Z',
            updatedAt: '2023-04-14T10:50:44Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '2240562',
            weight: 1,
            statistic: {
              id: 10814098,
              createdAt: '2023-05-09T07:35:06Z',
              updatedAt: '2023-05-09T07:35:06Z',
              linkID: 207839,
              followerCount: 3082,
              followerCount1M: null,
              followerCount3M: null,
              followerCount6M: null,
              commentCount: 0,
              commentCount1M: 0,
              commentCount3M: 0,
              commentCount6M: 5,
              likeCount: 0,
              likeCount1M: 0,
              likeCount3M: 47,
              likeCount6M: 562,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 3,
              shareCount6M: 88,
              postCount: 0,
              postCount1M: 0,
              postCount3M: 3,
              postCount6M: 11,
              videoPostCount: 0,
              videoPostCount1M: 0,
              videoPostCount3M: 0,
              videoPostCount6M: 0,
              videoViewCount: 0,
              videoViewCount1M: 0,
              videoViewCount3M: 0,
              videoViewCount6M: 0,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 0,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 0,
              followCountGrowth1M: null,
              followCountGrowth3M: null,
              followCountGrowth6M: null,
              followCountGrowthRate1M: 0,
              followCountGrowthRate3M: 0,
              followCountGrowthRate6M: 0,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 0,
              shortPostCount3M: 0,
              shortPostCount6M: 0,
              shortViewCount: 0,
              shortViewCount1M: 0,
              shortViewCount3M: 0,
              shortViewCount6M: 0,
              score: 0,
              score1M: 0,
              score3M: 0,
              score6M: 0,
              scoreGrowth: null,
              scoreGrowth1M: null,
              scoreGrowth3M: null,
              scoreGrowth6M: null,
              shortPlayCount1M: 0,
              shortPlayCount3M: 0,
              shortPlayCount6M: 0,
            },
            audience: null,
          },
          {
            id: 207840,
            kid: 102173,
            url: 'https://www.youtube.com',
            type: 'YouTube',
            platformType: 0,
            platformID: '',
            crawledID: 'UCgi1YyJdI4avyNy1ZP_OJNQ',
            tags: null,
            permanentURL: 'https://www.youtube.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2021-05-31T12:35:48Z',
            updatedAt: '2023-03-08T03:58:20Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '201240',
            weight: 1,
            statistic: {
              id: 9874430,
              createdAt: '2021-06-03T20:27:33Z',
              updatedAt: '2023-06-07T04:37:27Z',
              linkID: 207840,
              followerCount: 11400,
              followerCount1M: 11400,
              followerCount3M: 11500,
              followerCount6M: 11600,
              commentCount: 0,
              commentCount1M: 0,
              commentCount3M: 0,
              commentCount6M: 0,
              likeCount: 0,
              likeCount1M: 0,
              likeCount3M: 0,
              likeCount6M: 0,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: 0,
              postCount3M: 0,
              postCount6M: 0,
              videoPostCount: 0,
              videoPostCount1M: 0,
              videoPostCount3M: 0,
              videoPostCount6M: 0,
              videoViewCount: 0,
              videoViewCount1M: 0,
              videoViewCount3M: 0,
              videoViewCount6M: 0,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 0,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 0,
              followCountGrowth1M: -100,
              followCountGrowth3M: -100,
              followCountGrowth6M: -200,
              followCountGrowthRate1M: 0,
              followCountGrowthRate3M: -0.008695652,
              followCountGrowthRate6M: -0.01724138,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 0,
              shortPostCount3M: 0,
              shortPostCount6M: 0,
              shortViewCount: 0,
              shortViewCount1M: 0,
              shortViewCount3M: 0,
              shortViewCount6M: 0,
              score: 67,
              score1M: 67,
              score3M: 67,
              score6M: 67,
              scoreGrowth: 0,
              scoreGrowth1M: 0,
              scoreGrowth3M: 0,
              scoreGrowth6M: 0,
              shortPlayCount1M: 0,
              shortPlayCount3M: 0,
              shortPlayCount6M: 0,
            },
            audience: null,
          },
          {
            id: 207841,
            kid: 102173,
            url: 'https://www.instagram.com',
            type: 'IG',
            platformType: 3,
            platformID: '',
            crawledID: '5955606049',
            tags: null,
            permanentURL: 'https://www.instagram.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2021-05-31T12:35:48Z',
            updatedAt: '2023-03-08T03:44:14Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '228625',
            weight: 1,
            statistic: {
              id: 9879228,
              createdAt: '2021-06-23T05:56:43Z',
              updatedAt: '2023-06-08T05:36:13Z',
              linkID: 207841,
              followerCount: 256587,
              followerCount1M: 255515,
              followerCount3M: 249628,
              followerCount6M: 247250,
              commentCount: 0,
              commentCount1M: 1565,
              commentCount3M: 5923,
              commentCount6M: 10761,
              likeCount: 0,
              likeCount1M: 92172,
              likeCount3M: 389226,
              likeCount6M: 794760,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: 5,
              postCount3M: 27,
              postCount6M: 51,
              videoPostCount: 0,
              videoPostCount1M: 3,
              videoPostCount3M: 11,
              videoPostCount6M: 28,
              videoViewCount: 0,
              videoViewCount1M: 1678707,
              videoViewCount3M: 7320401,
              videoViewCount6M: 18405559,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 0,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 0,
              followCountGrowth1M: 1072,
              followCountGrowth3M: 6959,
              followCountGrowth6M: 9337,
              followCountGrowthRate1M: 0.0041954485,
              followCountGrowthRate3M: 0.027877482,
              followCountGrowthRate6M: 0.0377634,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 3,
              shortPostCount3M: 11,
              shortPostCount6M: 28,
              shortViewCount: 0,
              shortViewCount1M: 1076035,
              shortViewCount3M: 4746141,
              shortViewCount6M: 11672969,
              score: 85,
              score1M: 85,
              score3M: 85,
              score6M: 85,
              scoreGrowth: 0,
              scoreGrowth1M: 0,
              scoreGrowth3M: 0,
              scoreGrowth6M: 0,
              shortPlayCount1M: 1678707,
              shortPlayCount3M: 7320401,
              shortPlayCount6M: 18405559,
            },
            audience: null,
          },
          {
            id: 207842,
            kid: 102173,
            url: 'https://www.tiktok.com/@junpi92',
            type: 'TikTok',
            platformType: 4,
            platformID: '',
            crawledID: '6789034275678766082',
            tags: null,
            permanentURL: 'https://www.tiktok.com/@6789034275678766082',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2021-05-31T12:35:48Z',
            updatedAt: '2023-03-08T03:51:24Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '175343',
            weight: 0,
            statistic: {
              id: 9896821,
              createdAt: '2021-07-14T03:04:15Z',
              updatedAt: '2021-07-13T10:17:28Z',
              linkID: 207842,
              followerCount: 84600,
              followerCount1M: 84600,
              followerCount3M: null,
              followerCount6M: null,
              commentCount: 0,
              commentCount1M: null,
              commentCount3M: 0,
              commentCount6M: 0,
              likeCount: 0,
              likeCount1M: null,
              likeCount3M: 0,
              likeCount6M: 0,
              shareCount: 0,
              shareCount1M: null,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: null,
              postCount3M: 0,
              postCount6M: 0,
              videoPostCount: 0,
              videoPostCount1M: null,
              videoPostCount3M: 0,
              videoPostCount6M: 0,
              videoViewCount: 0,
              videoViewCount1M: null,
              videoViewCount3M: 0,
              videoViewCount6M: 0,
              livePostCount: null,
              livePostCount1M: null,
              livePostCount3M: null,
              livePostCount6M: null,
              liveViewCount: null,
              liveViewCount1M: null,
              liveViewCount3M: null,
              liveViewCount6M: null,
              followCountGrowth1M: null,
              followCountGrowth3M: null,
              followCountGrowth6M: null,
              followCountGrowthRate1M: null,
              followCountGrowthRate3M: null,
              followCountGrowthRate6M: null,
              link: null,
              shortPostCount: null,
              shortPostCount1M: null,
              shortPostCount3M: null,
              shortPostCount6M: null,
              shortViewCount: null,
              shortViewCount1M: null,
              shortViewCount3M: null,
              shortViewCount6M: null,
              score: null,
              score1M: null,
              score3M: null,
              score6M: null,
              scoreGrowth: null,
              scoreGrowth1M: null,
              scoreGrowth3M: null,
              scoreGrowth6M: null,
              shortPlayCount1M: null,
              shortPlayCount3M: null,
              shortPlayCount6M: null,
            },
            audience: null,
          },
        ],
        gender: 'Male',
        property: ['BeautyCare', 'Makeup', 'Music', 'Campus', 'Education'],
        specialty: ['YouTuber'],
        service: '圖文',
        location: '日本',
        countryCode: 'jp',
        description: '',
        customTag: null,
        isVisible: true,
        crawledDescription:
          '人生初の書籍『現在住所は冷蔵庫。自己肯定感急上昇』(2023年1月19日発売)ご予約はプロフィールリンクから。\nお仕事のお問い合わせはメールからお願いします。',
        image: null,
        createdAt: '2021-05-31T12:35:48Z',
        updatedAt: '2023-06-08T06:17:08Z',
        deletedAt: null,
        datahubKolIdentityId: '33951',
        isVerified: false,
        agencyUser: null,
        kolUser: null,
        cowork: null,
      },
      reportPostID: 0,
      reportKolID: 10577,
      handfilledID: 0,
      postCrawlID: null,
      unavailableAt: null,
      playCount: 231,
      likeCount: 238,
      commentCount: 56,
      shareCount: 0,
      viewCount: 2301,
      engagementCount: 12891,
      reachCount: 3048,
      clickCount: 13,
      pcu: 0,
      youTubeImpressionCount: 0,
      storyReachCount: 0,
      storyClickCount: 0,
      articleViewCount: 0,
      budget: 3000,
      likeValue: 303902.2,
      commentValue: 40333.2,
      shareValue: 0,
      viewValue: 840518,
      pCUValue: 0,
      mediaValue: 1184753.4,
      time: '2023-05-17T08:38:05Z',
      url: 'https://www.instagram.com',
      postType: 'unknown',
      publishTime: '2023-05-09T09:32:45Z',
      platformType: 3,
      matchedKeywords: null,
      text: '【咖啡藝術的開始！雷達手沖咖啡組】☕️🎨 大家好！今天要跟大家分享一個讓我愛上咖啡的秘密武器——雷達手沖咖啡組！🌟',
      isHidden: null,
      uniqueKey: '8ee056f2-1e76-4172-bd11-f039e4a2f1fd',
      blogLink: '',
      postID: '',
      thumbnailURL: demoCooperationSrc5,
      useHandfilled: false,
      isShort: true,
    },
    {
      id: 10577,
      createdAt: '2023-05-17T06:28:56Z',
      updatedAt: '2023-05-17T06:28:56Z',
      deletedAt: null,
      reportID: 'demo',
      report: null,
      UUID: 'KOL-UUID-3',
      kolName: '花兒 Flower🌸',
      categories: ['BeautyCare', 'Makeup', 'Music', 'Campus', 'Education'],
      customizedTagsNames: ['Food', 'Food/Beverage'],
      followerCount: 256587,
      platform: 3,
      platforms: null,
      lastScannedAt: null,
      handfilleds: [
        {
          id: 11039,
          createdAt: '2023-05-18T02:58:58Z',
          updatedAt: '2023-05-18T02:58:58Z',
          deletedAt: null,
          reportKolID: 10577,
          reportKOL: null,
          reportID: 'demo',
          report: null,
          isHidden: null,
          url: 'https://www.instagram.com',
          budget: 12000,
          reachCount: 0,
          clickCount: 0,
          blogLink: null,
          viewCount: 0,
          pcu: 0,
          impressionCount: 0,
          likeCount: 5400,
          commentCount: 66,
          shareCount: 0,
          useHandfilled: false,
          articleViewCount: null,
        },
      ],
      crawlerStatuses: null,
      crawlerTasks: null,
      kol: {
        id: 102173,
        UUID: 'KOL-UUID-3',
        type: 'Person',
        name: ['花兒 Flower🌸', 'a.k.a', 'jnp92119'],
        links: [
          {
            id: 207839,
            kid: 102173,
            url: 'https://www.twitter.com',
            type: 'Twitter',
            platformType: 5,
            platformID: '',
            crawledID: '242938647',
            tags: null,
            permanentURL: 'https://twitter.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2021-05-31T12:35:48Z',
            updatedAt: '2023-04-14T10:50:44Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '2240562',
            weight: 1,
            statistic: {
              id: 10814098,
              createdAt: '2023-05-09T07:35:06Z',
              updatedAt: '2023-05-09T07:35:06Z',
              linkID: 207839,
              followerCount: 3082,
              followerCount1M: null,
              followerCount3M: null,
              followerCount6M: null,
              commentCount: 0,
              commentCount1M: 0,
              commentCount3M: 0,
              commentCount6M: 5,
              likeCount: 0,
              likeCount1M: 0,
              likeCount3M: 47,
              likeCount6M: 562,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 3,
              shareCount6M: 88,
              postCount: 0,
              postCount1M: 0,
              postCount3M: 3,
              postCount6M: 11,
              videoPostCount: 0,
              videoPostCount1M: 0,
              videoPostCount3M: 0,
              videoPostCount6M: 0,
              videoViewCount: 0,
              videoViewCount1M: 0,
              videoViewCount3M: 0,
              videoViewCount6M: 0,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 0,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 0,
              followCountGrowth1M: null,
              followCountGrowth3M: null,
              followCountGrowth6M: null,
              followCountGrowthRate1M: 0,
              followCountGrowthRate3M: 0,
              followCountGrowthRate6M: 0,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 0,
              shortPostCount3M: 0,
              shortPostCount6M: 0,
              shortViewCount: 0,
              shortViewCount1M: 0,
              shortViewCount3M: 0,
              shortViewCount6M: 0,
              score: 0,
              score1M: 0,
              score3M: 0,
              score6M: 0,
              scoreGrowth: null,
              scoreGrowth1M: null,
              scoreGrowth3M: null,
              scoreGrowth6M: null,
              shortPlayCount1M: 0,
              shortPlayCount3M: 0,
              shortPlayCount6M: 0,
            },
            audience: null,
          },
          {
            id: 207840,
            kid: 102173,
            url: 'https://www.youtube.com',
            type: 'YouTube',
            platformType: 0,
            platformID: '',
            crawledID: 'UCgi1YyJdI4avyNy1ZP_OJNQ',
            tags: null,
            permanentURL: 'https://www.youtube.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2021-05-31T12:35:48Z',
            updatedAt: '2023-03-08T03:58:20Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '201240',
            weight: 1,
            statistic: {
              id: 9874430,
              createdAt: '2021-06-03T20:27:33Z',
              updatedAt: '2023-06-07T04:37:27Z',
              linkID: 207840,
              followerCount: 11400,
              followerCount1M: 11400,
              followerCount3M: 11500,
              followerCount6M: 11600,
              commentCount: 0,
              commentCount1M: 0,
              commentCount3M: 0,
              commentCount6M: 0,
              likeCount: 0,
              likeCount1M: 0,
              likeCount3M: 0,
              likeCount6M: 0,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: 0,
              postCount3M: 0,
              postCount6M: 0,
              videoPostCount: 0,
              videoPostCount1M: 0,
              videoPostCount3M: 0,
              videoPostCount6M: 0,
              videoViewCount: 0,
              videoViewCount1M: 0,
              videoViewCount3M: 0,
              videoViewCount6M: 0,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 0,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 0,
              followCountGrowth1M: -100,
              followCountGrowth3M: -100,
              followCountGrowth6M: -200,
              followCountGrowthRate1M: 0,
              followCountGrowthRate3M: -0.008695652,
              followCountGrowthRate6M: -0.01724138,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 0,
              shortPostCount3M: 0,
              shortPostCount6M: 0,
              shortViewCount: 0,
              shortViewCount1M: 0,
              shortViewCount3M: 0,
              shortViewCount6M: 0,
              score: 67,
              score1M: 67,
              score3M: 67,
              score6M: 67,
              scoreGrowth: 0,
              scoreGrowth1M: 0,
              scoreGrowth3M: 0,
              scoreGrowth6M: 0,
              shortPlayCount1M: 0,
              shortPlayCount3M: 0,
              shortPlayCount6M: 0,
            },
            audience: null,
          },
          {
            id: 207841,
            kid: 102173,
            url: 'https://www.instagram.com',
            type: 'IG',
            platformType: 3,
            platformID: '',
            crawledID: '5955606049',
            tags: null,
            permanentURL: 'https://www.instagram.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2021-05-31T12:35:48Z',
            updatedAt: '2023-03-08T03:44:14Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '228625',
            weight: 1,
            statistic: {
              id: 9879228,
              createdAt: '2021-06-23T05:56:43Z',
              updatedAt: '2023-06-08T05:36:13Z',
              linkID: 207841,
              followerCount: 256587,
              followerCount1M: 255515,
              followerCount3M: 249628,
              followerCount6M: 247250,
              commentCount: 0,
              commentCount1M: 1565,
              commentCount3M: 5923,
              commentCount6M: 10761,
              likeCount: 0,
              likeCount1M: 92172,
              likeCount3M: 389226,
              likeCount6M: 794760,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: 5,
              postCount3M: 27,
              postCount6M: 51,
              videoPostCount: 0,
              videoPostCount1M: 3,
              videoPostCount3M: 11,
              videoPostCount6M: 28,
              videoViewCount: 0,
              videoViewCount1M: 1678707,
              videoViewCount3M: 7320401,
              videoViewCount6M: 18405559,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 0,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 0,
              followCountGrowth1M: 1072,
              followCountGrowth3M: 6959,
              followCountGrowth6M: 9337,
              followCountGrowthRate1M: 0.0041954485,
              followCountGrowthRate3M: 0.027877482,
              followCountGrowthRate6M: 0.0377634,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 3,
              shortPostCount3M: 11,
              shortPostCount6M: 28,
              shortViewCount: 0,
              shortViewCount1M: 1076035,
              shortViewCount3M: 4746141,
              shortViewCount6M: 11672969,
              score: 85,
              score1M: 85,
              score3M: 85,
              score6M: 85,
              scoreGrowth: 0,
              scoreGrowth1M: 0,
              scoreGrowth3M: 0,
              scoreGrowth6M: 0,
              shortPlayCount1M: 1678707,
              shortPlayCount3M: 7320401,
              shortPlayCount6M: 18405559,
            },
            audience: null,
          },
          {
            id: 207842,
            kid: 102173,
            url: 'https://www.tiktok.com/@junpi92',
            type: 'TikTok',
            platformType: 4,
            platformID: '',
            crawledID: '6789034275678766082',
            tags: null,
            permanentURL: 'https://www.tiktok.com/@6789034275678766082',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2021-05-31T12:35:48Z',
            updatedAt: '2023-03-08T03:51:24Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '175343',
            weight: 0,
            statistic: {
              id: 9896821,
              createdAt: '2021-07-14T03:04:15Z',
              updatedAt: '2021-07-13T10:17:28Z',
              linkID: 207842,
              followerCount: 84600,
              followerCount1M: 84600,
              followerCount3M: null,
              followerCount6M: null,
              commentCount: 0,
              commentCount1M: null,
              commentCount3M: 0,
              commentCount6M: 0,
              likeCount: 0,
              likeCount1M: null,
              likeCount3M: 0,
              likeCount6M: 0,
              shareCount: 0,
              shareCount1M: null,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: null,
              postCount3M: 0,
              postCount6M: 0,
              videoPostCount: 0,
              videoPostCount1M: null,
              videoPostCount3M: 0,
              videoPostCount6M: 0,
              videoViewCount: 0,
              videoViewCount1M: null,
              videoViewCount3M: 0,
              videoViewCount6M: 0,
              livePostCount: null,
              livePostCount1M: null,
              livePostCount3M: null,
              livePostCount6M: null,
              liveViewCount: null,
              liveViewCount1M: null,
              liveViewCount3M: null,
              liveViewCount6M: null,
              followCountGrowth1M: null,
              followCountGrowth3M: null,
              followCountGrowth6M: null,
              followCountGrowthRate1M: null,
              followCountGrowthRate3M: null,
              followCountGrowthRate6M: null,
              link: null,
              shortPostCount: null,
              shortPostCount1M: null,
              shortPostCount3M: null,
              shortPostCount6M: null,
              shortViewCount: null,
              shortViewCount1M: null,
              shortViewCount3M: null,
              shortViewCount6M: null,
              score: null,
              score1M: null,
              score3M: null,
              score6M: null,
              scoreGrowth: null,
              scoreGrowth1M: null,
              scoreGrowth3M: null,
              scoreGrowth6M: null,
              shortPlayCount1M: null,
              shortPlayCount3M: null,
              shortPlayCount6M: null,
            },
            audience: null,
          },
        ],
        gender: 'Male',
        property: ['BeautyCare', 'Makeup', 'Music', 'Campus', 'Education'],
        specialty: ['YouTuber'],
        service: '圖文',
        location: '日本',
        countryCode: 'jp',
        description: '',
        customTag: null,
        isVisible: true,
        crawledDescription:
          '人生初の書籍『現在住所は冷蔵庫。自己肯定感急上昇』(2023年1月19日発売)ご予約はプロフィールリンクから。\nお仕事のお問い合わせはメールからお願いします。',
        image: null,
        createdAt: '2021-05-31T12:35:48Z',
        updatedAt: '2023-06-08T06:17:08Z',
        deletedAt: null,
        datahubKolIdentityId: '33951',
        isVerified: false,
        agencyUser: null,
        kolUser: null,
        cowork: null,
      },
      reportPostID: 0,
      reportKolID: 10577,
      handfilledID: 0,
      postCrawlID: null,
      unavailableAt: null,
      playCount: 0,
      likeCount: 333,
      commentCount: 43,
      shareCount: 2,
      viewCount: 0,
      engagementCount: 5466,
      reachCount: 2224,
      clickCount: 45,
      pcu: 0,
      youTubeImpressionCount: 0,
      storyReachCount: 0,
      storyClickCount: 0,
      articleViewCount: 0,
      budget: 3000,
      likeValue: 128519.99,
      commentValue: 21819.6,
      shareValue: 0,
      viewValue: 0,
      pCUValue: 0,
      mediaValue: 150339.6,
      time: '2023-05-17T08:38:05Z',
      url: 'https://www.instagram.com',
      postType: 'image',
      publishTime: '2023-04-30T10:16:41Z',
      platformType: 3,
      matchedKeywords: null,
      text: '【咖啡藝術的開始！雷達手沖咖啡組】☕️🎨 大家好！今天要跟大家分享一個讓我愛上咖啡的秘密武器——雷達手沖咖啡組！🌟',
      isHidden: null,
      uniqueKey: '7b4d967f-0f84-49b5-a52d-35cc381e5c2b',
      blogLink: '',
      postID: '',
      thumbnailURL: demoCooperationSrc6,
      useHandfilled: false,
      isShort: false,
    },
    {
      id: 10579,
      createdAt: '2023-05-17T06:28:56Z',
      updatedAt: '2023-05-17T06:28:56Z',
      deletedAt: null,
      reportID: 'demo',
      report: null,
      UUID: 'KOL-UUID-4',
      kolName: '小V🐳Channel',
      categories: [
        'BeautyCare',
        'Makeup',
        'FortuneTelling',
        'Campus',
        'Outfit',
      ],
      customizedTagsNames: ['Food', 'Food/Beverage'],
      followerCount: 184000,
      platform: 0,
      platforms: null,
      lastScannedAt: null,
      handfilleds: [
        {
          id: 11040,
          createdAt: '2023-05-18T02:59:06Z',
          updatedAt: '2023-05-18T02:59:06Z',
          deletedAt: null,
          reportKolID: 10579,
          reportKOL: null,
          reportID: 'demo',
          report: null,
          isHidden: null,
          url: 'https://www.youtube.com',
          budget: 0,
          reachCount: 0,
          clickCount: 0,
          blogLink: null,
          viewCount: 20036,
          pcu: 0,
          impressionCount: 241000,
          likeCount: 664,
          commentCount: 73,
          shareCount: 0,
          useHandfilled: false,
          articleViewCount: null,
        },
      ],
      crawlerStatuses: null,
      crawlerTasks: null,
      kol: {
        id: 94565,
        UUID: 'KOL-UUID-4',
        type: 'Person',
        name: ['小V🐳Channel'],
        links: [
          {
            id: 194450,
            kid: 94565,
            url: 'https://www.youtube.com',
            type: 'YouTube',
            platformType: 0,
            platformID: '',
            crawledID: 'UC3UkKUU_Y7Nr3Hhhho_fQxw',
            tags: null,
            permanentURL: 'https://www.youtube.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2021-05-03T11:36:01Z',
            updatedAt: '2023-03-08T03:51:26Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '34286',
            weight: 1,
            statistic: {
              id: 9870217,
              createdAt: '2021-05-07T06:36:46Z',
              updatedAt: '2023-05-24T04:21:40Z',
              linkID: 194450,
              followerCount: 184000,
              followerCount1M: 183000,
              followerCount3M: 182000,
              followerCount6M: 175000,
              commentCount: 0,
              commentCount1M: 82,
              commentCount3M: 660,
              commentCount6M: 1149,
              likeCount: 0,
              likeCount1M: 830,
              likeCount3M: 12619,
              likeCount6M: 20011,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: 1,
              postCount3M: 8,
              postCount6M: 15,
              videoPostCount: 0,
              videoPostCount1M: 1,
              videoPostCount3M: 8,
              videoPostCount6M: 15,
              videoViewCount: 0,
              videoViewCount1M: 26793,
              videoViewCount3M: 377109,
              videoViewCount6M: 831548,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 0,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 0,
              followCountGrowth1M: 1000,
              followCountGrowth3M: 2000,
              followCountGrowth6M: 9000,
              followCountGrowthRate1M: 0.0054644807,
              followCountGrowthRate3M: 0.010989011,
              followCountGrowthRate6M: 0.05142857,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 0,
              shortPostCount3M: 0,
              shortPostCount6M: 0,
              shortViewCount: 0,
              shortViewCount1M: 26793,
              shortViewCount3M: 377109,
              shortViewCount6M: 831548,
              score: 90,
              score1M: 90,
              score3M: 90,
              score6M: 90,
              scoreGrowth: 0,
              scoreGrowth1M: 0,
              scoreGrowth3M: 0,
              scoreGrowth6M: 0,
              shortPlayCount1M: 0,
              shortPlayCount3M: 0,
              shortPlayCount6M: 0,
            },
            audience: null,
          },
          {
            id: 194451,
            kid: 94565,
            url: 'https://www.instagram.com',
            type: 'IG',
            platformType: 3,
            platformID: '',
            crawledID: '1707555709',
            tags: null,
            permanentURL: 'https://www.instagram.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2021-05-03T11:36:01Z',
            updatedAt: '2023-03-08T03:57:52Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '43362',
            weight: 1,
            statistic: {
              id: 9871015,
              createdAt: '2021-05-26T04:10:20Z',
              updatedAt: '2023-06-08T05:29:02Z',
              linkID: 194451,
              followerCount: 64083,
              followerCount1M: 64123,
              followerCount3M: 64042,
              followerCount6M: 63061,
              commentCount: 0,
              commentCount1M: 99,
              commentCount3M: 196,
              commentCount6M: 477,
              likeCount: 0,
              likeCount1M: 50900,
              likeCount3M: 103189,
              likeCount6M: 283197,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: 10,
              postCount3M: 19,
              postCount6M: 48,
              videoPostCount: 0,
              videoPostCount1M: 1,
              videoPostCount3M: 1,
              videoPostCount6M: 1,
              videoViewCount: 0,
              videoViewCount1M: 59483,
              videoViewCount3M: 59483,
              videoViewCount6M: 59483,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 0,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 0,
              followCountGrowth1M: -40,
              followCountGrowth3M: 41,
              followCountGrowth6M: 1022,
              followCountGrowthRate1M: -0.00062380114,
              followCountGrowthRate3M: 0.0006402049,
              followCountGrowthRate6M: 0.01620653,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 1,
              shortPostCount3M: 1,
              shortPostCount6M: 1,
              shortViewCount: 0,
              shortViewCount1M: 20119,
              shortViewCount3M: 20119,
              shortViewCount6M: 20119,
              score: 81,
              score1M: 81,
              score3M: 81,
              score6M: 81,
              scoreGrowth: 0,
              scoreGrowth1M: 0,
              scoreGrowth3M: 0,
              scoreGrowth6M: 0,
              shortPlayCount1M: 59483,
              shortPlayCount3M: 59483,
              shortPlayCount6M: 59483,
            },
            audience: null,
          },
        ],
        gender: 'Female',
        property: [
          'BeautyCare',
          'Makeup',
          'FortuneTelling',
          'Campus',
          'Outfit',
        ],
        specialty: ['YouTuber'],
        service: '短影音',
        location: '日本',
        countryCode: 'jp',
        description: '',
        customTag: null,
        isVisible: true,
        crawledDescription: '',
        image: null,
        createdAt: '2021-05-03T11:36:00Z',
        updatedAt: '2023-06-08T06:18:52Z',
        deletedAt: null,
        datahubKolIdentityId: '124157',
        isVerified: false,
        agencyUser: null,
        kolUser: null,
        cowork: null,
      },
      reportPostID: 0,
      reportKolID: 10579,
      handfilledID: 0,
      postCrawlID: null,
      unavailableAt: null,
      playCount: 284,
      likeCount: 284,
      commentCount: 37,
      shareCount: 0,
      viewCount: 23843,
      engagementCount: 737,
      reachCount: 2393,
      clickCount: 34,
      pcu: 0,
      youTubeImpressionCount: 241000,
      storyReachCount: 0,
      storyClickCount: 0,
      articleViewCount: 0,
      budget: 3000,
      likeValue: 26560,
      commentValue: 219000,
      shareValue: 0,
      viewValue: 32057.6,
      pCUValue: 0,
      mediaValue: 277617.6,
      time: '2023-05-17T08:29:07Z',
      url: 'https://www.youtube.com',
      postType: 'image',
      publishTime: '2023-05-12T11:00:16Z',
      platformType: 0,
      matchedKeywords: null,
      text: '在新的一天開始前，我必須跟你們分享我的秘密武器：雷達手沖咖啡組！這個組合絕對是我每天清晨的救星！🌟',
      isHidden: null,
      uniqueKey: '02473c75-1776-4701-a9df-da175af4bd74',
      blogLink: '',
      postID: '',
      thumbnailURL: demoCooperationSrc1,
      useHandfilled: false,
      isShort: false,
    },
    {
      id: 10581,
      createdAt: '2023-05-17T06:28:56Z',
      updatedAt: '2023-05-17T06:28:56Z',
      deletedAt: null,
      reportID: 'demo',
      report: null,
      UUID: 'KOL-UUID-5',
      kolName: '妞妞每天睡過頭',
      categories: ['Travel'],
      customizedTagsNames: [],
      followerCount: 90207,
      platform: 3,
      platforms: null,
      lastScannedAt: null,
      handfilleds: [
        {
          id: 11041,
          createdAt: '2023-05-18T02:59:19Z',
          updatedAt: '2023-05-18T02:59:19Z',
          deletedAt: null,
          reportKolID: 10581,
          reportKOL: null,
          reportID: 'demo',
          report: null,
          isHidden: null,
          url: 'https://www.instagram.com',
          budget: 6000,
          reachCount: 0,
          clickCount: 0,
          blogLink: null,
          viewCount: 962443,
          pcu: 0,
          impressionCount: 0,
          likeCount: 15965,
          commentCount: 88,
          shareCount: 0,
          useHandfilled: false,
          articleViewCount: null,
        },
        {
          id: 11042,
          createdAt: '2023-05-18T02:59:27Z',
          updatedAt: '2023-05-18T02:59:27Z',
          deletedAt: null,
          reportKolID: 10581,
          reportKOL: null,
          reportID: 'demo',
          report: null,
          isHidden: null,
          url: 'https://www.instagram.com',
          budget: 5000,
          reachCount: 0,
          clickCount: 0,
          blogLink: null,
          viewCount: 756190,
          pcu: 0,
          impressionCount: 0,
          likeCount: 9043,
          commentCount: 81,
          shareCount: 0,
          useHandfilled: false,
          articleViewCount: null,
        },
      ],
      crawlerStatuses: null,
      crawlerTasks: null,
      kol: {
        id: 99470,
        UUID: 'KOL-UUID-5',
        type: 'Person',
        name: ['妞妞每天睡過頭', 'noponopisu2', 'ほのぴすグルメ', ''],
        links: [
          {
            id: 203035,
            kid: 99470,
            url: 'https://twitter.com',
            type: 'Twitter',
            platformType: 5,
            platformID: '',
            crawledID: '1223850362748059648',
            tags: null,
            permanentURL: 'https://twitter.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2021-05-31T12:27:48Z',
            updatedAt: '2023-04-14T10:50:44Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '2239631',
            weight: 1,
            statistic: {
              id: 10814052,
              createdAt: '2023-05-08T09:28:09Z',
              updatedAt: '2023-05-31T10:15:08Z',
              linkID: 203035,
              followerCount: 6011,
              followerCount1M: 6044,
              followerCount3M: null,
              followerCount6M: null,
              commentCount: 0,
              commentCount1M: 0,
              commentCount3M: 7,
              commentCount6M: 29,
              likeCount: 0,
              likeCount1M: 0,
              likeCount3M: 186,
              likeCount6M: 1014,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 12,
              shareCount6M: 35,
              postCount: 0,
              postCount1M: 0,
              postCount3M: 6,
              postCount6M: 32,
              videoPostCount: 0,
              videoPostCount1M: 0,
              videoPostCount3M: 0,
              videoPostCount6M: 0,
              videoViewCount: 0,
              videoViewCount1M: 0,
              videoViewCount3M: 0,
              videoViewCount6M: 0,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 0,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 0,
              followCountGrowth1M: -33,
              followCountGrowth3M: null,
              followCountGrowth6M: null,
              followCountGrowthRate1M: 0,
              followCountGrowthRate3M: 0,
              followCountGrowthRate6M: 0,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 0,
              shortPostCount3M: 0,
              shortPostCount6M: 0,
              shortViewCount: 0,
              shortViewCount1M: 0,
              shortViewCount3M: 0,
              shortViewCount6M: 0,
              score: 0,
              score1M: 0,
              score3M: 0,
              score6M: 0,
              scoreGrowth: 0,
              scoreGrowth1M: 0,
              scoreGrowth3M: 0,
              scoreGrowth6M: 0,
              shortPlayCount1M: 0,
              shortPlayCount3M: 0,
              shortPlayCount6M: 0,
            },
            audience: null,
          },
          {
            id: 203036,
            kid: 99470,
            url: 'https://www.youtube.com',
            type: 'YouTube',
            platformType: 0,
            platformID: '',
            crawledID: 'UCv2K9FVAoQKT2I2IfsfiPOA',
            tags: null,
            permanentURL: 'https://www.youtube.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2021-05-31T12:27:48Z',
            updatedAt: '2023-03-08T03:57:52Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '103871',
            weight: 1,
            statistic: {
              id: 9951052,
              createdAt: '2022-05-23T07:29:39Z',
              updatedAt: '2023-06-07T04:57:59Z',
              linkID: 203036,
              followerCount: 35600,
              followerCount1M: 32800,
              followerCount3M: 29760,
              followerCount6M: 22900,
              commentCount: 0,
              commentCount1M: 0,
              commentCount3M: 0,
              commentCount6M: 36,
              likeCount: 0,
              likeCount1M: 0,
              likeCount3M: 0,
              likeCount6M: 2883,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: 0,
              postCount3M: 0,
              postCount6M: 1,
              videoPostCount: 0,
              videoPostCount1M: 0,
              videoPostCount3M: 0,
              videoPostCount6M: 1,
              videoViewCount: 0,
              videoViewCount1M: 0,
              videoViewCount3M: 0,
              videoViewCount6M: 120824,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 0,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 0,
              followCountGrowth1M: 2800,
              followCountGrowth3M: 5840,
              followCountGrowth6M: 12700,
              followCountGrowthRate1M: 0.085365854,
              followCountGrowthRate3M: 0.19623657,
              followCountGrowthRate6M: 0.55458516,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 0,
              shortPostCount3M: 0,
              shortPostCount6M: 0,
              shortViewCount: 0,
              shortViewCount1M: 0,
              shortViewCount3M: 0,
              shortViewCount6M: 120824,
              score: 82,
              score1M: 82,
              score3M: 82,
              score6M: 82,
              scoreGrowth: 0,
              scoreGrowth1M: 0,
              scoreGrowth3M: 0,
              scoreGrowth6M: 0,
              shortPlayCount1M: 0,
              shortPlayCount3M: 0,
              shortPlayCount6M: 0,
            },
            audience: null,
          },
          {
            id: 203037,
            kid: 99470,
            url: 'https://www.instagram.com',
            type: 'IG',
            platformType: 3,
            platformID: '',
            crawledID: '21263849910',
            tags: null,
            permanentURL: 'https://www.instagram.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2021-05-31T12:27:48Z',
            updatedAt: '2023-03-08T03:51:36Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '95013',
            weight: 1,
            statistic: {
              id: 9876585,
              createdAt: '2021-06-23T05:55:45Z',
              updatedAt: '2023-06-08T05:37:55Z',
              linkID: 203037,
              followerCount: 90207,
              followerCount1M: 85435,
              followerCount3M: 72631,
              followerCount6M: 66314,
              commentCount: 0,
              commentCount1M: 1169,
              commentCount3M: 2884,
              commentCount6M: 6063,
              likeCount: 0,
              likeCount1M: 100166,
              likeCount3M: 262936,
              likeCount6M: 644770,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: 29,
              postCount3M: 64,
              postCount6M: 138,
              videoPostCount: 0,
              videoPostCount1M: 20,
              videoPostCount3M: 37,
              videoPostCount6M: 63,
              videoViewCount: 0,
              videoViewCount1M: 11476139,
              videoViewCount3M: 37040468,
              videoViewCount6M: 81378005,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 3,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 42590,
              followCountGrowth1M: 4772,
              followCountGrowth3M: 17576,
              followCountGrowth6M: 23893,
              followCountGrowthRate1M: 0.05585533,
              followCountGrowthRate3M: 0.24199033,
              followCountGrowthRate6M: 0.360301,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 20,
              shortPostCount3M: 37,
              shortPostCount6M: 63,
              shortViewCount: 0,
              shortViewCount1M: 721591,
              shortViewCount3M: 2190307,
              shortViewCount6M: 4522435,
              score: 91,
              score1M: 91,
              score3M: 91,
              score6M: 91,
              scoreGrowth: 0,
              scoreGrowth1M: 0,
              scoreGrowth3M: 0,
              scoreGrowth6M: 0,
              shortPlayCount1M: 11476139,
              shortPlayCount3M: 37040468,
              shortPlayCount6M: 81378005,
            },
            audience: null,
          },
          {
            id: 203038,
            kid: 99470,
            url: 'https://www.tiktok.com/@noponopisu2',
            type: 'TikTok',
            platformType: 4,
            platformID: '',
            crawledID: '6714986900383466497',
            tags: null,
            permanentURL: 'https://www.tiktok.com/@6714986900383466497',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2021-05-31T12:27:48Z',
            updatedAt: '2023-03-08T03:51:24Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '112854',
            weight: 0,
            statistic: {
              id: 9896479,
              createdAt: '2021-07-14T03:00:13Z',
              updatedAt: '2021-07-13T10:19:38Z',
              linkID: 203038,
              followerCount: 729800,
              followerCount1M: 729800,
              followerCount3M: null,
              followerCount6M: null,
              commentCount: 0,
              commentCount1M: null,
              commentCount3M: 0,
              commentCount6M: 0,
              likeCount: 0,
              likeCount1M: null,
              likeCount3M: 0,
              likeCount6M: 0,
              shareCount: 0,
              shareCount1M: null,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: null,
              postCount3M: 0,
              postCount6M: 0,
              videoPostCount: 0,
              videoPostCount1M: null,
              videoPostCount3M: 0,
              videoPostCount6M: 0,
              videoViewCount: 0,
              videoViewCount1M: null,
              videoViewCount3M: 0,
              videoViewCount6M: 0,
              livePostCount: null,
              livePostCount1M: null,
              livePostCount3M: null,
              livePostCount6M: null,
              liveViewCount: null,
              liveViewCount1M: null,
              liveViewCount3M: null,
              liveViewCount6M: null,
              followCountGrowth1M: null,
              followCountGrowth3M: null,
              followCountGrowth6M: null,
              followCountGrowthRate1M: null,
              followCountGrowthRate3M: null,
              followCountGrowthRate6M: null,
              link: null,
              shortPostCount: null,
              shortPostCount1M: null,
              shortPostCount3M: null,
              shortPostCount6M: null,
              shortViewCount: null,
              shortViewCount1M: null,
              shortViewCount3M: null,
              shortViewCount6M: null,
              score: null,
              score1M: null,
              score3M: null,
              score6M: null,
              scoreGrowth: null,
              scoreGrowth1M: null,
              scoreGrowth3M: null,
              scoreGrowth6M: null,
              shortPlayCount1M: null,
              shortPlayCount3M: null,
              shortPlayCount6M: null,
            },
            audience: null,
          },
        ],
        gender: 'Female',
        property: ['Travel'],
        specialty: [],
        service: '短影音',
        location: '日本',
        countryCode: 'jp',
        description: '',
        customTag: null,
        isVisible: true,
        crawledDescription:
          '無所属です🌸\n毎月いろんなところに格安旅しています！\nNEXT✈︎6月韓国チェジュ島/釜山、7月バンコク韓国、8月中国予定\n🎬TikTok750K short movie creator\n札幌育ち東京住み\n💌honopisu2020@gmail.com\nマネージャーが返信します！',
        image: null,
        createdAt: '2021-05-31T12:27:48Z',
        updatedAt: '2023-06-09T01:05:32Z',
        deletedAt: null,
        datahubKolIdentityId: '31024',
        isVerified: false,
        agencyUser: null,
        kolUser: null,
        cowork: null,
      },
      reportPostID: 0,
      reportKolID: 10581,
      handfilledID: 0,
      postCrawlID: null,
      unavailableAt: null,
      playCount: 0,
      likeCount: 384,
      commentCount: 67,
      shareCount: 2,
      viewCount: 0,
      engagementCount: 53,
      reachCount: 5424,
      clickCount: 27,
      pcu: 0,
      youTubeImpressionCount: 0,
      storyReachCount: 0,
      storyClickCount: 0,
      articleViewCount: 0,
      budget: 2000,
      likeValue: 0,
      commentValue: 17521.8,
      shareValue: 0,
      viewValue: 0,
      pCUValue: 0,
      mediaValue: 17521.8,
      time: '2023-05-17T08:38:05Z',
      url: 'https://www.instagram.com',
      postType: 'image',
      publishTime: '2023-05-01T14:12:50Z',
      platformType: 3,
      matchedKeywords: null,
      text: '咖啡愛好者們，讓我們一起來探索雷達手沖咖啡的魅力吧！這是一種獨特而迷人的咖啡沖煮方式，讓我們一起揭開它的神秘面紗。',
      isHidden: null,
      uniqueKey: '3b7a160a-a26b-4104-a8ae-a37ace486ed3',
      blogLink: '',
      postID: '',
      thumbnailURL: demoCooperationSrc2,
      useHandfilled: false,
      isShort: false,
    },
    {
      id: 10581,
      createdAt: '2023-05-17T06:28:56Z',
      updatedAt: '2023-05-17T06:28:56Z',
      deletedAt: null,
      reportID: 'demo',
      report: null,
      UUID: 'KOL-UUID-5',
      kolName: '妞妞每天睡過頭',
      categories: ['Travel'],
      customizedTagsNames: ['Food'],
      followerCount: 90207,
      platform: 3,
      platforms: null,
      lastScannedAt: null,
      handfilleds: [
        {
          id: 11041,
          createdAt: '2023-05-18T02:59:19Z',
          updatedAt: '2023-05-18T02:59:19Z',
          deletedAt: null,
          reportKolID: 10581,
          reportKOL: null,
          reportID: 'demo',
          report: null,
          isHidden: null,
          url: 'https://www.instagram.com',
          budget: 6000,
          reachCount: 0,
          clickCount: 0,
          blogLink: null,
          viewCount: 962443,
          pcu: 0,
          impressionCount: 0,
          likeCount: 15965,
          commentCount: 88,
          shareCount: 0,
          useHandfilled: false,
          articleViewCount: null,
        },
        {
          id: 11042,
          createdAt: '2023-05-18T02:59:27Z',
          updatedAt: '2023-05-18T02:59:27Z',
          deletedAt: null,
          reportKolID: 10581,
          reportKOL: null,
          reportID: 'demo',
          report: null,
          isHidden: null,
          url: 'https://www.instagram.com',
          budget: 5000,
          reachCount: 0,
          clickCount: 0,
          blogLink: null,
          viewCount: 756190,
          pcu: 0,
          impressionCount: 0,
          likeCount: 9043,
          commentCount: 81,
          shareCount: 0,
          useHandfilled: false,
          articleViewCount: null,
        },
      ],
      crawlerStatuses: null,
      crawlerTasks: null,
      kol: {
        id: 99470,
        UUID: 'KOL-UUID-5',
        type: 'Person',
        name: ['妞妞每天睡過頭', 'noponopisu2', 'ほのぴすグルメ', ''],
        links: [
          {
            id: 203035,
            kid: 99470,
            url: 'https://twitter.com',
            type: 'Twitter',
            platformType: 5,
            platformID: '',
            crawledID: '1223850362748059648',
            tags: null,
            permanentURL: 'https://twitter.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2021-05-31T12:27:48Z',
            updatedAt: '2023-04-14T10:50:44Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '2239631',
            weight: 1,
            statistic: {
              id: 10814052,
              createdAt: '2023-05-08T09:28:09Z',
              updatedAt: '2023-05-31T10:15:08Z',
              linkID: 203035,
              followerCount: 6011,
              followerCount1M: 6044,
              followerCount3M: null,
              followerCount6M: null,
              commentCount: 0,
              commentCount1M: 0,
              commentCount3M: 7,
              commentCount6M: 29,
              likeCount: 0,
              likeCount1M: 0,
              likeCount3M: 186,
              likeCount6M: 1014,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 12,
              shareCount6M: 35,
              postCount: 0,
              postCount1M: 0,
              postCount3M: 6,
              postCount6M: 32,
              videoPostCount: 0,
              videoPostCount1M: 0,
              videoPostCount3M: 0,
              videoPostCount6M: 0,
              videoViewCount: 0,
              videoViewCount1M: 0,
              videoViewCount3M: 0,
              videoViewCount6M: 0,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 0,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 0,
              followCountGrowth1M: -33,
              followCountGrowth3M: null,
              followCountGrowth6M: null,
              followCountGrowthRate1M: 0,
              followCountGrowthRate3M: 0,
              followCountGrowthRate6M: 0,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 0,
              shortPostCount3M: 0,
              shortPostCount6M: 0,
              shortViewCount: 0,
              shortViewCount1M: 0,
              shortViewCount3M: 0,
              shortViewCount6M: 0,
              score: 0,
              score1M: 0,
              score3M: 0,
              score6M: 0,
              scoreGrowth: 0,
              scoreGrowth1M: 0,
              scoreGrowth3M: 0,
              scoreGrowth6M: 0,
              shortPlayCount1M: 0,
              shortPlayCount3M: 0,
              shortPlayCount6M: 0,
            },
            audience: null,
          },
          {
            id: 203036,
            kid: 99470,
            url: 'https://www.youtube.com',
            type: 'YouTube',
            platformType: 0,
            platformID: '',
            crawledID: 'UCv2K9FVAoQKT2I2IfsfiPOA',
            tags: null,
            permanentURL: 'https://www.youtube.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2021-05-31T12:27:48Z',
            updatedAt: '2023-03-08T03:57:52Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '103871',
            weight: 1,
            statistic: {
              id: 9951052,
              createdAt: '2022-05-23T07:29:39Z',
              updatedAt: '2023-06-07T04:57:59Z',
              linkID: 203036,
              followerCount: 35600,
              followerCount1M: 32800,
              followerCount3M: 29760,
              followerCount6M: 22900,
              commentCount: 0,
              commentCount1M: 0,
              commentCount3M: 0,
              commentCount6M: 36,
              likeCount: 0,
              likeCount1M: 0,
              likeCount3M: 0,
              likeCount6M: 2883,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: 0,
              postCount3M: 0,
              postCount6M: 1,
              videoPostCount: 0,
              videoPostCount1M: 0,
              videoPostCount3M: 0,
              videoPostCount6M: 1,
              videoViewCount: 0,
              videoViewCount1M: 0,
              videoViewCount3M: 0,
              videoViewCount6M: 120824,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 0,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 0,
              followCountGrowth1M: 2800,
              followCountGrowth3M: 5840,
              followCountGrowth6M: 12700,
              followCountGrowthRate1M: 0.085365854,
              followCountGrowthRate3M: 0.19623657,
              followCountGrowthRate6M: 0.55458516,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 0,
              shortPostCount3M: 0,
              shortPostCount6M: 0,
              shortViewCount: 0,
              shortViewCount1M: 0,
              shortViewCount3M: 0,
              shortViewCount6M: 120824,
              score: 82,
              score1M: 82,
              score3M: 82,
              score6M: 82,
              scoreGrowth: 0,
              scoreGrowth1M: 0,
              scoreGrowth3M: 0,
              scoreGrowth6M: 0,
              shortPlayCount1M: 0,
              shortPlayCount3M: 0,
              shortPlayCount6M: 0,
            },
            audience: null,
          },
          {
            id: 203037,
            kid: 99470,
            url: 'https://www.instagram.com',
            type: 'IG',
            platformType: 3,
            platformID: '',
            crawledID: '21263849910',
            tags: null,
            permanentURL: 'https://www.instagram.com',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2021-05-31T12:27:48Z',
            updatedAt: '2023-03-08T03:51:36Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '95013',
            weight: 1,
            statistic: {
              id: 9876585,
              createdAt: '2021-06-23T05:55:45Z',
              updatedAt: '2023-06-08T05:37:55Z',
              linkID: 203037,
              followerCount: 90207,
              followerCount1M: 85435,
              followerCount3M: 72631,
              followerCount6M: 66314,
              commentCount: 0,
              commentCount1M: 1169,
              commentCount3M: 2884,
              commentCount6M: 6063,
              likeCount: 0,
              likeCount1M: 100166,
              likeCount3M: 262936,
              likeCount6M: 644770,
              shareCount: 0,
              shareCount1M: 0,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: 29,
              postCount3M: 64,
              postCount6M: 138,
              videoPostCount: 0,
              videoPostCount1M: 20,
              videoPostCount3M: 37,
              videoPostCount6M: 63,
              videoViewCount: 0,
              videoViewCount1M: 11476139,
              videoViewCount3M: 37040468,
              videoViewCount6M: 81378005,
              livePostCount: 0,
              livePostCount1M: 0,
              livePostCount3M: 0,
              livePostCount6M: 3,
              liveViewCount: 0,
              liveViewCount1M: 0,
              liveViewCount3M: 0,
              liveViewCount6M: 42590,
              followCountGrowth1M: 4772,
              followCountGrowth3M: 17576,
              followCountGrowth6M: 23893,
              followCountGrowthRate1M: 0.05585533,
              followCountGrowthRate3M: 0.24199033,
              followCountGrowthRate6M: 0.360301,
              link: null,
              shortPostCount: 0,
              shortPostCount1M: 20,
              shortPostCount3M: 37,
              shortPostCount6M: 63,
              shortViewCount: 0,
              shortViewCount1M: 721591,
              shortViewCount3M: 2190307,
              shortViewCount6M: 4522435,
              score: 91,
              score1M: 91,
              score3M: 91,
              score6M: 91,
              scoreGrowth: 0,
              scoreGrowth1M: 0,
              scoreGrowth3M: 0,
              scoreGrowth6M: 0,
              shortPlayCount1M: 11476139,
              shortPlayCount3M: 37040468,
              shortPlayCount6M: 81378005,
            },
            audience: null,
          },
          {
            id: 203038,
            kid: 99470,
            url: 'https://www.tiktok.com/@noponopisu2',
            type: 'TikTok',
            platformType: 4,
            platformID: '',
            crawledID: '6714986900383466497',
            tags: null,
            permanentURL: 'https://www.tiktok.com/@6714986900383466497',
            source: null,
            crawlerTasks: null,
            latestCrawlerTask: null,
            image: null,
            createdAt: '2021-05-31T12:27:48Z',
            updatedAt: '2023-03-08T03:51:24Z',
            deletedAt: null,
            datahubKolUrlIdentityId: '112854',
            weight: 0,
            statistic: {
              id: 9896479,
              createdAt: '2021-07-14T03:00:13Z',
              updatedAt: '2021-07-13T10:19:38Z',
              linkID: 203038,
              followerCount: 729800,
              followerCount1M: 729800,
              followerCount3M: null,
              followerCount6M: null,
              commentCount: 0,
              commentCount1M: null,
              commentCount3M: 0,
              commentCount6M: 0,
              likeCount: 0,
              likeCount1M: null,
              likeCount3M: 0,
              likeCount6M: 0,
              shareCount: 0,
              shareCount1M: null,
              shareCount3M: 0,
              shareCount6M: 0,
              postCount: 0,
              postCount1M: null,
              postCount3M: 0,
              postCount6M: 0,
              videoPostCount: 0,
              videoPostCount1M: null,
              videoPostCount3M: 0,
              videoPostCount6M: 0,
              videoViewCount: 0,
              videoViewCount1M: null,
              videoViewCount3M: 0,
              videoViewCount6M: 0,
              livePostCount: null,
              livePostCount1M: null,
              livePostCount3M: null,
              livePostCount6M: null,
              liveViewCount: null,
              liveViewCount1M: null,
              liveViewCount3M: null,
              liveViewCount6M: null,
              followCountGrowth1M: null,
              followCountGrowth3M: null,
              followCountGrowth6M: null,
              followCountGrowthRate1M: null,
              followCountGrowthRate3M: null,
              followCountGrowthRate6M: null,
              link: null,
              shortPostCount: null,
              shortPostCount1M: null,
              shortPostCount3M: null,
              shortPostCount6M: null,
              shortViewCount: null,
              shortViewCount1M: null,
              shortViewCount3M: null,
              shortViewCount6M: null,
              score: null,
              score1M: null,
              score3M: null,
              score6M: null,
              scoreGrowth: null,
              scoreGrowth1M: null,
              scoreGrowth3M: null,
              scoreGrowth6M: null,
              shortPlayCount1M: null,
              shortPlayCount3M: null,
              shortPlayCount6M: null,
            },
            audience: null,
          },
        ],
        gender: 'Female',
        property: ['Travel'],
        specialty: [],
        service: '短影音',
        location: '日本',
        countryCode: 'jp',
        description: '',
        customTag: null,
        isVisible: true,
        crawledDescription:
          '無所属です🌸\n毎月いろんなところに格安旅しています！\nNEXT✈︎6月韓国チェジュ島/釜山、7月バンコク韓国、8月中国予定\n🎬TikTok750K short movie creator\n札幌育ち東京住み\n💌honopisu2020@gmail.com\nマネージャーが返信します！',
        image: null,
        createdAt: '2021-05-31T12:27:48Z',
        updatedAt: '2023-06-09T01:05:32Z',
        deletedAt: null,
        datahubKolIdentityId: '31024',
        isVerified: false,
        agencyUser: null,
        kolUser: null,
        cowork: null,
      },
      reportPostID: 0,
      reportKolID: 10581,
      handfilledID: 0,
      postCrawlID: null,
      unavailableAt: null,
      playCount: 555878,
      likeCount: 360,
      commentCount: 89,
      shareCount: 0,
      viewCount: 555878,
      engagementCount: 24,
      reachCount: 1393,
      clickCount: 30,
      pcu: 0,
      youTubeImpressionCount: 0,
      storyReachCount: 0,
      storyClickCount: 0,
      articleViewCount: 0,
      budget: 2000,
      likeValue: 0,
      commentValue: 7934.4004,
      shareValue: 0,
      viewValue: 778229.2,
      pCUValue: 0,
      mediaValue: 786163.56,
      time: '2023-05-17T08:38:05Z',
      url: 'https://www.instagram.com',
      postType: 'unknown',
      publishTime: '2023-04-25T10:30:00Z',
      platformType: 3,
      matchedKeywords: null,
      text: '上一篇跟大家分享的雷達手沖咖啡，經過我的爭取，廠商決定提供更新的優惠給大家！ 已經購買的大家也不用擔心，都會擁有最新的優惠哦！！',
      isHidden: null,
      uniqueKey: '77bd0906-e1b1-4218-a2a2-1b91639ba8a9',
      blogLink: '',
      postID: '',
      thumbnailURL: demoCooperationSrc3,
      useHandfilled: false,
      isShort: true,
    },
  ],
  handfilleds: null,
  guestVisibility: null,
  crawlerJobs: null,
  crawlerJobReport: {
    reportId: 0,
    crawlerJobId: 0,
    isRead: null,
    triggerUserId: 0,
    triggerAt: '0001-01-01T00:00:00Z',
    reportType: '',
    isNotify: null,
    crawlerJob: null,
    report: null,
    triggerUser: {
      id: 0,
      createdAt: '0001-01-01T00:00:00Z',
      updatedAt: '0001-01-01T00:00:00Z',
      deletedAt: null,
      billingToUser: null,
      socialAccount: null,
      radarContractAcceptions: null,
      agencyRelation: null,
      kolRelations: null,
      kolRadarMainContract: null,
      agencyRadarMainContract: null,
      authAssignments: null,
      guestAssignments: null,
      currentWorkspaceID: 0,
      type: '',
      phone: '',
      isEnable: null,
      isVerified: null,
      group: null,
      company: '',
      jobTitle: '',
      industry: '',
      kolID: null,
      language: '',
      country: '',
      companyPhone: '',
      companyAddress: '',
      incompleteRegistration: false,
      commissionPercentage: '0',
      accountingInfo: null,
      agentContactInfo: null,
      userCustomizedFunction: null,
      deprecatedMongoID: '',
      kOLs: null,
      targetRadarMainContract: null,
      targetRadarMainContractAcception: null,
      detailInfo: null,
      roleInWorkspace: null,
    },
  },
  latestTriggerCrawlerJobReport: {
    reportId: 'demo',
    crawlerJobId: 511322,
    isRead: true,
    triggerUserId: 39022,
    triggerAt: '2023-05-17T08:24:57Z',
    reportType: 'deep_report',
    isNotify: true,
    crawlerJob: {
      id: 511322,
      createdAt: '2023-05-17T08:24:58Z',
      updatedAt: '2023-05-17T08:39:04Z',
      deletedAt: null,
      uuid: 'bcc26e07-e1e5-4a98-9501-8e5032f8e8de',
      tasks: null,
      status: 'succeeded',
      crawlerJobReport: {
        reportId: 0,
        crawlerJobId: 0,
        isRead: null,
        triggerUserId: 0,
        triggerAt: '0001-01-01T00:00:00Z',
        reportType: '',
        isNotify: null,
        crawlerJob: null,
        report: null,
        triggerUser: {
          id: 0,
          createdAt: '0001-01-01T00:00:00Z',
          updatedAt: '0001-01-01T00:00:00Z',
          deletedAt: null,
          billingToUser: null,
          socialAccount: null,
          radarContractAcceptions: null,
          agencyRelation: null,
          kolRelations: null,
          kolRadarMainContract: null,
          agencyRadarMainContract: null,
          authAssignments: null,
          guestAssignments: null,
          currentWorkspaceID: 0,
          type: '',
          phone: '',
          isEnable: null,
          isVerified: null,
          group: null,
          company: '',
          jobTitle: '',
          industry: '',
          kolID: null,
          language: '',
          country: '',
          companyPhone: '',
          companyAddress: '',
          incompleteRegistration: false,
          commissionPercentage: '0',
          accountingInfo: null,
          agentContactInfo: null,
          userCustomizedFunction: null,
          deprecatedMongoID: '',
          kOLs: null,
          targetRadarMainContract: null,
          targetRadarMainContractAcception: null,
          detailInfo: null,
          roleInWorkspace: null,
        },
      },
      crawlerJobKol: {
        kolUUID: '',
        crawlerJobId: 0,
        triggerAt: '0001-01-01T00:00:00Z',
        crawlerJob: null,
      },
      subscriptions: null,
      meta: {
        kolIds: null,
        kolUuids: null,
        datahubRequestIds: [
          '767acd07-35ba-4163-98e3-e407a808dff7',
          '0f07af2e-7763-49f9-b8d6-e5766b43e76c',
        ],
        crawlerIdToCrawlerTaskUuidMapping: {
          '1240261626': '9477e4d3-1b97-4b24-bf08-fdeab5a57a59',
          '1707555709': '08d3ad04-9e7f-4d85-8166-813a0ca4427f',
          '2108944174': 'a2f9bc3a-1f31-4401-818b-55370cbe6f25',
          '4451451877': 'ad4a8472-d960-4e18-a32a-b05fef374d8c',
          '5955606049': 'f3fd7990-7264-49e3-ae64-96a81fa166d7',
          '21263849910': 'b1de3af0-a286-4e7e-89cc-12a69029f122',
          '21982141270': 'e5532bed-24b0-48a4-a0bb-e4e8b7c71f79',
          uC3UkKUUY7Nr3HhhhoFQxw: 'a3ead4db-b54a-4982-b959-cab01ab4ab25',
          uCFEF5lVqJGV190yU5GOKAw: '9ba74aec-b23c-475c-a35d-8145df0856bd',
          uCQkSmfjLdzcKA95jJwAYbSA: '5d6367b6-aceb-494b-b725-64c479ce0d2a',
          uCgi1YyJdI4avyNy1ZPOJNQ: 'e567b1f7-1a02-4e44-881f-1970b0666929',
          uCkmQx9zSwidPupChljGjwGA: '97082d33-fab3-45f8-9941-cfd64f48b137',
          uCv2K9FVAoQKT2I2IfsfiPOA: 'd561faf4-a7db-445d-a305-1a71c12d7cb9',
        },
      },
    },
    report: null,
    triggerUser: {
      id: 0,
      createdAt: '0001-01-01T00:00:00Z',
      updatedAt: '0001-01-01T00:00:00Z',
      deletedAt: null,
      billingToUser: null,
      socialAccount: null,
      radarContractAcceptions: null,
      agencyRelation: null,
      kolRelations: null,
      kolRadarMainContract: null,
      agencyRadarMainContract: null,
      authAssignments: null,
      guestAssignments: null,
      currentWorkspaceID: 0,
      type: '',
      phone: '',
      isEnable: null,
      isVerified: null,
      group: null,
      company: '',
      jobTitle: '',
      industry: '',
      kolID: null,
      language: '',
      country: '',
      companyPhone: '',
      companyAddress: '',
      incompleteRegistration: false,
      commissionPercentage: '0',
      accountingInfo: null,
      agentContactInfo: null,
      userCustomizedFunction: null,
      deprecatedMongoID: '',
      kOLs: null,
      targetRadarMainContract: null,
      targetRadarMainContractAcception: null,
      detailInfo: null,
      roleInWorkspace: null,
    },
  },
  latestCrawlerJob: {
    id: 511322,
    createdAt: '2023-05-17T08:24:58Z',
    updatedAt: '2023-05-17T08:39:04Z',
    deletedAt: null,
    uuid: 'bcc26e07-e1e5-4a98-9501-8e5032f8e8de',
    tasks: null,
    status: 'succeeded',
    crawlerJobReport: {
      reportId: 0,
      crawlerJobId: 0,
      isRead: null,
      triggerUserId: 0,
      triggerAt: '0001-01-01T00:00:00Z',
      reportType: '',
      isNotify: null,
      crawlerJob: null,
      report: null,
      triggerUser: {
        id: 0,
        createdAt: '0001-01-01T00:00:00Z',
        updatedAt: '0001-01-01T00:00:00Z',
        deletedAt: null,
        billingToUser: null,
        socialAccount: null,
        radarContractAcceptions: null,
        agencyRelation: null,
        kolRelations: null,
        kolRadarMainContract: null,
        agencyRadarMainContract: null,
        authAssignments: null,
        guestAssignments: null,
        currentWorkspaceID: 0,
        type: '',
        phone: '',
        isEnable: null,
        isVerified: null,
        group: null,
        company: '',
        jobTitle: '',
        industry: '',
        kolID: null,
        language: '',
        country: '',
        companyPhone: '',
        companyAddress: '',
        incompleteRegistration: false,
        commissionPercentage: '0',
        accountingInfo: null,
        agentContactInfo: null,
        userCustomizedFunction: null,
        deprecatedMongoID: '',
        kOLs: null,
        targetRadarMainContract: null,
        targetRadarMainContractAcception: null,
        detailInfo: null,
        roleInWorkspace: null,
      },
    },
    crawlerJobKol: {
      kolUUID: '',
      crawlerJobId: 0,
      triggerAt: '0001-01-01T00:00:00Z',
      crawlerJob: null,
    },
    subscriptions: null,
    meta: {
      kolIds: null,
      kolUuids: null,
      datahubRequestIds: [
        '767acd07-35ba-4163-98e3-e407a808dff7',
        '0f07af2e-7763-49f9-b8d6-e5766b43e76c',
      ],
      crawlerIdToCrawlerTaskUuidMapping: {
        '1240261626': '9477e4d3-1b97-4b24-bf08-fdeab5a57a59',
        '1707555709': '08d3ad04-9e7f-4d85-8166-813a0ca4427f',
        '2108944174': 'a2f9bc3a-1f31-4401-818b-55370cbe6f25',
        '4451451877': 'ad4a8472-d960-4e18-a32a-b05fef374d8c',
        '5955606049': 'f3fd7990-7264-49e3-ae64-96a81fa166d7',
        '21263849910': 'b1de3af0-a286-4e7e-89cc-12a69029f122',
        '21982141270': 'e5532bed-24b0-48a4-a0bb-e4e8b7c71f79',
        uC3UkKUUY7Nr3HhhhoFQxw: 'a3ead4db-b54a-4982-b959-cab01ab4ab25',
        uCFEF5lVqJGV190yU5GOKAw: '9ba74aec-b23c-475c-a35d-8145df0856bd',
        uCQkSmfjLdzcKA95jJwAYbSA: '5d6367b6-aceb-494b-b725-64c479ce0d2a',
        uCgi1YyJdI4avyNy1ZPOJNQ: 'e567b1f7-1a02-4e44-881f-1970b0666929',
        uCkmQx9zSwidPupChljGjwGA: '97082d33-fab3-45f8-9941-cfd64f48b137',
        uCv2K9FVAoQKT2I2IfsfiPOA: 'd561faf4-a7db-445d-a305-1a71c12d7cb9',
      },
    },
    isRead: true,
  },
}
const demoStatistics: Statistics = {
  statisticsAll: {
    viewRate: 0.2212617739998868,
    engagementRate: 0.007499527068439082,
    type: 'all',
  },
  statisticsCustom: {},
}
const demoTerms: FetchTermsResponse = [
  {
    ID: 7397,
    Term: '好喝',
    IsInclude: true,
  },
  {
    ID: 7398,
    Term: '清楚',
    IsInclude: true,
  },
  {
    ID: 7399,
    Term: '誠實',
    IsInclude: true,
  },
  {
    ID: 7400,
    Term: '品質',
    IsInclude: true,
  },
  {
    ID: 7401,
    Term: '美味',
    IsInclude: true,
  },
]
const demoComments: FetchCommentsResponse = [
  {
    Comment: Array(135).fill('好喝').join(''),
  },
  {
    Comment: Array(45).fill('清楚').join(''),
  },
  {
    Comment: Array(42).fill('誠實').join(''),
  },
  {
    Comment: Array(25).fill('品質').join(''),
  },
  {
    Comment: Array(23).fill('美味').join(''),
  },
]
const demoTermsFrequency: FetchTermsFrequencyResponse = [
  {
    Text: '咖啡',
    Value: 1,
  },
  {
    Text: '手沖',
    Value: 10,
  },
  {
    Text: '組',
    Value: 10,
  },
  {
    Text: '推薦',
    Value: 1,
  },
  {
    Text: '品質',
    Value: 25,
  },
  {
    Text: '好喝',
    Value: 135,
  },
  {
    Text: '香氣',
    Value: 3,
  },
  {
    Text: '自家',
    Value: 1,
  },
  {
    Text: '咖啡豆',
    Value: 3,
  },
  {
    Text: '磨豆機',
    Value: 6,
  },
  {
    Text: '口感',
    Value: 2,
  },
  {
    Text: '美味',
    Value: 23,
  },
  {
    Text: '享受',
    Value: 5,
  },
  {
    Text: '優惠',
    Value: 1,
  },
  {
    Text: '折扣',
    Value: 2,
  },
  {
    Text: '咖啡愛好者',
    Value: 1,
  },
  {
    Text: '開始',
    Value: 2,
  },
  {
    Text: '早晨',
    Value: 1,
  },
  {
    Text: '喜歡',
    Value: 19,
  },
  {
    Text: '精美',
    Value: 1,
  },
  {
    Text: '濃郁',
    Value: 3,
  },
  {
    Text: '熱愛',
    Value: 1,
  },
  {
    Text: '清晨',
    Value: 1,
  },
  {
    Text: '興奮',
    Value: 3,
  },
  {
    Text: '無限可能性',
    Value: 1,
  },
  {
    Text: '早安',
    Value: 3,
  },
  {
    Text: '提神',
    Value: 1,
  },
  {
    Text: '品味',
    Value: 3,
  },
  {
    Text: '愛上',
    Value: 64,
  },
  {
    Text: '藝術',
    Value: 1,
  },
  {
    Text: '開始',
    Value: 1,
  },
  {
    Text: '心情愉悅',
    Value: 1,
  },
  {
    Text: '體驗',
    Value: 3,
  },
  {
    Text: '自家沖泡',
    Value: 1,
  },
  {
    Text: '每天',
    Value: 1,
  },
  {
    Text: '品質',
    Value: 1,
  },
  {
    Text: '香醇',
    Value: 21,
  },
  {
    Text: '新鮮磨碎',
    Value: 5,
  },
  {
    Text: '清楚',
    Value: 45,
  },
  {
    Text: '真實',
    Value: 1,
  },
  {
    Text: '分享',
    Value: 1,
  },
  {
    Text: '價格',
    Value: 2,
  },
  {
    Text: '客觀',
    Value: 1,
  },
  {
    Text: '操作簡單',
    Value: 1,
  },
  {
    Text: '誠實',
    Value: 42,
  },
  {
    Text: '中立',
    Value: 2,
  },
]

const demoCrawlerStatus: FetchReportCrawlerStatusResponse = []
const demoFetchCommentBoardResponse: unknown = {
  page: 1,
  perPage: 10,
  total: 38,
  totalPage: 4,
  data: [
    {
      commentId: 'UgzW4igqcTVYk2neZy14AaABAg',
      commentType: 'yt',
      postId: 'gjSyIY12C0s',
      userName: 'Messenger 1',
      pinned: false,
      comment: 'Nice sharing 😀 👍',
      sentiment: 'positive',
    },
    {
      commentId: 'Ugy4Xvla2ee5L_mkNXd4AaABAg',
      commentType: 'yt',
      postId: 'gjSyIY12C0s',
      userName: 'Messenger 2',
      pinned: false,
      comment: '👍👍👍',
      sentiment: 'positive',
    },
    {
      commentId: 'UgyWcdBTSRvKAEZKP4R4AaABAg',
      commentType: 'yt',
      postId: 'gjSyIY12C0s',
      userName: 'Messenger 3',
      pinned: false,
      comment: '很棒的分享！',
      sentiment: 'positive',
    },
    {
      commentId: 'UgzvhKqHQMEMHbeAJY54AaABAg',
      commentType: 'yt',
      postId: 'gjSyIY12C0s',
      userName: 'Messenger 4',
      pinned: true,
      comment: '好喜歡自然的介紹，感覺真的很好玩誒～',
      sentiment: 'positive',
    },
    {
      commentId: 'UgzvhKqHQMEMHbeAJY54AaABAg.9k4lhySFiDs9k79vhONFP_',
      commentType: 'yt',
      postId: 'gjSyIY12C0s',
      userName: 'Messenger 5',
      pinned: false,
      comment: '有機會一定要來打卡見識見識！(￣▽￣)"',
      sentiment: 'positive',
    },
    {
      commentId: 'UgxkoNn_XHIjqjkT5LN4AaABAg',
      commentType: 'yt',
      postId: 'gjSyIY12C0s',
      userName: 'Messenger 6',
      pinned: true,
      comment: '還可以看到製豆的過程',
      sentiment: 'positive',
    },
    {
      commentId: 'Ugzjra1jlDTKcBd9xhZ4AaABAg',
      commentType: 'yt',
      postId: 'gjSyIY12C0s',
      userName: 'Messenger 7',
      pinned: false,
      comment: '實地參觀咖啡園並用餐+品嘗真不錯呢',
      sentiment: 'positive',
    },
    {
      commentId: 'Ugzjra1jlDTKcBd9xhZ4AaABAg.9k3EvqSVKKa9k79f77u2RN',
      commentType: 'yt',
      postId: 'gjSyIY12C0s',
      userName: 'Messenger 8',
      pinned: true,
      comment: '我偶爾喜歡買紙本券做紀念哈哈哈',
      sentiment: 'positive',
    },
    {
      commentId: 'aaaaa',
      commentType: 'yt',
      postId: 'gjSyIY12C0s',
      userName: 'Messenger 9',
      pinned: true,
      comment: '想問一下咖啡壺的容量是多少？',
      sentiment: 'neutral',
    },
    {
      commentId: 'bbbbb',
      commentType: 'yt',
      postId: 'gjSyIY12C0s',
      userName: 'Messenger 10',
      pinned: true,
      comment: '請問這個手沖咖啡組可以在哪裡購買到？',
      sentiment: 'neutral',
    },
    {
      commentId: 'ccccc',
      commentType: 'yt',
      postId: 'gjSyIY12C0s',
      userName: 'Messenger 11',
      pinned: false,
      comment: '想知道品質如何，有人使用過嗎？',
      sentiment: 'neutral',
    },
    {
      commentId: 'ddddd',
      commentType: 'yt',
      postId: 'gjSyIY12C0s',
      userName: 'Messenger 12',
      pinned: false,
      comment: '這個組合的價格有點高，不太划算。',
      sentiment: 'negative',
    },
    {
      commentId: 'eeeee',
      commentType: 'yt',
      postId: 'gjSyIY12C0s',
      userName: 'Messenger 13',
      pinned: false,
      comment: '看起來很麻煩，不知道操作起來會不會很困難。',
      sentiment: 'negative',
    },
    {
      commentId: 'fffff',
      commentType: 'yt',
      postId: 'gjSyIY12C0s',
      userName: 'Messenger 14',
      pinned: false,
      comment: '感覺這只是一個過度商業化的推廣，不可靠。',
      sentiment: 'negative',
    },
    {
      commentId: 'ggggg',
      commentType: 'yt',
      postId: 'gjSyIY12C0s',
      userName: 'Messenger 15',
      pinned: false,
      comment: '不喜歡這種廣告式的貼文，感覺有點虛假。',
      sentiment: 'negative',
    },
    {
      commentId: 'hhhhh',
      commentType: 'yt',
      postId: 'gjSyIY12C0s',
      userName: 'Messenger 16',
      pinned: true,
      comment: '雖然外觀看起來不錯，但不確定品質是否能符合期待。',
      sentiment: 'negative',
    },
  ],
}
const demoFetchCommentChartAnalysisResponse: unknown = {
  sentiment: {
    negative: 0.2535885167464115,
    neutral: 0.04704944178628389,
    positive: 0.69896331738437,
    unknown: 0.00039872408293460925,
  },
  platformSentiment: [
    {
      platform: 'ig',
      sentiment: {
        negative: 0.26867219917012447,
        neutral: 0.0508298755186722,
        positive: 0.6804979253112033,
        unknown: 0,
      },
    },
    {
      platform: 'twitter',
      sentiment: {
        negative: 0,
        neutral: 0,
        positive: 0,
        unknown: 1,
      },
    },
    {
      platform: 'yt',
      sentiment: {
        negative: 0.20344827586206896,
        neutral: 0.034482758620689655,
        positive: 0.7603448275862069,
        unknown: 0.0017241379310344827,
      },
    },
  ],
  kolSentiment: [
    {
      kolUUID: 'KOL-UUID-2',
      kolName: 'Joy 喬伊吃吃喝喝',
      sentiment: {
        negative: 0.18697829716193656,
        neutral: 0.0333889816360601,
        positive: 0.7779632721202003,
        unknown: 0.001669449081803005,
      },
    },
    {
      kolUUID: 'KOL-UUID-3',
      kolName: '花兒 Flower🌸',
      sentiment: {
        negative: 0.298819255222525,
        neutral: 0.051771117166212535,
        positive: 0.6494096276112625,
        unknown: 0,
      },
    },
    {
      kolUUID: 'KOL-UUID-4',
      kolName: '小V🐳Channel',
      sentiment: {
        negative: 0.2413793103448276,
        neutral: 0.06896551724137931,
        positive: 0.6896551724137931,
        unknown: 0,
      },
    },
    {
      kolUUID: 'KOL-UUID-5',
      kolName: '妞妞每天睡過頭',
      sentiment: {
        negative: 0.24394463667820068,
        neutral: 0.04498269896193772,
        positive: 0.7110726643598616,
        unknown: 0,
      },
    },
    {
      kolUUID: 'KOL-UUID-1',
      kolName: '王小明吃美食 Xiao Min Foodie',
      sentiment: {
        negative: 0.22695035460992907,
        neutral: 0.07801418439716312,
        positive: 0.6950354609929078,
        unknown: 0,
      },
    },
  ],
}

export {
  demoReport,
  demoStatistics,
  demoTerms,
  demoComments,
  demoTermsFrequency,
  demoCrawlerStatus,
  demoFetchCommentBoardResponse,
  demoFetchCommentChartAnalysisResponse,
}
