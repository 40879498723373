import { isEqual } from 'lodash-es'
import {
  EVENT_FOLLOWER_COUNT_RANGE_RECORD,
  FOLLOWER_COUNT_RANGE_RECORD,
} from '@/components/kol/common/follower-level-selector'
import FollowerLevel from '@/types/schema/follower-level'
import { SearchForKolProperties } from '@/utils/ampli'

const mapEventPropertyFilterFollowerRange = (followerCountRange: {
  followerStartFrom?: number
  followerEndTo?: number
}): SearchForKolProperties['filterFollowerRange'] | undefined => {
  const level = Object.keys(FOLLOWER_COUNT_RANGE_RECORD).find((key) =>
    isEqual(FOLLOWER_COUNT_RANGE_RECORD[key], followerCountRange),
  ) as FollowerLevel | undefined

  return level ? EVENT_FOLLOWER_COUNT_RANGE_RECORD[level] : undefined
}

export default mapEventPropertyFilterFollowerRange
