import cloneDeep from 'lodash-es/cloneDeep'
import isUndefined from 'lodash-es/isUndefined'
import {
  PartialUserMetadata,
  UserMetadata,
  userMetadataSchema,
} from '@/types/schema/user-metadata'
import logger from '@/utils/logger'

const allVersions = ['1', '2', '3'] as const

type AllVersionsType = (typeof allVersions)[number]

const latestVersion = allVersions[allVersions.length - 1]

const defaultUserMetadata: UserMetadata = {
  version_no: latestVersion,
  completed_onboarding: false,
  pricing_announcement_date: undefined,
  read_202408_pricing_announcement: false,
  kol_ai_search_mode: true,
}

const migrations: Record<
  AllVersionsType,
  (data: PartialUserMetadata) => PartialUserMetadata
> = {
  '1': (data: PartialUserMetadata): PartialUserMetadata => {
    if (isUndefined(data.completed_onboarding)) {
      data.completed_onboarding = defaultUserMetadata.completed_onboarding
      data.pricing_announcement_date =
        defaultUserMetadata.pricing_announcement_date
    }
    return data
  },
  '2': (data: PartialUserMetadata): PartialUserMetadata => {
    if (isUndefined(data.kol_ai_search_mode)) {
      data.kol_ai_search_mode = defaultUserMetadata.kol_ai_search_mode
    }
    return data
  },
  '3': (data: PartialUserMetadata): PartialUserMetadata => {
    if (isUndefined(data.read_202408_pricing_announcement)) {
      data.read_202408_pricing_announcement =
        defaultUserMetadata.read_202408_pricing_announcement
    }
    return data
  },
}

export const isLatestVersion = (data: PartialUserMetadata): boolean => {
  return data.version_no === latestVersion
}

export const resetWithDefaultData = (
  data: PartialUserMetadata,
): UserMetadata => {
  const result = Object.keys(defaultUserMetadata).reduce(
    (acc: UserMetadata, key) => {
      if (key === 'version_no') {
        acc[key] = latestVersion
        return acc
      }

      acc[key] = data[key] ?? defaultUserMetadata[key]
      return acc
    },
    cloneDeep(defaultUserMetadata),
  )

  return result
}

const migrateData = (data: PartialUserMetadata): UserMetadata => {
  let migratedData = cloneDeep(data)

  const versionIndex = isUndefined(migratedData?.version_no)
    ? -1
    : Object.keys(migrations).indexOf(migratedData?.version_no)

  const migrationPath = Object.keys(migrations).slice(versionIndex + 1)

  for (const version of migrationPath) {
    migratedData = migrations[version](migratedData)
    migratedData.version_no = version
  }

  const parsedMigratedData = userMetadataSchema.safeParse(migratedData)

  if (!parsedMigratedData.success) {
    logger.error(parsedMigratedData.error)
    return resetWithDefaultData(data)
  }

  return parsedMigratedData.data
}

export default migrateData
