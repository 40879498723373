import { Epic, ofType } from 'redux-observable'
import { map } from 'rxjs/operators'
import { Action, ACTION_TYPES } from '@/types/redux/report'

const setAllChartData: Epic = (action$) =>
  action$.pipe(
    ofType(ACTION_TYPES.SET_REPORT),
    map((action: Action['SET_REPORT']): Action['SET_ALL_CHART_DATA'] => {
      const data = (action.payload?.reportStatistics as any) ?? []
      return {
        type: ACTION_TYPES.SET_ALL_CHART_DATA,
        payload: { data },
      }
    }),
  )

const reportEpics = [setAllChartData]

export default reportEpics
